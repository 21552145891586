import React, { useCallback } from 'react';
import Link from 'src/auth/components/Link';
import styled, { css } from 'styled-components';
import createUniqueChecker from '../../../shared/utils/createUniqueChecker';
import invariant from '../../../shared/utils/invariant';
import Color from '../../constants/Color';
import useDropdownContext from '../../hooks/useDropdownContext';
import type Option from '../../models/Option';
import CheckBox from '../CheckBox';

interface OptionsProps {
  value: any;
  options: Option[];
  onSelect: (value: Option) => void;
  multiple?: boolean;
  linkToLot?: boolean;
  dateTimeView?: boolean;
}

export default function Options(props: OptionsProps) {
  const { options, onSelect, value, multiple, linkToLot, dateTimeView } = props;
  const { close } = useDropdownContext();
  const isUnique = createUniqueChecker();

  const checkIsSelected = useCallback(
    (option: Option) => {
      if (!value) {
        return false;
      }

      if (multiple) {
        return value.some((entry: Option) => entry.key === option.key);
      }

      return value.key === option.key;
    },
    [value, multiple]
  );

  return (
    <StyledDataWrapper dateTimeView={dateTimeView}>
      {options.map((option) => {
        const { label, key, icon } = option;
        const isSelected = checkIsSelected(option);

        invariant(
          isUnique(key.toString()),
          'Key of every option has to be unique'
        );

        const onClick = () => {
          onSelect(option);

          if (!multiple) {
            close();
          }
        };

        return (
          <StyledDataContainer
            onClick={!multiple ? onClick : undefined}
            key={key}
            isSelected={!multiple && isSelected}
            dateTimeView={dateTimeView}
            multiple={multiple}
          >
            {multiple && (
              <CheckBox
                checked={isSelected}
                onClick={onClick}
                title='Select'
                readonly
                dropdown
              />
            )}
            <StyledDataLabel isSelected={isSelected} multiple={multiple}>
              <StyledProductIcon className={icon} />
              {label}
            </StyledDataLabel>
            {linkToLot && (
              <Link to={`/parking/lots/${option.key}/products`}>
                <StyledIcon
                  className='pa-android-arrow-forward'
                  isSelected={isSelected}
                />
              </Link>
            )}
          </StyledDataContainer>
        );
      })}
    </StyledDataWrapper>
  );
}

const StyledDataWrapper = styled.div<{ dateTimeView?: boolean }>`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 0px;

  ${(props) => {
    const { dateTimeView } = props;

    if (dateTimeView) {
      return css`
        max-height: 190px;
      `;
    }

    return css`
      max-height: 300px;
    `;
  }}
`;

const StyledDataContainer = styled.div<{
  isSelected: boolean;
  dateTimeView?: boolean;
  multiple?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px 14px;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;

  ${(props) => {
    const { dateTimeView } = props;

    if (dateTimeView) {
      return css`
        height: 24px;
      `;
    }

    return css`
      height: 36px;
    `;
  }}

  ${(props) => {
    const { multiple } = props;

    if (multiple) {
      return css`
        justify-content: flex-start;
      `;
    }

    return css`
      justify-content: space-between;
    `;
  }}

	${(props) => {
    const { isSelected } = props;

    if (isSelected) {
      return css`
        background-color: ${Color.PRIMARY_BRAND};

        :hover {
          background-color: ${Color.PRIMARY_HOVER};
        }
      `;
    }

    return css`
      background-color: ${Color.BACKGROUND_LIGHTEST};

      :hover {
        background-color: ${Color.BACKGROUND_LIGTH};
      }
    `;
  }}
`;

const StyledDataLabel = styled.div<{
  isSelected: boolean;
  multiple?: boolean;
}>`
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  user-select: none;
  display: flex;
  align-items: center;

  ${(props) => {
    const { isSelected, multiple } = props;

    if (isSelected && !multiple) {
      return css`
        color: ${Color.TEXT_LIGHTEST};
        font-weight: bold;
      `;
    }

    if (isSelected && multiple) {
      return css`
        color: ${Color.PRIMARY_BRAND};
      `;
    }

    return css`
      color: ${Color.TEXT_DARKER};
      font-weight: normal;
    `;
  }}
`;

const StyledIcon = styled.i<{ isSelected: boolean }>`
  color: ${Color.PRIMARY_BRAND};
  font-size: 16px;

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }

  ${(props) => {
    const { isSelected } = props;

    if (isSelected) {
      return css`
        color: ${Color.BACKGROUND_LIGHTER};

        :hover {
          color: ${Color.BACKGROUND_LIGHTEST};
        }
      `;
    }
    return css``;
  }}
`;

const StyledProductIcon = styled.i`
  font-size: 20px;
`;
