import { useCallback } from 'react';
import InvitationKeysStatuses from 'src/keysharing/constants/InvitationKeysStatuses';
import toInvitationKey from 'src/keysharing/mappers/toInvitationKey';
import toInvitationKeyResendParams from 'src/keysharing/mappers/toInvitationKeyResendParams';
import toInvitationKeyUpdateByIdParams from 'src/keysharing/mappers/toInvitationKeyUpdateByIdParams';
import type InvitationKey from 'src/keysharing/models/InvitationKey/InvitationKey';
import type InvitationKeyResendParams from 'src/keysharing/models/InvitationKey/InvitationKeyResendParams';
import type InvitationKeyResendResponse from 'src/keysharing/models/InvitationKey/InvitationKeyResendResponse';
import type InvitationKeyResendValues from 'src/keysharing/models/InvitationKey/InvitationKeyResendValues';
import type InvitationKeyResponse from 'src/keysharing/models/InvitationKey/InvitationKeyResponse';
import type InvitationKeyUpdateByIdParams from 'src/keysharing/models/InvitationKey/InvitationKeyUpdateByIdParams';
import type InvitationKeyUpdateByIdResponse from 'src/keysharing/models/InvitationKey/InvitationKeyUpdateByIdResponse';
import type InvitationKeyUpdateByIdValues from 'src/keysharing/models/InvitationKey/InvitationKeyUpdateByIdValues';
import type InvitationKeyUpdateParams from 'src/keysharing/models/InvitationKey/InvitationKeyUpdateParams';
import type InvitationKeyUpdateResponse from 'src/keysharing/models/InvitationKey/InvitationKeyUpdateResponse';

import type InvitationKeyUpdateValues from 'src/keysharing/models/InvitationKey/InvitationKeyUpdateValues';
import type KeyManagementQuery from 'src/keysharing/models/KeyManagementQuery';
import type KeyManagementQueryParams from 'src/keysharing/models/KeyManagementQueryParams';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toInvitationKeyUpdateParams from '../mappers/toInvitationKeyUpdateParams';
import toKeyManagementQuery from '../mappers/toKeyManagementQuery';

const invitationKeysBaseURL = '/key-invitations';
interface InvitationKeysService {
  listAllInvitationKeys: (
    queryParams: KeyManagementQueryParams
  ) => Promise<GetAllDetails<InvitationKey>>;

  updateInvitationKeys: (
    values: InvitationKeyUpdateValues
  ) => Promise<InvitationKeyUpdateResponse[]>;

  updateInvitationKeysById: (
    id: number,
    values: InvitationKeyUpdateByIdValues
  ) => Promise<InvitationKeyUpdateByIdResponse>;

  blockInvitationKey: (ids: number[]) => Promise<InvitationKeyUpdateResponse[]>;

  blockInvitationKeyById: (
    id: number
  ) => Promise<InvitationKeyUpdateByIdResponse>;

  resendInvitationKeys: (
    values: InvitationKeyResendValues
  ) => Promise<InvitationKeyResendResponse[]>;
}

export default function useInvitationKeysService(): InvitationKeysService {
  const { get, patch, post } = useApiService();

  const listAllInvitationKeys = useCallback(
    async (queryParams: KeyManagementQueryParams) => {
      const query = toKeyManagementQuery(queryParams);
      const response = await get<InvitationKeyResponse[], KeyManagementQuery>(
        `${invitationKeysBaseURL}`,
        query
      );
      const data = response.data.map(toInvitationKey);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const updateInvitationKeys = useCallback(
    async (values: InvitationKeyUpdateValues) => {
      const params = toInvitationKeyUpdateParams(values);

      const response = await patch<
        InvitationKeyUpdateParams,
        InvitationKeyUpdateResponse[]
      >(`${invitationKeysBaseURL}`, params);

      return response.data;
    },
    [patch]
  );

  const updateInvitationKeysById = useCallback(
    async (id: number, values: InvitationKeyUpdateByIdValues) => {
      const params = toInvitationKeyUpdateByIdParams(values);
      const response = await patch<
        InvitationKeyUpdateByIdParams,
        InvitationKeyUpdateByIdResponse
      >(`${invitationKeysBaseURL}/${id}`, params);

      return response.data;
    },
    [patch]
  );

  const blockInvitationKey = useCallback(
    (ids: number[]) =>
      updateInvitationKeys({
        ids,
        status: InvitationKeysStatuses.BLOCKED,
      }),
    [updateInvitationKeys]
  );

  const blockInvitationKeyById = useCallback(
    (id: number) =>
      updateInvitationKeysById(id, {
        status: InvitationKeysStatuses.BLOCKED,
      }),
    [updateInvitationKeysById]
  );

  const resendInvitationKeys = useCallback(
    async (values: InvitationKeyResendValues) => {
      const params = toInvitationKeyResendParams(values);
      const response = await post<
        InvitationKeyResendParams,
        InvitationKeyResendResponse[]
      >(`${invitationKeysBaseURL}/resend`, params);

      return response.data;
    },
    [post]
  );

  return {
    listAllInvitationKeys,
    updateInvitationKeys,
    updateInvitationKeysById,
    blockInvitationKey,
    blockInvitationKeyById,
    resendInvitationKeys,
  };
}
