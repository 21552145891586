import type Error from 'src/lots/models/Error';
import type ErrorResponse from 'src/lots/models/ErrorResponse';
import toProductError from './toProductError';

export default function toError(data: ErrorResponse): Error {
  const {
    id,
    error_code,
    jammed,
    sensor_dirty,
    pin_broken,
    rtcc_invalid,
    battery_empty,
    master_key_not_set,
    deleted_at,
    created_at,
    updated_at,
    product,
  } = data;

  return {
    id,
    errorCode: error_code,
    jammed,
    sensorDirty: sensor_dirty,
    pinBroken: pin_broken,
    rtccInvalid: rtcc_invalid,
    batteryEmpty: battery_empty,
    masterKeyNotSet: master_key_not_set,
    deletedAt: deleted_at,
    createdAt: created_at,
    updatedAt: updated_at,
    product: toProductError(product),
  };
}
