import type ErrorType from '../models/ErrorType';

export default function formatErrors(errors: ErrorType[]): {
  [key: string]: string;
} {
  return errors.reduce((acc: any, item: any) => {
    if (!item.field) {
      // TODO refactor this when we have more than one error in array
      return { ...acc, ...item };
    }

    const key = item.field
      .split('_')
      .reduce((acc: string, item: string, index: number) => {
        if (index === 0) {
          return item;
        }

        const first = item.slice(0, 1);
        const rest = item.slice(1).split('.')[0];

        return `${acc}${first.toUpperCase()}${rest}`;
      }, '');
    return { ...acc, [key]: item.message };
  }, {});
}
