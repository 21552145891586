import React, { useCallback, useEffect, useState } from 'react';
import type BoundLicense from 'src/lots/models/BoundLicense';
import useProductService from 'src/products/services/useProductService';
import Content from 'src/shared/components/Content';
import StyledNoData from 'src/shared/components/StyledNoData';
import ActionIcon from 'src/shared/constants/ActionIcon';
import PaginationSize from 'src/shared/constants/DataSize';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import Button from '../../shared/components/Button';
import Card from '../../shared/components/Card';
import CheckBox from '../../shared/components/CheckBox';
import Control from '../../shared/components/Control';
import Main from '../../shared/components/Main';
import { useGlobalConfirmationModal } from '../../shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../shared/components/Modals/GlobalSuccessModal';
import Pagination from '../../shared/components/Pagination';
import StyledBlockIcon from '../../shared/components/StyledBlockIcon';
import Table from '../../shared/components/Table';
import Title from '../../shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';
import PaginationItemDisplay from '../../shared/constants/PaginationItemDisplay';
import useIsMounted from '../../shared/hooks/useIsMounted';
import type Meta from '../../shared/models/Meta';

interface BoundLicensesTableProps {
  productId: number;
  modalView?: boolean;
}

export default function BoundLicensesTable(props: BoundLicensesTableProps) {
  const { modalView, productId } = props;
  const { listAllBoundLicenses, unbindLicense } = useProductService();
  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState<BoundLicense[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);
  const [activeId, setActiveId] = useState<number | undefined>();
  const [selectedPlates, setSelectedPlates] = useState<number[]>([]);

  const { currentPage } = meta;

  const getBoundLicenses = useCallback(
    async (page: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data, meta } = await listAllBoundLicenses(productId, {
          page,
          size: PaginationSize.STANDARD,
        });

        if (isMounted()) {
          setData(data);
          setSelectedPlates([]);
          setIsDataLoading(false);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [listAllBoundLicenses, isMounted, productId]
  );

  useEffect(() => {
    getBoundLicenses(1);
  }, [getBoundLicenses]);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: successMessage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onRemoveAllBindings = useCallback(async () => {
    try {
      if (selectedPlates.length === 0) return;

      await Promise.all(
        selectedPlates.map((plate) => unbindLicense(productId, plate))
      );

      if (selectedPlates.length === data.length && currentPage > 1) {
        getBoundLicenses(currentPage - 1);
      }
      getBoundLicenses(currentPage);

      if (isMounted()) {
        setSuccessMessage('All licenses are removed successfully!');
        setSelectedPlates([]);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    productId,
    isMounted,
    selectedPlates,
    unbindLicense,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    currentPage,
    data,
    getBoundLicenses,
  ]);

  const updateSelectablePlates = useCallback((id: number) => {
    setSelectedPlates((selectedPlates) => {
      if (selectedPlates.includes(id)) {
        return selectedPlates.filter((selectedPlate) => selectedPlate !== id);
      }
      return [...selectedPlates, id];
    });
  }, []);

  const onSelectAllSelectablePlates = useCallback(() => {
    if (selectedPlates.length === data.length) {
      setSelectedPlates([]);
      return;
    }

    if (data.length === 0) {
      return;
    }

    const selectablePlatesIds = data.map((plate) => plate.id);

    setSelectedPlates(selectablePlatesIds);
  }, [data, selectedPlates]);

  const { openGlobalConfirmationModal: openRemoveAllModal } =
    useGlobalConfirmationModal({
      action: onRemoveAllBindings,
      message:
        'Are you sure you want to remove all selected License Plates? \n This action is permanent and can not be undone.',
    });

  const onRemoveBinding = useCallback(
    async (id: number) => {
      try {
        await unbindLicense(productId, id);

        if (data.length === 1 && currentPage > 1) {
          getBoundLicenses(currentPage - 1);
        }

        getBoundLicenses(currentPage);

        if (isMounted()) {
          setSuccessMessage('License plate is removed successfully!');
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      productId,
      isMounted,
      unbindLicense,
      data,
      currentPage,
      getBoundLicenses,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  const { openGlobalConfirmationModal: openRemoveOneModal } =
    useGlobalConfirmationModal({
      action: onRemoveBinding,
      message:
        'Are you sure you want to remove selected License Plates? \n This action is permanent and can not be undone.',
      values: activeId,
    });

  const openConfirmationModal = useCallback(
    async (id: number) => {
      if (isMounted()) {
        setActiveId(id);
        openRemoveOneModal();
      }
    },
    [openRemoveOneModal, isMounted]
  );

  return (
    <Main modalView={modalView}>
      {!modalView && <Title>License Plates</Title>}
      <Content widthSize={modalView ? '100%' : '70%'} addTopMargin={!modalView}>
        <Card sticky modalView={modalView}>
          <Control left>
            <CheckBox
              checked={data.length > 0 && data.length === selectedPlates.length}
              onClick={onSelectAllSelectablePlates}
              disabled={data.length === 0}
              readonly
              title='Select All'
            />
            {selectedPlates.length > 0 ? (
              <Button size={ButtonSize.MIDDLE} onClick={openRemoveAllModal}>
                Remove access
              </Button>
            ) : null}
          </Control>
        </Card>
        <Card modalView={modalView}>
          <Table isLoading={isDataLoading}>
            <Table.Colgroup>
              <col span={1} style={{ width: '10%' }} />
              <col span={1} style={{ width: '80%' }} />
              <col span={1} style={{ width: '10%' }} />
            </Table.Colgroup>
            <Table.Head>
              <Table.Row>
                <Table.Header />
                <Table.Header>LICENSE PLATE</Table.Header>
                <Table.Header />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {data.length > 0 ? (
                data.map((licensePlateData) => {
                  const { id, licensePlate } = licensePlateData;

                  const selected = selectedPlates.includes(id);

                  const selectId = () => {
                    updateSelectablePlates(id);
                  };

                  const onRemoveClick = () => {
                    openConfirmationModal(id);
                  };

                  return (
                    <Table.Row key={id}>
                      <Table.Cell>
                        <CheckBox
                          checked={selected}
                          onClick={selectId}
                          readonly
                          title='Select'
                        />
                      </Table.Cell>
                      <Table.Cell title={licensePlate} short>
                        {licensePlate}
                      </Table.Cell>
                      <Table.Cell right>
                        <StyledBlockIcon
                          className={ActionIcon.DELETE}
                          onClick={onRemoveClick}
                          data-testid='removeIcon'
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell merged={4}>
                    <StyledNoData>No bound license plates.</StyledNoData>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card>
        {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
          <Pagination meta={meta} getData={getBoundLicenses} />
        )}
      </Content>
    </Main>
  );
}
