import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import type LotWhitelistLog from 'src/anpr/models/LotWhitelistLog';
import type LotWhitelistLogFilterValues from 'src/anpr/models/LotWhitelistLogFilterValues';

import { StyledIcon } from 'src/shared/components/Breadcrumb';
import StyledLink from 'src/shared/components/BreadcrumbLink';
import Card from 'src/shared/components/Card';
import Content from 'src/shared/components/Content';
import Form from 'src/shared/components/Form';
import LotWhitelistLogsFilter from 'src/shared/components/LotWhitelistLogsFilter';
import Main from 'src/shared/components/Main';
import Pagination from 'src/shared/components/Pagination';
import StyledCell from 'src/shared/components/StyledCell';
import StyledNoData from 'src/shared/components/StyledNoData';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import PaginationSize from 'src/shared/constants/DataSize';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import PaginationItemDisplay from 'src/shared/constants/PaginationItemDisplay';
import capitalizeFirstLetter from 'src/shared/utils/capitalizeFirstLetter';
import { formatDate, formatTime } from 'src/shared/utils/formatDateTime';
import formatOperationTokenDates from 'src/shared/utils/formatOperationTokenDates';
import styled from 'styled-components';
import * as yup from 'yup';
import useIsMounted from '../../shared/hooks/useIsMounted';
import type Meta from '../../shared/models/Meta';
import useLogsSerivce from '../services/useLogsSerivce';

const initialValues: LotWhitelistLogFilterValues = {
  startTime: undefined,
  endTime: undefined,
  accountUserId: '',
  accountUserName: '',
  licensePlate: '',
};

const validationSchema = yup.object().shape({
  startTime: yup.date().nullable().notRequired(),
  endTime: yup
    .date()
    .nullable()
    .notRequired()
    .when('startTime', {
      is: (startTime: Date | null) => startTime !== null,
      then: yup
        .date()
        .nullable()
        .notRequired()
        .min(yup.ref('startTime'), "End Date can't be before Start Date"),
    }),
});

export default function LotWhitelistLogs() {
  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<LotWhitelistLog[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);
  const { getAllLotWhitelistLogs } = useLogsSerivce();

  const { params } = useRouteMatch<{ lotId: string }>();
  const { lotId } = params;

  const [filterValues, setFilterValues] = useState<LotWhitelistLogFilterValues>(
    {
      startTime: undefined,
      endTime: undefined,
      accountUserId: '',
      accountUserName: '',
      licensePlate: '',
    }
  );

  const getLotWhitelistLogs = useCallback(
    async (page: number) => {
      try {
        if (!lotId) {
          return;
        }

        if (isMounted()) {
          setIsDataLoading(true);
        }

        const {
          endTime,
          startTime,
          accountUserId,
          accountUserName,
          licensePlate,
        } = filterValues;

        const { data, meta } = await getAllLotWhitelistLogs({
          page,
          size: PaginationSize.STANDARD,
          lotId: parseInt(lotId),
          startTime,
          endTime,
          accountUserId: accountUserId ? parseInt(accountUserId) : undefined,
          accountUserName: accountUserName || undefined,
          licensePlate: licensePlate || undefined,
        });

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [getAllLotWhitelistLogs, isMounted, filterValues, lotId]
  );

  useEffect(() => {
    getLotWhitelistLogs(1);
  }, [getLotWhitelistLogs]);

  const onSubmit = useCallback(
    async (values: LotWhitelistLogFilterValues) => {
      const {
        startTime,
        endTime,
        accountUserId,
        accountUserName,
        licensePlate,
      } = values;

      if (isMounted()) {
        setFilterValues({
          startTime,
          endTime,
          accountUserId,
          accountUserName,
          licensePlate,
        });
      }
    },
    [isMounted]
  );

  const onResetAllFilters = useCallback(() => {
    if (isMounted()) {
      setFilterValues({
        startTime: undefined,
        endTime: undefined,
        accountUserId: '',
        accountUserName: '',
        licensePlate: '',
      });
    }
  }, [isMounted]);

  return (
    <>
      <Main>
        <StyledLink to={`/parking/lots/${lotId}/shared/anpr`}>
          <StyledIcon className='pa-chevron-left' small />
          <Title>ANPR access list</Title>
        </StyledLink>
      </Main>
      <Main>
        <Content widthSize='27%'>
          <Form
            name='Filter'
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <LotWhitelistLogsFilter onResetAllFilters={onResetAllFilters} />
          </Form>
        </Content>
        <Content widthSize='70%'>
          <Card>
            <Table isLoading={isDataLoading}>
              <Table.Head>
                <Table.Row>
                  <Table.Header>LICENSE PLATE</Table.Header>
                  <Table.Header>OPERATOR</Table.Header>
                  <Table.Header>SHARED ACCESS</Table.Header>
                  <Table.Header>ACTION</Table.Header>
                  <Table.Header>DATE</Table.Header>
                  <Table.Header>TIME</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {data.length > 0 ? (
                  data.map((logs) => {
                    const {
                      id,
                      createdAt,
                      accountUserId,
                      licensePlate,
                      startTime,
                      endTime,
                      operation,
                      accountUser,
                    } = logs;

                    const { name } = accountUser;

                    return (
                      <Table.Row key={id}>
                        <Table.Cell>{licensePlate}</Table.Cell>
                        <Table.Cell>
                          <StyledSpan>{accountUserId}</StyledSpan>
                          <span>{name}</span>
                        </Table.Cell>
                        <Table.Cell minWidth>
                          <StyledCell>
                            Start time:
                            <br />
                            End time:
                          </StyledCell>
                          <div>
                            {formatOperationTokenDates(startTime)}
                            <br />
                            {formatOperationTokenDates(endTime)}
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {capitalizeFirstLetter(operation)}
                        </Table.Cell>
                        <Table.Cell>{formatDate(createdAt)}</Table.Cell>
                        <Table.Cell>{formatTime(createdAt)}</Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell merged={5}>
                      <StyledNoData>No Anpr Shared Access Logs.</StyledNoData>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card>
          {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
            <Pagination meta={meta} getData={getLotWhitelistLogs} />
          )}
        </Content>
      </Main>
    </>
  );
}

const StyledSpan = styled.span`
  margin-right: 10px;
`;
