import type RotaryParkingPlaceCreateRequest from 'src/rotaries/models/RotaryParkingPlace/RotaryParkingPlaceCreateRequest';
import type RotaryParkingPlaceFormValues from 'src/rotaries/models/RotaryParkingPlace/RotaryParkingPlaceFormValues';

export default function toRotaryParkingPlaceCreateRequest(
  data: RotaryParkingPlaceFormValues,
  rotaryId: number
): RotaryParkingPlaceCreateRequest {
  const { name } = data;

  return {
    name,
    rotary_id: rotaryId,
  };
}
