import type LotEntry from 'src/anpr/models/LotEntries/LotEntry';
import type LotEntryResponse from 'src/anpr/models/LotEntries/LotEntryResponse';
import toLprCamera from './toLprCamera';
import toRelatedLotEntry from './toRelatedLotEntry';

export default function toLotEntry(data: LotEntryResponse): LotEntry {
  const {
    id,
    scan_time,
    lpr_camera_id,
    lot_id,
    is_allowed,
    related_lot_entry_id,
    duration,
    direction,
    license_plate,
    license_plate_image_url,
    full_image_url,
    created_at,
    updated_at,
    lpr_camera,
    related_lot_entry,
  } = data;

  return {
    id,
    scanTime: scan_time,
    lprCameraId: lpr_camera_id,
    lotId: lot_id,
    isAllowed: !!is_allowed,
    relatedLotEntryId: related_lot_entry_id,
    duration,
    direction,
    licensePlate: license_plate,
    licensePlateImageUrl: license_plate_image_url,
    fullImageUrl: full_image_url,
    createdAt: created_at,
    updatedAt: updated_at,
    lprCamera: toLprCamera(lpr_camera),
    relatedLotEntry: related_lot_entry
      ? toRelatedLotEntry(related_lot_entry)
      : null,
  };
}
