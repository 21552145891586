import { useMonth } from '@datepicker-react/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Months from 'src/shared/constants/Months';
import styled from 'styled-components';
import ArrowDirection from '../../constants/ArrowDirection';
import Color from '../../constants/Color';
import useDatePickerContext from '../../hooks/useDatePickerContext';
import type Option from '../../models/Option';
import SelectFieldWithoutFormik from '../SelectField/SelectFieldWithoutFormik';
import Arrow from './Arrow';
import Day from './Day';

interface CalendarProps {
  setIsAdditionalDropdownOpen?: (value: boolean) => void;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  dateTimeView?: boolean;
}

const NUMBER_OF_YEARS = 10;
const CURRENT_DATE = new Date();

export default function Calendar(props: CalendarProps) {
  const {
    setIsAdditionalDropdownOpen,
    dateTimeView,
    minBookingDate,
    maxBookingDate,
  } = props;
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);

  useEffect(() => {
    if (setIsAdditionalDropdownOpen) {
      setIsAdditionalDropdownOpen(isMonthDropdownOpen || isYearDropdownOpen);
    }
  }, [setIsAdditionalDropdownOpen, isMonthDropdownOpen, isYearDropdownOpen]);

  const {
    activeMonths,
    firstDayOfWeek,
    goToNextMonths,
    goToPreviousMonths,
    goToDate,
  } = useDatePickerContext();
  const [{ month, year }] = activeMonths;
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  const goToMonth = useCallback(
    (value: Option | undefined) => {
      if (!value) {
        return;
      }

      const newMonth = new Date(CURRENT_DATE.setFullYear(year, value.key));
      goToDate(newMonth);
    },
    [goToDate, year]
  );

  const goToYear = useCallback(
    (value: Option | undefined) => {
      if (!value) {
        return;
      }
      const newYear = new Date(CURRENT_DATE.setFullYear(value.key, month));
      goToDate(newYear);
    },
    [goToDate, month]
  );

  const months = useMemo(() => {
    if (year === minBookingDate?.getFullYear()) {
      return Months.filter((month) => month.key >= minBookingDate.getMonth());
    }
    if (year === maxBookingDate?.getFullYear()) {
      return Months.filter((month) => month.key <= maxBookingDate.getMonth());
    }
    return Months;
  }, [year, minBookingDate, maxBookingDate]);

  const years: Option[] = useMemo(() => {
    let tmp: Option[] = [];
    const year = new Date().getFullYear();
    let yearsRange = NUMBER_OF_YEARS;
    if (minBookingDate) {
      const range = Math.abs(minBookingDate.getFullYear() - year);
      if (range > yearsRange) {
        yearsRange = range;
      }
    }
    if (maxBookingDate) {
      const range = Math.abs(maxBookingDate.getFullYear() - year);
      if (range > yearsRange) {
        yearsRange = range;
      }
    }

    const startYear = year - yearsRange;
    const endYear = year + yearsRange;

    for (let i = startYear; i <= endYear; i++) {
      tmp.push({
        key: i,
        label: i.toString(),
      });
    }

    if (minBookingDate) {
      const index = tmp.findIndex(
        (x) => x.key === minBookingDate.getFullYear()
      );
      if (index === -1) {
        return tmp;
      }
      tmp = tmp.slice(index);
    }

    if (maxBookingDate) {
      const index = tmp.findIndex(
        (x) => x.key === maxBookingDate.getFullYear()
      );
      if (index === -1) {
        return tmp;
      }
      tmp = tmp.slice(0, index + 1);
    }

    return tmp;
  }, [minBookingDate, maxBookingDate]);

  const options = useMemo(() => months, [months]);

  const initialMonthValue = useMemo(() => {
    const initialMonth = options.find((m) => m.key === month);
    if (initialMonth) {
      return {
        key: initialMonth.key,
        label: initialMonth.label,
      };
    }
    return {
      key: options[0].key,
      label: options[0].label,
    };
  }, [month, options]);

  const initialYearValue = useMemo(
    () => ({
      key: year,
      label: year.toString(),
    }),
    [year]
  );

  useEffect(() => {
    goToMonth(initialMonthValue);
  }, [initialMonthValue, goToMonth]);

  const disabled = useMemo(() => {
    const tmp = {
      LEFT: false,
      RIGHT: false,
    };
    if (minBookingDate) {
      tmp.LEFT =
        year <= minBookingDate?.getFullYear() &&
        month <= minBookingDate?.getMonth();
    }
    if (maxBookingDate) {
      tmp.RIGHT =
        year >= maxBookingDate?.getFullYear() &&
        month >= maxBookingDate?.getMonth();
    }

    return tmp;
  }, [minBookingDate, maxBookingDate, month, year]);

  return (
    <>
      <StyledNavigation>
        <Arrow
          disabled={disabled?.LEFT}
          onClick={goToPreviousMonths}
          arrowDirection={ArrowDirection.LEFT}
        />
        {dateTimeView && (
          <>
            <SelectFieldWithoutFormik
              name='month'
              label=''
              placeholder='Month'
              initialValue={initialMonthValue}
              options={options}
              onChange={goToMonth}
              setIsAdditionalDropdownOpen={setIsMonthDropdownOpen}
              dateTimeView={dateTimeView}
              hideLabel
            />
            <SelectFieldWithoutFormik
              name='year'
              label=''
              placeholder='Year'
              initialValue={initialYearValue}
              options={years}
              onChange={goToYear}
              setIsAdditionalDropdownOpen={setIsYearDropdownOpen}
              dateTimeView={dateTimeView}
              yearView
              hideLabel
            />
          </>
        )}
        {!dateTimeView && <StyledTitle>{monthLabel}</StyledTitle>}
        <Arrow
          disabled={disabled?.RIGHT}
          onClick={goToNextMonths}
          arrowDirection={ArrowDirection.RIGTH}
        />
      </StyledNavigation>
      <StyledLabels>
        {weekdayLabels.map((label) => (
          <StyledLabel key={label}>{label}</StyledLabel>
        ))}
      </StyledLabels>
      <StyledDays>
        {days.map((day, index) => {
          if (typeof day === 'number') {
            return <StyledBlank key={`${index}-${day}`} />;
          }

          const { dayLabel, date } = day;

          return (
            <Day key={`${index}-${dayLabel}`} date={date} dayLabel={dayLabel} />
          );
        })}
      </StyledDays>
    </>
  );
}

const StyledLabel = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${Color.TEXT_DARKER};
  margin: 0px;
`;

const StyledTitle = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${Color.TEXT_DARKER};
  margin: 0px;
`;

const StyledLabels = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 10px;
`;

const StyledNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 10px 10px;
`;

const StyledBlank = styled.div`
  display: flex;
  height: 30px;
  width: calc(100% / 7);
`;

const StyledDays = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px 10px 20px 10px;
`;
