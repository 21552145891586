import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'src/shared/components/Form';
import { useGlobalConfirmationModal } from 'src/shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import * as yup from 'yup';
import AppRole from '../../shared/constants/AppRole';
import PhoneValidation from '../../shared/constants/PhoneValidation';
import { useGlobalModal } from '../../shared/hooks/useGlobalModal';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useUserHasRole from '../../shared/hooks/useUserHasRole';
import { isNotString } from '../../shared/utils/checks';
import AccountUserForm from '../components/AccountUserForm';
import AccountUserTable from '../components/AccountUserTable';
import toAccountUserFormValues from '../mappers/toAccountUserFormValues';
import type AccountUser from '../models/AccountUser';
import type AccountUserFormValues from '../models/AccountUserFormValues';
import useAccountUserService from '../services/useAccountUserService';

const defaultValues: AccountUserFormValues = {
  role: null,
  name: '',
  email: '',
  phone: '',
};

interface Props {
  accountId: number;
}

export default function AccountUsersList(props: Props) {
  const { accountId } = props;
  const [data, setData] = useState<AccountUser[]>([]);
  const [activeId, setActiveId] = useState<number | undefined>();
  const [initialValues, setInitialValues] =
    useState<AccountUserFormValues>(defaultValues);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const {
    findAllAccountUsers,
    findOneAccountUser,
    updateAccountUser,
    removeAccountUser,
    findAccountUsersById,
  } = useAccountUserService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();

  const userCanReadUserRole = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        role: userCanReadUserRole
          ? yup.object().nullable().required('Role is a required field')
          : yup.object().nullable(),
        name: yup.string().required('Name is a required field'),
        email: yup
          .string()
          .email('Enter a valid email address')
          .required('Email is a required field'),
        phone: yup
          .string()
          .trim()
          .matches(PhoneValidation, 'Enter a valid phone number'),
      }),
    [userCanReadUserRole]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        if (userIsParklioInternal) {
          const { data } = await findAccountUsersById(accountId);
          if (isMounted()) {
            setData(data);
            setIsDataLoading(false);
          }
        } else {
          const { data } = await findAllAccountUsers();
          if (isMounted()) {
            setData(data);
            setIsDataLoading(false);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [
    findAllAccountUsers,
    findAccountUsersById,
    userIsParklioInternal,
    accountId,
    isMounted,
  ]);

  const getChangedValues = useCallback(
    (values: AccountUserFormValues) => ({
      email: values.email !== initialValues.email ? values.email : undefined,
      name: values.name !== initialValues.name ? values.name : undefined,
      role:
        values.role?.key !== initialValues.role?.key ? values.role : undefined,
      phone: values.phone !== initialValues.phone ? values.phone : undefined,
    }),
    [initialValues]
  );

  const onRemove = useCallback(async () => {
    if (activeId) {
      await removeAccountUser(activeId);

      const { data } = await findAllAccountUsers();

      if (isMounted()) {
        setData(data);
      }

      setActiveId(undefined);
    }
  }, [setData, findAllAccountUsers, removeAccountUser, isMounted, activeId]);

  const [openEditUserModal, closeEditUserModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='accountUser'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        isLoading={areDetailsLoading}
      >
        <AccountUserForm closeParentModal={closeEditModal} />
      </Form>
    </GlobalModal>
  ));

  const closeEditModal = useCallback(() => {
    closeEditUserModal();
    setActiveId(undefined);
    setInitialValues(defaultValues);
  }, [closeEditUserModal]);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal: closeEditModal,
    message: 'Successfuly updated existing user!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onRemove,
    message: 'Are you sure you want to remove this user?',
  });

  const openEditModal = useCallback(
    async (id: number) => {
      try {
        if (isMounted()) {
          openEditUserModal();
          setAreDetailsLoading(true);
        }

        const response = await findOneAccountUser(id);

        const values = toAccountUserFormValues(response);

        if (isMounted()) {
          setAreDetailsLoading(false);
          setInitialValues(values);
          setActiveId(id);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    },
    [findOneAccountUser, isMounted, openEditUserModal]
  );

  const openRemoveModal = useCallback(
    (id: number) => {
      openGlobalConfirmationModal();
      setActiveId(id);
    },
    [openGlobalConfirmationModal]
  );

  const onSubmit = useCallback(
    async (
      values: AccountUserFormValues,
      { setErrors }: FormikHelpers<AccountUserFormValues>
    ) => {
      try {
        if (!activeId) return;

        const valuesToUpdate = getChangedValues(values);

        await updateAccountUser(activeId, valuesToUpdate);

        const { data } = await findAllAccountUsers();

        if (isMounted()) {
          setData(data);
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      activeId,
      isMounted,
      updateAccountUser,
      findAllAccountUsers,
      getChangedValues,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  return (
    <AccountUserTable
      data={data}
      isLoading={isDataLoading}
      openEditModal={openEditModal}
      openRemoveModal={openRemoveModal}
      accountId={accountId}
    />
  );
}
