import styled from 'styled-components';
import Color from '../constants/Color';

const Text = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: ${Color.TEXT_DARKER};

  :last-child {
    margin: 30px 0px 40px 0px;
  }
`;

export default Text;
