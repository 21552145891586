import type Weblink from '../models/Weblink/Weblink';
import type WeblinkResponse from '../models/Weblink/WeblinkResponse';
import toKeyProduct from './toKeyProduct';
import toOperationToken from './toOperationToken';

export default function toWeblink(data: WeblinkResponse): Weblink {
  const {
    id,
    sender_id,
    receiver_identifier,
    product_id,
    created_at,
    updated_at,
    operation_token,
    product,
  } = data;

  return {
    id,
    senderId: sender_id,
    receiverIdentifier: receiver_identifier,
    productId: product_id,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    product: toKeyProduct(product),
    operationToken: toOperationToken(operation_token),
  };
}
