import OccupancyStatuses from 'src/products/constants/OccupancyStatuses';
import Color from 'src/shared/constants/Color';

export const getDetectionStatusColor = (
  occupancyStatus: OccupancyStatuses,
  isLotOnline: boolean
) => {
  if (!isLotOnline) {
    return Color.PARKING_PLACE_UNKNOWN;
  }

  switch (occupancyStatus) {
    case OccupancyStatuses.FREE:
      return Color.PARKING_PLACE_FREE;
    case OccupancyStatuses.OCCUPIED:
      return Color.PARKING_PLACE_OCCUPIED;
    case OccupancyStatuses.UNKNOWN:
      return Color.PARKING_PLACE_UNKNOWN;
    default:
      return Color.PARKING_PLACE_UNKNOWN;
  }
};
