import type RotaryCreateRequest from 'src/rotaries/models/RotaryCreateRequest';
import type RotaryFormValues from 'src/rotaries/models/RotaryFormValues';

export default function toRotaryCreateRequest(
  data: RotaryFormValues,
  lotId?: number
): RotaryCreateRequest {
  const { name, address } = data;

  return {
    name,
    address,
    lot_id: lotId,
  };
}
