import type LotDetectionChangeEvent from 'src/detection/socketEvents/LotDetectionChangeEvent';
import type LotDetectionChangeEventPayload from 'src/detection/socketEvents/LotDetectionChangeEventPayload';
import toLotDetectionChangeEventData from './toLotDetectionChangeEventData';

export default function toLotDetectionChangeEvent(
  data: LotDetectionChangeEventPayload
): LotDetectionChangeEvent {
  const { lots } = data;

  return {
    lots: lots.map(toLotDetectionChangeEventData),
  };
}
