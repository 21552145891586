import type Country from '../models/Country';
import type CountryResponse from '../models/CountryResponse';

export default function toCountry(data: CountryResponse): Country {
  const { id, name, code, created_at, updated_at } = data;

  return {
    id,
    name,
    code,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
