import type { FirstDayOfWeek, MonthType } from '@datepicker-react/hooks';
import { createContext } from 'react';

interface DatePickerContextData {
  focusedDate: Date | null;
  firstDayOfWeek: FirstDayOfWeek;
  activeMonths: MonthType[];
  isDateFocused: (date: Date) => boolean;
  isDateSelected: (date: Date) => boolean;
  isDateHovered: (date: Date) => boolean;
  isDateBlocked: (date: Date) => boolean;
  isFirstOrLastSelectedDate: (date: Date) => boolean;
  onDateFocus: (date: Date) => void;
  onDateHover: (date: Date) => void;
  onDateSelect: (date: Date) => void;
  goToPreviousMonths: () => void;
  goToNextMonths: () => void;
  goToDate: (date: Date) => void;
}

const initialDatePickerContextData: DatePickerContextData = {
  focusedDate: null,
  firstDayOfWeek: 0,
  activeMonths: [],
  isDateFocused: () => false,
  isDateSelected: () => false,
  isDateHovered: () => false,
  isDateBlocked: () => false,
  isFirstOrLastSelectedDate: () => false,
  onDateFocus: () => ({}),
  onDateHover: () => ({}),
  onDateSelect: () => ({}),
  goToPreviousMonths: () => ({}),
  goToNextMonths: () => ({}),
  goToDate: () => ({}),
};

const DatePickerContext = createContext(initialDatePickerContextData);

export default DatePickerContext;
