import type Error from 'src/shared/models/Error';

export default function handleErrors(errors: Error[]) {
  const error = errors.find((e) => e.code === 9);

  if (error) {
    throw error;
  }

  const badCredentials = {
    email: 'Wrong email and/or password!',
    password: 'Wrong email and/or password!',
  };

  throw badCredentials;
}
