import React from 'react';
import { NavLink } from 'react-router-dom';
import type { NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import Color from '../../constants/Color';

interface LinkProps {
  to: string;
  exact?: boolean;
  children: React.ReactNode;
}

export default function Link(props: LinkProps) {
  const { children, to, exact } = props;

  return (
    <StyledLink to={to} activeClassName='active' exact={exact}>
      {children}
    </StyledLink>
  );
}

const StyledLink = styled(NavLink)<NavLinkProps>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: ${Color.TEXT_DARKER};
  padding: 10px;

  &.active {
    border-radius: 5px;
    background-color: ${Color.BACKGROUND_LIGTH};
    color: ${Color.TEXT_BRAND};
    :hover {
      color: ${Color.PRIMARY_HOVER};
    }
  }
`;
