import { useFormikContext } from 'formik';
import React from 'react';
import Button from 'src/shared/components/Button';
import ExitButton from 'src/shared/components/Buttons/ExitButton';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import Section from 'src/shared/components/Section';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';

interface ZoneAddModalProps {
  closeParentModal: () => void;
}

export default function ZoneAddModal(props: ZoneAddModalProps) {
  const { closeParentModal } = props;
  const { dirty } = useFormikContext();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Section>
        <Title>Create a new zone</Title>
        <TextField
          autofocus
          label='Name'
          placeholder='Enter zone name'
          type='text'
          name='name'
          stacked
          short
        />
      </Section>
      <Line />
      <Control>
        <Button
          size={ButtonSize.LARGE}
          type='button'
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
        >
          Cancel
        </Button>
        <Button size={ButtonSize.LARGE} type='submit' primary addZoneSize>
          Create a new zone
        </Button>
      </Control>
    </>
  );
}
