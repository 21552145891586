import Color from 'src/shared/constants/Color';
import styled, { css } from 'styled-components';

export const StyledDateTimeWrapper = styled.div<{ block?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0px 0px 0px;
  width: fit-content;

  ${(props) => {
    const { block } = props;

    if (block) {
      return css`
        width: 100%;
        margin: 15px 0px 0px 0px;
      `;
    }
    return css``;
  }}

  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const StyledColumn = styled.div<{ tariffView?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${(props) => {
    const { tariffView } = props;

    if (tariffView) {
      return css`
        margin-right: 0px;
      `;
    }

    return css`
      margin-right: 10px;
    `;
  }}
`;

export const StyledDateTimeContainer = styled.div<{ block?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${(props) => {
    const { block } = props;

    if (block) {
      return css`
        display: block;
      `;
    }
    return css``;
  }}

  @media (max-width: 500px) {
    display: block;
  }
`;

export const StyledDateContainer = styled.div<{ disabled?: boolean }>`
  height: 36px;
  border: 1px solid ${Color.BORDER_LIGHT};
  background-color: ${Color.BACKGROUND_LIGHTEST};
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;

  @media (max-width: 500px) {
    width: 75%;
  }

  :focus {
    outline: none;
  }

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        background-color: ${Color.BACKGROUND_LIGTH};
        cursor: default;
      `;
    }
    return css``;
  }}
`;

export const StyledTimeContainer = styled.div<{
  block?: boolean;
  disabled?: boolean;
}>`
  height: 36px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;

  ${(props) => {
    const { block } = props;

    if (block) {
      return css`
        margin-top: 5px;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        background-color: ${Color.BACKGROUND_LIGTH};
        cursor: default;
      `;
    }
    return css``;
  }}

    @media (max-width: 500px) {
    width: 75%;
    margin-top: 5px;
  }
`;

export const StyledInput = styled.input`
  height: 36px;
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding: 0px 14px;
  color: ${Color.TEXT_DARKER};
  background-color: ${Color.BACKGROUND_LIGHTEST};
  width: 100%;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${Color.TEXT_LIGHT};
  }

  :disabled {
    background-color: ${Color.BACKGROUND_LIGTH};
  }
`;

export const StyledText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding: 0px 14px;
  margin: 0px;
  color: ${Color.TEXT_DARKER};
`;

export const StyledPlaceholder = styled(StyledText)`
  color: ${Color.TEXT_LIGHT};
`;

export const StyledReadonlyData = styled.p`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: 36px;
  color: ${Color.TEXT_DARKER};
`;

export const StyledDateFieldColumn = styled.div<{
  short?: boolean;
  small?: boolean;
  modalView?: boolean;
}>`
  position: relative;
  flex-direction: column;

  ${(props) => {
    const { short, small, modalView } = props;

    if ((!short && !small) || modalView) {
      return css`
        width: 100%;
      `;
    }

    if (small) {
      return css`
        width: 200px;
      `;
    }

    return css`
      width: 300px;
    `;
  }}
`;

export const StyledWrapper = styled.div<{
  stacked?: boolean;
  short?: boolean;
  modalView?: boolean;
  tariffView?: boolean;
}>`
  align-items: flex-start;
  margin: 30px 0px 0px 0px;

  @media (max-width: 999px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }

  ${(props) => {
    const { short, modalView } = props;

    if (short && !modalView) {
      return css`
        width: max-content;
      `;
    }

    if (modalView) {
      return css`
        width: 25%;
        margin-right: 15px;
        margin-top: 15px;
        display: inline-block;
      `;
    }

    return css`
      width: 100%;
      display: flex;
    `;
  }}

  ${(props) => {
    const { stacked } = props;

    if (!stacked) {
      return css`
        flex-direction: row;
      `;
    }

    return css`
      flex-direction: column;
      margin: 15px 0px 0px 0px;
    `;
  }}

    :last-child {
    margin: 30px 0px 40px 0px;
  }

  ${(props) => {
    const { tariffView } = props;

    if (tariffView) {
      return css`
        :last-child {
          margin: 30px 0px 0px 0px;
        }
      `;
    }
    return css``;
  }}
`;
