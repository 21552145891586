import SelectAllFilterValue from '../../shared/constants/SelectAllFilterValue';

enum LicensePlatesStatusValues {
  ALL = SelectAllFilterValue,
  ACTIVE = 0,
  EXPIRED = 2,
  DELETED = 5,
}

export default LicensePlatesStatusValues;
