import type LotDetectionChangeEventData from 'src/detection/socketEvents/LotDetectionChangeEventData';
import type LotDetectionChangeEventDataPayload from 'src/detection/socketEvents/LotDetectionChangeEventDataPayload';

export default function toLotDetectionChangeEventData(
  data: LotDetectionChangeEventDataPayload
): LotDetectionChangeEventData {
  const { id, detection_status, occupied_count } = data;

  return {
    id,
    detectionStatus: detection_status,
    occupiedCount: occupied_count,
  };
}
