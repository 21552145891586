import type ParkingPlace from 'src/detection/models/ParkingPlace';
import type ParkingPlaceResponse from 'src/detection/models/ParkingPlaceResponse';

export default function toParkingPlace(
  data: ParkingPlaceResponse
): ParkingPlace {
  const {
    id,
    uuid,
    name,
    lot_id,
    occupancy_status,
    external_id,
    zone_id,
    deleted_at,
    created_at,
    updated_at,
  } = data;

  return {
    id,
    uuid,
    name,
    lotId: lot_id,
    occupancyStatus: occupancy_status,
    externalId: external_id,
    zoneId: zone_id,
    createdAt: created_at,
    updatedAt: updated_at,
    deletedAt: deleted_at,
  };
}
