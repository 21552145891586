import type Gate from 'src/products/models/Gate/Gate';
import type GateResponse from 'src/products/models/Gate/GateResponse';
import toConcreteProduct from '../Product/toConcreteProduct';

export default function toGate(data: GateResponse): Gate {
  const { id, product, state, permanent_position } = data;

  return {
    id,
    product: toConcreteProduct(product),
    state,
    permanentPosition: permanent_position,
  };
}
