import type Gateway from 'src/products/models/Gateway/Gateway';
import type GatewayFormValues from 'src/products/models/Gateway/GatewayFormValues';

export default function toGatewayFormValues(data: Gateway): GatewayFormValues {
  const { name, uuid, firmwareVersion, ipAddress } = data;

  return {
    name,
    uuid,
    firmwareVersion: firmwareVersion || '',
    ipAddress: ipAddress || '',
  };
}
