import type LicensePlateRemoveRequest from 'src/anpr/models/LotWhitelist/LicensePlateRemoveRequest';
import type LicensePlateRemoveValues from 'src/anpr/models/LotWhitelist/LicensePlateRemoveValues';

export default function toLicensePlateRemoveRequest(
  data: LicensePlateRemoveValues
): LicensePlateRemoveRequest {
  const { ids } = data;

  return { ids };
}
