import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import useAuthContext from 'src/auth/hooks/useAuthContext';
import AccountUserInvitationConfirmation from 'src/auth/screens/AccountUserInvitationConfirmation';
import EmailConfirmation from 'src/auth/screens/EmailConfirmation';
import GeneralTerms from 'src/auth/screens/GeneralTerms';
import Login from 'src/auth/screens/Login';
import NewPassword from 'src/auth/screens/NewPassword';
import ResetPassword from 'src/auth/screens/ResetPassword';
import WeblinkScreen from 'src/auth/screens/WeblinkScreen';

export default function Auth() {
  const { isLoggedIn } = useAuthContext();
  const history = useHistory();

  if (isLoggedIn) {
    return null;
  }

  return (
    <Switch>
      <Route exact path='/login'>
        <Login />
      </Route>
      <Route exact path='/account-users/invitation/accept'>
        <AccountUserInvitationConfirmation />
      </Route>
      <Route exact path='/reset-password'>
        <ResetPassword />
      </Route>
      <Route exact path='/v2/account-users/password-reset/confirmation'>
        <NewPassword />
      </Route>
      <Route exact path='/weblinks/:id/operation'>
        <WeblinkScreen />
      </Route>
      <Route exact path='/account-users/email-activation/confirmation'>
        <EmailConfirmation />
      </Route>
      <Route exact path='/general-terms'>
        <GeneralTerms />
      </Route>
      <Route>
        <Redirect
          to={{
            pathname: '/login',
            state: { goBackLocation: history.location.pathname },
          }}
        />
      </Route>
    </Switch>
  );
}
