import React from 'react';
import type GatewayStatus from 'src/products/constants/GatewayStatus';
import SystemControlButton from '../../shared/components/SystemControlButton';
import DetailsColumn from './DetailsColumn';
import DetailsRow from './DetailsRow';

interface GatewaySystemControlFormProps {
  onClick: () => void;
  status?: GatewayStatus;
  deviceInProgress: boolean;
}

export default function GatewaySystemControlForm(
  props: GatewaySystemControlFormProps
) {
  const { onClick, status, deviceInProgress } = props;

  return (
    <DetailsRow wider addMargin>
      <DetailsColumn>
        <SystemControlButton
          onClick={onClick}
          name='Reboot Gateway'
          info='The Gateway will turn off and then turn on again. It might take a few minutes for the Gateway to reboot.'
          status={status}
          deviceInProgress={deviceInProgress}
        />
      </DetailsColumn>
    </DetailsRow>
  );
}
