import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import toLprCameraStatusEvent from 'src/anpr/mappers/toLprCameraStatusEvent';
import type CameraUpdateFormValues from 'src/anpr/models/LprCamera/CameraUpdateFormValues';
import type LprCamera from 'src/anpr/models/LprCamera/LprCamera';
import type LprCameraStatusEventPayload from 'src/anpr/models/LprCameraStatusEventPayload';
import useLprCamerasService from 'src/anpr/services/useLprCamerasService';
import ExitButton from 'src/shared/components/Buttons/ExitButton';
import Form from 'src/shared/components/Form';
import { useGlobalConfirmationModal } from 'src/shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import Tabs from 'src/shared/components/Tabs';
import SocketEvents from 'src/shared/constants/SocketEvents';
import SocketPath from 'src/shared/constants/SocketPath';
import SocketTimer from 'src/shared/constants/SocketTimer';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import useSocket from 'src/shared/hooks/useSocket';
import * as yup from 'yup';
import { isNotString } from '../../shared/utils/checks';
import CameraSystemControlForm from './CameraSystemControlForm';
import CameraUpdateForm from './CameraUpdateForm';
import SetupCameraCredentials from './SetupCameraCredentials';

interface CameraDetailsModalProps {
  id: number | undefined;
  closeParentModal: () => void;
  onCameraNameChange: (name: string, id: number, zoneId?: number) => void;
  showToast?: (msg: string, device?: string) => void;
  hideSystemControl?: boolean;
}

const defaultFormValues: CameraUpdateFormValues = {
  name: '',
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
});

export default function CameraDetailsModal(props: CameraDetailsModalProps) {
  const {
    id,
    closeParentModal,
    onCameraNameChange,
    showToast,
    hideSystemControl,
  } = props;

  const [details, setDetails] = useState<LprCamera | undefined>();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [deviceInProgress, setDeviceInProgress] = useState(false);
  const [shouldRefreshPage, setShouldRefreshPage] = useState(false);
  const socket = useSocket(SocketPath.SOCKETIO);
  const isMounted = useIsMounted();
  const { findOneCamera, updateCamera, removeCamera, rebootCamera } =
    useLprCamerasService();
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const handleLprCameraStatusEvent = (payload: string) => {
      try {
        const eventPayload: LprCameraStatusEventPayload = JSON.parse(payload);
        const eventData = toLprCameraStatusEvent(eventPayload);

        const { deviceId, status } = eventData;

        if (deviceId !== details?.deviceId) {
          return;
        }
        setDetails((oldCamera) => {
          if (!oldCamera) return undefined;
          return {
            ...oldCamera,
            status,
          };
        });
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };
    socket?.on(SocketEvents.LPR_CAMERA_STATUS, handleLprCameraStatusEvent);

    return () => {
      socket?.off(SocketEvents.LPR_CAMERA_STATUS, handleLprCameraStatusEvent);
    };
  }, [socket, details, openGlobalFailureModal, setMessage]);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
    refreshPage: shouldRefreshPage,
  });

  const onCameraRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await removeCamera(id);

      if (isMounted()) {
        setMessageSuccess('Camera deleted successfully!');
        setShouldRefreshPage(true);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeCamera,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const onCameraReboot = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      if (isMounted()) {
        setDeviceInProgress(true);
      }

      await rebootCamera(id);

      let operationListener: Socket | undefined;

      const operationTimeout = setTimeout(() => {
        if (operationListener) {
          operationListener = socket?.off(
            SocketEvents.LPR_CAMERA_STATUS,
            handleLprCameraStatusEvent
          );
        }
        setDeviceInProgress(false);
        setMessage({
          code: 'Unknown',
          message: 'Operation timeout',
        });
        openGlobalFailureModal();
      }, SocketTimer.PRODUCT_OPERATION_TIMEOUT);

      if (!socket) {
        throw Error();
      }

      const handleLprCameraStatusEvent = (payload: string) => {
        try {
          const eventPayload: LprCameraStatusEventPayload = JSON.parse(payload);
          const eventData = toLprCameraStatusEvent(eventPayload);

          const { deviceId, status } = eventData;

          if (deviceId !== details?.deviceId) {
            return;
          }
          clearTimeout(operationTimeout);
          operationListener = socket?.off(
            SocketEvents.LPR_CAMERA_STATUS,
            handleLprCameraStatusEvent
          );

          setDetails((oldCamera) => {
            if (!oldCamera) return undefined;
            return {
              ...oldCamera,
              status,
            };
          });
          if (showToast) {
            showToast(
              'It might take a few minutes for the camera to connect to the network.',
              details?.deviceId
            );
          }

          if (isMounted()) {
            setDeviceInProgress(false);
          }
        } catch {
          setMessage({ code: 'Unknown', message: 'JSON parse error' });
          openGlobalFailureModal();
        }
      };

      socket?.on(SocketEvents.LPR_CAMERA_STATUS, handleLprCameraStatusEvent);
    } catch (error) {
      if (isMounted()) {
        setDeviceInProgress(false);
      }
      throw error;
    }
  }, [
    id,
    rebootCamera,
    details,
    isMounted,
    openGlobalFailureModal,
    setMessage,
    socket,
    showToast,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onCameraRemove,
    message: 'Are you sure you want to remove this camera?',
  });

  useEffect(() => {
    const loadCameraDetails = async () => {
      try {
        if (!id) {
          return;
        }
        if (isMounted()) {
          setAreDetailsLoading(true);
        }
        const data = await findOneCamera(id);
        if (isMounted()) {
          setDetails(data);
          setInitialValues({ name: data.name });
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    loadCameraDetails();
  }, [id, isMounted, findOneCamera]);

  const onCameraFormSubmit = useCallback(
    async (
      values: CameraUpdateFormValues,
      { setErrors, resetForm }: FormikHelpers<CameraUpdateFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }

        await updateCamera(id, values);

        if (isMounted()) {
          resetForm({});
          setMessageSuccess('Camera updated successfully!');
          setShouldRefreshPage(false);
          openGlobalSuccessModal();
          onCameraNameChange(values.name, id, details?.zoneId);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      id,
      updateCamera,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      onCameraNameChange,
      setMessage,
      details,
    ]
  );

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='camera'
            onSubmit={onCameraFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
          >
            <CameraUpdateForm
              details={details}
              openRemoveModal={openGlobalConfirmationModal}
              closeParentModal={closeParentModal}
              isLoading={areDetailsLoading}
            />
          </Form>
        </Tabs.Panel>
        {!hideSystemControl ? (
          <Tabs.Panel name='systemControl' label='System Control'>
            <CameraSystemControlForm
              onClick={onCameraReboot}
              status={details?.status}
              deviceInProgress={deviceInProgress}
            />
          </Tabs.Panel>
        ) : (
          <></>
        )}
        <Tabs.Panel name='setup' label='Setup Credentials'>
          <SetupCameraCredentials id={details?.id} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
