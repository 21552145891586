import type DetectCameraViewSchema from 'src/detection/models/DetectCameraViewSchema';
import type DetectCameraViewSchemaResponse from 'src/detection/models/DetectCameraViewSchemaResponse';
import toDetectCameraViewSchemaParkingPlaces from './toDetectCameraViewSchemaParkingPlaces';

export default function toDetectCameraViewSchema(
  data: string
): DetectCameraViewSchema {
  const parsedData: DetectCameraViewSchemaResponse = JSON.parse(data);
  const { viewBox, parkingPlaces } = parsedData;

  return {
    viewBox: viewBox,
    parkingPlaces: parkingPlaces.map(toDetectCameraViewSchemaParkingPlaces),
  };
}
