import type LotChangeEventProductError from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventProductError';
import type LotChangeEventProductErrorPayload from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventProductErrorPayload';

export default function toLotChangeEventProductError(
  data: LotChangeEventProductErrorPayload
): LotChangeEventProductError {
  const {
    id,
    jammed,
    sensor_dirty,
    pin_broken,
    battery_empty,
    rtcc_invalid,
    master_key_not_set,
  } = data;
  return {
    errorId: id,
    jammed: !!jammed,
    sensorDirty: !!sensor_dirty,
    pinBroken: !!pin_broken,
    batteryEmpty: !!battery_empty,
    rtccInvalid: !!rtcc_invalid,
    masterKeyNotSet: !!master_key_not_set,
  };
}
