import type InvitationKeyUpdateParams from '../models/InvitationKey/InvitationKeyUpdateParams';
import type InvitationKeyUpdateValues from '../models/InvitationKey/InvitationKeyUpdateValues';

export default function toInvitationKeyUpdateParams(
  data: InvitationKeyUpdateValues
): InvitationKeyUpdateParams {
  const { ids, status } = data;

  return { ids, status };
}
