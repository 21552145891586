import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type AccountUser from 'src/home/models/AccountUser';
import type AccountUserQueryParams from 'src/home/models/AccountUserQueryParams';
import useAccountUserService from 'src/home/services/useAccountUserService';
import Button from 'src/shared/components/Button';
import Card from 'src/shared/components/Card';
import Content from 'src/shared/components/Content';
import Input from 'src/shared/components/Input';
import Main from 'src/shared/components/Main';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import Pagination from 'src/shared/components/Pagination';
import Title from 'src/shared/components/Title';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import SearchTimer from 'src/shared/constants/SearchTimer';
import debounce from 'src/shared/utils/debounce';
import AccountRole from '../../shared/constants/AccountRole';
import AppRole from '../../shared/constants/AppRole';
import ButtonSize from '../../shared/constants/ButtonSize';
import PaginationItemDisplay from '../../shared/constants/PaginationItemDisplay';
import { useGlobalModal } from '../../shared/hooks/useGlobalModal';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useUserHasRole from '../../shared/hooks/useUserHasRole';
import type Meta from '../../shared/models/Meta';
import AccountTable from '../components/AccountTable';
import AccountInvitationList from './AccountInvitationList';
import AddAccountWizzard from './AddAccountWizzard';
import AddUser from './AddUser';

export default function ParklioInternalDashboard() {
  const [data, setData] = useState<AccountUser[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { findAllAccountUsersParklioInternal } = useAccountUserService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();
  const [accountNameSearchValue, setAccountNameSearchValue] = useState('');
  const [accountNameInputValue, setAccountNameInputValue] = useState('');
  const accountNameRefSearchValue = React.useRef<HTMLInputElement>(null);
  const [managerEmailSearchValue, setManagerEmailSearchValue] = useState('');
  const [managerEmailInputValue, setManagerEmailInputValue] = useState('');
  const managerEmailRefSearchValue = React.useRef<HTMLInputElement>(null);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);

  const userCanAddAccount = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const [openAddUserModal, closeAddUserModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <AddUser closeParentModal={closeAddUserModal} refreshPage />
    </GlobalModal>
  ));

  const [openAddAccountModal, closeAddAccountModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <AddAccountWizzard closeParentModal={closeAddAccountModal} refreshPage />
    </GlobalModal>
  ));

  const onSearchAccountName = useCallback(async () => {
    if (accountNameRefSearchValue.current === null) {
      return;
    }
    if (accountNameInputValue !== accountNameRefSearchValue.current.value) {
      return;
    }

    setAccountNameSearchValue(accountNameInputValue);
    setIsDataLoading(false);
  }, [accountNameInputValue]);

  const onSearchManagerEmail = useCallback(async () => {
    if (managerEmailRefSearchValue.current === null) {
      return;
    }
    if (managerEmailInputValue !== managerEmailRefSearchValue.current.value) {
      return;
    }

    setManagerEmailSearchValue(managerEmailInputValue);
    setIsDataLoading(false);
  }, [managerEmailInputValue]);

  useEffect(() => {
    setIsDataLoading(true);
    const search = debounce(onSearchAccountName, SearchTimer.INPUT_SEARCH);
    search();
  }, [onSearchAccountName]);

  useEffect(() => {
    setIsDataLoading(true);
    const search = debounce(onSearchManagerEmail, SearchTimer.INPUT_SEARCH);
    search();
  }, [onSearchManagerEmail]);

  const onChangeInputAccountName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAccountNameInputValue(event.target.value);
  };

  const onChangeInputManagerEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setManagerEmailInputValue(event.target.value);
  };

  const getAccountData = useCallback(
    async (page: number) => {
      try {
        if (!isMounted()) {
          return;
        }
        setIsDataLoading(true);

        let queryParams: AccountUserQueryParams = {
          page,
          size: 20,
          accountRoleId: AccountRole.PMS,
          name: '',
          email: '',
        };

        if (accountNameSearchValue !== '' || managerEmailSearchValue !== '') {
          queryParams = {
            page,
            size: 20,
            accountRoleId: undefined,
            name: accountNameSearchValue,
            email: managerEmailSearchValue,
          };
        }

        const { data, meta } = await findAllAccountUsersParklioInternal(
          queryParams
        );

        if (isMounted()) {
          setData(data);
          if (meta !== undefined) {
            setMeta(meta);
          }
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
          setMeta((meta) => ({ ...meta, total: 0 }));
        }
        throw error;
      }
    },
    [
      findAllAccountUsersParklioInternal,
      isMounted,
      accountNameSearchValue,
      managerEmailSearchValue,
    ]
  );

  useEffect(() => {
    getAccountData(1);
  }, [getAccountData]);

  return (
    <Main left>
      <Content widthSize='70%'>
        <Title>Parklio Internal Dashboard</Title>
        <Card addHorizontalPadding>
          <Input
            placeholder='Search by manager email'
            type='text'
            ref={managerEmailRefSearchValue}
            value={managerEmailInputValue}
            onChange={onChangeInputManagerEmail}
          />
          <Input
            placeholder='Search by account name'
            type='text'
            ref={accountNameRefSearchValue}
            value={accountNameInputValue}
            onChange={onChangeInputAccountName}
          />
        </Card>
        <AccountTable data={data} isLoading={isDataLoading} />
        {meta.total >= PaginationItemDisplay.ACCOUNT_NUMBER_DISPLAY && (
          <Pagination meta={meta} getData={getAccountData} />
        )}
      </Content>
      {userCanAddAccount && (
        <Content widthSize='25%' addTopMargin>
          <Button
            onClick={openAddAccountModal}
            size={ButtonSize.LARGE}
            primary
            type='button'
          >
            Add account
          </Button>
          <Button
            size={ButtonSize.LARGE}
            type='button'
            primary
            onClick={openAddUserModal}
          >
            Add user
          </Button>
          <AccountInvitationList />
        </Content>
      )}
    </Main>
  );
}
