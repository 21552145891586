import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import Color from '../../constants/Color';

interface CellProps<Value> {
  center?: boolean;
  right?: boolean;
  action?: (data?: Value) => void;
  data?: Value;
  primary?: boolean;
  children: React.ReactNode;
  error?: boolean;
  invalid?: boolean;
  bold?: boolean;
  short?: boolean;
  title?: string;
  active?: boolean;
  disabled?: boolean;
  noMaxWidth?: boolean;
  minWidth?: boolean;
  show?: string;
  merged?: number;
  smallText?: boolean;
  alignCenter?: boolean;
  flexColumn?: boolean;
  occupancyColor?: Color;
}

export default function Cell<Value>(props: CellProps<Value>) {
  const {
    center,
    right,
    action,
    data,
    children,
    primary,
    error,
    invalid,
    bold,
    short,
    title,
    active,
    disabled,
    noMaxWidth,
    minWidth,
    show,
    merged,
    smallText,
    alignCenter,
    flexColumn,
    occupancyColor,
  } = props;
  const onClick = useMemo(() => {
    if (!action) {
      return undefined;
    }

    return () => {
      action(data);
    };
  }, [action, data]);

  return (
    <StyledCell
      error={error}
      invalid={invalid}
      primary={primary}
      active={active}
      center={center}
      right={right}
      onClick={onClick}
      show={show}
      colSpan={merged}
      smallText={smallText}
      alignCenter={alignCenter}
      occupancyColor={occupancyColor}
    >
      <StyledCellContent
        bold={bold}
        short={short}
        title={title}
        disabled={disabled}
        noMaxWidth={noMaxWidth}
        minWidth={minWidth}
        center={center}
        right={right}
        smallText={smallText}
        flexColumn={flexColumn}
      >
        {children}
      </StyledCellContent>
    </StyledCell>
  );
}

const StyledCell = styled.td<{
  primary?: boolean;
  center?: boolean;
  right?: boolean;
  error?: boolean;
  invalid?: boolean;
  active?: boolean;
  show?: string;
  smallText?: boolean;
  alignCenter?: boolean;
  occupancyColor?: Color;
}>`
  height: 60px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  min-width: 40px;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  ${(props) => {
    const { center, right } = props;

    if (center) {
      return css`
        text-align: center;
      `;
    }

    if (right) {
      return css`
        text-align: right;
      `;
    }

    return css`
      text-align: left;
    `;
  }}

  ${(props) => {
    const { smallText } = props;

    if (smallText) {
      return css`
        font-size: 13px;
      `;
    }

    return css`
      font-size: 14px;
    `;
  }}

    ${(props) => {
    const { primary, active, error, invalid, occupancyColor } = props;

    if (error) {
      return css`
        color: ${Color.ERROR};
        cursor: pointer;
      `;
    }

    if (invalid) {
      return css`
        color: ${Color.ERROR};
        cursor: auto;
      `;
    }

    if (primary) {
      return css`
        color: ${Color.TEXT_BRAND};
        cursor: pointer;

        :hover {
          color: ${Color.PRIMARY_HOVER};
        }
      `;
    }

    if (active) {
      return css`
        color: ${Color.TEXT_BRAND};
        cursor: default;
      `;
    }

    if (occupancyColor) {
      return css`
        color: ${occupancyColor};
      `;
    }

    return css`
      color: ${Color.TEXT_DARKER};
    `;
  }}

  ${(props) => {
    const { alignCenter } = props;

    if (alignCenter) {
      return css`
        display: flex;
        justify-content: center;
      `;
    }
    return css``;
  }}

    ${(props) => {
    const { show } = props;

    if (show === 'smallDevices') {
      return css`
        @media (max-width: 600px) {
          display: table-cell;
        }

        display: none;
      `;
    }

    if (show === 'largeDevices') {
      return css`
        @media (max-width: 600px) {
          display: none;
        }

        display: table-cell;
      `;
    }

    return css``;
  }}
`;

const StyledCellContent = styled.div<{
  bold?: boolean;
  short?: boolean;
  disabled?: boolean;
  noMaxWidth?: boolean;
  minWidth?: boolean;
  center?: boolean;
  right?: boolean;
  smallText?: boolean;
  flexColumn?: boolean;
}>`
  flex-direction: row;
  align-items: center;

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        color: ${Color.TEXT_LIGHT};
        opacity: 0.5;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { flexColumn } = props;

    if (flexColumn) {
      return css`
        flex-direction: column;
        align-items: flex-start;
      `;
    }
    return css``;
  }}
    
    ${(props) => {
    const { center, right } = props;

    if (center) {
      return css`
        justify-content: center;
      `;
    }

    if (right) {
      return css`
        justify-content: flex-end;
      `;
    }

    return css`
      margin-right: 10px;
    `;
  }}

    ${(props) => {
    const { bold } = props;

    if (bold) {
      return css`
        font-weight: bold;
      `;
    }

    return css`
      font-weight: none;
    `;
  }}

    ${(props) => {
    const { short, smallText } = props;

    if (short) {
      return css`
        max-width: 190px;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      `;
    }

    if (smallText) {
      return css`
        white-space: pre;
        max-width: none;
        display: flex;
      `;
    }

    return css`
      max-width: none;
      display: flex;
    `;
  }}

    ${(props) => {
    const { noMaxWidth } = props;

    if (noMaxWidth) {
      return css`
        @media (max-width: 768px) {
          display: inline-flex;
          max-width: none;
          width: 100%;
        }
      `;
    }
    return css``;
  }}

    ${(props) => {
    const { minWidth } = props;

    if (minWidth) {
      return css`
        min-width: 220px;
      `;
    }
    return css``;
  }}
`;
