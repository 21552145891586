import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import type Rotary from 'src/rotaries/models/Rotary';
import Card from '../../shared/components/Card';
import Table from '../../shared/components/Table';

interface RotaryTableProps {
  data: Rotary[];
  isLoading?: boolean;
}

export default function RotaryTable(props: RotaryTableProps) {
  const { data, isLoading } = props;
  const { push } = useHistory();

  const viewItem = useCallback(
    (data?: Rotary) => {
      if (!data) {
        return;
      }

      const { id } = data;

      push(`/rotaries/${id}`);
    },
    [push]
  );

  return (
    <Card>
      <Table isLoading={isLoading}>
        <Table.Head>
          <Table.Row>
            <Table.Header>NAME</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map((rotary) => {
            const { name, id } = rotary;

            return (
              <Table.Row key={id}>
                <Table.Cell action={viewItem} primary data={rotary}>
                  {name}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Card>
  );
}
