import type ProductBrain from 'src/lots/models/ProductBrain';
import type ProductBrainResponse from 'src/lots/models/ProductBrainResponse';

export default function toProductBrain(
  data: ProductBrainResponse
): ProductBrain {
  const { id, state, permanent_position } = data;

  return {
    id,
    state,
    permanentPosition: permanent_position,
  };
}
