import type LotChangeEventChain from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventChain';
import type LotChangeEventChainPayload from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventChainPayload';

export default function toLotChangeEventChain(
  data: LotChangeEventChainPayload
): LotChangeEventChain {
  const { state, permanent_position } = data;

  return {
    state,
    permanentPosition: permanent_position,
  };
}
