import type AccountUser from 'src/settings/models/AccountUser';
import type AccountUserFormValues from '../models/AccountUserFormValues';

export default function toAccountUserFormValues(
  data: AccountUser
): AccountUserFormValues {
  const { email, name, role, phone } = data;

  return { email, name, role, phone: phone || '' };
}
