import type ParkingPlace from 'src/detection/models/ParkingPlace';
import type ParkingPlaceFormValues from 'src/detection/models/ParkingPlaceFormValues';

export default function toParkingPlaceFormValues(
  data: ParkingPlace,
  zoneName: string
): ParkingPlaceFormValues {
  const { name, externalId, uuid, zoneId } = data;

  return {
    name,
    externalId,
    uuid,
    zone: { key: zoneId, label: zoneName },
  };
}
