import React from 'react';
import Button from 'src/shared/components/Button';
import ExitButton from 'src/shared/components/Buttons/ExitButton';
import Description from 'src/shared/components/Description';
import Line from 'src/shared/components/Line';
import Section from 'src/shared/components/Section';
import StyledModalIcon from 'src/shared/components/StyledModalIcon';
import ButtonSize from 'src/shared/constants/ButtonSize';
import styled from 'styled-components';

interface InquiryResponseProps {
  closeParentModal: () => void;
  onTryAgainClick?: () => void;
  success: boolean;
  message: string;
}

export default function InquiryResponse(props: InquiryResponseProps) {
  const { closeParentModal, onTryAgainClick, success, message } = props;

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Section>
        <StyledModalIcon
          className={success ? 'pa-check-circle' : 'pa-x-circle'}
          size='80px'
          data-testid={success ? 'pa-check-circle' : 'pa-x-circle'}
        />
        <Description inquiryResponseView>{message} </Description>
        <StyledDiv>
          {!success && (
            <Button
              type='button'
              size={ButtonSize.MEDIUM}
              onClick={onTryAgainClick}
            >
              Try again
            </Button>
          )}
        </StyledDiv>
        <Line addMargin />
      </Section>
    </>
  );
}

const StyledDiv = styled.div`
  display: flex;
  align-self: center;
  width: 232px;
`;
