import type ProductLicensesQuery from 'src/product-license/models/ProductLicenseQuery';
import type ProductLicensesQueryParams from 'src/product-license/models/ProductLicensesQueryParams';

export default function toProductLicensesQuery(
  data: ProductLicensesQueryParams
): ProductLicensesQuery {
  const { accountId, page, size } = data;

  return {
    account_id: accountId,
    page,
    size,
  };
}
