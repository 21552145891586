import type AccountUserRole from '../models/AccountUserRole';
import type AccountUserRoleResponse from '../models/AccountUserRoleResponse';

export default function toAccountUserRole(
  data: AccountUserRoleResponse
): AccountUserRole {
  const { id, name, created_at, updated_at } = data;

  return {
    id,
    name,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
