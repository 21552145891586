import type WeblinkCreateRequest from '../models/Weblink/WeblinkCreateRequest';
import type WeblinkFormValues from '../models/Weblink/WeblinkFormValues';

export default function toWeblinkCreateRequest(
  productId: number[],
  data: WeblinkFormValues
): WeblinkCreateRequest {
  const { startTime, endTime, receiverEmail } = data;

  return {
    products: productId,
    receiver_email: receiverEmail,
    start_time: startTime ? startTime.toISOString() : '',
    end_time: endTime ? endTime.toISOString() : '',
  };
}
