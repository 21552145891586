import { createContext } from 'react';
import type LicensePurchase from 'src/license/models/LicensePurchase';

interface LicenseContextData {
  purchasedLicenses: LicensePurchase[];
  setPurchasedLicenses: (purchasedLicenses: LicensePurchase[]) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const initialLicenseContextData: LicenseContextData = {
  purchasedLicenses: [],
  setPurchasedLicenses: () => ({}),
  isLoading: false,
  setIsLoading: () => ({}),
};

const LicenseContext = createContext(initialLicenseContextData);

export default LicenseContext;
