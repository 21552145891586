import type DetectCameraFormValues from 'src/detection/models/DetectCameraFormValues';
import type DetectCameraRequest from 'src/detection/models/DetectCameraRequest';

export default function toDetectCameraCreateRequest(
  values: DetectCameraFormValues
): DetectCameraRequest {
  const { name, gateway, uuid, externalId } = values;

  return {
    name,
    parking_detection_gateway_id: gateway?.key || 0,
    external_id: externalId,
    uuid,
  };
}
