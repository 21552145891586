import styled, { css, keyframes } from 'styled-components';
import Color from '../constants/Color';

interface SpinnerProps {
  small?: boolean;
  primary?: boolean;
  anpr?: boolean;
  detectCamera?: boolean;
  lastElement?: boolean;
}

const rotation = keyframes`
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<SpinnerProps>`
  /*
    border: 5px solid transparent;
    border-radius: 50%;
    animation: rotation 0.8s linear infinite;
    */

  border-radius: 50%;
  animation: ${rotation} 0.8s ease infinite;

  ${(props) => {
    const { small } = props;

    if (small) {
      return css`
        width: 25px;
        height: 25px;
        border: 3px solid transparent;
      `;
    }

    return css`
      width: 50px;
      height: 50px;
      border: 5px solid transparent;
    `;
  }}

  ${(props) => {
    const { primary } = props;

    if (primary) {
      return css`
        border-top-color: ${Color.PRIMARY_BRAND};
        border-bottom-color: ${Color.PRIMARY_BRAND};
      `;
    }

    return css`
      border-top-color: ${Color.BACKGROUND_LIGHTER};
      border-bottom-color: ${Color.BACKGROUND_LIGHTER};
    `;
  }}
    
    ${(props) => {
    const { anpr } = props;

    if (anpr) {
      return css`
        margin-top: 20px;
        align-self: center;
      `;
    }
    return css``;
  }}    
  
  ${(props) => {
    const { detectCamera } = props;

    if (detectCamera) {
      return css`
        margin: auto;

        @media (max-width: 1155px) {
          margin: 20px auto 40px auto;
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { lastElement } = props;

    if (lastElement) {
      return css`
        margin: 40px 30px;
      `;
    }
    return css``;
  }}
`;

export default Spinner;
