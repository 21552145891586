import { useField, useFormikContext } from 'formik';
import React from 'react';
import Button from 'src/shared/components/Button';
import CheckBoxField from 'src/shared/components/CheckBoxField';
import Control from 'src/shared/components/Control';
import DateTimeDiv from 'src/shared/components/DateTimeDiv';
import DateTimeField from 'src/shared/components/DateTimeField';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import Section from 'src/shared/components/Section';
import Title from 'src/shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';
import type QRCodeSharingFormValues from '../models/QRCodes/QRCodeSharingFormValues';

interface QRCodeSharingFormProps {
  closeParentModal: () => void;
}

export default function QRCodesSharingForm(props: QRCodeSharingFormProps) {
  const { closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext<QRCodeSharingFormValues>();
  const [isUnlimited] = useField({ name: 'isUnlimited' });
  const [startTime] = useField({ name: 'startTime' });
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      <Section>
        <Title bold>Share QR Code</Title>
        <CheckBoxField label='Unlimited access' name='isUnlimited' />
        <DateTimeDiv>
          <DateTimeField
            name='startTime'
            label='Start Time'
            placeholderDate='Start Date'
            placeholderTime='Start Time'
            minBookingDate={new Date()}
            minutesOffset={30}
          />
          <DateTimeField
            name='endTime'
            label='End Time'
            placeholderDate='End Date'
            placeholderTime='End Time'
            minBookingDate={new Date(startTime.value)}
            endTimeOffset
            disabled={isUnlimited.value}
          />
        </DateTimeDiv>
      </Section>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          fitContent
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Generate QR Code
        </Button>
      </Control>
    </>
  );
}
