import capitalizeFirstLetter from 'src/shared/utils/capitalizeFirstLetter';
import type Option from '../../shared/models/Option';

enum ParkingDetectionImplementationStatus {
  IMPLEMENTED = 'implemented',
  NOT_IMPLEMENTED = 'not_implemented',
  PENDING = 'pending',
  BLOCKED = 'blocked',
}

const StatusLabels: Record<number, ParkingDetectionImplementationStatus> = {
  1: ParkingDetectionImplementationStatus.IMPLEMENTED,
  2: ParkingDetectionImplementationStatus.NOT_IMPLEMENTED,
  3: ParkingDetectionImplementationStatus.PENDING,
  4: ParkingDetectionImplementationStatus.BLOCKED,
};

const StatusValues: Record<ParkingDetectionImplementationStatus, number> = {
  implemented: 1,
  not_implemented: 2,
  pending: 3,
  blocked: 4,
};

const StatusOptions: Option[] = [
  {
    key: StatusValues[ParkingDetectionImplementationStatus.IMPLEMENTED],
    label: capitalizeFirstLetter(
      ParkingDetectionImplementationStatus.IMPLEMENTED
    ),
  },
  {
    key: StatusValues[ParkingDetectionImplementationStatus.PENDING],
    label: capitalizeFirstLetter(ParkingDetectionImplementationStatus.PENDING),
  },
  {
    key: StatusValues[ParkingDetectionImplementationStatus.BLOCKED],
    label: capitalizeFirstLetter(ParkingDetectionImplementationStatus.BLOCKED),
  },
];

export {
  ParkingDetectionImplementationStatus,
  StatusLabels,
  StatusValues,
  StatusOptions,
};
