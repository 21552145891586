import type ParkingPlaceChange from 'src/detection/models/ParkingPlaceChange';
import type ParkingPlaceChangePayload from 'src/detection/models/ParkingPlaceChangePayload';
import toParkingPlaceChangeData from './toParkingPlaceChangeData';

export default function toParkingPlaceChange(
  data: ParkingPlaceChangePayload
): ParkingPlaceChange {
  const { places } = data;

  return {
    places: places.map(toParkingPlaceChangeData),
  };
}
