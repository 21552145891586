import type LprImageStorageSubscription from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscription';
import type LprImageStorageSubscriptionResponse from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionResponse';

export default function toLprImageStorageSubscription(
  data: LprImageStorageSubscriptionResponse
): LprImageStorageSubscription {
  const {
    id,
    lpr_camera_id,
    status,
    activation_date,
    expiry_date,
    initial_expiry_date,
    termination_date,
    subscription_plan,
    deleted_at,
    created_at,
    updated_at,
  } = data;

  return {
    id,
    lprCameraId: lpr_camera_id,
    status,
    activationDate: activation_date,
    expiryDate: expiry_date,
    initialExpiryDate: initial_expiry_date,
    terminationDate: termination_date,
    subscriptionPlan: subscription_plan,
    deletedAt: deleted_at ? new Date(deleted_at) : null,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
