import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import toLotFormValues from 'src/lots/mappers/toLotFormValues';
import * as yup from 'yup';
import Form from '../../shared/components/Form';
import { useGlobalFailureModal } from '../../shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../shared/components/Modals/GlobalSuccessModal';
import useIsMounted from '../../shared/hooks/useIsMounted';
import { isNotString } from '../../shared/utils/checks';
import LotInfoForm from '../components/LotInfoForm';
import type LotFormValues from '../models/LotFormValues';
import useLotService from '../services/useLotService';

const defaultValues: LotFormValues = {
  name: '',
  address: '',
  description: '',
  timeZone: null,
  latitude: '',
  longitude: '',
};

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is a required field')
    .min(3, 'Name should be at least 3 characters long'),
  address: yup
    .string()
    .required('Address is a required field')
    .min(3, 'Address should be at least 3 characters long'),
  timeZone: yup.object().nullable().required('Time Zone is a required field'),
  latitude: yup.string().required('Latitude is a required field'),
  longitude: yup.string().required('Longitude is a required field'),
});

interface LotInfoProps {
  onLotNameChange: (name: string) => void;
}

export default function LotInfo(props: LotInfoProps) {
  const { onLotNameChange } = props;
  const { params } = useRouteMatch<{ lotId?: string }>();
  const { lotId } = params;

  const id = useMemo(() => {
    if (!lotId) {
      return undefined;
    }

    return parseInt(lotId);
  }, [lotId]);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [initialValues, setInitialValues] =
    useState<LotFormValues>(defaultValues);
  const { updateLot, findOneLot } = useLotService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      try {
        if (!id) {
          return;
        }

        if (isMounted()) {
          setAreDetailsLoading(true);
        }

        const response = await findOneLot(id);

        const values = toLotFormValues(response);

        if (isMounted()) {
          setAreDetailsLoading(false);
          setInitialValues(values);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [findOneLot, isMounted, id]);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: 'Successfuly updated existing lot!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const getChangedValues = useCallback(
    (values: LotFormValues) => ({
      name: values.name !== initialValues.name ? values.name : undefined,
      address:
        values.address !== initialValues.address ? values.address : undefined,
      description:
        values.description !== initialValues.description
          ? values.description
          : undefined,
      timeZone:
        values.timeZone !== initialValues.timeZone
          ? values.timeZone
          : undefined,
      longitude:
        values.longitude !== initialValues.longitude
          ? values.longitude
          : undefined,
      latitude:
        values.latitude !== initialValues.latitude
          ? values.latitude
          : undefined,
    }),
    [initialValues]
  );

  const onSubmit = useCallback(
    async (
      values: LotFormValues,
      { setErrors }: FormikHelpers<LotFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }
        setAreDetailsLoading(true);

        const valuesToUpdate = getChangedValues(values);

        await updateLot(id, valuesToUpdate);

        if (isMounted()) {
          setInitialValues(values);
          onLotNameChange(values.name);
          setAreDetailsLoading(false);
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setAreDetailsLoading(false);
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      updateLot,
      getChangedValues,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      id,
      setMessage,
      onLotNameChange,
    ]
  );

  return (
    <Form
      name='lot'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isLoading={areDetailsLoading}
    >
      <LotInfoForm />
    </Form>
  );
}
