import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import type CameraParkingPlaces from 'src/detection/models/CameraParkingPlaces';
import type DetectCamera from 'src/detection/models/DetectCamera';
import type ParkingPlace from 'src/detection/models/ParkingPlace';
import ParkingDetectionStatus from 'src/products/constants/ParkingDetectionStatus';
import {
  DetectCameraImg,
  DetectCameraWrapper,
  StyledSvg,
  UpdateInfo,
} from 'src/shared/components/DetectCamera';
import Dropdown from 'src/shared/components/Dropdown';
import Spinner from 'src/shared/components/Spinner';
import HoverDropdownDimensions from 'src/shared/constants/HoverDropdownDimensions';
import formatDates from 'src/shared/utils/formatDates';
import { getDetectionStatusColor } from 'src/shared/utils/getDetectionStatusColor';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useDetectCameraService from '../services/useDetectCameraService';
import ParkingPlaceInfo from './ParkingPlaceInfo';

interface DetectCameraListProps {
  lotDetectionStatus: ParkingDetectionStatus | undefined;
  lastUpdate: string | undefined;
  parkingPlaces: ParkingPlace[];
}

interface CameraPlaces {
  id: number;
  mergedPlaces: CameraParkingPlaces[] | undefined;
  viewBox: string;
}

interface CameraImage {
  id: number;
  frame: string;
}

const DROPDOWN_ID = 'info';
const DROPDOWN_OFFSET = 15;
const INTERVAL = 15 * 1000;

export default function DetectCameraList(props: DetectCameraListProps) {
  const { lotDetectionStatus, lastUpdate, parkingPlaces } = props;
  const { params } = useRouteMatch<{ lotId?: string }>();
  const { lotId } = params;
  const [cameras, setCameras] = useState<DetectCamera[]>([]);
  const [images, setImages] = useState<CameraImage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeData, setActiveData] = useState<
    CameraParkingPlaces | undefined
  >();
  const [style, setStyle] = useState<{
    top: number;
    left: number;
    visible: boolean;
  }>({
    top: 0,
    left: 0,
    visible: false,
  });
  const hoverDivRef = useRef<HTMLDivElement>(null);
  const { findAllDetectCameras, findDetectCameraFrame } =
    useDetectCameraService();
  const isMounted = useIsMounted();

  const id = useMemo(() => {
    if (!lotId) {
      return undefined;
    }

    return parseInt(lotId);
  }, [lotId]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!id) {
          if (isMounted()) {
            setIsLoading(false);
          }
          return;
        }

        const { data } = await findAllDetectCameras({ lotId: id });

        if (isMounted()) {
          setCameras(data);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [id, isMounted, findAllDetectCameras]);

  const cameraPlaces: CameraPlaces[] = useMemo(() => {
    const cam = cameras.map((cam) => {
      const mergedPlaces: CameraParkingPlaces[] | undefined =
        cam.viewSchema?.parkingPlaces.map((viewSchemaPlace) => {
          const placeDetails = parkingPlaces.find(
            (place) => place.id === viewSchemaPlace.id
          );
          return {
            placeId: placeDetails?.id,
            name: placeDetails?.name,
            occupancyStatus: placeDetails?.occupancyStatus,
            updatedAt: placeDetails?.updatedAt,
            cx: viewSchemaPlace.cx,
            cy: viewSchemaPlace.cy,
            r: viewSchemaPlace.r,
          };
        });

      return {
        id: cam.id,
        mergedPlaces: mergedPlaces || [],
        viewBox: cam.viewSchema?.viewBox || '',
      };
    });
    return cam;
  }, [cameras, parkingPlaces]);

  const getFrame = useCallback(async () => {
    try {
      if (cameras.length === 0) return;

      const frames = await Promise.all(
        cameras.map((c) => findDetectCameraFrame(c.id))
      );
      const images: CameraImage[] = frames.map((frame, i) => ({
        id: cameras[i].id,
        frame,
      }));

      if (isMounted()) {
        setIsLoading(false);
        setImages(images);
      }
    } catch (error) {
      if (isMounted()) {
        setIsLoading(false);
      }
      throw error;
    }
  }, [cameras, findDetectCameraFrame, isMounted]);

  useEffect(() => {
    getFrame();
    const timer = setInterval(() => {
      getFrame();
    }, INTERVAL);
    return () => clearInterval(timer);
  }, [getFrame]);

  const close = useCallback(() => {
    setStyle({
      top: 0,
      left: 0,
      visible: false,
    });
    setActiveData(undefined);
  }, []);

  const calculateHorizontalEdges = useCallback((position: number) => {
    const width = window.innerWidth;

    if (position - HoverDropdownDimensions.WIDTH / 2 < 0) {
      return DROPDOWN_OFFSET;
    }

    if (
      position + HoverDropdownDimensions.WIDTH / 2 + DROPDOWN_OFFSET >
      width
    ) {
      return width - HoverDropdownDimensions.WIDTH - DROPDOWN_OFFSET;
    }

    return position - HoverDropdownDimensions.WIDTH / 2;
  }, []);

  const calculateVerticalEdges = useCallback(
    (position: number, circleHeight: number) => {
      const height = window.innerHeight;

      if (position < 0) {
        return DROPDOWN_OFFSET + circleHeight;
      }
      if (position + HoverDropdownDimensions.HEIGHT > height) {
        return (
          position -
          HoverDropdownDimensions.HEIGHT -
          circleHeight -
          DROPDOWN_OFFSET
        );
      }

      return position;
    },
    []
  );

  const open = useCallback(
    (placeId: number | undefined, circleHeight: number | undefined) => {
      if (!placeId || !circleHeight) return;

      const element = document.getElementById(placeId.toString());
      const height_offset = 10;

      if (!element) return;
      const dimensions = element.getBoundingClientRect();
      const { bottom, left } = dimensions;

      const x = left;
      const y = bottom + height_offset;

      setStyle({
        top: calculateVerticalEdges(y, circleHeight),
        left: calculateHorizontalEdges(x),
        visible: true,
      });
    },
    [calculateVerticalEdges, calculateHorizontalEdges]
  );

  const isLotOnline = useMemo(
    () => lotDetectionStatus === ParkingDetectionStatus.ONLINE,
    [lotDetectionStatus]
  );

  return (
    <>
      {isLoading ? (
        <Spinner primary detectCamera />
      ) : (
        cameraPlaces.map((camera) => {
          const { id, viewBox, mergedPlaces } = camera;

          const image = images.find((img) => img.id === id);
          return (
            <DetectCameraWrapper key={id}>
              <Dropdown
                close={close}
                id={DROPDOWN_ID}
                parkingPlaceStyle={style}
                ref={hoverDivRef}
              >
                <ParkingPlaceInfo
                  data={activeData}
                  lotDetectionStatus={lotDetectionStatus}
                />
              </Dropdown>
              {viewBox && (
                <StyledSvg
                  viewBox={viewBox}
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                >
                  {mergedPlaces &&
                    mergedPlaces.map((place) => {
                      const { placeId, occupancyStatus, cx, cy, r } = place;
                      const showInfo = () => {
                        setActiveData(place);
                        open(placeId, parseInt(r));
                      };

                      return (
                        <g
                          key={placeId}
                          onMouseEnter={showInfo}
                          onMouseLeave={close}
                          id={placeId?.toString()}
                        >
                          <circle
                            cx={cx}
                            cy={cy}
                            r={r}
                            fill={
                              occupancyStatus &&
                              getDetectionStatusColor(
                                occupancyStatus,
                                isLotOnline
                              )
                            }
                            fillOpacity='0.5'
                          />
                          <circle
                            cx={cx}
                            cy={cy}
                            r={0.8 * parseInt(r)}
                            fill={
                              occupancyStatus &&
                              getDetectionStatusColor(
                                occupancyStatus,
                                isLotOnline
                              )
                            }
                            strokeLinejoin='round'
                            strokeWidth='1'
                            strokeLinecap='round'
                            stroke='#000'
                          />
                        </g>
                      );
                    })}
                </StyledSvg>
              )}
              {!isLotOnline && (
                <UpdateInfo>
                  {lastUpdate
                    ? `Last updated: ${formatDates(lastUpdate, true)}`
                    : undefined}
                </UpdateInfo>
              )}
              <DetectCameraImg
                src={image?.frame}
                alt='Parking places'
                addOpacity={!isLotOnline}
                position={viewBox ? 'absolute' : 'relative'}
              />
            </DetectCameraWrapper>
          );
        })
      )}
    </>
  );
}
