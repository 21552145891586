import type ProductGate from 'src/lots/models/ProductGate';
import type ProductGateResponse from 'src/lots/models/ProductGateResponse';

export default function toProductGate(data: ProductGateResponse): ProductGate {
  const { id, state, permanent_position } = data;

  return {
    id,
    state,
    permanentPosition: permanent_position,
  };
}
