import { useCallback } from 'react';

import toBollard from 'src/products/mappers/Bollard/toBollard';
import toBollardQuery from 'src/products/mappers/Bollard/toBollardQuery';
import toBollardUpdateParams from 'src/products/mappers/Bollard/toBollardUpdateParams';
import type Bollard from 'src/products/models/Bollard/Bollard';
import type BollardFormValues from 'src/products/models/Bollard/BollardFormValues';
import type BollardQuery from 'src/products/models/Bollard/BollardQuery';
import type BollardQueryParams from 'src/products/models/Bollard/BollardQueryParams';
import type BollardRemoveResponse from 'src/products/models/Bollard/BollardRemoveResponse';
import type BollardResponse from 'src/products/models/Bollard/BollardResponse';
import type BollardUpdateParams from 'src/products/models/Bollard/BollardUpdateParams';
import type BollardUpdateResponse from 'src/products/models/Bollard/BollardUpdateResponse';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';

const bollardBaseURL = '/bollards';
interface BollardService {
  findAllBollards: (
    queryParams: BollardQueryParams
  ) => Promise<GetAllDetails<Bollard>>;
  findOneBollard: (id: number) => Promise<Bollard>;
  updateBollard: (
    id: number,
    values: BollardFormValues
  ) => Promise<BollardUpdateResponse>;
  removeBollard: (id: number) => Promise<BollardRemoveResponse>;
}

export default function useBollardService(): BollardService {
  const { get, remove, patch } = useApiService();

  const findAllBollards = useCallback(
    async (queryParams: BollardQueryParams) => {
      const query = toBollardQuery(queryParams);
      const response = await get<BollardResponse[], BollardQuery>(
        `${bollardBaseURL}`,
        query
      );

      const data = response.data.map(toBollard);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findOneBollard = useCallback(
    async (id: number) => {
      const response = await get<BollardResponse>(`${bollardBaseURL}/${id}`);

      const data = toBollard(response.data);

      return data;
    },
    [get]
  );

  const updateBollard = useCallback(
    async (id: number, values: BollardFormValues) => {
      const params = toBollardUpdateParams(values);
      const response = await patch<BollardUpdateParams, BollardUpdateResponse>(
        `${bollardBaseURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeBollard = useCallback(
    async (id: number) => {
      const response = await remove<BollardRemoveResponse>(
        `${bollardBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return { findAllBollards, findOneBollard, updateBollard, removeBollard };
}
