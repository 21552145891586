import { useCallback } from 'react';
import toBarrierQuery from 'src/products/mappers/Barrier/toBarrierQuery';
import toBarrierUpdateParams from 'src/products/mappers/Barrier/toBarrierUpdateParams';
import type Barrier from 'src/products/models/Barrier/Barrier';
import type BarrierQuery from 'src/products/models/Barrier/BarrierQuery';
import type BarrierQueryParams from 'src/products/models/Barrier/BarrierQueryParams';
import type BarrierRemoveResponse from 'src/products/models/Barrier/BarrierRemoveResponse';
import type BarrierResponse from 'src/products/models/Barrier/BarrierResponse';
import type BarrierUpdateFormValues from 'src/products/models/Barrier/BarrierUpdateFormValues';
import type BarrierUpdateParams from 'src/products/models/Barrier/BarrierUpdateParams;';
import type BarrierUpdateResponse from 'src/products/models/Barrier/BarrierUpdateResponse';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toBarrier from '../mappers/Barrier/toBarrier';

const barrierBaseURL = '/barriers';
interface BarrierService {
  findAllBarriers: (
    queryParams: BarrierQueryParams
  ) => Promise<GetAllDetails<Barrier>>;
  findOneBarrier: (id: number) => Promise<Barrier>;
  updateBarrier: (
    id: number,
    values: BarrierUpdateFormValues
  ) => Promise<BarrierUpdateResponse>;
  removeBarrier: (id: number) => Promise<BarrierRemoveResponse>;
}

export default function useBarrierService(): BarrierService {
  const { get, remove, patch } = useApiService();

  const findAllBarriers = useCallback(
    async (queryParams: BarrierQueryParams) => {
      const query = toBarrierQuery(queryParams);
      const response = await get<BarrierResponse[], BarrierQuery>(
        `${barrierBaseURL}`,
        query
      );

      const data = response.data.map(toBarrier);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findOneBarrier = useCallback(
    async (id: number) => {
      const response = await get<BarrierResponse>(`${barrierBaseURL}/${id}`);

      return toBarrier(response.data);
    },
    [get]
  );

  const updateBarrier = useCallback(
    async (id: number, values: BarrierUpdateFormValues) => {
      const params = toBarrierUpdateParams(values);
      const response = await patch<BarrierUpdateParams, BarrierUpdateResponse>(
        `${barrierBaseURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeBarrier = useCallback(
    async (id: number) => {
      const response = await remove<BarrierRemoveResponse>(
        `${barrierBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return { findAllBarriers, findOneBarrier, updateBarrier, removeBarrier };
}
