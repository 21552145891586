import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import type { ModalType } from '../../contexts/GlobalModalContext';

interface GlobalModalRootProps {
  /**
   * Map of modal instances associated by unique ids
   */
  modals: Record<string, ModalType>;
  /**
   * Container component for modals
   *
   * Modals will be rendered as children of this component. React.Fragment is
   * used by defualt, specifying a different component can change the way modals
   * are rendered across the whole application.
   */
  component?: React.ComponentType<any>;
  /**
   * Specifies the root element to render modals into
   */
  container?: Element;
}

interface ModalRendererProps {
  /**
   * Functional component representing the modal
   */
  component: ModalType;
}

/**
 * Component responsible for rendering the modal.
 *
 * The identity of `Component` may change dependeing on the inputs passed to
 * `useModal`. If we simply rendered `<Component />` then the modal would be
 * susceptible to rerenders whenever one of the inputs change.
 */

const Modal = ({ component, ...rest }: ModalRendererProps) => component(rest);
const ModalRenderer = memo(Modal);

/**
 * Modal Root
 *
 * Renders modals using react portal.
 */
const GlobalModal = ({
  modals,
  container,
  component: RootComponent = React.Fragment,
}: GlobalModalRootProps) => {
  const [mountNode, setMountNode] = useState<Element | undefined>(undefined);

  // This effect will not be ran in the server environment
  useEffect(() => setMountNode(container || document.body), [container]);

  return mountNode
    ? ReactDOM.createPortal(
        <RootComponent>
          {Object.keys(modals).map((key) => (
            <ModalRenderer key={key} component={modals[key]} />
          ))}
        </RootComponent>,
        mountNode
      )
    : null;
};

export const GlobalModalRoot = React.memo(GlobalModal);
