import { START_DATE, useDatepicker } from '@datepicker-react/hooks';
import type { OnDatesChangeProps } from '@datepicker-react/hooks';
import { useField } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DatePickerContext from 'src/shared/contexts/DatePickerContext';
import { areNotSameDates } from 'src/shared/utils/checks';
import generateTimeLabel from 'src/shared/utils/generateTimeLabel';
import cutOffAt from '../../shared/utils/cutOffAt';
import SearchSelectIcon from '../constants/SearchSelectIcon';
import type Option from '../models/Option';
import Calendar from './DateField/Calendar';
import Icon from './DateField/Icon';
import {
  StyledColumn,
  StyledDateContainer,
  StyledDateTimeContainer,
  StyledDateTimeWrapper,
  StyledInput,
  StyledPlaceholder,
  StyledText,
  StyledTimeContainer,
} from './DateTimeStyle';
import Dropdown from './Dropdown';
import Error from './Error';
import Label from './Label';
import TimeIcon from './SelectField/Icon';
import Options from './SelectField/Options';

interface DateTimeFieldProps {
  name: string;
  label: string;
  placeholderDate: string;
  placeholderTime: string;
  disabled?: boolean;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  minutesOffset?: number;
  endTimeOffset?: boolean;
  noInitialValue?: boolean;
  block?: boolean;
}

export default function DateTimeField(props: DateTimeFieldProps) {
  const {
    name,
    label,
    disabled,
    placeholderDate,
    placeholderTime,
    minBookingDate,
    maxBookingDate,
    endTimeOffset,
    block,
    noInitialValue,
  } = props;
  const [field, meta, helpers] = useField({ name });
  const wrapperRefDate = useRef<React.ElementRef<'div'>>(null);
  const wrapperRefTime = useRef<React.ElementRef<'div'>>(null);
  const inputRefDate = useRef<React.ElementRef<'div'>>(null);
  const inputRefTime = useRef<React.ElementRef<'input'>>(null);
  const [isTimeDropdownOpen, setIsTimeDropdownOpen] = useState(false);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isAdditionalDropdownOpen, setIsAdditionalDropdownOpen] =
    useState(false);
  const [shouldSetTouchedDate, setShouldSetTouchedDate] = useState(false);
  const [shouldSetTouchedTime, setShouldSetTouchedTime] = useState(false);

  const { value } = field;
  const { error, touched } = meta;
  const shouldShowError = error && touched;
  const { setValue, setTouched } = helpers;
  const minutesOffset = useMemo(
    () => props.minutesOffset || 30,
    [props.minutesOffset]
  );

  const dateValue = useRef(value || (noInitialValue ? null : new Date()));

  const getMinutes = useCallback(
    (value: Date) => value.getHours() * 60 + value.getMinutes(),
    []
  );

  const times = useMemo(() => {
    const dayMinutes = 24 * 60;
    const times = [];

    for (let offset = 0; offset < dayMinutes; offset += minutesOffset) {
      times.push({
        key: offset,
        label: generateTimeLabel(offset),
      });
    }

    return times;
  }, [minutesOffset]);

  const generateTime = useCallback(
    (date: Date | null): Option[] => {
      if (!date) return [{ key: -1, label: '' }];

      if (minBookingDate && !areNotSameDates(date, minBookingDate)) {
        const minDateMinutes = getMinutes(minBookingDate);
        return times.filter((time) =>
          endTimeOffset
            ? minDateMinutes < time.key
            : minDateMinutes - minutesOffset < time.key
        );
      }

      return times;
    },
    [endTimeOffset, getMinutes, minBookingDate, times, minutesOffset]
  );

  const timeValue = useRef<Option | null>(
    noInitialValue ? null : generateTime(dateValue.current)[0]
  );

  useEffect(() => {
    if (!value && noInitialValue) {
      dateValue.current = null;
      timeValue.current = null;
      return;
    }

    if (!value && dateValue.current && timeValue.current) {
      const newDate = new Date(dateValue.current);
      newDate.setHours(
        Math.floor(timeValue.current.key / 60),
        timeValue.current.key % 60
      );
      dateValue.current = newDate;
      setValue(newDate);
    }
  }, [value, setValue, noInitialValue]);

  const onSelectTime = useCallback(
    (option: Option) => {
      if (!dateValue.current) return;
      const newDate = new Date(dateValue.current);
      if (timeValue.current && option.key === timeValue.current.key) {
        timeValue.current = null;
        newDate.setHours(0, 0);
        if (
          endTimeOffset &&
          minBookingDate &&
          !areNotSameDates(newDate, minBookingDate)
        ) {
          newDate.setHours(23, 59, 59);
          dateValue.current = newDate;
        }
        setValue(newDate);
        return;
      }

      newDate.setHours(Math.floor(option.key / 60), option.key % 60);

      dateValue.current = newDate;
      timeValue.current = option;

      setValue(newDate);
    },
    [setValue, endTimeOffset, minBookingDate]
  );

  const pickDate = useCallback(
    (data: OnDatesChangeProps) => {
      const { startDate } = data;
      setIsDateDropdownOpen(false);

      if (!startDate) return;

      if (!areNotSameDates(value, startDate)) {
        setValue(null);
        timeValue.current = null;
        dateValue.current = null;
        return;
      }

      if (timeValue.current) {
        let newTimeValue = timeValue.current;
        const timeOptions = generateTime(startDate);
        const index = timeOptions.findIndex(
          (time) => time.key === timeValue.current?.key
        );
        if (index === -1) {
          newTimeValue = timeOptions[0];
          if (!newTimeValue) {
            startDate.setDate(startDate.getDate() + 1);
            const newTime = generateTime(startDate)[0];
            startDate.setHours(Math.floor(newTime.key / 60), newTime.key % 60);
            dateValue.current = startDate;
            timeValue.current = newTime;
            setValue(startDate);
            return;
          }
        }

        timeValue.current = newTimeValue;
        startDate.setHours(
          Math.floor(newTimeValue.key / 60),
          newTimeValue.key % 60
        );
      } else if (
        endTimeOffset &&
        minBookingDate &&
        !areNotSameDates(startDate, minBookingDate)
      ) {
        startDate.setHours(23, 59, 59);
      }

      dateValue.current = startDate;
      setValue(startDate);
    },
    [endTimeOffset, generateTime, minBookingDate, setValue, value]
  );

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
    goToDate,
  } = useDatepicker({
    startDate: dateValue.current,
    minBookingDate,
    maxBookingDate,
    endDate: null,
    focusedInput: START_DATE,
    onDatesChange: pickDate,
    numberOfMonths: 1,
  });

  useEffect(() => {
    if (timeValue.current && dateValue.current) {
      if (endTimeOffset && minBookingDate) {
        if (dateValue.current.getTime() < minBookingDate.getTime()) {
          goToDate(minBookingDate);
          dateValue.current = minBookingDate;
          setValue(minBookingDate);
        }
      }

      const timeOptions = generateTime(dateValue.current);
      const index = timeOptions.findIndex(
        (time) => time.key === timeValue.current?.key
      );

      if (index === -1) {
        const newValue = timeOptions[0];
        const newDate = new Date(dateValue.current);
        if (!newValue) {
          newDate.setDate(newDate.getDate() + 1);
          const newTime = generateTime(newDate)[0];
          timeValue.current = newTime;
          newDate.setHours(Math.floor(newTime.key / 60), newTime.key % 60);
          dateValue.current = newDate;
          setValue(newDate);
          return;
        }

        timeValue.current = newValue;
        newDate.setHours(Math.floor(newValue.key / 60), newValue.key % 60);
        dateValue.current = newDate;
        setValue(newDate);
        return;
      }
    }
    if (!timeValue.current && dateValue.current) {
      if (endTimeOffset && minBookingDate) {
        if (
          new Date(dateValue.current.toDateString()).getTime() <
          new Date(minBookingDate.toDateString()).getTime()
        ) {
          minBookingDate.setHours(23, 59, 59);
          dateValue.current = minBookingDate;
          setValue(minBookingDate);
        }
      }
    }
  }, [
    generateTime,
    minBookingDate,
    endTimeOffset,
    goToDate,
    setValue,
    getMinutes,
  ]);

  useEffect(() => {
    if (value === null) {
      goToDate(new Date());
    }
  }, [goToDate, value]);

  const activePlaceholder = useMemo(() => {
    if (isTimeDropdownOpen && timeValue.current != null) {
      return timeValue.current.label;
    }
    return placeholderTime;
  }, [placeholderTime, isTimeDropdownOpen]);

  const openTime = useCallback(() => {
    setIsTimeDropdownOpen(true);

    if (!shouldSetTouchedTime) {
      setShouldSetTouchedTime(true);
    }
  }, [setIsTimeDropdownOpen, shouldSetTouchedTime]);

  const openDate = useCallback(() => {
    setIsDateDropdownOpen(true);

    if (!shouldSetTouchedDate) {
      setShouldSetTouchedDate(true);
    }
  }, [setIsDateDropdownOpen, shouldSetTouchedDate]);

  const closeTime = useCallback(() => {
    setIsTimeDropdownOpen(false);
    setShouldSetTouchedTime(false);
  }, []);

  const closeDate = useCallback(() => {
    setIsDateDropdownOpen(false);
    setShouldSetTouchedDate(false);
  }, []);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (
        !wrapperRefDate?.current?.contains(event.target as Node) &&
        shouldSetTouchedDate &&
        !isAdditionalDropdownOpen
      ) {
        closeDate();
        goToDate(value);
        setTouched(true);
      }

      if (
        !wrapperRefTime?.current?.contains(event.target as Node) &&
        shouldSetTouchedTime
      ) {
        closeTime();
        setTouched(true);
      }
    };

    document.addEventListener('click', listener, { capture: true });
    document.addEventListener('focusin', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
      document.removeEventListener('focusin', listener, { capture: true });
    };
  }, [
    closeDate,
    closeTime,
    setTouched,
    shouldSetTouchedDate,
    shouldSetTouchedTime,
    isAdditionalDropdownOpen,
    goToDate,
    value,
  ]);

  const focusInputDate = useCallback(() => {
    inputRefDate?.current?.focus();
  }, []);

  const focusInputTime = useCallback(() => {
    inputRefTime?.current?.focus();
  }, []);

  const toggleAdditionalDropdown = useCallback((value: boolean) => {
    setIsAdditionalDropdownOpen(value);
  }, []);

  return (
    <DatePickerContext.Provider
      value={{
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths,
        goToDate,
      }}
    >
      <StyledDateTimeWrapper block={block}>
        <Label stacked onClick={focusInputDate}>
          {label}
        </Label>
        <StyledDateTimeContainer block={block}>
          <StyledColumn ref={wrapperRefDate}>
            <StyledDateContainer
              tabIndex={0}
              onFocus={disabled ? undefined : openDate}
              ref={inputRefDate}
              disabled={disabled}
            >
              {!!dateValue.current && (
                <StyledText>{dateValue.current.toDateString()}</StyledText>
              )}
              {!dateValue.current && (
                <StyledPlaceholder>{placeholderDate}</StyledPlaceholder>
              )}
              <Icon />
            </StyledDateContainer>
            {isDateDropdownOpen && (
              <Dropdown close={closeDate} dateTimeView>
                <Calendar
                  dateTimeView
                  setIsAdditionalDropdownOpen={toggleAdditionalDropdown}
                  minBookingDate={minBookingDate}
                  maxBookingDate={maxBookingDate}
                />
              </Dropdown>
            )}
          </StyledColumn>
          <StyledColumn ref={wrapperRefTime}>
            <StyledTimeContainer
              onFocus={disabled ? undefined : openTime}
              block={block}
              disabled={disabled}
            >
              <StyledInput
                ref={inputRefTime}
                type='text'
                readOnly
                // inline function due to React 18 - setState causes infinite loop, ref does not trigger rerender
                value={cutOffAt(timeValue.current?.label || '', 48)}
                placeholder={cutOffAt(activePlaceholder, 48)}
                disabled={disabled}
              />
              <TimeIcon icon={SearchSelectIcon.TIME} onClick={focusInputTime} />
            </StyledTimeContainer>
            {shouldShowError && <Error>*{error}</Error>}
            {isTimeDropdownOpen && (
              <Dropdown close={closeTime}>
                <Options
                  value={timeValue.current}
                  onSelect={onSelectTime}
                  // inline function due to React 18 - setState causes infinite loop, ref does not trigger rerender
                  options={generateTime(dateValue.current)}
                />
              </Dropdown>
            )}
          </StyledColumn>
        </StyledDateTimeContainer>
      </StyledDateTimeWrapper>
    </DatePickerContext.Provider>
  );
}
