import styled from 'styled-components';
import Color from '../constants/Color';

const AddIcon = styled.i`
  width: 100%;
  color: ${Color.PARKING_PLACE_UNKNOWN};
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: ${Color.SHADOW_DARKEST_TRANSPARENT};
  }
`;

export default AddIcon;
