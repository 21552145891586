import type AccountInfo from '../models/AccountInfo';
import type AccountInfoFormValues from '../models/AccountInfoFormValues';

export default function toAccountInfoByIdFormValues(
  data: AccountInfo
): AccountInfoFormValues | undefined {
  const { name, accountRole, contactEmail, contactName, contactPhone } = data;

  return {
    name: name || '',
    accountRole: accountRole
      ? { key: accountRole.id, label: accountRole.name }
      : null,
    contactEmail: contactEmail || '',
    contactName: contactName || '',
    contactPhone: contactPhone || '',
  };
}
