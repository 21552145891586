import React from 'react';
import { ParkingDetectionImplementationStatus } from 'src/products/constants/ParkingDetectionImplementationStatus';
import Card from 'src/shared/components/Card';
import StyledNoData from 'src/shared/components/StyledNoData';
import Color from 'src/shared/constants/Color';
import styled from 'styled-components';

interface NoDetectionMessageProps {
  implementationStatus: ParkingDetectionImplementationStatus;
}

export default function NoDetectionMessage(props: NoDetectionMessageProps) {
  const { implementationStatus } = props;

  return (
    <Card>
      <StyledNoData>
        {implementationStatus ===
          ParkingDetectionImplementationStatus.PENDING && (
          <>
            <StyledP>
              Parklio™ Detect&#39;s implementation is currently in progress.
            </StyledP>
            <StyledP>
              Please be patient as it will take some time for the AI system to
              implement the software in your parking lot/area. You will receive
              an email notification once the process has been completed.
            </StyledP>
            <StyledP>
              If you have any questions regarding the pending status, please
              contact{' '}
              <StyledLink href='mailto:support@parklio.com'>
                support@parklio.com
              </StyledLink>
              .
            </StyledP>
          </>
        )}
        {implementationStatus ===
          ParkingDetectionImplementationStatus.BLOCKED && (
          <>
            <StyledP>Parklio™ Detect is currently blocked for use.</StyledP>
            <StyledP>
              For further instructions on how to resolve this issue, please
              contact the Parklio Support Team at{' '}
              <StyledLink href='mailto:support@parklio.com'>
                support@parklio.com
              </StyledLink>
              .
            </StyledP>
          </>
        )}
        {implementationStatus ===
          ParkingDetectionImplementationStatus.NOT_IMPLEMENTED && (
          <StyledP>
            Parklio™ Detect has not been implemented to this parking lot.
          </StyledP>
        )}
      </StyledNoData>
    </Card>
  );
}

const StyledLink = styled.a`
  text-decoration: none;
  color: ${Color.PRIMARY_TRANSPARENT};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

const StyledP = styled.p`
  margin: 0 0 10px 0;

  :last-child {
    margin-bottom: 0;
  }
`;
