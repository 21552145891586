import { useField } from 'formik';
import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import Color from '../../shared/constants/Color';
import type InputIcon from '../constants/InputIcon';

interface InputProps {
  name: string;
  icon: InputIcon;
  label: string;
  type: string;
  placeholder: string;
  autoComplete?: string;
}

export default function Input(props: InputProps) {
  const { icon, label, placeholder, name, type, autoComplete } = props;
  const ref = useRef<React.ElementRef<'input'>>(null);
  const [field, meta] = useField({ name, type });
  const { onBlur, onChange, value } = field;
  const { touched, error } = meta;
  const shouldRenderError = touched && error;

  const focusInput = useCallback(() => {
    ref?.current?.focus();
  }, []);

  return (
    <>
      <StyledWrapper onClick={focusInput}>
        <StyledIcon className={icon} />
        <StyledColumn>
          <StyledLabel>{label}</StyledLabel>
          <StyledInput
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            type={type}
            placeholder={placeholder}
            name={name}
            ref={ref}
            autoComplete={autoComplete}
          />
        </StyledColumn>
      </StyledWrapper>
      {shouldRenderError && <StyledError>*{error}</StyledError>}
    </>
  );
}

const StyledWrapper = styled.div`
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: flex-start;
  margin: 20px 0px;
  width: 100%;

  :first-of-type {
    margin-top: 0px;
  }

  :last-of-type {
    margin-bottom: 0px;
  }
`;

const StyledLabel = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${Color.TEXT_DARKER};
`;

const StyledInput = styled.input`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${Color.TEXT_DARKER};
  background-color: ${Color.BACKGROUND_LIGHTEST};
  width: 100%;
  border: none;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${Color.TEXT_LIGHT};
  }
`;

const StyledError = styled.p`
  color: ${Color.ERROR};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
`;

const StyledIcon = styled.i`
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 27px;
  text-align: center;
  color: ${Color.PRIMARY_BRAND};
`;
