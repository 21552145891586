import AccountUserRole from '../../shared/constants/AccountUserRole';
import type AccountUserCreateRequest from '../models/AccountUserCreateRequest';
import type AccountUserFormValues from '../models/AccountUserFormValues';

export default function toAccountUserCreateRequest(
  data: AccountUserFormValues
): AccountUserCreateRequest {
  const { name, email, role, phone } = data;

  return {
    name,
    email,
    phone: phone ? phone.replace(/\s/g, '') : null,
    account_user_role_id: role ? role.key : AccountUserRole.OPERATOR,
  };
}
