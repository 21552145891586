import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from 'src/shared/components/Spinner';
import styled from 'styled-components';
import useAccountUserService from '../../settings/services/useAccountUserService';
import Color from '../../shared/constants/Color';
import useIsMounted from '../../shared/hooks/useIsMounted';
import Container from '../components/Container';
import ParklioSign from '../components/ParklioSign';
import Title from '../components/Title';

export default function EmailConfirmation() {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { emailConfirmation } = useAccountUserService();
  const isMounted = useIsMounted();
  const location = useLocation();
  const { search } = location;

  const { token, signature } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as { [key: string]: string };

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }

        await emailConfirmation(token, signature);

        if (isMounted()) {
          setSuccess(true);
        }
      } catch {
        if (isMounted()) {
          setSuccess(false);
        }
      } finally {
        if (isMounted()) {
          setIsLoading(false);
        }
      }
    };
    getData();
  }, [emailConfirmation, isMounted, token, signature]);

  return (
    <Container removeText>
      <ParklioSign />
      <Title>Email confirmation</Title>
      <StyledDiv>
        {isLoading ? (
          <Spinner primary data-testid='spinner' />
        ) : (
          <StyledText>
            {success ? (
              <>
                Success!<p>Email successfully confirmed.</p>
              </>
            ) : (
              <>
                Error!<p>Invalid URL signature.</p>
              </>
            )}
          </StyledText>
        )}
      </StyledDiv>
    </Container>
  );
}

const StyledText = styled.div`
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: ${Color.TEXT_DARKER};
  margin: 10px 0 30px 0;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: normal;
  }
`;

const StyledDiv = styled.div`
  width: 80%;
  max-width: 500px;
  padding: 35px 30px;
  background-color: ${Color.BACKGROUND_LIGHTEST};
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 10px;
  display: flex;
  min-height: 250px;
  text-align: center;
  align-items: center;
  margin-top: 2%;
  justify-content: center;
`;
