import React from 'react';
import styled from 'styled-components';
import Color from '../../constants/Color';

interface DeleteButtonProps {
  onClick: () => void;
}

export default function DeleteButton(props: DeleteButtonProps) {
  const { onClick } = props;

  return (
    <StyledWrapper onClick={onClick}>
      <StyledIcon className='pa-delete' />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: auto auto 14px 0px;
  cursor: pointer;

  @media (max-width: 520px) {
    margin-top: 40px;
  }
`;

const StyledIcon = styled.i`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 20px;
  margin: 0px 10px 0px 0px;
  color: ${Color.PRIMARY_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;
