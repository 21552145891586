import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RotaryList from '../screens/RotaryList';
import RotaryParkingPlaces from '../screens/RotaryParkingPlaces';

export default function Rotaries() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <RotaryList />
      </Route>
      <Route path={`${path}/:rotaryId`}>
        <RotaryParkingPlaces />
      </Route>
      <Route>
        <h3>404 PAGE</h3>
      </Route>
    </Switch>
  );
}
