import type Currency from 'src/shared/models/Currency';
import type CurrencyResponse from 'src/shared/models/CurrencyResponse';

export default function toCurrency(data: CurrencyResponse): Currency {
  const { id, name, code, symbol, created_at, updated_at, deleted_at } = data;

  return {
    id,
    name,
    code,
    symbol,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    deletedAt: deleted_at ? new Date(deleted_at) : null,
  };
}
