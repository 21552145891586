import { FieldArray } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type ZoneModelData from 'src/lots/models/Zone';
import type ZoneData from 'src/lots/models/ZoneData';
import type ZoneDropdown from 'src/lots/models/ZoneDropdown';
import Button from 'src/shared/components/Button';
import SelectFieldWithoutFormik from 'src/shared/components/SelectField/SelectFieldWithoutFormik';
import Spinner from 'src/shared/components/Spinner';
import Title from 'src/shared/components/Title';
import ButtonSize from 'src/shared/constants/ButtonSize';
import Color from 'src/shared/constants/Color';
import HTMLIds from 'src/shared/constants/HTMLIds';
import type Tariff from 'src/tariffs/models/Tariff';
import type TariffId from 'src/tariffs/models/TariffId';
import styled, { css } from 'styled-components';
import AppRole from '../../shared/constants/AppRole';
import useUserHasRole from '../../shared/hooks/useUserHasRole';
import type Option from '../../shared/models/Option';
import Zone from './Zone';

interface ZonesProps {
  openRemoveModal: (data: ZoneModelData) => void;
  onAdd: () => void;
  data: ZoneData[];
  initialValues: ZoneDropdown;
  newZoneId: Option | undefined;
  onCameraNameChange: (name: string, id: number, zoneId?: number) => void;
  onProductNameChange: (name: string, id: number, zoneId?: number) => void;
  onTariffChange: (tariffs: TariffId[] | Tariff, zoneId: number) => void;
  findCurrencySymbol: (id: number) => void;
  invoiceCurrency:
    | { id: number | undefined; symbol: string | undefined }
    | undefined;
  accountId: number | undefined;
  isLoading?: boolean;
}

const changePositionOn = 190;

export default function Zones(props: ZonesProps) {
  const {
    data,
    openRemoveModal,
    initialValues,
    isLoading,
    onAdd,
    newZoneId,
    onCameraNameChange,
    onProductNameChange,
    onTariffChange,
    findCurrencySymbol,
    invoiceCurrency,
    accountId,
  } = props;
  const [changePosition, setChangePosition] = useState(false);
  const [search, setSearch] = useState('');
  const [toggle, setToggle] = useState<{ [key: number]: boolean }>({});
  const [zoneId, setZoneId] = useState<Option | null | undefined>(null);
  const [elementId, setElementId] = useState(zoneId?.key.toString());
  const userHasRole = useUserHasRole();

  const userCanManageZones = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN |
      AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const scrollTo = useCallback(() => {
    if (!elementId) {
      return;
    }

    const tmp = document.getElementById(elementId);

    if (!tmp) {
      return;
    }

    tmp.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [elementId]);

  useEffect(() => {
    if (zoneId) {
      setElementId(zoneId.toString());
      scrollTo();
    }
  }, [zoneId, scrollTo]);

  useEffect(() => {
    if (newZoneId) {
      setZoneId(newZoneId);
    }
  }, [newZoneId]);

  useEffect(() => {
    const toggleValues = data.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: true,
      }),
      {}
    );

    setToggle(toggleValues);
  }, [data]);

  const onDropdownChange = useCallback((value: Option | undefined) => {
    setZoneId(value);
  }, []);

  const removeItem = useCallback(
    (data?: ZoneModelData) => {
      if (!data) {
        return;
      }

      openRemoveModal(data);
    },
    [openRemoveModal]
  );

  const options = useMemo(
    () =>
      data
        .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
        .map(({ id, name }) => ({
          key: id,
          label: name,
        })),
    [data, search]
  );

  useEffect(() => {
    const tmp = document.getElementById(HTMLIds.CONTAINER);
    if (!tmp) {
      return undefined;
    }

    const changeStickyDivPosition = () => {
      if (tmp.scrollTop > changePositionOn) {
        setChangePosition(true);
      } else {
        setChangePosition(false);
      }
    };

    tmp.addEventListener('scroll', changeStickyDivPosition, false);
    return () => {
      tmp.removeEventListener('scroll', changeStickyDivPosition);
    };
  }, []);

  return (
    <>
      <StickyDiv changePosition={changePosition} id='stickyDiv'>
        <StyledHeader>
          <Title>Zones</Title>
          <SelectFieldDiv>
            <SelectFieldWithoutFormik
              label='Zones'
              placeholder='Zones'
              name='ZoneSelectField'
              initialValue={initialValues.zone}
              options={options}
              onSearch={setSearch}
              search={search}
              short
              onChange={onDropdownChange}
              hideLabel
            />
          </SelectFieldDiv>
          {userCanManageZones && (
            <Button type='button' size={ButtonSize.MIDDLE} onClick={onAdd}>
              + Add new zone
            </Button>
          )}
        </StyledHeader>
        {userCanManageZones && (
          <StyledHeader changePosition>
            <Button type='submit' size={ButtonSize.MIDDLE} primary>
              Save all changes
            </Button>
          </StyledHeader>
        )}
      </StickyDiv>
      {isLoading ? (
        <Spinner primary />
      ) : (
        <FieldArray name='zoneInfo'>
          {() =>
            data.map((zone, index) => {
              const { id } = zone;

              const onClick = () => {
                setToggle((zoneValue) => ({
                  ...zoneValue,
                  [id]: !zoneValue[id],
                }));
              };

              return (
                <Zone
                  key={id}
                  data={zone}
                  index={index}
                  onClick={onClick}
                  toggle={toggle[id]}
                  zoneId={Number(zoneId?.key)}
                  elementId={elementId}
                  openRemoveModal={removeItem}
                  onCameraNameChange={onCameraNameChange}
                  onProductNameChange={onProductNameChange}
                  onTariffChange={onTariffChange}
                  findCurrencySymbol={findCurrencySymbol}
                  invoiceCurrency={invoiceCurrency}
                  accountId={accountId}
                />
              );
            })
          }
        </FieldArray>
      )}
    </>
  );
}

const StickyDiv = styled.div<{ changePosition: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  z-index: 100;
  background-color: ${Color.BACKGROUND};
  padding-bottom: 10px;
  border-radius: 5px;
  justify-content: space-between;

  ${(props) => {
    const { changePosition } = props;

    if (changePosition) {
      return css`
        position: sticky;
        top: -5px;
        width: 110%;
        margin-left: -5%;
        padding: 0 5% 10px 5%;
      `;
    }

    return css`
      position: sticky;
      top: -5px;
    `;
  }};

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SelectFieldDiv = styled.div`
  margin: 0 10px;
  @media (max-width: 700px) {
    margin: 1% 0;
  }
`;

export const StyledHeader = styled.div<{ changePosition?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }

  ${(props) => {
    const { changePosition } = props;

    if (changePosition) {
      return css`
        @media (max-width: 700px) {
          margin: 1% 0;
        }
      `;
    }
    return css``;
  }}
`;
