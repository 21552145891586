import type { FormikHelpers } from 'formik';
import React, { useCallback, useMemo } from 'react';
import type ParkingPlace from 'src/detection/models/ParkingPlace';
import type ParkingPlaceFormValues from 'src/detection/models/ParkingPlaceFormValues';
import type Zone from 'src/lots/models/Zone';
import ExitButton from 'src/shared/components/Buttons/ExitButton';
import Form from 'src/shared/components/Form';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import * as yup from 'yup';
import useIsMounted from '../../shared/hooks/useIsMounted';
import { isNotString } from '../../shared/utils/checks';
import useParkingPlaceService from '../services/useParkingPlaceService';
import ParkingPlaceAddForm from './ParkingPlaceAddForm';

interface AddNewParkingPlaceModalProps {
  closeParentModal: () => void;
  onAddNewParkingPlace: (data: ParkingPlace | ParkingPlace[]) => void;
  zones: Zone[];
}

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is a required field')
    .min(3, 'Name should be at least 3 characters long'),
  zone: yup.object().nullable().required('Zone is a required field'),
  placeCount: yup
    .number()
    .required('Number of places is required')
    .min(1, 'Number of places must be greater than 0'),
});

export default function AddNewParkingPlaceModal(
  props: AddNewParkingPlaceModalProps
) {
  const { closeParentModal, onAddNewParkingPlace, zones } = props;
  const { createParkingPlace } = useParkingPlaceService();
  const isMounted = useIsMounted();

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: 'Successfuly added new Parking Place!',
  });

  const initialValues: ParkingPlaceFormValues = useMemo(
    () => ({
      name: '',
      zone: { key: zones[0].id, label: zones[0].name },
      multiplePlaces: false,
      placeCount: 1,
    }),
    [zones]
  );

  const onSubmit = useCallback(
    async (
      values: ParkingPlaceFormValues,
      { setErrors }: FormikHelpers<ParkingPlaceFormValues>
    ) => {
      try {
        const response = await createParkingPlace(values);

        if (isMounted()) {
          openGlobalSuccessModal();
          onAddNewParkingPlace(response);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      onAddNewParkingPlace,
      openGlobalFailureModal,
      setMessage,
      openGlobalSuccessModal,
      isMounted,
      createParkingPlace,
    ]
  );

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Form
        name='addParkingPlace'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <ParkingPlaceAddForm
          closeParentModal={closeParentModal}
          zones={zones}
        />
      </Form>
    </>
  );
}
