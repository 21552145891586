import type { FormikHelpers } from 'formik';
import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import AccountUserForm from '../../settings/components/AccountUserForm';
import type AccountUserFormValues from '../../settings/models/AccountUserFormValues';
import useAccountUserService from '../../settings/services/useAccountUserService';
import Form from '../../shared/components/Form';
import { useGlobalFailureModal } from '../../shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../shared/components/Modals/GlobalSuccessModal';
import AppRole from '../../shared/constants/AppRole';
import PhoneValidation from '../../shared/constants/PhoneValidation';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useUserHasRole from '../../shared/hooks/useUserHasRole';
import { isNotString } from '../../shared/utils/checks';

interface AddUserProps {
  closeParentModal: () => void;
  refreshPage?: boolean;
}

const initialAddUserValues: AccountUserFormValues = {
  role: null,
  name: '',
  email: '',
  phone: '',
};

export default function AddUser(props: AddUserProps) {
  const { closeParentModal, refreshPage } = props;
  const { createAccountUser } = useAccountUserService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();

  const userCanReadUserRole = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const addUserValidationSchema = useMemo(
    () =>
      yup.object({
        role: userCanReadUserRole
          ? yup.object().nullable().required('Role is a required field')
          : yup.object().nullable(),
        name: yup.string().required('Name is a required field'),
        email: yup
          .string()
          .email('Enter a valid email address')
          .required('Email is a required field'),
        phone: yup
          .string()
          .trim()
          .matches(PhoneValidation, 'Enter a valid phone number'),
      }),
    [userCanReadUserRole]
  );

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: 'Successfuly created new user!',
    refreshPage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onAddUserSubmit = useCallback(
    async (
      values: AccountUserFormValues,
      { setErrors, resetForm }: FormikHelpers<AccountUserFormValues>
    ) => {
      try {
        await createAccountUser(values);
        if (isMounted()) {
          resetForm({});
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      createAccountUser,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  return (
    <Form
      name='addUser'
      initialValues={initialAddUserValues}
      validationSchema={addUserValidationSchema}
      onSubmit={onAddUserSubmit}
    >
      <AccountUserForm closeParentModal={closeParentModal} />
    </Form>
  );
}
