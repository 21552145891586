import type DetectGateway from 'src/detection/models/DetectGateway';
import type DetectGatewayResponse from 'src/detection/models/DetectGatewayResponse';

export default function toDetectGateway(
  data: DetectGatewayResponse
): DetectGateway {
  const {
    id,
    external_id,
    lot_id,
    name,
    uuid,
    status,
    created_at,
    updated_at,
  } = data;

  return {
    id,
    externalId: external_id,
    lotId: lot_id,
    name,
    uuid,
    status,
    createdAt: created_at,
    updatedAt: updated_at,
  };
}
