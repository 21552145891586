import { useCallback } from 'react';
import toAccountRole from '../mappers/toAccountRole';
import type AccountRole from '../models/AccountRole';
import type AccountRoleResponse from '../models/AccountRoleResponse';
import useApiService from './useApiService';

interface AccountRoleService {
  findAllAccountRoles: () => Promise<AccountRole[]>;
  filterAccountRoles: (roleNumber: number) => Promise<AccountRole[]>;
}

const accountRoleBaseUrl = '/account-roles';

export default function useAccountRoleService(): AccountRoleService {
  const { get } = useApiService();

  const findAllAccountRoles = useCallback(async () => {
    const response = await get<AccountRoleResponse[]>(`${accountRoleBaseUrl}`);
    const data = response.data.map(toAccountRole);
    return data;
  }, [get]);

  const filterAccountRoles = useCallback(
    async (excludeRoleNumber: number) => {
      const response = await findAllAccountRoles();
      return response.filter(({ id }) => id !== excludeRoleNumber);
    },
    [findAllAccountRoles]
  );

  return { findAllAccountRoles, filterAccountRoles };
}
