import { useCallback } from 'react';
import LprImageStorageSubscriptionStatuses from 'src/anpr/constants/LprImageStorageSubscriptionStatuses';
import toLprImageStorageSubscription from 'src/anpr/mappers/toLprImageStorageSubscription';
import toLprImageStorageSubscriptionCreateRequest from 'src/anpr/mappers/toLprImageStorageSubscriptionCreateRequest';
import toLprImageStorageSubscriptionQuery from 'src/anpr/mappers/toLprImageStorageSubscriptionQuery';
import toLprImageStorageSubscriptionUpdateParams from 'src/anpr/mappers/toLprImageStorageSubscriptionUpdateParams';
import type LprImageStorageSubscription from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscription';
import type LprImageStorageSubscriptionCreateFormValues from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionCreateFormValues';
import type LprImageStorageSubscriptionCreateRequest from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionCreateRequest';
import type LprImageStorageSubscriptionQuery from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionQuery';
import type LprImageStorageSubscriptionQueryParams from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionQueryParams';
import type LprImageStorageSubscriptionRemoveResponse from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionRemoveResponse';
import type LprImageStorageSubscriptionResponse from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionResponse';
import type LprImageStorageSubscriptionUpdateFormValues from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionUpdateFormValues';
import type LprImageStorageSubscriptionUpdateParams from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionUpdateParams';
import toMeta from 'src/shared/mappers/toMeta';
import type GetAllDetails from 'src/shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';

const lprImageStorageSubscriptionsURL = '/lpr-storage-subscriptions';

interface LprImageStorageSubscriptionsService {
  listAllLprImageStorageSubscriptions: (
    values: LprImageStorageSubscriptionQueryParams
  ) => Promise<GetAllDetails<LprImageStorageSubscription>>;
  getOneLprImageStorageSubscription: (
    id: number
  ) => Promise<LprImageStorageSubscription>;
  removeLprImageStorageSubscription: (
    id: number
  ) => Promise<LprImageStorageSubscriptionRemoveResponse>;
  updateLprImageStorageSubscription: (
    id: number,
    values: LprImageStorageSubscriptionUpdateFormValues
  ) => Promise<LprImageStorageSubscription>;
  createLprImageStorageSubscription: (
    values: LprImageStorageSubscriptionCreateFormValues,
    lprCameraId: number
  ) => Promise<LprImageStorageSubscription>;
}

export default function useLprImageStorageSubscriptionsService(): LprImageStorageSubscriptionsService {
  const { post, get, patch, remove } = useApiService();

  const listAllLprImageStorageSubscriptions = useCallback(
    async (values: LprImageStorageSubscriptionQueryParams) => {
      const requestBody = toLprImageStorageSubscriptionQuery(values);

      const response = await get<
        LprImageStorageSubscriptionResponse[],
        LprImageStorageSubscriptionQuery
      >(`${lprImageStorageSubscriptionsURL}`, requestBody);

      const data = response.data.map(toLprImageStorageSubscription);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const getOneLprImageStorageSubscription = useCallback(
    async (id: number) => {
      const response = await get<LprImageStorageSubscriptionResponse>(
        `${lprImageStorageSubscriptionsURL}/${id}`
      );

      return toLprImageStorageSubscription(response.data);
    },
    [get]
  );

  const updateLprImageStorageSubscription = useCallback(
    async (id: number, values: LprImageStorageSubscriptionUpdateFormValues) => {
      const params = toLprImageStorageSubscriptionUpdateParams(values);
      const response = await patch<
        LprImageStorageSubscriptionUpdateParams,
        LprImageStorageSubscriptionResponse
      >(`${lprImageStorageSubscriptionsURL}/${id}`, params);

      return toLprImageStorageSubscription(response.data);
    },
    [patch]
  );

  const createLprImageStorageSubscription = useCallback(
    async (
      values: LprImageStorageSubscriptionCreateFormValues,
      lprCameraId: number
    ) => {
      const request = toLprImageStorageSubscriptionCreateRequest(
        values,
        lprCameraId,
        LprImageStorageSubscriptionStatuses.ACTIVE
      );
      const response = await post<
        LprImageStorageSubscriptionCreateRequest,
        LprImageStorageSubscriptionResponse
      >(`${lprImageStorageSubscriptionsURL}`, request);

      return toLprImageStorageSubscription(response.data);
    },
    [post]
  );

  const removeLprImageStorageSubscription = useCallback(
    async (id: number) => {
      const response = await remove<LprImageStorageSubscriptionRemoveResponse>(
        `${lprImageStorageSubscriptionsURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return {
    listAllLprImageStorageSubscriptions,
    getOneLprImageStorageSubscription,
    updateLprImageStorageSubscription,
    createLprImageStorageSubscription,
    removeLprImageStorageSubscription,
  };
}
