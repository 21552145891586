import SelectAllFilterValue from 'src/shared/constants/SelectAllFilterValue';

enum WeblinksStatusValues {
  ALL = SelectAllFilterValue,
  ACTIVE = 0,
  BLOCKED = 1,
  EXPIRED = 2,
  DEPLETED = 3,
  INVALID = 4,
}

export default WeblinksStatusValues;
