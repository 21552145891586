import React, { useState } from 'react';
import Color from 'src/shared/constants/Color';
import styled from 'styled-components';
import ButtonSize from '../../constants/ButtonSize';
import { useGlobalModal } from '../../hooks/useGlobalModal';
import Button from '../Button';
import Control from '../Control';
import Error from '../Error';
import Line from '../Line';
import GlobalModal from './GlobalModal';

interface GlobalFailureModalProps {
  closeParentModal?: () => void;
  action?: () => void;
}

const closeAllModals = (parentModal?: () => void, childModal?: () => void) => {
  if (parentModal) parentModal();
  if (childModal) childModal();
};

export function useGlobalFailureModal(props: GlobalFailureModalProps) {
  const { closeParentModal, action } = props;
  const [message, setMessage] = useState<{ code: string; message: string }>();
  const Close = () => {
    closeAllModals(closeParentModal, closeGlobalFailureModal);
  };

  const [openGlobalFailureModal, closeGlobalFailureModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen popup>
        <>
          <Error large>
            <p>{message?.message}</p>
            <p>{`Code: ${message?.code}`}</p>
          </Error>
          {action && <StyledP onClick={action}>Add license</StyledP>}
          <Line />
          <Control>
            <Button
              autofocus
              onClick={Close}
              size={ButtonSize.LARGE}
              type='button'
            >
              Close
            </Button>
          </Control>
        </>
      </GlobalModal>
    )
  );
  return {
    openGlobalFailureModal,
    closeGlobalFailureModal,
    setMessage,
  };
}

const StyledP = styled.p`
  color: ${Color.TEXT_BRAND};
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 30px;
`;
