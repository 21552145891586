import type LotChangeEventBarrier from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventBarrier';
import type LotChangeEventBarrierPayload from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventBarrierPayload';

export default function toLotChangeEventBarrier(
  data: LotChangeEventBarrierPayload
): LotChangeEventBarrier {
  const { is_car_on_top, state, is_autoclose } = data;

  return {
    isCarOnTop: is_car_on_top,
    state,
    isAutoclose: is_autoclose,
  };
}
