import { useCallback } from 'react';
import useAuthContext from 'src/auth/hooks/useAuthContext';
import type AccountUserInvitationConfirmationFormValues from 'src/home/models/AccountUserInvitationConfirmationFormValues';
import type AccountUserInvitationConfirmationQuery from 'src/home/models/AccountUserInvitationConfirmationQuery';
import type AccountUserInvitationConfirmationRequest from 'src/home/models/AccountUserInvitationConfirmationRequest';
import type AccountUserInvitationConfirmationResponse from 'src/home/models/AccountUserInvitationConfirmationResponse';
import type AccountUserInvitationResendRequest from 'src/home/models/AccountUserInvitationResendRequest';
import type AccountUserInvitationResendResponse from 'src/home/models/AccountUserInvitationResendResponse';
import type AccountUserInvitationResponse from 'src/home/models/AccountUserInvitationResponse';
import type AccountUserInvitationUpdateParams from 'src/home/models/AccountUserInvitationUpdateParams';
import type AccountUserInvitationUpdateResponse from 'src/home/models/AccountUserInvitationUpdateResponse';
import type AccountUserInvitationUpdateValues from 'src/home/models/AccountUserInvitationUpdateValues';

import toSessionData from '../../auth/mappers/toSessionData';

import type AccountUserRemoveResponse from '../../settings/models/AccountUserRemoveResponse';
import toMeta from '../../shared/mappers/toMeta';

import type GetAllDetails from '../../shared/models/GetAllDetails';
import type SessionData from '../../shared/models/SessionData';
import useApiService from '../../shared/services/useApiService';
import useSessionService from '../../shared/services/useSessionService';
import toAccountUserInvitation from '../mappers/toAccountUserInvitation';
import toAccountUserInvitationConfirmationQuery from '../mappers/toAccountUserInvitationConfirmationQuery';
import toAccountUserInvitationConfirmationRequest from '../mappers/toAccountUserInvitationConfirmationRequest';
import toAccountUserInvitationResendRequest from '../mappers/toAccountUserInvitationResendRequest';
import toAccountUserInvitationUpdateParams from '../mappers/toAccountUserInvitationUpdateParams';
import type AccountUserInvitation from '../models/AccountUserInvitation';

const accountUserInvitationBaseURL = '/account-user-invitations';
interface AccountUserInvitationService {
  accountUserInvitationConfirmation: (
    email: string,
    token: string,
    signature: string,
    values: AccountUserInvitationConfirmationFormValues
  ) => Promise<SessionData>;
  findAllAccountUserInvitations: () => Promise<
    GetAllDetails<AccountUserInvitation>
  >;
  removeAccountUserInvitation: (
    id: number
  ) => Promise<AccountUserRemoveResponse>;
  resendAccountUserInvitation: (
    email: string
  ) => Promise<AccountUserInvitationResendResponse>;
  updateAccountUserInvitation: (
    id: number,
    values: AccountUserInvitationUpdateValues
  ) => Promise<AccountUserInvitationUpdateResponse>;
  blockAccountUserInvitation: (
    id: number
  ) => Promise<AccountUserInvitationUpdateResponse>;
}

export default function useAccountUserInvitationService(): AccountUserInvitationService {
  const { get, post, patch, remove } = useApiService();

  const { post: postUnauthorized } = useApiService({
    withAuth: false,
  });
  const { setIsLoggedIn, setSessionData: setSessionDataState } =
    useAuthContext();
  const { setSessionData } = useSessionService();

  const accountUserInvitationConfirmation = useCallback(
    async (
      email: string,
      token: string,
      signature: string,
      values: AccountUserInvitationConfirmationFormValues
    ) => {
      const requestBody = toAccountUserInvitationConfirmationRequest(values);
      const query = toAccountUserInvitationConfirmationQuery(
        email,
        token,
        signature
      );

      const response = await postUnauthorized<
        AccountUserInvitationConfirmationRequest,
        AccountUserInvitationConfirmationResponse,
        AccountUserInvitationConfirmationQuery
      >(`${accountUserInvitationBaseURL}/accept`, requestBody, query);

      const sessionData = toSessionData(response.data);

      setIsLoggedIn(true);
      setSessionData(sessionData);
      setSessionDataState(sessionData);

      return sessionData;
    },
    [postUnauthorized, setIsLoggedIn, setSessionData, setSessionDataState]
  );

  const findAllAccountUserInvitations = useCallback(async () => {
    const response = await get<AccountUserInvitationResponse[]>(
      `${accountUserInvitationBaseURL}`
    );

    const data = response.data.map(toAccountUserInvitation);
    const meta = toMeta(response.meta);

    return { data, meta };
  }, [get]);

  const removeAccountUserInvitation = useCallback(
    async (id: number) => {
      const response = await remove<AccountUserRemoveResponse>(
        `${accountUserInvitationBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  const resendAccountUserInvitation = useCallback(
    async (email: string) => {
      const requestBody = toAccountUserInvitationResendRequest(email);
      const response = await post<
        AccountUserInvitationResendRequest,
        AccountUserInvitationResendResponse
      >(`${accountUserInvitationBaseURL}/resend`, requestBody);

      return response;
    },
    [post]
  );

  const updateAccountUserInvitation = useCallback(
    async (id: number, values: AccountUserInvitationUpdateValues) => {
      const params = toAccountUserInvitationUpdateParams(values);
      const response = await patch<
        AccountUserInvitationUpdateParams,
        AccountUserInvitationUpdateResponse
      >(`${accountUserInvitationBaseURL}/${id}`, params);

      return response;
    },
    [patch]
  );

  const blockAccountUserInvitation = useCallback(
    (id: number) => updateAccountUserInvitation(id, { status: 'blocked' }),
    [updateAccountUserInvitation]
  );

  return {
    accountUserInvitationConfirmation,
    findAllAccountUserInvitations,
    removeAccountUserInvitation,
    resendAccountUserInvitation,
    updateAccountUserInvitation,
    blockAccountUserInvitation,
  };
}
