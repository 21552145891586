import { useField } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isNotArray } from 'src/shared/utils/checks';
import createUniqueChecker from 'src/shared/utils/createUniqueChecker';
import invariant from 'src/shared/utils/invariant';
import styled, { css } from 'styled-components';

import Color from '../../constants/Color';
import type Option from '../../models/Option';
import Error from '../Error';
import Label from '../Label';
import Item from './Item';
import List from './List';

interface ToggleFieldProps {
  label: string;
  name: string;
  options: Option[];
  multiple?: boolean;
  stacked?: boolean;
  column?: boolean;
  readonly?: boolean;
  tariffView?: boolean;
}

export default function ToggleField(props: ToggleFieldProps) {
  const {
    label,
    name,
    options,
    multiple,
    stacked,
    column,
    readonly,
    tariffView,
  } = props;
  const [field, meta, helper] = useField({ name });
  const { value } = field;
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);

  invariant(
    !multiple || !isNotArray(value),
    'If Check is multiselect initial value has to be Array'
  );

  const { error, touched } = meta;
  const { setValue, setTouched } = helper;
  const uniqueChecker = createUniqueChecker();
  const shouldShowError = error && touched;
  const [shouldSetTouched, setShouldSetTouched] = useState(false);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (
        !wrapperRef?.current?.contains(event.target as Node) &&
        shouldSetTouched
      ) {
        setTouched(true);
      }
    };

    document.addEventListener('click', listener, { capture: true });
    document.addEventListener('focusin', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
      document.removeEventListener('focusin', listener, { capture: true });
    };
  }, [setTouched, shouldSetTouched]);

  const checkIsSelected = useCallback(
    (option: Option) => {
      if (!value) {
        return false;
      }

      if (multiple) {
        return value.some((entry: Option) => entry.key === option.key);
      }

      return value.key === option.key;
    },
    [value, multiple]
  );

  const select = useCallback(
    async (option: Option) => {
      if (!shouldSetTouched) {
        setShouldSetTouched(true);
      }

      const isSelectedOption = (entry: Option) => entry.key === option.key;

      const isNotSelectedOption = (entry: Option) => entry.key !== option.key;

      if (multiple && value.some(isSelectedOption)) {
        return setValue(value.filter(isNotSelectedOption));
      }

      if (multiple && !value.some(isSelectedOption)) {
        return setValue([...value, option]);
      }

      if (!value || option.key !== value.key) {
        return setValue(option);
      }

      return setValue(value);
    },
    [multiple, setValue, value, shouldSetTouched]
  );

  const readonlyValue = useMemo(() => {
    if (!value) {
      return 'N/A';
    }

    return value.label;
  }, [value]);

  return (
    <StyledToggleWrapper stacked={stacked} label={label} wrapref={wrapperRef}>
      {!readonly && (
        <List column={column}>
          {options.map((option) => {
            const { key, label } = option;

            invariant(
              uniqueChecker(key.toString()),
              'Key of the Checklist option has to be unique'
            );

            const isSelected = checkIsSelected(option);
            const onSelect = () => select(option);

            return (
              <Item
                key={key}
                label={label}
                isSelected={isSelected}
                onSelect={onSelect}
                tariffView={tariffView}
              />
            );
          })}
        </List>
      )}
      {readonly && <StyledReadonlyData>{readonlyValue}</StyledReadonlyData>}
      {shouldShowError && <Error>*{error}</Error>}
    </StyledToggleWrapper>
  );
}

interface ToggleWrapperProps {
  stacked?: boolean;
  label: string;
  children: React.ReactNode;
  wrapref?: React.RefObject<HTMLDivElement>;
}

function StyledToggleWrapper(props: ToggleWrapperProps) {
  const { stacked, label, children, wrapref } = props;
  return (
    <StyledWrapper stacked={stacked} ref={wrapref}>
      <Label stacked={stacked}>{label}</Label>
      <StyledColumn>{children}</StyledColumn>
    </StyledWrapper>
  );
}
interface ToggleProps {
  onClick: () => void;
  state: boolean;
  label: string;
}

export function Toggle(props: ToggleProps) {
  const { onClick, state, label } = props;

  return (
    <StyledToggleWrapper label={label}>
      <List>
        <Item label='Off' isSelected={!state} onSelect={onClick} />
        <Item label='On' isSelected={state} onSelect={onClick} />
      </List>
    </StyledToggleWrapper>
  );
}

const StyledColumn = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

const StyledWrapper = styled.div<{ stacked?: boolean }>`
  display: flex;
  margin: 30px 0px 0px 0px;

  @media (max-width: 500px) {
    flex-direction: column;
  }

  ${(props) => {
    const { stacked } = props;

    if (!stacked) {
      return css`
        flex-direction: row;
      `;
    }

    return css`
      flex-direction: column;
    `;
  }}

  :last-child {
    margin: 30px 0px 40px 0px;
  }
`;

const StyledReadonlyData = styled.p`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: 36px;
  color: ${Color.TEXT_DARKER};
`;
