import { createContext } from 'react';
import type SessionData from '../../shared/models/SessionData';

interface AuthContextData {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  sessionData?: SessionData;
  setSessionData: (sessionData: SessionData | undefined) => void;
}

export const initialAuthContextData: AuthContextData = {
  isLoggedIn: false,
  setIsLoggedIn: () => ({}),
  sessionData: undefined,
  setSessionData: () => ({}),
};

const AuthContext = createContext(initialAuthContextData);

export default AuthContext;
