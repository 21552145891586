import { useField, useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import type ParkingPlaceFormValues from 'src/detection/models/ParkingPlaceFormValues';
import type Zone from 'src/lots/models/Zone';
import Button from 'src/shared/components/Button';
import CheckBoxField from 'src/shared/components/CheckBoxField';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import SelectField from 'src/shared/components/SelectField';
import TextField from 'src/shared/components/TextField';
import ButtonSize from 'src/shared/constants/ButtonSize';
import type Option from 'src/shared/models/Option';
import DetailsColumn from '../../products/components/DetailsColumn';
import DetailsRow from '../../products/components/DetailsRow';

interface ParkingPlaceAddFormProps {
  closeParentModal: () => void;
  zones: Zone[];
}
export default function ParkingPlaceAddForm(props: ParkingPlaceAddFormProps) {
  const { closeParentModal, zones } = props;
  const [searchZone, setSearchZone] = useState('');
  const { isSubmitting, dirty } = useFormikContext<ParkingPlaceFormValues>();
  const [addMultiplePlaces] = useField({ name: 'multiplePlaces' });

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const gatewayOptions: Option[] = useMemo(
    () =>
      zones
        .filter(({ name }) =>
          name.toLowerCase().includes(searchZone.toLowerCase())
        )
        .map(({ id, name }) => ({
          key: id,
          label: name,
        })),
    [zones, searchZone]
  );

  return (
    <>
      <DetailsRow>
        <DetailsColumn>
          <TextField
            label='NAME'
            placeholder='Name'
            type='text'
            name='name'
            short
            stacked
          />
          <SelectField
            label='ZONE'
            placeholder='Select Zone'
            name='zone'
            options={gatewayOptions}
            onSearch={setSearchZone}
            search={searchZone}
            short
            stacked
          />
          <Line addMargin />

          <CheckBoxField label='Add mutiple places' name='multiplePlaces' />
          {addMultiplePlaces.value && (
            <TextField
              label='Enter number of places'
              placeholder='Number of places'
              type='number'
              name='placeCount'
              short
              stacked
            />
          )}
        </DetailsColumn>
      </DetailsRow>
      <Line addMargin />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
