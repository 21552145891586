import React from 'react';
import { List } from '../Cards/CardStyle';
import DetectCameraCard from './DetectCameraCard';

interface DetectGatewayCardListProps {
  children: React.ReactNode;
}

export default function DetectGatewayCardList(
  props: DetectGatewayCardListProps
) {
  const { children } = props;

  return <List>{children}</List>;
}

DetectGatewayCardList.Card = DetectCameraCard;
