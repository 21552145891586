import { useMemo } from 'react';
import LprCameraStatus from 'src/anpr/constants/LprCameraStatus';
import AllProductStatuses from '../../products/constants/AllProductStatuses';

export default function useCameraStatusLabel(status: string | undefined) {
  return useMemo(() => {
    if (status === LprCameraStatus.OFFLINE) {
      return AllProductStatuses.OFFLINE;
    }

    if (status === LprCameraStatus.ONLINE) {
      return AllProductStatuses.ONLINE;
    }

    return AllProductStatuses.UNKNOWN;
  }, [status]);
}
