import styled, { css } from 'styled-components';
import Color from '../../shared/constants/Color';

const Title = styled.h1<{ addMargin?: boolean }>`
  font-family: 'Montserrat';
  font-weight: bold;
  font-style: normal;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: ${Color.TEXT_DARKER};

  @media (max-width: 700px) {
    color: ${Color.TEXT_LIGHTER};
  }

  ${(props) => {
    const { addMargin } = props;

    if (addMargin) {
      return css`
        margin: 15% 0 2.5vh 0;
        @media (max-width: 700px) {
          color: ${Color.TEXT_DARKER};
        }
      `;
    }

    return css`
      margin: 0px;
    `;
  }}
`;

export default Title;
