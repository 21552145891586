import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { isNotArray } from '../../../shared/utils/checks';
import createUniqueChecker from '../../../shared/utils/createUniqueChecker';
import invariant from '../../../shared/utils/invariant';
import Color from '../../constants/Color';
import TabsContext from '../../contexts/TabsContext';
import Line from '../Line';
import Panel from './Panel';
import Tab from './Tab';

interface TabsProps {
  children: JSX.Element[] | JSX.Element;
  noBackground?: boolean;
  flexEnd?: boolean;
}

export default function Tabs(props: TabsProps) {
  const { children, noBackground, flexEnd } = props;
  const isUnique = createUniqueChecker();

  const childrenTabs = isNotArray(children) ? [children] : children;

  const tabs = childrenTabs.map((child) => {
    const { props: childprops, type } = child;

    if (type.toString() === React.Fragment.toString()) {
      return { name: undefined, label: undefined };
    }
    invariant(
      type === Panel,
      'Wizzard child has to be instance of Wizzard.Step'
    );

    invariant(isUnique(childprops.name), 'Tab name has to be unique');

    return { name: childprops.name, label: childprops.label };
  });

  const [{ name }] = tabs;
  const [activeTab, setActiveTab] = useState(name);

  const onSelect = useCallback(
    (name: string) => {
      setActiveTab(name);
    },
    [setActiveTab]
  );

  return (
    <TabsContext.Provider value={{ activeTab }}>
      <StyledTabContainer noBackground={noBackground} flexEnd={flexEnd}>
        {tabs.map((tab) => {
          const { name, label } = tab;
          if (name === undefined) {
            return undefined;
          }

          return (
            <Tab key={name} name={name} onSelect={onSelect}>
              {label}
            </Tab>
          );
        })}
      </StyledTabContainer>
      <Line />
      {children}
    </TabsContext.Provider>
  );
}

Tabs.Panel = Panel;

const StyledTabContainer = styled.div<{
  noBackground?: boolean;
  flexEnd?: boolean;
}>`
  width: 100%;
  height: 75px;
  background-color: ${Color.BACKGROUND_LIGHTER};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  @media (max-width: 480px) {
    overflow: auto;
  }

  ${(props) => {
    const { noBackground } = props;

    if (noBackground) {
      return css`
        background-color: ${Color.BACKGROUND};
        border-bottom: 1px solid ${Color.BORDER_LIGHT};
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { flexEnd } = props;

    if (flexEnd) {
      return css`
        justify-content: flex-end;
      `;
    }
    return css``;
  }};
`;
