import type LprImageStorageSubscriptionQuery from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionQuery';
import type LprImageStorageSubscriptionQueryParams from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionQueryParams';

export default function toLprImageStorageSubscriptionQuery(
  data: LprImageStorageSubscriptionQueryParams
): LprImageStorageSubscriptionQuery {
  const { lotId, lprCameraId, accountId } = data;

  return {
    lot_id: lotId,
    lpr_camera_id: lprCameraId,
    account_id: accountId,
  };
}
