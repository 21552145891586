import type LotCreateRequest from 'src/lots/models/LotCreateRequest';
import type LotFormValues from 'src/lots/models/LotFormValues';
import type ParkingDetectionIntegrations from 'src/products/constants/ParkingDetectionIntegrations';

export default function toLotCreateRequest(
  data: LotFormValues,
  targetAccountId?: number,
  detectionIntegration?: ParkingDetectionIntegrations
): LotCreateRequest {
  const { name, address, description, timeZone, latitude, longitude } = data;

  return {
    name,
    address,
    description,
    time_zone: timeZone?.key || null,
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    target_account_id: targetAccountId,
    parking_detection_integration: detectionIntegration,
  };
}
