import React, { useCallback, useMemo } from 'react';
import AddUser from 'src/home/screens/AddUser';
import Button from 'src/shared/components/Button';
import Card from 'src/shared/components/Card';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import StyledNoData from 'src/shared/components/StyledNoData';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import ActionIcon from 'src/shared/constants/ActionIcon';
import AppRole from 'src/shared/constants/AppRole';
import ButtonSize from 'src/shared/constants/ButtonSize';
import InvitationStates from 'src/shared/constants/InvitationStates';
import { useGlobalModal } from 'src/shared/hooks/useGlobalModal';
import useUserHasRole from 'src/shared/hooks/useUserHasRole';
import type AccountUserInvitation from '../../home/models/AccountUserInvitation';

interface AccountUserInvitationTableProps {
  openRemoveModal: (id: number) => void;
  onResendInvitation: (id: number, email: string) => void;
  data: AccountUserInvitation[];
  isLoading: boolean;
  resendingInvitation: { [key: number]: boolean };
}

const ACTIVE_INVITATION_TIME_MILISECONDS = 48 * 60 * 60 * 1000;

export default function AccountUserInvitationTable(
  props: AccountUserInvitationTableProps
) {
  const {
    data,
    openRemoveModal,
    isLoading,
    resendingInvitation,
    onResendInvitation,
  } = props;

  const userHasRole = useUserHasRole();

  const removeItem = useCallback(
    (data?: AccountUserInvitation) => {
      if (!data) {
        return;
      }

      const { id } = data;

      openRemoveModal(id);
    },
    [openRemoveModal]
  );

  const onClick = useCallback(
    (id: number, email: string) => {
      onResendInvitation(id, email);
    },
    [onResendInvitation]
  );

  const [openAddUserModal, closeAddUserModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <AddUser closeParentModal={closeAddUserModal} refreshPage />
    </GlobalModal>
  ));

  const userCanAddParkingManager = useMemo(() => {
    const roles = AppRole.PMS_SUPER_ADMIN | AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  return (
    <>
      <Title>Pending Managers</Title>
      {userCanAddParkingManager && (
        <Button
          size={ButtonSize.MIDDLE}
          type='button'
          primary
          onClick={openAddUserModal}
          addTopMargin
        >
          + Add Parking Manager
        </Button>
      )}
      <Card>
        <Table isLoading={isLoading}>
          <Table.Body>
            {data.length > 0 ? (
              data.map((accountUserInvitationFindAllDetails) => {
                const { email, id, updatedAt, name, status } =
                  accountUserInvitationFindAllDetails;
                if (status === 'activated' || status === 'reactived') {
                  return null;
                }
                const isExpired =
                  new Date().valueOf() - updatedAt.valueOf() >
                  ACTIVE_INVITATION_TIME_MILISECONDS;

                return (
                  <Table.Row key={id}>
                    <Table.Cell title={name} short bold>
                      {email}
                    </Table.Cell>
                    {isExpired ? (
                      <Table.Cell
                        center
                        action={removeItem}
                        primary
                        data={accountUserInvitationFindAllDetails}
                      >
                        <Table.Icon icon={ActionIcon.DELETE} />
                      </Table.Cell>
                    ) : (
                      <Table.Cell>
                        <></>
                      </Table.Cell>
                    )}
                    <Table.Cell disabled={!isExpired}>
                      {isExpired ? (
                        <Button
                          size={ButtonSize.SMALL}
                          type='button'
                          onClick={() => onClick(id, email)}
                          disabled={resendingInvitation[id]}
                        >
                          {resendingInvitation[id]
                            ? InvitationStates.SENDING
                            : InvitationStates.RESEND}
                        </Button>
                      ) : (
                        InvitationStates.PENDING
                      )}
                    </Table.Cell>
                    <Table.Cell>{status}</Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell>
                  <StyledNoData>No pending Parking Managers</StyledNoData>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
    </>
  );
}
