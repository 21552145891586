import PaginationSize from 'src/shared/constants/DataSize';
import type BaseQueryParams from 'src/shared/models/BaseQueryParams';
import type GetAllDetails from 'src/shared/models/GetAllDetails';

export default async function fetchAllData<ResponseType, ParamsType>(
  requset: (
    query: ParamsType & BaseQueryParams
  ) => Promise<GetAllDetails<ResponseType>>,
  params: ParamsType
) {
  let responseData: ResponseType[] = [];
  let page = 1;
  let shouldSendAnotherRequest = true;
  const size = PaginationSize.MAX;

  while (shouldSendAnotherRequest) {
    const query = {
      ...params,
      page,
      size,
    };

    const { data, meta } = await requset(query);
    responseData = responseData.concat(data);
    if (meta?.lastPage !== page) {
      page++;
    } else {
      shouldSendAnotherRequest = false;
    }
  }

  return responseData;
}
