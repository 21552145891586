import type NewPasswordFormValues from '../models/NewPasswordFormValues';
import type NewPasswordRequest from '../models/NewPasswordRequest';

export default function toNewPasswordRequest(
  data: NewPasswordFormValues,
  token: string
): NewPasswordRequest {
  const { password, passwordConfirmation } = data;

  return {
    password,
    password_confirmation: passwordConfirmation,
    token: token,
  };
}
