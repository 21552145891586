import type ParkingPlaceUpdateParams from 'src/detection/models/ParkingPlaceUpdateParams';
import type ParkingPlaceUpdateValues from 'src/detection/models/ParkingPlaceUpdateValues';

export default function toParkingPlaceUpdateParams(
  data: ParkingPlaceUpdateValues
): ParkingPlaceUpdateParams {
  const { zoneId, name, externalId, uuid } = data;

  return {
    zone_id: zoneId,
    name,
    external_id: externalId,
    uuid,
  };
}
