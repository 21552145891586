import { useFormikContext } from 'formik';
import React from 'react';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import Section from 'src/shared/components/Section';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';
import useWizardContext from '../../shared/hooks/useWizardContext';

export default function UserStep() {
  const { back } = useWizardContext();
  const { isSubmitting } = useFormikContext();

  return (
    <>
      <Section>
        <Title bold>Invite User</Title>
        <TextField
          label='Email'
          placeholder='Email'
          type='text'
          name='loginEmail'
          stacked
        />
        <TextField
          label='Name'
          placeholder='Name'
          type='text'
          name='loginName'
          stacked
        />
      </Section>
      <Line />
      <Control>
        <Button
          onClick={back}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Back
        </Button>
        <Button
          size={ButtonSize.LARGE}
          type='submit'
          primary
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Finish
        </Button>
      </Control>
    </>
  );
}
