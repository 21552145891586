import type ChainFormValues from 'src/products/models/Chain/ChainFormValues';
import type ChainUpdateParams from 'src/products/models/Chain/ChainUpdateParams';

export default function toChainUpdateParams(
  data: ChainFormValues
): ChainUpdateParams {
  return {
    name: data.name,
  };
}
