import { useCallback } from 'react';
import type AccountUser from 'src/home/models/AccountUser';
import type AccountUserQuery from 'src/home/models/AccountUserQuery';
import type AccountUserQueryParams from 'src/home/models/AccountUserQueryParams';
import type AccountUserResponse from 'src/home/models/AccountUserResponse';

import ContentType from '../../shared/constants/ContentType';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toAccountUser from '../mappers/toAccountUser';
import toAccountUserQuery from '../mappers/toAccountUserQuery';

const accountUserBaseURL = '/account-users';

interface AccountUserService {
  findAllAccountUsersParklioInternal: (
    params: AccountUserQueryParams
  ) => Promise<GetAllDetails<AccountUser>>;
}

export default function useAccountUserService(): AccountUserService {
  const { get } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const findAllAccountUsersParklioInternal = useCallback(
    async (queryParams: AccountUserQueryParams) => {
      const query = toAccountUserQuery(queryParams);
      const response = await get<AccountUserResponse[], AccountUserQuery>(
        `${accountUserBaseURL}`,
        query
      );
      const data = response.data.map(toAccountUser);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  return {
    findAllAccountUsersParklioInternal,
  };
}
