import { useCallback } from 'react';
import toRecurringOperationKeyCreateRequest from 'src/keysharing/mappers/toRecurringOperationKeyCreateRequest';

import type KeySharingFormValues from 'src/keysharing/models/KeySharingFormValues';
import type OperationKeyCreateRequest from 'src/keysharing/models/OperationKeyCreateRequest';
import type OperationKeyCreateResponse from 'src/keysharing/models/OperationKeyCreateResponse';
import type RecurringOperationKeyCreateRequest from 'src/keysharing/models/RecurringOperationKeyCreateRequest';
import type RecurringOperationKeyCreateResponse from 'src/keysharing/models/RecurringOperationKeyCreateResponse';
import useApiService from '../../shared/services/useApiService';
import toOperationKeyCreateRequest from '../mappers/toOperationKeyCreateRequest';

const operationKeysBaseURL = '/operation-keys';
interface OperationKeysService {
  createOperationKey: (
    productId: number[],
    values: KeySharingFormValues
  ) => Promise<OperationKeyCreateResponse>;

  createRecurringOperationKey: (
    productId: number[],
    values: KeySharingFormValues
  ) => Promise<RecurringOperationKeyCreateResponse>;
}

export default function useOperationKeysService(): OperationKeysService {
  const { post } = useApiService();

  const createOperationKey = useCallback(
    async (productId: number[], values: KeySharingFormValues) => {
      const requestBody = toOperationKeyCreateRequest(productId, values);

      const response = await post<
        OperationKeyCreateRequest,
        OperationKeyCreateResponse
      >(`${operationKeysBaseURL}`, requestBody);

      return response;
    },
    [post]
  );

  const createRecurringOperationKey = useCallback(
    async (productId: number[], values: KeySharingFormValues) => {
      const requestBody = toRecurringOperationKeyCreateRequest(
        productId,
        values
      );

      const response = await post<
        RecurringOperationKeyCreateRequest,
        RecurringOperationKeyCreateResponse
      >(`${operationKeysBaseURL}/recurring`, requestBody);

      return response;
    },
    [post]
  );

  return {
    createOperationKey,
    createRecurringOperationKey,
  };
}
