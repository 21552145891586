import type ManagerUpdate from 'src/managers/models/ManagerUpdate';
import type ManagerUpdateResponse from 'src/managers/models/ManagerUpdateResponse';

export default function toManagerUpdate(
  data: ManagerUpdateResponse
): ManagerUpdate {
  const { success, account_user_id, id } = data;

  return {
    success,
    accountUserId: account_user_id,
    id,
  };
}
