import React from 'react';
import styled from 'styled-components';
import Color from '../../constants/Color';
import type PrimaryNavigationButtonIcon from '../../constants/PrimaryNavigationButtonIcon';

interface ButtonProps {
  text?: string;
  title?: string;
  icon?: PrimaryNavigationButtonIcon;
  onClick?: () => void;
}

export default function Button(props: ButtonProps) {
  const { icon, onClick, text, title } = props;

  return (
    <StyledButton onClick={onClick} title={title}>
      {icon && <StyledIcon className={icon} />}
      {text && <StyledText>{text}</StyledText>}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: ${Color.BACKGROUND_LIGHTER_TRANSPARENT};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

const StyledText = styled.p`
  margin: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${Color.TEXT_LIGHTER};
`;

const StyledIcon = styled.i`
  font-family: anticon;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: ${Color.TEXT_LIGHTER};
`;
