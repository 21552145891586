import React from 'react';
import styled from 'styled-components';
import Color from '../../constants/Color';

interface DeleteButtonProps {
  onClick: () => void;
}

export default function ExitButton(props: DeleteButtonProps) {
  const { onClick } = props;

  return (
    <StyledWrapper onClick={onClick} data-testid='exit'>
      <StyledIcon className='pa-x-circle' />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: -35px -50px 10px 0;
  cursor: pointer;
`;

const StyledIcon = styled.i`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 20px;
  margin: 0px 10px 0px 0px;
  color: ${Color.PRIMARY_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;
