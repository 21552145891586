import { createContext } from 'react';

interface SideMenuContextData {
  close: () => void;
}

const initialSideMenuContextData: SideMenuContextData = {
  close: () => ({}),
};

const SideMenuContext = createContext(initialSideMenuContextData);

export default SideMenuContext;
