import type ProductChangeState from 'src/lots/models/ProductChangeState';
import type ProductChangeStateResponse from 'src/lots/models/ProductChangeStateResponse';

export default function toProductChangeState(
  data: ProductChangeStateResponse
): ProductChangeState {
  const { success, event_id, message } = data;

  return { success, eventId: event_id, message };
}
