import { useField } from 'formik';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import ActionIcon from 'src/shared/constants/ActionIcon';
import { isNotNullOrUndefined } from 'src/shared/utils/checks';
import styled, { css } from 'styled-components';
import Color from '../constants/Color';
import Error from './Error';
import Label from './Label';

interface TextFieldProps {
  name: string;
  label: string | JSX.Element;
  type: string;
  placeholder: string;
  short?: boolean;
  stacked?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  reverse?: boolean;
  small?: boolean;
  medium?: boolean;
  autoComplete?: string;
  modalView?: boolean;
  customWidth?: string;
  customMargin?: boolean;
  addEyeIcon?: boolean;
  inline?: boolean;
  zoneView?: boolean;
  deleteView?: boolean;
  mediumLabel?: boolean;
  tariffView?: boolean;
  testId?: string;
  inlineShort?: boolean;
  primary?: boolean;
  vehicleCount?: boolean;
  autofocus?: boolean;
  minValue?: number;
  removeCursor?: boolean;
}

export default function TextField(props: TextFieldProps) {
  const {
    label,
    name,
    type,
    placeholder,
    short,
    stacked,
    readonly,
    disabled,
    reverse,
    small,
    medium,
    autoComplete,
    modalView,
    customWidth,
    customMargin,
    addEyeIcon,
    inline,
    zoneView,
    deleteView,
    mediumLabel,
    tariffView,
    testId,
    inlineShort,
    primary,
    vehicleCount,
    autofocus,
    minValue,
    removeCursor,
  } = props;
  const [field, meta] = useField({ name, type });
  const [inputType, setInputType] = useState(type);
  const [eyeIcon, setEyeIcon] = useState(ActionIcon.EYE);
  const ref = useRef<React.ElementRef<'input'>>(null);
  const { onBlur, onChange, value } = field;
  const { error, touched } = meta;
  const shouldShowError = error && touched;
  const readonlyValue = useMemo(() => {
    if (!isNotNullOrUndefined(value)) {
      return 'N/A';
    }

    return value;
  }, [value]);

  const focusInput = useCallback(() => {
    ref?.current?.focus();
  }, []);

  const togglePassword = useCallback(() => {
    if (inputType === 'password') {
      setInputType('text');
      setEyeIcon(ActionIcon.DISABLED_EYE);
    } else {
      setInputType('password');
      setEyeIcon(ActionIcon.EYE);
    }
  }, [inputType]);

  return (
    <StyledWrapper
      customMargin={customMargin}
      customWidth={customWidth}
      deleteView={deleteView}
      inlineShort={inlineShort}
      medium={medium}
      modalView={modalView}
      reverse={reverse}
      small={small}
      stacked={stacked}
      tariffView={tariffView}
      vehicleCount={vehicleCount}
      zoneView={zoneView}
    >
      {label !== '' && (
        <Label
          htmlFor={name}
          vehicleCount={vehicleCount}
          stacked={stacked}
          onClick={focusInput}
          reverse={reverse}
          zoneView={zoneView}
          mediumLabel={mediumLabel}
          tariffView={tariffView}
          primary={primary}
          removeCursor={removeCursor}
        >
          {label}
        </Label>
      )}
      <StyledColumn
        short={short}
        reverse={reverse}
        small={small}
        eyeIcon={addEyeIcon}
        inline={inline}
        tariffView={tariffView}
        inlineShort={inlineShort}
        vehicleCount={vehicleCount}
      >
        {!readonly && (
          <StyledInput
            id={name}
            autoFocus={autofocus}
            onBlur={onBlur}
            onChange={onChange}
            value={value === null || value === undefined ? '' : value}
            ref={ref}
            name={name}
            type={inputType}
            placeholder={placeholder}
            disabled={disabled}
            reverse={reverse || tariffView}
            autoComplete={autoComplete}
            data-testid={testId}
            vehicleCount={vehicleCount}
            min={minValue}
          />
        )}
        {addEyeIcon && (
          <StyledIcon className={eyeIcon} onClick={togglePassword} />
        )}
        {readonly && (
          <StyledReadonlyData vehicleCount={vehicleCount}>
            {readonlyValue}
          </StyledReadonlyData>
        )}
        {shouldShowError && <Error>*{error}</Error>}
      </StyledColumn>
    </StyledWrapper>
  );
}

const StyledColumn = styled.div<{
  short?: boolean;
  reverse?: boolean;
  small?: boolean;
  eyeIcon?: boolean;
  inline?: boolean;
  inlineShort?: boolean;
  tariffView?: boolean;
  vehicleCount?: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${(props) => {
    const { short, reverse, small, inline, tariffView, vehicleCount } = props;

    if (short) {
      return css`
        width: 300px;
        @media (max-width: 500px) {
          width: 250px;
        }
      `;
    }

    if (small) {
      return css`
        width: 200px;
        margin-right: 10px;
      `;
    }

    if (inline) {
      return css`
        width: 80%;
        @media (max-width: 500px) {
          width: 250px;
        }
      `;
    }

    if (reverse) {
      return css`
        width: 60px;
        margin-right: 10px;
      `;
    }

    if (tariffView) {
      return css`
        width: 60px;
        margin-right: 10px;
        @media (max-width: 650px) {
          width: 100%;
        }
      `;
    }

    if (vehicleCount) {
      return css`
        width: 100%;
      `;
    }
    return css`
      width: 100%;
    `;
  }}

  ${(props) => {
    const { eyeIcon } = props;

    if (eyeIcon) {
      return css`
        position: relative;
        justify-content: center;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { inlineShort } = props;

    if (inlineShort) {
      return css`
        align-items: flex-end;
      `;
    }
    return css``;
  }}
`;

const StyledWrapper = styled.div<{
  stacked?: boolean;
  reverse?: boolean;
  medium?: boolean;
  modalView?: boolean;
  customWidth?: string;
  customMargin?: boolean;
  zoneView?: boolean;
  deleteView?: boolean;
  small?: boolean;
  tariffView?: boolean;
  inlineShort?: boolean;
  vehicleCount?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  margin: 30px 0px 0px 0px;
  & :focus {
    background-color: ${Color.BACKGROUND_DARK};
  }
  @media (max-width: 999px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }

  :last-child {
    margin: 30px 0px 40px 0px;
  }

  ${(props) => {
    const { vehicleCount } = props;

    if (vehicleCount) {
      return css`
        @media (max-width: 500px) {
          flex-direction: row;
          :last-child {
            margin: 0px;
          }
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { medium, small, inlineShort } = props;

    if (small) {
      return css`
        width: max-content;
      `;
    }

    if (medium) {
      return css`
        width: 70%;
      `;
    }

    if (inlineShort) {
      return css`
        width: 300px;
      `;
    }

    return css`
      width: 100%;
    `;
  }}

  ${(props) => {
    const { stacked } = props;

    if (!stacked) {
      return css`
        flex-direction: row;
      `;
    }

    return css`
      flex-direction: column;
    `;
  }}
  
  ${(props) => {
    const { customMargin } = props;

    if (customMargin) {
      return css`
        margin: 15px 0px 0px 0px;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { vehicleCount } = props;

    if (vehicleCount) {
      return css`
        margin: 0px 0px 0px 0px;
        @media (min-width: 500px) {
          :last-child {
            margin: 0px 0px 0px 0px;
          }
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { zoneView } = props;

    if (zoneView) {
      return css`
        margin: 0px;

        :last-child {
          margin: 0;
        }
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { deleteView } = props;

    if (deleteView) {
      return css`
        justify-content: center;
        margin-top: 0;

        :last-child {
          margin-top: 0;
        }
      `;
    }
    return css``;
  }}

    ${(props) => {
    const { modalView } = props;

    if (modalView) {
      return css`
        display: inline-block;
        margin-right: 15px;
        margin-top: 15px;
        width: 25%;
      `;
    }
    return css``;
  }}
    
    ${(props) => {
    const { reverse } = props;

    if (reverse) {
      return css`
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 250px;
        margin: 15px 0 0 0;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { tariffView } = props;

    if (tariffView) {
      return css`
        width: auto;

        :last-child {
          margin: 30px 0px 0px 0px;
        }

        @media (max-width: 999px) {
          width: auto;
        }

        @media (max-width: 650px) {
          flex-direction: column;
        }

        @media (max-width: 600px) {
          width: 100%;
        }
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { customWidth } = props;

    if (customWidth) {
      return css`
        width: ${customWidth};

        :last-child {
          margin-bottom: 0;
        }
      `;
    }
    return css``;
  }}
`;

const StyledInput = styled.input<{ reverse?: boolean; vehicleCount?: boolean }>`
  height: 36px;
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${Color.TEXT_DARKER};
  background-color: ${Color.BACKGROUND_LIGHTEST};
  width: 100%;

  ${(props) => {
    const { reverse } = props;

    if (reverse) {
      return css`
        padding: 0 7px;
      `;
    }

    return css`
      padding: 0px 14px;
    `;
  }}

  ${(props) => {
    const { vehicleCount } = props;

    if (vehicleCount) {
      return css`
        text-align: center;
        width: 65px;
        font-size: 14px;
        margin: 0px 10px;
        padding: 0px;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        background-color: ${Color.BACKGROUND_LIGTH};
        cursor: default;
      `;
    }
    return css``;
  }}

    @media (max-width: 500px) {
    width: 75%;
  }

  ${(props) => {
    const { vehicleCount } = props;

    if (vehicleCount) {
      return css`
        @media (max-width: 500px) {
          width: 65px;
        }
      `;
    }
    return css``;
  }}

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${Color.TEXT_LIGHT};
  }
`;

const StyledReadonlyData = styled.p<{ vehicleCount?: boolean }>`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: 36px;
  color: ${Color.TEXT_DARKER};
  ${(props) => {
    const { vehicleCount } = props;

    if (vehicleCount) {
      return css`
        color: ${Color.TEXT_BRAND};
        text-align: right;
        font-size: 36px;
        margin: 0px 10px;
      `;
    }
    return css``;
  }}
`;

const StyledIcon = styled.i`
  position: absolute;
  display: flex;
  right: 0;
  padding-right: 10px;
  color: ${Color.PRIMARY_BRAND};
  font-site: 18px;
`;
