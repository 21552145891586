import type KeyLogResendParams from '../models/KeyLog/KeyLogResendParams';
import type KeyLogResendValues from '../models/KeyLog/KeyLogResendValues';

export default function toKeyLogResendParams(
  data: KeyLogResendValues
): KeyLogResendParams {
  const { ids } = data;

  return { ids };
}
