import type LicensePlateAllowFormValues from 'src/anpr/models/LotWhitelist/LicensePlateAllowFormValues';
import type LicensePlateAllowRequest from 'src/anpr/models/LotWhitelist/LicensePlateAllowRequest';

export default function toLicensePlateAllowRequest(
  lotId: number,
  data: LicensePlateAllowFormValues
): LicensePlateAllowRequest {
  const { licensePlates, name, email, startTime, endTime, isUnlimited } = data;

  const responseBody: LicensePlateAllowRequest = {
    lot_id: lotId,
    name,
    email,
    license_plates: generateLicensePlateArray(licensePlates),
    start_time: startTime ? startTime.toISOString() : '',
  };

  if (isUnlimited) {
    responseBody.is_unlimited = 1;
  } else {
    responseBody.end_time = endTime ? endTime.toISOString() : '';
  }

  return responseBody;
}

function generateLicensePlateArray(licensePlate: string) {
  const plates = licensePlate.split(/[ ,.;:]+/);
  return plates;
}
