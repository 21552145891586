import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import toGatewayFormValues from 'src/products/mappers/Gateway/toGatewayFormValues';
import useGatewayService from 'src/products/services/useGatewayService';
import SocketEvents from 'src/shared/constants/SocketEvents';
import SocketPath from 'src/shared/constants/SocketPath';
import SocketTimer from 'src/shared/constants/SocketTimer';
import useSocket from 'src/shared/hooks/useSocket';

import * as yup from 'yup';
import ExitButton from '../../shared/components/Buttons/ExitButton';
import Form from '../../shared/components/Form';
import { useGlobalConfirmationModal } from '../../shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../shared/components/Modals/GlobalSuccessModal';
import Tabs from '../../shared/components/Tabs';
import useIsMounted from '../../shared/hooks/useIsMounted';
import { isNotString } from '../../shared/utils/checks';
import type Gateway from '../models/Gateway/Gateway';
import type GatewayFormValues from '../models/Gateway/GatewayFormValues';
import type GatewayStatusEvent from '../models/Gateway/GatewayStatusEvent';
import GatewaySystemControlForm from './GatewaySystemControlForm';
import GatewayUpdateForm from './GatewayUpdateForm';

interface GatewayDetailsModalProps {
  id: number | undefined;
  closeParentModal: () => void;
  onGatewayNameChange: (name: string, id: number) => void;
  showToast?: (msg: string, device?: string) => void;
}

const defaultFormValues: GatewayFormValues = {
  name: '',
  uuid: '',
  firmwareVersion: '',
  ipAddress: '',
};

const gatewayValidationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
});

export default function GatewayDetailsModal(props: GatewayDetailsModalProps) {
  const { id, closeParentModal, onGatewayNameChange, showToast } = props;

  const [gatewayDetails, setGatewayDetails] = useState<Gateway | undefined>();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [deviceInProgress, setDeviceInProgress] = useState(false);
  const [shouldRefreshPage, setShouldRefreshPage] = useState(false);
  const { findOneGateway, removeGateway, updateGateway, rebootGateway } =
    useGatewayService();
  const isMounted = useIsMounted();
  const socket = useSocket(SocketPath.SOCKETIO);
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
    refreshPage: shouldRefreshPage,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        if (!id) {
          return;
        }
        if (isMounted()) {
          setAreDetailsLoading(true);
        }

        const data = await findOneGateway(id);
        const values = toGatewayFormValues(data);

        if (isMounted()) {
          setInitialValues(values);
          setGatewayDetails(data);
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [id, isMounted, findOneGateway]);

  const getChangedGatewayValues = useCallback(
    (values: GatewayFormValues) => ({
      name: values.name !== initialValues.name ? values.name : undefined,
    }),
    [initialValues]
  );

  const onGatewaySubmit = useCallback(
    async (
      values: GatewayFormValues,
      { setErrors }: FormikHelpers<GatewayFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }

        const valuesToUpdate = getChangedGatewayValues(values);

        await updateGateway(id, valuesToUpdate);

        if (isMounted()) {
          setMessageSuccess('Successfuly updated gateway!');
          openGlobalSuccessModal();
          onGatewayNameChange(values.name, id);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      id,
      onGatewayNameChange,
      openGlobalFailureModal,
      setMessage,
      openGlobalSuccessModal,
      isMounted,
      updateGateway,
      getChangedGatewayValues,
    ]
  );

  const onGatewayRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      await removeGateway(id);

      if (isMounted()) {
        setMessageSuccess('Gateway deleted successfully!');
        setShouldRefreshPage(true);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    openGlobalFailureModal,
    openGlobalSuccessModal,
    setMessage,
    removeGateway,
    isMounted,
  ]);

  const onGatewayReboot = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      if (isMounted()) {
        setDeviceInProgress(true);
      }

      await rebootGateway(id);

      let operationListener: Socket | undefined;

      const operationTimeout = setTimeout(() => {
        if (operationListener) {
          operationListener = socket?.off(
            SocketEvents.GATEWAY_STATUS,
            handleOneGatewayStatusEvent
          );
        }
        setDeviceInProgress(false);
        setMessage({
          code: 'Unknown',
          message: 'Operation timeout',
        });
        openGlobalFailureModal();
      }, SocketTimer.PRODUCT_OPERATION_TIMEOUT);

      if (!socket) {
        throw Error();
      }

      const handleOneGatewayStatusEvent = (payload: string) => {
        try {
          const event: GatewayStatusEvent = JSON.parse(payload);
          const { uuid, status } = event;

          if (uuid !== gatewayDetails?.uuid) {
            return;
          }
          clearTimeout(operationTimeout);
          operationListener = socket?.off(
            SocketEvents.GATEWAY_STATUS,
            handleOneGatewayStatusEvent
          );

          setGatewayDetails((oldGateway) => {
            if (!oldGateway) return undefined;
            return {
              ...oldGateway,
              status,
            };
          });
          if (showToast) {
            showToast(
              'It might take a few minutes for the gateway to reboot.',
              gatewayDetails.uuid
            );
          }

          if (isMounted()) {
            setDeviceInProgress(false);
          }
        } catch {
          setMessage({ code: 'Unknown', message: 'JSON parse error' });
          openGlobalFailureModal();
        }
      };

      socket?.on(SocketEvents.GATEWAY_STATUS, handleOneGatewayStatusEvent);
    } catch (error) {
      if (isMounted()) {
        setDeviceInProgress(false);
      }
      throw error;
    }
  }, [
    id,
    rebootGateway,
    gatewayDetails,
    isMounted,
    openGlobalFailureModal,
    setMessage,
    socket,
    showToast,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onGatewayRemove,
    message: 'Are you sure you want to remove this gateway?',
  });

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='gateway'
            onSubmit={onGatewaySubmit}
            initialValues={initialValues}
            validationSchema={gatewayValidationSchema}
            isLoading={areDetailsLoading}
          >
            <GatewayUpdateForm
              details={gatewayDetails}
              isLoading={areDetailsLoading}
              openRemoveModal={openGlobalConfirmationModal}
              closeParentModal={closeParentModal}
            />
          </Form>
        </Tabs.Panel>
        <Tabs.Panel name='systemControl' label='System Control'>
          <GatewaySystemControlForm
            onClick={onGatewayReboot}
            status={gatewayDetails?.status}
            deviceInProgress={deviceInProgress}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
