import type GatewayUpdateParams from 'src/products/models/Gateway/GatewayUpdateParams';
import type GatewayUpdateValues from 'src/products/models/Gateway/GatewayUpdateValues';

export default function toGatewayUpdateParams(
  data: GatewayUpdateValues
): GatewayUpdateParams {
  const { name } = data;

  return { name };
}
