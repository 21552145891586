import type ConcreteProductError from 'src/lots/models/ConcreteProductError';
import type ConcreteProductErrorResponse from 'src/lots/models/ConcreteProductErrorResponse';

export default function toConcreteProductError(
  data: ConcreteProductErrorResponse
): ConcreteProductError {
  const {
    id,
    error_code,
    jammed,
    sensor_dirty,
    pin_broken,
    rtcc_invalid,
    battery_empty,
    master_key_not_set,
    deleted_at,
    created_at,
    updated_at,
  } = data;

  return {
    id,
    errorEode: error_code,
    jammed: !!jammed,
    sensorDirty: !!sensor_dirty,
    pinBroken: !!pin_broken,
    rtccInvalid: !!rtcc_invalid,
    batteryEmpty: !!battery_empty,
    masterKeyNotSet: !!master_key_not_set,
    deletedAt: deleted_at ? new Date(deleted_at) : null,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
