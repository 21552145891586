import { useField } from 'formik';
import React, { useMemo } from 'react';
import Card from 'src/shared/components/Card';
import ButtonSize from '../constants/ButtonSize';
import Button from './Button';
import ButtonsContainer from './ButtonsContainer';
import DateTimeField from './DateTimeField';
import TextField from './TextField';

interface LotWhitelistLogsFilterProps {
  onResetAllFilters: () => void;
}

export default function LotWhitelistLogsFilter(
  props: LotWhitelistLogsFilterProps
) {
  const { onResetAllFilters } = props;
  const [field] = useField({ name: 'startTime' });

  const today = useMemo(() => new Date(), []);

  const tomorrow = useMemo(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  }, []);

  return (
    <Card>
      <TextField
        label='License Plate'
        placeholder='License Plate'
        type='text'
        name='licensePlate'
        stacked
        customMargin
      />
      <TextField
        label='Operator ID'
        placeholder='ID of the Operator'
        type='text'
        name='accountUserId'
        stacked
        customMargin
      />
      <TextField
        label='Operator Name'
        placeholder='Operator Name'
        type='text'
        name='accountUserName'
        stacked
        customMargin
      />
      <DateTimeField
        name='startTime'
        label='From'
        placeholderDate='Date From'
        placeholderTime='Time From'
        maxBookingDate={today}
        block
        noInitialValue
      />
      <DateTimeField
        name='endTime'
        label='To'
        placeholderDate='Date To'
        placeholderTime='Time To'
        minBookingDate={new Date(field.value)}
        maxBookingDate={tomorrow}
        endTimeOffset
        block
        noInitialValue
      />
      <ButtonsContainer>
        <Button
          size={ButtonSize.MEDIUM}
          type='reset'
          addVerticalMargin
          onClick={onResetAllFilters}
        >
          Reset all
        </Button>
        <Button
          size={ButtonSize.MEDIUM}
          type='submit'
          addVerticalMargin
          primary
        >
          Apply filters
        </Button>
      </ButtonsContainer>
    </Card>
  );
}
