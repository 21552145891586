import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import LicensePlatesStatuses from 'src/anpr/constants/LicensePlatesStatuses';
import LicensePlatesStatusValues from 'src/anpr/constants/LicensePlatesStatusValues';
import type LotWhitelist from 'src/anpr/models/LotWhitelist/LotWhitelist';
import { StyledDivIcon, StyledIcon } from 'src/anpr/screens/AnprList';
import useLotWhitelistsService from 'src/anpr/services/useLotWhitelistsService';

import { getLicensePlateStatus } from 'src/anpr/utils/getLicensePlateStatus';
import type Dropdown from 'src/keysharing/models/Dropdown';
import type SharedListFilterValues from 'src/keysharing/models/SharedListFilterValues';
import Button from 'src/shared/components/Button';
import ButtonsContainer from 'src/shared/components/ButtonsContainer';
import Card from 'src/shared/components/Card';
import CheckBox from 'src/shared/components/CheckBox';
import Content from 'src/shared/components/Content';
import Control from 'src/shared/components/Control';
import DropdownFilter from 'src/shared/components/DropdownFilter';
import Form from 'src/shared/components/Form';
import Main from 'src/shared/components/Main';
import { useGlobalConfirmationModal } from 'src/shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import Pagination from 'src/shared/components/Pagination';
import SearchFilter from 'src/shared/components/SearchFilter';
import SelectFieldWithoutFormik from 'src/shared/components/SelectField/SelectFieldWithoutFormik';
import StyledBlockIcon from 'src/shared/components/StyledBlockIcon';
import StyledCell from 'src/shared/components/StyledCell';
import StyledNoData from 'src/shared/components/StyledNoData';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import ActionIcon from 'src/shared/constants/ActionIcon';
import ButtonSize from 'src/shared/constants/ButtonSize';
import PaginationSize from 'src/shared/constants/DataSize';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import PaginationItemDisplay from 'src/shared/constants/PaginationItemDisplay';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import capitalizeFirstLetter from 'src/shared/utils/capitalizeFirstLetter';
import formatOperationTokenDates from 'src/shared/utils/formatOperationTokenDates';
import * as yup from 'yup';
import type Meta from '../../shared/models/Meta';
import type Option from '../../shared/models/Option';

const initialValues: SharedListFilterValues = {
  licensePlate: '',
  startTime: null,
  endTime: null,
};

const optiondata: Option[] = [
  { key: LicensePlatesStatusValues.ALL, label: 'All' },
  { key: LicensePlatesStatusValues.ACTIVE, label: 'Active' },
  { key: LicensePlatesStatusValues.EXPIRED, label: 'Expired' },
];

const initialDropdownValues: Dropdown = {
  filter: {
    key: LicensePlatesStatusValues.ALL,
    label: 'All',
  },
};

const validationSchema = yup.object().shape({
  receiverIdentifier: yup
    .string()
    .min(3, 'License plate should be at least 3 characters long'),
  startTime: yup.date().nullable().notRequired(),
  endTime: yup
    .date()
    .nullable()
    .notRequired()
    .when('startTime', {
      is: (startTime: Date | null) => startTime !== null,
      then: yup
        .date()
        .nullable()
        .notRequired()
        .min(yup.ref('startTime'), "End Date can't be before Start Date"),
    }),
});

export default function AnprSharedAccessTable() {
  const { listLotWhitelist, removeLicensePlates, removeLicensePlateById } =
    useLotWhitelistsService();
  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState<{
    [key: number]: boolean;
  }>({});
  const [showConfirmationButtons, setShowConfirmationButtons] = useState<{
    [key: number]: boolean;
  }>({});
  const [filter, setFilter] = useState<Option | undefined | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState<LotWhitelist[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);

  const { currentPage } = meta;

  const { params } = useRouteMatch<{ lotId: string }>();
  const { lotId } = params;
  const { push } = useHistory();

  const [filterValues, setFilterValues] = useState<SharedListFilterValues>({
    licensePlate: '',
    startTime: undefined,
    endTime: undefined,
  });

  const [selectedPlates, setSelectedPlates] = useState<number[]>([]);

  const options: Option[] = optiondata.map(({ key, label }) => ({
    key,
    label,
  }));

  const getLicensePlatesData = useCallback(
    async (page: number) => {
      try {
        if (!lotId) return;

        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { startTime, endTime, licensePlate } = filterValues;

        const { data, meta } = await listLotWhitelist({
          page,
          size: PaginationSize.STANDARD,
          lotId: parseInt(lotId),
          licensePlate,
          startTime: startTime?.toISOString(),
          endTime: endTime?.toISOString(),
          status: getLicensePlateStatus(filter?.key),
        });

        if (isMounted()) {
          setData(data);
          setSelectedPlates([]);
          setIsDataLoading(false);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [listLotWhitelist, isMounted, lotId, filterValues, filter]
  );

  useEffect(() => {
    getLicensePlatesData(1);
  }, [getLicensePlatesData]);

  const onSubmit = useCallback(
    async (values: SharedListFilterValues) => {
      const { licensePlate, startTime, endTime } = values;

      if (isMounted()) {
        setFilterValues((oldValues) => {
          const {
            licensePlate: oldLicensePlate,
            startTime: oldStartTime,
            endTime: oldEndTime,
          } = oldValues;
          if (
            oldLicensePlate === licensePlate &&
            oldStartTime?.getTime() === startTime?.getTime() &&
            oldEndTime?.getTime() === endTime?.getTime()
          ) {
            return oldValues;
          }
          return {
            licensePlate,
            startTime,
            endTime,
          };
        });
      }
    },
    [isMounted]
  );

  const onResetAllFilters = useCallback(() => {
    if (isMounted()) {
      setFilterValues((oldValues) => {
        const {
          licensePlate: oldLicensePlate,
          startTime: oldStartTime,
          endTime: oldEndTime,
        } = oldValues;
        if (oldLicensePlate === '' && !oldStartTime && !oldEndTime) {
          return oldValues;
        }
        return {
          licensePlate: '',
          startTime: undefined,
          endTime: undefined,
        };
      });
    }
  }, [isMounted]);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: successMessage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onRemoveAcceess = useCallback(async () => {
    try {
      if (selectedPlates.length === 0) return;

      const response = await removeLicensePlates({ ids: selectedPlates });

      const successfullyRemoved = response
        .filter((plate) => plate.success)
        .map((plate) => plate.id);
      if (successfullyRemoved.length === data.length && currentPage > 1) {
        getLicensePlatesData(currentPage - 1);
      }
      getLicensePlatesData(currentPage);

      if (isMounted()) {
        successfullyRemoved.length === selectedPlates.length
          ? setSuccessMessage('All plates are removed successfully!')
          : setSuccessMessage(
              'Sorry for the inconvenience! Something went wrong, please try again.'
            );
        setSelectedPlates([]);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    isMounted,
    selectedPlates,
    removeLicensePlates,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    currentPage,
    data,
    getLicensePlatesData,
  ]);

  const updateSelectablePlates = useCallback((id: number) => {
    setSelectedPlates((selectedPlates) => {
      if (selectedPlates.includes(id)) {
        return selectedPlates.filter((selectedPlate) => selectedPlate !== id);
      }
      return [...selectedPlates, id];
    });
  }, []);

  const onSelectAllSelectablePlates = useCallback(() => {
    if (selectedPlates.length === data.length) {
      setSelectedPlates([]);
      return;
    }

    if (data.length === 0) {
      return;
    }

    const selectablePlatesIds = data.map((plate) => plate.id);

    setSelectedPlates(selectablePlatesIds);
  }, [data, selectedPlates]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onRemoveAcceess,
    message:
      'Are you sure you want to remove access for all selected License Plates?',
  });

  const onDeleteIconClick = useCallback(
    (id: number) => {
      if (showDeleteIcon[id]) {
        setShowDeleteIcon({ [id]: false });
        setShowConfirmationButtons({ [id]: true });
      }
    },
    [showDeleteIcon]
  );

  const onLicensePlateRemove = useCallback(
    async (id: number) => {
      try {
        await removeLicensePlateById(id);

        if (data.length === 1 && currentPage > 1) {
          getLicensePlatesData(currentPage - 1);
        }
        getLicensePlatesData(currentPage);

        if (isMounted()) {
          setShowConfirmationButtons({ [id]: false });
          setSuccessMessage('License plate is removed successfully!');
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      removeLicensePlateById,
      data,
      currentPage,
      getLicensePlatesData,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  const goToSharedAnprLogsScreen = useCallback(() => {
    if (!lotId) {
      return;
    }

    push(`/parking/lots/${lotId}/shared/anpr/lot-whitelist-logs`);
  }, [push, lotId]);

  const onSelectDropdownValue = useCallback((value: Option | undefined) => {
    setFilter(value);
  }, []);

  return (
    <Main>
      <Content widthSize='27%'>
        <Title>License Plates</Title>
        <Form
          name='Filter'
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <SearchFilter
            maxBookingDateFlag
            emailFlag
            anpr
            onResetAllFilters={onResetAllFilters}
          />
        </Form>
        <StyledDivIcon onClick={goToSharedAnprLogsScreen}>
          <StyledIcon className={ActionIcon.TIME} />
          <span>ANPR Shared Access Logs</span>
        </StyledDivIcon>
      </Content>
      <Content widthSize='70%' addTopMargin>
        <Card sticky>
          <Control left>
            <CheckBox
              checked={data.length > 0 && data.length === selectedPlates.length}
              onClick={onSelectAllSelectablePlates}
              disabled={data.length === 0}
              readonly
              title='Select All'
            />
            <DropdownFilter>
              <SelectFieldWithoutFormik
                label=''
                placeholder='All'
                name='filter'
                initialValue={initialDropdownValues.filter}
                options={options}
                hideInput
                onChange={onSelectDropdownValue}
              />
            </DropdownFilter>
            {selectedPlates.length > 0 ? (
              <Button
                size={ButtonSize.MIDDLE}
                onClick={openGlobalConfirmationModal}
              >
                Remove access
              </Button>
            ) : null}
          </Control>
        </Card>
        <Card>
          <Table isLoading={isDataLoading}>
            <Table.Head>
              <Table.Row>
                <Table.Header />
                <Table.Header>LICENSE PLATE</Table.Header>
                <Table.Header>NAME</Table.Header>
                <Table.Header>SHARED ACCESS</Table.Header>
                <Table.Header />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {data.length > 0 ? (
                data.map((licensePlateData) => {
                  const { id, licensePlate, startTime, endTime, status, name } =
                    licensePlateData;

                  const selected = selectedPlates.includes(id);

                  const selectId = () => {
                    updateSelectablePlates(id);
                  };

                  const onHover = () => {
                    if (showConfirmationButtons[id] !== true) {
                      setShowDeleteIcon({ [id]: true });
                    }
                  };
                  const onLeave = () => {
                    setShowDeleteIcon({ [id]: false });
                  };

                  const onIconClick = () => {
                    onDeleteIconClick(id);
                  };

                  const onBlockClick = () => {
                    onLicensePlateRemove(id);
                  };

                  const onCancelClick = () => {
                    setShowConfirmationButtons({ [id]: false });
                  };

                  return (
                    <Table.Row
                      key={id}
                      disabled={status !== LicensePlatesStatuses.ACTIVE}
                      onMouseEnter={onHover}
                      onMouseLeave={onLeave}
                    >
                      <Table.Cell>
                        <CheckBox
                          checked={selected}
                          onClick={selectId}
                          readonly
                          title='Select'
                        />
                      </Table.Cell>
                      <Table.Cell title={licensePlate} short>
                        {licensePlate}
                      </Table.Cell>
                      <Table.Cell title={name} short>
                        {name}
                      </Table.Cell>
                      <Table.Cell minWidth>
                        <StyledCell>
                          Start time:
                          <br />
                          End time:
                        </StyledCell>
                        <div>
                          {formatOperationTokenDates(startTime)}
                          <br />
                          {formatOperationTokenDates(endTime)}
                        </div>
                      </Table.Cell>
                      <Table.Cell>{capitalizeFirstLetter(status)}</Table.Cell>
                      <Table.Cell right>
                        {showDeleteIcon[id] && (
                          <StyledBlockIcon
                            className={ActionIcon.DELETE}
                            onClick={onIconClick}
                          />
                        )}
                        {showConfirmationButtons[id] && (
                          <ButtonsContainer table>
                            <Button
                              size={ButtonSize.SMALL}
                              style={{ marginRight: '5px' }}
                              onClick={onBlockClick}
                              primary
                            >
                              Remove
                            </Button>
                            <Button
                              size={ButtonSize.SMALL}
                              onClick={onCancelClick}
                            >
                              Cancel
                            </Button>
                          </ButtonsContainer>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell merged={4}>
                    <StyledNoData>No license plate added.</StyledNoData>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card>
        {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
          <Pagination meta={meta} getData={getLicensePlatesData} />
        )}
      </Content>
    </Main>
  );
}
