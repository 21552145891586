import type ResetPasswordFormValues from '../models/ResetPasswordFormValues';
import type ResetPasswordRequest from '../models/ResetPasswordRequest';

export default function toResetPasswordRequest(
  data: ResetPasswordFormValues
): ResetPasswordRequest {
  const { email } = data;

  return {
    email: email.replace(/\s/g, ''),
  };
}
