import { useCallback } from 'react';
import toDetectCameraCreateRequest from 'src/detection/mappers/toDetectCameraCreateRequest';
import toDetectCameraQuery from 'src/detection/mappers/toDetectCameraQuery';
import toDetectCameraUpdateParams from 'src/detection/mappers/toDetectCameraUpdateParams';
import type DetectCamera from 'src/detection/models/DetectCamera';
import type DetectCameraFormValues from 'src/detection/models/DetectCameraFormValues';
import type DetectCameraQuery from 'src/detection/models/DetectCameraQuery';
import type DetectCameraQueryParams from 'src/detection/models/DetectCameraQueryParams';
import type DetectCameraRemoveResponse from 'src/detection/models/DetectCameraRemoveResponse';
import type DetectCameraRequest from 'src/detection/models/DetectCameraRequest';
import type DetectCameraResponse from 'src/detection/models/DetectCameraResponse';
import type DetectCameraUpdateParams from 'src/detection/models/DetectCameraUpdateParams';
import type DetectCameraUpdateValues from 'src/detection/models/DetectCameraUpdateValues';
import type DetectCameraUploadSvgRequest from 'src/detection/models/DetectCameraUploadSvgRequest';
import type DetectCameraUploadSvgResponse from 'src/detection/models/DetectCameraUploadSvgResponse';
import ContentType from 'src/shared/constants/ContentType';
import toMeta from 'src/shared/mappers/toMeta';
import type GetAllDetails from 'src/shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toDetectCamera from '../mappers/toDetectCamera';

const detectionCameraBaseURL = '/parking-detection-cameras';

interface DetectCameraService {
  findAllDetectCameras: (
    queryParams: DetectCameraQueryParams
  ) => Promise<GetAllDetails<DetectCamera>>;
  findAllDetectCamerasParklioInternal: (
    queryParams: DetectCameraQueryParams
  ) => Promise<GetAllDetails<DetectCamera>>;
  findOneDetectCamera: (id: number) => Promise<DetectCamera>;
  findDetectCameraFrame: (id: number) => Promise<string>;
  createDetectCamera: (values: DetectCameraFormValues) => Promise<DetectCamera>;
  updateDetectCamera: (
    id: number,
    values: DetectCameraUpdateValues
  ) => Promise<DetectCamera>;
  removeDetectCamera: (id: number) => Promise<DetectCameraRemoveResponse>;
  uploadSvg: (id: number, file: File) => Promise<DetectCameraUploadSvgResponse>;
}

export default function useDetectCameraService(): DetectCameraService {
  const { get, getImg, post, patch, remove } = useApiService();

  const { get: getParklioInternal } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const { post: postFile } = useApiService({
    withAuth: true,
    contentType: ContentType.FORM_DATA,
  });

  const findAllDetectCameras = useCallback(
    async (queryParams: DetectCameraQueryParams) => {
      const query = toDetectCameraQuery(queryParams);
      const response = await get<DetectCameraResponse[], DetectCameraQuery>(
        `${detectionCameraBaseURL}`,
        query
      );

      const data = response.data.map(toDetectCamera);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findAllDetectCamerasParklioInternal = useCallback(
    async (queryParams: DetectCameraQueryParams) => {
      const query = toDetectCameraQuery(queryParams);
      const response = await getParklioInternal<
        DetectCameraResponse[],
        DetectCameraQuery
      >(`${detectionCameraBaseURL}`, query);

      const data = response.data.map(toDetectCamera);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [getParklioInternal]
  );

  const findOneDetectCamera = useCallback(
    async (id: number) => {
      const response = await get<DetectCameraResponse>(
        `${detectionCameraBaseURL}/${id}`
      );

      const data = toDetectCamera(response.data);

      return data;
    },
    [get]
  );

  const findDetectCameraFrame = useCallback(
    async (id: number) => {
      const response = await getImg(`${detectionCameraBaseURL}/${id}/frame`);

      return URL.createObjectURL(response.img);
    },
    [getImg]
  );

  const createDetectCamera = useCallback(
    async (values: DetectCameraFormValues) => {
      const requestBody = toDetectCameraCreateRequest(values);
      const response = await post<DetectCameraRequest, DetectCameraResponse>(
        `${detectionCameraBaseURL}`,
        requestBody
      );

      return toDetectCamera(response.data);
    },
    [post]
  );

  const updateDetectCamera = useCallback(
    async (id: number, values: DetectCameraUpdateValues) => {
      const params = toDetectCameraUpdateParams(values);
      const response = await patch<
        DetectCameraUpdateParams,
        DetectCameraResponse
      >(`${detectionCameraBaseURL}/${id}`, params);

      return toDetectCamera(response.data);
    },
    [patch]
  );

  const removeDetectCamera = useCallback(
    async (id: number) => {
      const response = await remove<DetectCameraRemoveResponse>(
        `${detectionCameraBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  const uploadSvg = useCallback(
    async (id: number, file: File) => {
      const response = await postFile<
        DetectCameraUploadSvgRequest,
        DetectCameraUploadSvgResponse
      >(`${detectionCameraBaseURL}/${id}/upload-view-schema`, {
        file,
      });

      return response;
    },
    [postFile]
  );

  return {
    findAllDetectCameras,
    findDetectCameraFrame,
    findOneDetectCamera,
    findAllDetectCamerasParklioInternal,
    createDetectCamera,
    updateDetectCamera,
    removeDetectCamera,
    uploadSvg,
  };
}
