import type WeblinkUpdateByIdParams from '../models/Weblink/WeblinkUpdateByIdParams';
import type WeblinkUpdateByIdValues from '../models/Weblink/WeblinkUpdateByIdValues';

export default function toWeblinkUpdateByIdParams(
  data: WeblinkUpdateByIdValues
): WeblinkUpdateByIdParams {
  const { status } = data;

  return { status };
}
