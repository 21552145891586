import type LotUpdateParams from 'src/lots/models/LotUpdateParams';
import type LotUpdateValues from 'src/lots/models/LotUpdateValues';
import {
  ParkingDetectionImplementationStatus,
  StatusLabels,
} from 'src/products/constants/ParkingDetectionImplementationStatus';
import type ParkingDetectionIntegrations from 'src/products/constants/ParkingDetectionIntegrations';

export default function toLotUpdateParams(
  data: LotUpdateValues,
  detectionIntegration?: ParkingDetectionIntegrations
): LotUpdateParams {
  const {
    name,
    address,
    description,
    timeZone,
    latitude,
    longitude,
    detectionImplementationStatus,
    occupiedCount,
    spotCount,
  } = data;

  return {
    name,
    address,
    description: description === '' ? null : description,
    time_zone: timeZone?.key,
    latitude: latitude ? parseFloat(latitude) : undefined,
    longitude: longitude ? parseFloat(longitude) : undefined,
    parking_detection_integration: detectionIntegration,
    detection_implementation_status: detectionImplementationStatus
      ? StatusLabels[detectionImplementationStatus.key]
      : detectionIntegration
      ? ParkingDetectionImplementationStatus.PENDING
      : undefined,
    occupied_count: occupiedCount,
    spot_count: spotCount,
  };
}
