import type Profile from 'src/settings/models/Profile';
import type UserProfileFormValues from '../models/UserProfileFormValues';

export default function toUserProfileFormValues(
  data: Profile
): UserProfileFormValues {
  const { email, phone, name, role } = data;

  return {
    role,
    email,
    name,
    phone: phone || '',
  };
}
