import { useCallback } from 'react';
import toAccountUserQuery from 'src/home/mappers/toAccountUserQuery';
import type AccountUserQuery from 'src/home/models/AccountUserQuery';
import type AccountUserQueryParams from 'src/home/models/AccountUserQueryParams';
import toAccountUser from 'src/settings/mappers/toAccountUser';
import toEmailActivationQuery from 'src/settings/mappers/toEmailActivationQuery';
import toProfile from 'src/settings/mappers/toProfile';

import type AccountUser from 'src/settings/models/AccountUser';
import type AccountUserResponse from 'src/settings/models/AccountUserResponse';
import type EmailActivationQuery from 'src/settings/models/EmailActivationQuery';
import type EmailActivationRequest from 'src/settings/models/EmailActivationRequest';
import type EmailActivationResponse from 'src/settings/models/EmailActivationResponse';
import type EmailConfirmationRequest from 'src/settings/models/EmailConfirmationRequest';
import type Profile from 'src/settings/models/Profile';
import type ProfileResponse from 'src/settings/models/ProfileResponse';
import ContentType from '../../shared/constants/ContentType';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toAccountUserCreateRequest from '../mappers/toAccountUserCreateRequest';
import toAccountUserUpdateParams from '../mappers/toAccountUserUpdateParams';
import toEmailConfirmationQuery from '../mappers/toEmailConfirmationQuery';
import toNewPasswordQuery from '../mappers/toNewPasswordQuery';
import toNewPasswordRequest from '../mappers/toNewPasswordRequest';
import toResetPasswordRequest from '../mappers/toResetPasswordRequest';
import type AccountUserCreateRequest from '../models/AccountUserCreateRequest';
import type AccountUserCreateResponse from '../models/AccountUserCreateResponse';
import type AccountUserFormValues from '../models/AccountUserFormValues';
import type AccountUserRemoveResponse from '../models/AccountUserRemoveResponse';
import type AccountUserUpdateParams from '../models/AccountUserUpdateParams';
import type AccountUserUpdateResponse from '../models/AccountUserUpdateResponse';
import type AccountUserUpdateValues from '../models/AccountUserUpdateValues';
import type EmailConfirmationQuery from '../models/EmailConfirmationQuery';
import type EmailConfirmationResponse from '../models/EmailConfirmationResponse';
import type NewPasswordFormValues from '../models/NewPasswordFormValues';
import type NewPasswordQuery from '../models/NewPasswordQuery';
import type NewPasswordRequest from '../models/NewPasswordRequest';
import type NewPasswordResponse from '../models/NewPasswordResponse';
import type ResetPasswordFormValues from '../models/ResetPasswordFormValues';
import type ResetPasswordRequest from '../models/ResetPasswordRequest';
import type ResetPasswordResponse from '../models/ResetPasswordResponse';

const accountUserBaseURL = '/account-users';

interface AccountUserService {
  findOneAccountUser: (id: number) => Promise<AccountUser>;
  findAllAccountUsersParklioInternal: (
    params: AccountUserQueryParams
  ) => Promise<GetAllDetails<AccountUser>>;
  findProfileAccountUser: () => Promise<Profile>;
  findAllAccountUsers: () => Promise<GetAllDetails<AccountUser>>;
  findAccountUsersById: (id: number) => Promise<GetAllDetails<AccountUser>>;
  createAccountUser: (
    values: AccountUserFormValues
  ) => Promise<AccountUserCreateResponse>;
  requestResetPassword: (
    values: ResetPasswordFormValues
  ) => Promise<ResetPasswordResponse>;
  newPassword: (
    token: string,
    signature: string,
    values: NewPasswordFormValues
  ) => Promise<NewPasswordResponse>;
  updateAccountUser: (
    id: number,
    values: AccountUserUpdateValues
  ) => Promise<AccountUserUpdateResponse>;
  updateProfileAccountUser: (
    values: AccountUserUpdateValues
  ) => Promise<AccountUserUpdateResponse>;
  removeAccountUser: (id: number) => Promise<AccountUserRemoveResponse>;
  emailConfirmation: (
    token: string,
    signature: string
  ) => Promise<EmailConfirmationResponse>;
  emailActivation: (email: string) => Promise<EmailActivationResponse>;
}

export default function useAccountUserService(): AccountUserService {
  const { get, post, patch, remove } = useApiService();

  const { post: postUnauthorized } = useApiService({
    withAuth: false,
  });

  const { get: parklioInternalGetData } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const findOneAccountUser = useCallback(
    async (id: number) => {
      const response = await get<AccountUserResponse>(
        `${accountUserBaseURL}/${id}`
      );

      const data = toAccountUser(response.data);

      return data;
    },
    [get]
  );

  const findAllAccountUsersParklioInternal = useCallback(
    async (queryParams: AccountUserQueryParams) => {
      const query = toAccountUserQuery(queryParams);
      const response = await parklioInternalGetData<
        AccountUserResponse[],
        AccountUserQuery
      >(`${accountUserBaseURL}`, query);
      const data = response.data.map(toAccountUser);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [parklioInternalGetData]
  );

  const findAllAccountUsers = useCallback(async () => {
    const response = await get<AccountUserResponse[]>(`${accountUserBaseURL}`);

    const data = response.data.map(toAccountUser);
    const meta = toMeta(response.meta);

    return { data, meta };
  }, [get]);

  const findAccountUsersById = useCallback(
    async (id: number) => {
      const response = await get<AccountUserResponse[]>(
        `/accounts/${id}${accountUserBaseURL}`
      );

      const data = response.data.map(toAccountUser);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findProfileAccountUser = useCallback(async () => {
    const response = await get<ProfileResponse>(
      `${accountUserBaseURL}/profile`
    );
    const data = toProfile(response.data);

    return data;
  }, [get]);

  const createAccountUser = useCallback(
    async (values: AccountUserFormValues) => {
      const requestBody = toAccountUserCreateRequest(values);
      const response = await post<
        AccountUserCreateRequest,
        AccountUserCreateResponse
      >(`${accountUserBaseURL}`, requestBody);

      return response;
    },
    [post]
  );

  const requestResetPassword = useCallback(
    async (values: ResetPasswordFormValues) => {
      const requestBody = toResetPasswordRequest(values);

      const response = await postUnauthorized<
        ResetPasswordRequest,
        ResetPasswordResponse
      >(`${accountUserBaseURL}/password-reset`, requestBody);

      return response;
    },
    [postUnauthorized]
  );

  const newPassword = useCallback(
    async (token: string, signature: string, values: NewPasswordFormValues) => {
      const requestBody = toNewPasswordRequest(values, token);
      const query = toNewPasswordQuery(token, signature);

      const response = await postUnauthorized<
        NewPasswordRequest,
        NewPasswordResponse,
        NewPasswordQuery
      >(
        `${accountUserBaseURL}/password-reset/confirmation`,
        requestBody,
        query
      );

      return response;
    },
    [postUnauthorized]
  );

  const updateAccountUser = useCallback(
    async (id: number, values: AccountUserUpdateValues) => {
      const params = toAccountUserUpdateParams(values);
      const response = await patch<
        AccountUserUpdateParams,
        AccountUserUpdateResponse
      >(`${accountUserBaseURL}/${id}`, params);

      return response;
    },
    [patch]
  );

  const updateProfileAccountUser = useCallback(
    async (values: AccountUserUpdateValues) => {
      const params = toAccountUserUpdateParams(values);
      const response = await patch<
        AccountUserUpdateParams,
        AccountUserUpdateResponse
      >(`${accountUserBaseURL}/profile`, params);

      return response;
    },
    [patch]
  );

  const removeAccountUser = useCallback(
    async (id: number) => {
      const response = await remove<AccountUserRemoveResponse>(
        `${accountUserBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  const emailConfirmation = useCallback(
    async (token: string, signature: string) => {
      const query = toEmailConfirmationQuery(token, signature);

      const response = await postUnauthorized<
        EmailConfirmationRequest,
        EmailConfirmationResponse,
        EmailConfirmationQuery
      >(`${accountUserBaseURL}/email-activation/confirmation`, {}, query);

      return response;
    },
    [postUnauthorized]
  );

  const emailActivation = useCallback(
    async (email: string) => {
      const query = toEmailActivationQuery(email);

      const response = await postUnauthorized<
        EmailActivationRequest,
        EmailActivationResponse,
        EmailActivationQuery
      >(`${accountUserBaseURL}/email-activation`, {}, query);

      return response;
    },
    [postUnauthorized]
  );

  return {
    findOneAccountUser,
    findAllAccountUsersParklioInternal,
    findProfileAccountUser,
    findAllAccountUsers,
    createAccountUser,
    updateAccountUser,
    updateProfileAccountUser,
    removeAccountUser,
    findAccountUsersById,
    requestResetPassword,
    newPassword,
    emailConfirmation,
    emailActivation,
  };
}
