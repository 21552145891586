import type LotChangeEventGate from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventGate';
import type LotChangeEventGatePayload from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventGatePayload';

export default function toLotChangeEventGate(
  data: LotChangeEventGatePayload
): LotChangeEventGate {
  const { state, permanent_position } = data;

  return {
    state,
    permanentPosition: permanent_position,
  };
}
