import type ZoneQuery from 'src/lots/models/ZoneAllQuery';
import type ZoneQueryParams from 'src/lots/models/ZoneQueryParams';

export default function toZoneQueryParams(data: ZoneQuery): ZoneQueryParams {
  const { lotId } = data;

  return {
    lot_id: lotId,
  };
}
