import React from 'react';
import useAuthContext from 'src/auth/hooks/useAuthContext';
import SideMenu from 'src/shared/components/SideMenu';
import UserMenuActions from './UserMenuActions';

interface UserMenuProps {
  close: () => void;
}

export default function UserMenu(props: UserMenuProps) {
  const { close } = props;
  const { sessionData } = useAuthContext();

  return (
    <SideMenu close={close} title='User Settings' subtitle={sessionData?.email}>
      <SideMenu.Navigation>
        <SideMenu.Link to='/settings/user/profile'>
          Manage Profile
        </SideMenu.Link>
      </SideMenu.Navigation>
      <UserMenuActions />
    </SideMenu>
  );
}
