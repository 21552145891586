import type AccountUserInvitationConfirmationFormValues from 'src/home/models/AccountUserInvitationConfirmationFormValues';
import type AccountUserInvitationConfirmationRequest from 'src/home/models/AccountUserInvitationConfirmationRequest';

export default function toAccountUserInvitationConfirmationRequest(
  data: AccountUserInvitationConfirmationFormValues
): AccountUserInvitationConfirmationRequest {
  const { password, passwordConfirmation } = data;

  return { password, password_confirmation: passwordConfirmation };
}
