import toProduct from 'src/products/mappers/Product/toProduct';
import type WeblinkMetaData from '../models/Weblink/WeblinkMetaData';
import type WeblinkMetaDataResponse from '../models/Weblink/WeblinkMetaDataResponse';

export default function toWeblinkMetaData(
  data: WeblinkMetaDataResponse
): WeblinkMetaData {
  const { id, sender_id, product_id, created_at, updated_at, product } = data;

  return {
    id,
    senderId: sender_id,
    productId: product_id,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    product: toProduct(product),
  };
}
