import type Manager from 'src/managers/models/Manager';
import type ManagerResponse from 'src/managers/models/ManagerResponse';
import toAccountUser from 'src/settings/mappers/toAccountUser';

export default function toManager(data: ManagerResponse): Manager {
  const { id, account_user } = data;

  return {
    id,
    accountUser: toAccountUser(account_user),
  };
}
