import { createContext } from 'react';

interface DropdownContextData {
  close: () => void;
}

const initialDropdownContextData: DropdownContextData = {
  close: () => ({}),
};

const DropdownContext = createContext(initialDropdownContextData);

export default DropdownContext;
