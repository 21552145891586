import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import WeblinkScreenButtons from 'src/auth/components/WeblinkScreenButtons';
import type WeblinkMetaData from 'src/keysharing/models/Weblink/WeblinkMetaData';
import type Product from 'src/lots/models/Product';
import ProductTypes from 'src/products/constants/ProductTypes';
import useWeblinkStatusLabel from 'src/products/hooks/useWeblinkStatusLabel';
import Line from 'src/shared/components/Line';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import styled from 'styled-components';
import useWeblinksService from '../../keysharing/services/useWeblinksService';
import type ConcreteProductError from '../../lots/models/ConcreteProductError';
import DetailsColumn from '../../products/components/DetailsColumn';
import DetailsRow from '../../products/components/DetailsRow';
import ProductTableIcon from '../../products/components/ProductTableIcon';
import AllProductStatuses from '../../products/constants/AllProductStatuses';
import ProductOperations from '../../products/constants/ProductOperations';
import ProductState from '../../products/constants/ProductState';
import ProductStatus from '../../products/constants/ProductStatus';
import Color from '../../shared/constants/Color';
import useIsMounted from '../../shared/hooks/useIsMounted';
import Container from '../components/Container';
import Title from '../components/Title';

export default function WeblinkScreen() {
  const [data, setData] = useState<WeblinkMetaData>();
  const { getWeblinksMetaData, weblinksOperation } = useWeblinksService();
  const location = useLocation();
  const { search } = location;
  const { token } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as { [key: string]: string };
  const { params } = useRouteMatch<{ id?: string }>();
  const { id } = params;
  const weblinkId = parseInt(id || '');
  const isMounted = useIsMounted();
  const [deviceInProgress, setDeviceInProgress] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        if (!token) {
          setError('Invalid token.');
          return;
        }

        const data = await getWeblinksMetaData({ token });

        if (isMounted()) {
          setData(data);
        }
      } catch {
        if (isMounted()) {
          setError('Weblink expired.');
        }
      }
    };
    getData();
  }, [getWeblinksMetaData, isMounted, token]);

  const getProductOperationLabel = useCallback(
    (state: string) => {
      switch (state) {
        case ProductState.OPENED:
          return data?.product.barrier?.state
            ? AllProductStatuses.EXTEND
            : AllProductStatuses.CLOSE;
        case ProductState.CLOSED:
          return data?.product.barrier?.state
            ? AllProductStatuses.LOWER
            : AllProductStatuses.OPEN;
        case ProductState.DISABLED:
          return AllProductStatuses.DISABLED;
        default:
          return AllProductStatuses.NO_OPERATION;
      }
    },
    [data]
  );

  const getDeviceOperation = useCallback((state: string) => {
    switch (state) {
      case ProductState.OPENED:
        return ProductOperations.CLOSE;
      case ProductState.CLOSED:
        return ProductOperations.OPEN;
      default:
        return undefined;
    }
  }, []);

  const getErrors = (productError: ConcreteProductError | null | undefined) => {
    const errors = [];

    if (!productError) return [];

    const {
      jammed,
      sensorDirty,
      pinBroken,
      rtccInvalid,
      batteryEmpty,
      masterKeyNotSet,
    } = productError;

    if (jammed) errors.push(AllProductStatuses.JAMMED);

    if (sensorDirty) errors.push(AllProductStatuses.SENSOR_DIRTY);

    if (pinBroken) errors.push(AllProductStatuses.PIN_BROKEN);

    if (batteryEmpty) errors.push(AllProductStatuses.BATTERY_EMPTY);

    if (rtccInvalid) errors.push(AllProductStatuses.RTCC_INVALID);

    if (masterKeyNotSet) errors.push(AllProductStatuses.MASTER_KEY_NOT_SET);

    return errors;
  };

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const getDeviceState = (product: Product | undefined) => {
    if (!product) return undefined;

    switch (product.productTypeId) {
      case ProductTypes.BARRIER:
        return data?.product.barrier?.state;
      case ProductTypes.GATE:
        return data?.product.gate?.state;
      case ProductTypes.CHAIN:
        return data?.product.chain?.state;
      case ProductTypes.BRAIN:
        return data?.product.brain?.state;
      default:
        return undefined;
    }
  };

  const state = getDeviceState(data?.product);
  const statuses = useWeblinkStatusLabel(
    data?.product,
    true,
    data?.product.barrier?.isCarOnTop
  );
  const operation:
    | ProductOperations.OPEN
    | ProductOperations.CLOSE
    | undefined = getDeviceOperation(state || '');
  const buttonLabel =
    data?.product.status === ProductStatus.OFFLINE
      ? AllProductStatuses.NO_OPERATION
      : data?.product.barrier?.isCarOnTop
      ? AllProductStatuses.CAR_ON_TOP
      : getProductOperationLabel(state || '');
  const errors = getErrors(data?.product.productError);
  const rtccError = useMemo(() => {
    if (errors.length === 0) return false;

    if (errors.length === 1 && data?.product.productError?.rtccInvalid)
      return false;
    return true;
  }, [data, errors]);

  const onClick = useCallback(
    async (
      disabledStateOperation?: ProductOperations.OPEN | ProductOperations.CLOSE
    ) => {
      try {
        if (!token || !weblinkId) {
          return;
        }

        let changeState = operation;

        if (disabledStateOperation) {
          changeState = disabledStateOperation;
        }

        if (!changeState) return;

        if (isMounted()) {
          setDeviceInProgress(true);
        }

        await weblinksOperation(weblinkId, {
          token,
          operation: changeState,
        });
      } catch (error: any) {
        if (isMounted()) {
          setDeviceInProgress(false);
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      weblinksOperation,
      isMounted,
      openGlobalFailureModal,
      operation,
      token,
      weblinkId,
      setMessage,
    ]
  );

  const disabled = useMemo(
    () =>
      deviceInProgress ||
      data?.product.status !== ProductStatus.ONLINE ||
      data?.product.barrier?.isCarOnTop ||
      rtccError,
    [deviceInProgress, data, rtccError]
  );

  return (
    <Container removeText addNote>
      <Title addMargin>Weblink</Title>
      <StyledDiv>
        {data ? (
          <DetailsRow wider>
            <DetailsColumn removePadding>
              <StyledNameDiv>
                <ProductTableIcon iconType={data.product.productTypeId} />
                {data.product.name}
              </StyledNameDiv>
              <StyledText>
                {statuses?.map((status) => (
                  <p key={status}>{status}</p>
                ))}
              </StyledText>
              <Line />
              <StyledButtonDiv>
                <WeblinkScreenButtons
                  product={data.product}
                  buttonLabel={buttonLabel}
                  deviceInProgress={deviceInProgress}
                  disabled={disabled}
                  onClick={onClick}
                />
                <p>This action can be performed once.</p>
              </StyledButtonDiv>
            </DetailsColumn>
          </DetailsRow>
        ) : (
          <StyledError>{error}</StyledError>
        )}
      </StyledDiv>
    </Container>
  );
}

const StyledNameDiv = styled.div`
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: ${Color.PRIMARY_BRAND};
  margin-bottom: 10px;

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

const StyledButtonDiv = styled.div`
  align-items: center;
  margin: 30px 0 0 0;
  color: ${Color.ERROR};

  p {
    font-size: 14px;
    font-weight: 550;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

const StyledText = styled.div<{ redColor?: boolean }>`
  font-size: 16px;
  text-align: center;
  color: ${Color.TEXT_DARKER};
  margin: 10px 0 30px 0;
`;

const StyledDiv = styled.div`
  width: 80%;
  max-width: 500px;
  padding: 35px 30px;
  background-color: ${Color.BACKGROUND_LIGHTEST};
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 10px;
  display: flex;
  min-height: 250px;
  text-align: center;
  align-items: center;
`;

const StyledError = styled.p`
  font-family: 'Montserrat';
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: ${Color.TEXT_DARKER};
  margin: auto;
`;
