import { createContext } from 'react';
import type BreadcrumbData from '../models/BreadcrumbData';

interface WizardContextData {
  next: () => void;
  back: () => void;
  breadcrumbsData: BreadcrumbData[];
  activeIndex: number;
}

const initialWizardContextData: WizardContextData = {
  next: () => ({}),
  back: () => ({}),
  breadcrumbsData: [],
  activeIndex: 0,
};

const WizardContext = createContext(initialWizardContextData);

export default WizardContext;
