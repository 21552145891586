import React from 'react';
import LprImageStorageSubscriptionStatuses from 'src/anpr/constants/LprImageStorageSubscriptionStatuses';
import type LprCamera from 'src/anpr/models/LprCamera/LprCamera';
import {
  hasExpiryDateChanged,
  isStatusInRegularGracePeriod,
  remainingTime,
} from 'src/anpr/utils/imageStorageFunctions';
import Card from 'src/shared/components/Card';
import StyledLink from 'src/shared/components/Link';
import ParklioPhoneNumber from 'src/shared/components/ParklioPhoneNumber';
import StyledNoData from 'src/shared/components/StyledNoData';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import formatDate from 'src/shared/utils/formatDate';

interface LprImageSubscriptionTableProps {
  data: LprCamera;
}

export default function LprImageSubscriptionTable(
  props: LprImageSubscriptionTableProps
) {
  const { data } = props;
  const { name, storageSubscriptionPlan } = data;

  return (
    <Card>
      <Title addBottomMargin bold>
        {name}
      </Title>
      <Table>
        <Table.Head>
          {storageSubscriptionPlan &&
            storageSubscriptionPlan.status !==
              LprImageStorageSubscriptionStatuses.EXPIRED && (
              <Table.Row>
                <Table.Header>ACTIVATED</Table.Header>
                <Table.Header>VALID TIL</Table.Header>
                <Table.Header colSpan={2}>STATUS</Table.Header>
              </Table.Row>
            )}
        </Table.Head>
        <Table.Body>
          {storageSubscriptionPlan &&
            storageSubscriptionPlan.status !==
              LprImageStorageSubscriptionStatuses.EXPIRED && (
              <Table.Row>
                <Table.Cell
                  disabled={isStatusInRegularGracePeriod(
                    storageSubscriptionPlan
                  )}
                >
                  {formatDate(
                    storageSubscriptionPlan.activationDate,
                    false,
                    true
                  )}
                </Table.Cell>
                <Table.Cell
                  disabled={isStatusInRegularGracePeriod(
                    storageSubscriptionPlan
                  )}
                >
                  {formatDate(storageSubscriptionPlan.expiryDate, false, true)}
                </Table.Cell>
                {hasExpiryDateChanged(storageSubscriptionPlan) && (
                  <Table.Cell invalid>
                    {remainingTime(storageSubscriptionPlan)} days remaining
                  </Table.Cell>
                )}
                <Table.Cell
                  merged={
                    isStatusInRegularGracePeriod(storageSubscriptionPlan)
                      ? 2
                      : 1
                  }
                  invalid={isStatusInRegularGracePeriod(
                    storageSubscriptionPlan
                  )}
                >
                  {hasExpiryDateChanged(storageSubscriptionPlan) ||
                  storageSubscriptionPlan.status ===
                    LprImageStorageSubscriptionStatuses.ACTIVE
                    ? storageSubscriptionPlan.status.toUpperCase()
                    : isStatusInRegularGracePeriod(storageSubscriptionPlan)
                    ? `${remainingTime(storageSubscriptionPlan)} days remaining`
                    : undefined}
                </Table.Cell>
              </Table.Row>
            )}
          {(!storageSubscriptionPlan ||
            storageSubscriptionPlan.status ===
              LprImageStorageSubscriptionStatuses.EXPIRED) && (
            <Table.Row>
              <Table.Cell merged={4}>
                <StyledNoData imageStorageView>
                  If you wish to have license plate images for your camera,
                  contact our customer support for more <br />
                  information on:{' '}
                  <StyledLink href='mailto:anpr.subscription@parklio.co'>
                    anpr.subscription@parklio.co
                  </StyledLink>{' '}
                  or call us on the phone:{' '}
                  <ParklioPhoneNumber
                    callingCode='+385'
                    phoneNumber='977247276'
                  />
                  .
                </StyledNoData>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Card>
  );
}
