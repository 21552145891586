import React from 'react';
import InvitationKeysTable from 'src/keysharing/components/InvitationKeysTable';
import KeyLogsTable from 'src/keysharing/components/KeyLogsTable';
import QRCodesTable from 'src/keysharing/components/QRCodesTable';
import WeblinksTable from 'src/keysharing/components/WeblinksTable';
import BoundLicensesTable from 'src/products/components/BoundLicensesTable';
import Tabs from '../../shared/components/Tabs';

interface SharedProductListProps {
  productId: number;
  modalView?: boolean;
  licensePlates?: boolean;
}

export default function SharedProductList(props: SharedProductListProps) {
  const { modalView, productId, licensePlates } = props;

  return (
    <Tabs noBackground={!modalView}>
      <Tabs.Panel name='sharedKeys' label='Shared keys'>
        <KeyLogsTable modalView={modalView} productId={productId} />
      </Tabs.Panel>
      <Tabs.Panel name='weblinks' label='Weblinks'>
        <WeblinksTable modalView={modalView} productId={productId} />
      </Tabs.Panel>
      <Tabs.Panel name='invitationKeys' label='Pending access'>
        <InvitationKeysTable modalView={modalView} productId={productId} />
      </Tabs.Panel>
      {licensePlates ? (
        <Tabs.Panel name='licensePlates' label='License plates'>
          <BoundLicensesTable modalView={modalView} productId={productId} />
        </Tabs.Panel>
      ) : (
        <></>
      )}
      <Tabs.Panel name='qrCodes' label='QR Codes'>
        <QRCodesTable modalView productId={productId || 0} />
      </Tabs.Panel>
    </Tabs>
  );
}
