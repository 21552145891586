import { Formik, Form as UnstyledForm } from 'formik';
import type { FormikFormProps, FormikHelpers } from 'formik';
import React from 'react';
import styled from 'styled-components';
import type * as yup from 'yup';
import type { ObjectShape } from 'yup/lib/object';
import Color from '../../shared/constants/Color';

interface FormProps<Values> {
  validationSchema: yup.ObjectSchema<ObjectShape>;
  initialValues: Values;
  onSubmit: (values: Values, helpers: FormikHelpers<Values>) => void;
  name: string;
  children: React.ReactNode;
}

export default function Form<Values extends object>(props: FormProps<Values>) {
  const { name, children, onSubmit, initialValues, validationSchema } = props;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <StyledForm name={name}>{children}</StyledForm>
    </Formik>
  );
}

const StyledForm = styled(UnstyledForm)<FormikFormProps>`
  width: 80%;
  max-width: 386px;
  padding: 35px 30px;
  background-color: ${Color.BACKGROUND_LIGHTEST};
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 10px;
  margin: 35px 0px;
`;
