import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import cutOffAt from '../../../shared/utils/cutOffAt';
import Color from '../../constants/Color';
import SearchSelectIcon from '../../constants/SearchSelectIcon';
import SelectAllFilterValue from '../../constants/SelectAllFilterValue';
import type Option from '../../models/Option';
import Dropdown from '../Dropdown';
import Label from '../Label';
import Icon from './Icon';
import Options from './Options';

interface SelectFieldWithoutFormikProps {
  name: string;
  label: string;
  placeholder: string;
  options: Option[];
  initialValue: Option | null;
  onChange: (value: Option | undefined) => void;
  onSearch?: (value: string) => void;
  search?: string;
  short?: boolean;
  setIsAdditionalDropdownOpen?: (value: boolean) => void;
  dateTimeView?: boolean;
  yearView?: boolean;
  hideLabel?: boolean;
  hideInput?: boolean;
  linkToLot?: boolean;
}

export default function SelectFieldWithoutFormik(
  props: SelectFieldWithoutFormikProps
) {
  const {
    label,
    placeholder,
    options,
    initialValue,
    onSearch,
    search = '',
    short,
    onChange,
    setIsAdditionalDropdownOpen,
    dateTimeView,
    yearView,
    hideLabel,
    hideInput,
    linkToLot,
  } = props;
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const inputRef = useRef<React.ElementRef<'input'>>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const maxLength = useMemo(() => {
    if (!short) {
      return 48;
    }

    return 32;
  }, [short]);

  const icon = useMemo(() => {
    if (!isDropdownOpen || !onSearch) {
      return SearchSelectIcon.ARROW_DOWN;
    }

    return SearchSelectIcon.SEARCH;
  }, [isDropdownOpen, onSearch]);

  const activePlaceholder = useMemo(() => {
    if (isDropdownOpen && value != null) {
      return value.label;
    }

    return placeholder;
  }, [value, placeholder, isDropdownOpen]);

  const activeValue = useMemo(() => {
    if (isDropdownOpen && onSearch) {
      return search;
    }

    if (value) {
      return value.label;
    }

    return '';
  }, [isDropdownOpen, value, search, onSearch]);

  const [shouldSetTouched, setShouldSetTouched] = useState(false);

  const onSelect = useCallback(
    (option: Option) => {
      if (!value || option.key !== value.key) {
        if (onChange !== undefined) {
          option.key === SelectAllFilterValue
            ? onChange(undefined)
            : onChange(option);
        }
        return setValue(option);
      }

      if (onChange !== undefined) onChange(undefined);

      return setValue(null);
    },
    [setValue, value, onChange]
  );

  const open = useCallback(() => {
    setIsDropdownOpen(true);
    if (setIsAdditionalDropdownOpen) {
      setIsAdditionalDropdownOpen(true);
    }

    if (!shouldSetTouched) {
      setShouldSetTouched(true);
    }
  }, [shouldSetTouched, setIsAdditionalDropdownOpen]);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
    if (setIsAdditionalDropdownOpen) {
      setIsAdditionalDropdownOpen(false);
    }
    setShouldSetTouched(false);
  }, [setIsAdditionalDropdownOpen]);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (
        !wrapperRef?.current?.contains(event.target as Node) &&
        shouldSetTouched
      ) {
        close();
        setShouldSetTouched(true);
      }
    };

    document.addEventListener('click', listener, { capture: true });
    document.addEventListener('focusin', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
      document.removeEventListener('focusin', listener, { capture: true });
    };
  }, [close, shouldSetTouched, setShouldSetTouched]);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { value } = target;

      if (isDropdownOpen && onSearch) {
        onSearch(value);
      }
    },
    [isDropdownOpen, onSearch]
  );

  const focusInput = useCallback(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <StyledWrapper
      ref={wrapperRef}
      short={short}
      yearView={yearView}
      hideInput={hideInput}
    >
      {!hideInput && (
        <Label onClick={focusInput} hideLabel={hideLabel}>
          {label}
        </Label>
      )}
      <StyledColumn short={short}>
        <StyledContainer dateTimeView={dateTimeView} hideInput={hideInput}>
          <StyledInput
            ref={inputRef}
            type='text'
            onChange={onInputChange}
            onFocus={open}
            value={cutOffAt(activeValue, maxLength)}
            placeholder={cutOffAt(activePlaceholder, maxLength)}
            readOnly={!onSearch}
            dateTimeView={dateTimeView}
          />
          <Icon icon={icon} onClick={focusInput} dateTimeView={dateTimeView} />
        </StyledContainer>
        {isDropdownOpen && (
          <Dropdown close={close} hideInput={hideInput}>
            <Options
              value={value}
              onSelect={onSelect}
              options={options}
              dateTimeView={dateTimeView}
              linkToLot={linkToLot}
            />
          </Dropdown>
        )}
      </StyledColumn>
    </StyledWrapper>
  );
}

const StyledInput = styled.input<{ dateTimeView?: boolean }>`
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding: 0px 14px;
  color: ${Color.TEXT_DARKER};
  background-color: ${Color.BACKGROUND_LIGHTEST};
  width: 100%;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${Color.TEXT_LIGHT};
  }

  :disabled {
    background-color: ${Color.BACKGROUND_LIGTH};
  }

  ${(props) => {
    const { dateTimeView } = props;

    if (dateTimeView) {
      return css`
        height: 25px;
      `;
    }

    return css`
      height: 36px;
    `;
  }}
`;

const StyledColumn = styled.div<{ short?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props) => {
    const { short } = props;

    if (!short) {
      return css`
        width: 100%;
      `;
    }

    return css`
      width: 300px;
      @media (max-width: 500px) {
        width: 250px;
      }
    `;
  }}
`;

const StyledWrapper = styled.div<{
  short?: boolean;
  yearView?: boolean;
  hideInput?: boolean;
  hideLabel?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  width: 100%;
  flex-direction: column;

  @media (max-width: 999px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }

  ${(props) => {
    const { yearView } = props;

    if (yearView) {
      return css`
        width: 60%;
        margin-left: 10px;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { hideInput } = props;

    if (hideInput) {
      return css`
        margin: 0;
        width: 100%;
      `;
    }
    return css``;
  }}

      ${(props) => {
    const { hideLabel } = props;

    if (hideLabel) {
      return css`
        margin: 0;
      `;
    }
    return css``;
  }}
`;

const StyledContainer = styled.div<{
  dateTimeView?: boolean;
  hideInput?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;

  ${(props) => {
    const { dateTimeView } = props;

    if (dateTimeView) {
      return css`
        height: 25px;
      `;
    }

    return css`
      height: 36px;
    `;
  }}

  @media (max-width: 500px) {
    ${(props) => {
      const { dateTimeView } = props;

      if (dateTimeView) {
        return css`
          width: 100%;
        `;
      }

      return css`
        width: 75%;
      `;
    }}

    ${(props) => {
      const { hideInput } = props;

      if (hideInput) {
        return css`
          width: 100%;
        `;
      }

      return css`
        width: 75%;
      `;
    }}
  }
`;
