import * as ReactRouter from 'react-router-dom';
import styled from 'styled-components';
import Color from '../../shared/constants/Color';

const Link = styled(ReactRouter.Link)`
  color: ${Color.TEXT_BRAND};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  display: block;
  text-decoration: none;

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

export default Link;
