import type CallbackFunc from 'src/shared/constants/FunctionType';

export default function debounce(func: CallbackFunc, timeout: number) {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const arg = [...args];
      func(arg);
    }, timeout);
  };
}
