import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Form from 'src/shared/components/Form';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import * as yup from 'yup';
import PhoneValidation from '../../shared/constants/PhoneValidation';
import useIsMounted from '../../shared/hooks/useIsMounted';
import { isNotString } from '../../shared/utils/checks';
import UserProfileForm from '../components/UserProfileForm';
import toUserProfileFormValues from '../mappers/toUserProfileFormValues';
import type UserProfileFormValues from '../models/UserProfileFormValues';
import useAccountUserService from '../services/useAccountUserService';

const defaultValues: UserProfileFormValues = {
  role: null,
  email: '',
  name: '',
  phone: '',
};

const validationSchema = yup.object({
  role: yup.object().required('Role is a required field'),
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Email is a required field'),
  name: yup.string().required('Name is a required field'),
  phone: yup
    .string()
    .trim()
    .matches(PhoneValidation, 'Enter a valid phone number'),
});

export default function UserProfileEdit() {
  const [initialValues, setInitialValues] =
    useState<UserProfileFormValues>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const { findProfileAccountUser, updateProfileAccountUser } =
    useAccountUserService();
  const isMounted = useIsMounted();

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: 'Successfuly updated user profile!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const getChangedValues = useCallback(
    (values: UserProfileFormValues) => ({
      phone:
        values.phone !== initialValues.phone
          ? values.phone === ''
            ? null
            : values.phone
          : undefined,
    }),
    [initialValues]
  );

  const onSubmit = useCallback(
    async (
      values: UserProfileFormValues,
      { setErrors }: FormikHelpers<UserProfileFormValues>
    ) => {
      try {
        const valuesToUpdate = getChangedValues(values);

        setIsLoading(true);

        await updateProfileAccountUser(valuesToUpdate);

        const data = await findProfileAccountUser();
        const formValues = toUserProfileFormValues(data);

        if (isMounted()) {
          setInitialValues(formValues);
          openGlobalSuccessModal();
          setIsLoading(false);
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsLoading(false);
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      updateProfileAccountUser,
      getChangedValues,
      isMounted,
      findProfileAccountUser,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  useEffect(() => {
    const apiCall = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }

        const data = await findProfileAccountUser();
        const formValues = toUserProfileFormValues(data);

        if (isMounted()) {
          setInitialValues(formValues);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };

    apiCall();
  }, [findProfileAccountUser, isMounted, setIsLoading]);

  return (
    <Form
      name='userProfile'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <UserProfileForm />
    </Form>
  );
}
