import type Rotary from 'src/rotaries/models/Rotary';
import type RotaryResponse from 'src/rotaries/models/RotaryResponse';

export default function toRotary(data: RotaryResponse): Rotary {
  const { id, account_id, lot_id, name, address, created_at, updated_at } =
    data;

  return {
    id,
    accountId: account_id,
    lotId: lot_id,
    name,
    address,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
