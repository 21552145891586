import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GlobalModalContext } from '../contexts/GlobalModalContext';
import type { ModalType } from '../contexts/GlobalModalContext';
import useModalControl from './useModalControl';

/**
 * Callback types provided for descriptive type-hints
 */
type ShowModal = () => void;
type HideModal = () => void;
// type HideModalWithParent = () => void;

/**
 * Utility function to generate unique number per component instance
 */
const generateModalKey = (() => {
  let count = 0;

  return () => `${++count}`;
})();

/**
 * Check whether the argument is a stateless component.
 *
 * We take advantage of the stateless nature of functional components to be
 * inline the rendering of the modal component as part of another immutable
 * component.
 *
 * This is necessary for allowing the modal to update based on the inputs passed
 * as the second argument to useModal without unmounting the previous version of
 * the modal component.
 */
const isFunctionalComponent = (Component: React.FunctionComponent<any>) => {
  const { prototype } = Component;

  return !prototype || !prototype.isReactComponent;
};

/**
 * React hook for showing modal windows
 */
export const useGlobalModal = (
  component: ModalType
): [ShowModal, HideModal] => {
  if (!isFunctionalComponent(component)) {
    throw new Error(
      'Only stateless components can be used as an argument to useModal. You have probably passed a class component where a function was expected.'
    );
  }

  const key = useMemo(generateModalKey, []);
  const modal = useMemo(() => component, [component]);
  const context = useContext(GlobalModalContext);
  const [isShown, setShown] = useState<boolean>(false);
  const showModal = useCallback(() => setShown(true), []);
  const hideModal = useCallback(() => setShown(false), []);
  const { useEscapeKey } = useModalControl();

  useEscapeKey(hideModal);

  useEffect(() => {
    if (isShown) {
      context.showModal(key, modal);
    } else {
      context.hideModal(key);
    }

    return () => context.hideModal(key);
  }, [modal, isShown, context, key]);
  return [showModal, hideModal];
};
