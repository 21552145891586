import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import PasswordCreationErrorMessages from 'src/shared/constants/PasswordCreationErrorMessages';
import * as yup from 'yup';
import type NewPasswordFormValues from '../../settings/models/NewPasswordFormValues';
import useAccountUserService from '../../settings/services/useAccountUserService';
import useIsMounted from '../../shared/hooks/useIsMounted';
import Container from '../components/Container';
import Form from '../components/Form';
import NewPasswordForm from '../components/NewPasswordForm';
import NewPasswordText from '../components/NewPasswordText';
import ParklioSign from '../components/ParklioSign';
import Title from '../components/Title';

const initialValues: NewPasswordFormValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(PasswordCreationErrorMessages.REQUIRED)
    .min(8, PasswordCreationErrorMessages.MIN_LEN)
    .matches(/^(?=.*[a-z])/, PasswordCreationErrorMessages.LOWERCASE)
    .matches(/^(?=.*[A-Z])/, PasswordCreationErrorMessages.UPPERCASE)
    .matches(/^(?=.*[0-9])/, PasswordCreationErrorMessages.NUMBER)
    .matches(
      /[!?@#$%^&*()\-/_"'=+{};:,<.>]/,
      PasswordCreationErrorMessages.SPECIAL_CHAR
    ),
  passwordConfirmation: yup
    .string()
    .required('Confirm New Password is a required field')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
});

export default function NewPassword() {
  const [isSent, setIsSent] = useState(false);
  const { newPassword } = useAccountUserService();
  const isMounted = useIsMounted();
  const location = useLocation();
  const { search } = location;

  const { token, signature } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as { [key: string]: string };

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (values: NewPasswordFormValues) => {
      try {
        await newPassword(token, signature, values);

        if (isMounted()) {
          setIsSent(true);
        }
      } catch (error: any) {
        if (isMounted()) {
          openGlobalFailureModal();
          setMessage(error);
          setIsSent(false);
        }
      }
    },
    [
      isMounted,
      openGlobalFailureModal,
      newPassword,
      setMessage,
      signature,
      token,
    ]
  );

  return (
    <Container>
      <ParklioSign
        onClick={() => window.location.assign('/login')}
        cursorpointer='true'
      />
      <Title>New password</Title>
      {!isSent && (
        <Form
          name='newPassword'
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <NewPasswordForm />
        </Form>
      )}
      {isSent && <NewPasswordText />}
    </Container>
  );
}
