import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import type CameraParkingPlaces from 'src/detection/models/CameraParkingPlaces';
import type DetectCamera from 'src/detection/models/DetectCamera';
import type DetectCameraFormValues from 'src/detection/models/DetectCameraFormValues';
import type DetectGateway from 'src/detection/models/DetectGateway';
import type ParkingPlace from 'src/detection/models/ParkingPlace';
import type ParkingDetectionStatus from 'src/products/constants/ParkingDetectionStatus';
import ExitButton from 'src/shared/components/Buttons/ExitButton';
import Form from 'src/shared/components/Form';
import { useGlobalConfirmationModal } from 'src/shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import Tabs from 'src/shared/components/Tabs';
import * as yup from 'yup';
import useIsMounted from '../../shared/hooks/useIsMounted';
import { isNotString } from '../../shared/utils/checks';
import DetectCameraUpdateForm from '../components/DetectCameraUpdateForm';
import DetectCameraUpdateParkingSchema from '../components/DetectCameraUpdateParkingSchema';
import toDetectCameraFormValues from '../mappers/toDetectCameraFormValues';
import useDetectCameraService from '../services/useDetectCameraService';

interface DetectCameraDetailsModalProps {
  id: number | undefined;
  closeParentModal: () => void;
  onCameraNameChange: (name: string, id: number) => void;
  onRemoveDetectCamera: (id: number) => void;
  gateways: DetectGateway[];
  lotDetectionStatus: ParkingDetectionStatus | undefined;
  parkingPlaces: ParkingPlace[];
}

const defaultFormValues: DetectCameraFormValues = {
  name: '',
  uuid: '',
  gateway: null,
};

const validationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
  gateway: yup.object().nullable().required('Gateway is a required field'),
  uuid: yup.string().required('UUID is a required field'),
});

export default function DetectCameraDetailsModal(
  props: DetectCameraDetailsModalProps
) {
  const {
    id,
    closeParentModal,
    onCameraNameChange,
    onRemoveDetectCamera,
    gateways,
    lotDetectionStatus,
    parkingPlaces,
  } = props;

  const [cameraDetails, setCameraDetails] = useState<
    DetectCamera | undefined
  >();
  const [frame, setFrame] = useState('');
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const {
    findOneDetectCamera,
    updateDetectCamera,
    findDetectCameraFrame,
    removeDetectCamera,
  } = useDetectCameraService();
  const isMounted = useIsMounted();

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
  });

  const cameraPlaces: CameraParkingPlaces[] | undefined = useMemo(
    () =>
      cameraDetails?.viewSchema?.parkingPlaces.map((viewSchemaPlace) => {
        const placeDetails = parkingPlaces.find(
          (place) => place.id === viewSchemaPlace.id
        );
        return {
          placeId: placeDetails?.id,
          name: placeDetails?.name,
          occupancyStatus: placeDetails?.occupancyStatus,
          updatedAt: placeDetails?.updatedAt,
          cx: viewSchemaPlace.cx,
          cy: viewSchemaPlace.cy,
          r: viewSchemaPlace.r,
        };
      }),
    [cameraDetails, parkingPlaces]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        if (!id) {
          return;
        }
        if (isMounted()) {
          setAreDetailsLoading(true);
        }
        const data = await findOneDetectCamera(id);
        const img = await findDetectCameraFrame(data.id);
        const gateway = gateways.find(
          (gateway) => gateway.id === data.gatewayId
        );
        const values = toDetectCameraFormValues(data, gateway?.name || '');

        if (isMounted()) {
          setInitialValues(values);
          setCameraDetails(data);
          setFrame(img);
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [id, isMounted, findOneDetectCamera, gateways, findDetectCameraFrame]);

  const getChangedValues = useCallback(
    (values: DetectCameraFormValues) => ({
      name: values.name !== initialValues.name ? values.name : undefined,
      uuid: values.uuid !== initialValues.uuid ? values.uuid : undefined,
      gatewayId:
        values.gateway?.key !== initialValues.gateway?.key
          ? values.gateway?.key
          : undefined,
    }),
    [initialValues]
  );

  const onSubmit = useCallback(
    async (
      values: DetectCameraFormValues,
      { setErrors }: FormikHelpers<DetectCameraFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }

        const valuesToUpdate = getChangedValues(values);

        await updateDetectCamera(id, valuesToUpdate);

        if (isMounted()) {
          setMessageSuccess('Successfuly updated Detect Camera!');
          openGlobalSuccessModal();
          onCameraNameChange(values.name, id);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      id,
      onCameraNameChange,
      openGlobalFailureModal,
      setMessage,
      openGlobalSuccessModal,
      isMounted,
      updateDetectCamera,
      getChangedValues,
    ]
  );

  const onDetectCameraRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await removeDetectCamera(id);

      if (isMounted()) {
        setMessageSuccess('Detect Camera deleted successfully!');
        onRemoveDetectCamera(id);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeDetectCamera,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    onRemoveDetectCamera,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onDetectCameraRemove,
    message: 'Are you sure you want to remove this Detect Camera?',
  });

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='detectCamera'
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
            onSubmit={onSubmit}
          >
            <DetectCameraUpdateForm
              details={cameraDetails}
              isLoading={areDetailsLoading}
              closeParentModal={closeParentModal}
              gateways={gateways}
              frame={frame}
              lotDetectionStatus={lotDetectionStatus}
              openRemoveModal={openGlobalConfirmationModal}
              cameraPlaces={cameraPlaces}
            />
          </Form>
        </Tabs.Panel>
        <Tabs.Panel name='places' label='Parking places'>
          <DetectCameraUpdateParkingSchema
            id={cameraDetails?.id}
            closeParentModal={closeParentModal}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
