import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ReactComponent as BgTop } from '../../assets/svg/BgTop.svg';
import Color from '../../shared/constants/Color';

interface ContainerProps {
  children: React.ReactNode;
  removeText?: boolean;
  addNote?: boolean;
  fixedsvg?: string;
}

export default function Container(props: ContainerProps) {
  const { children, removeText, addNote, fixedsvg } = props;

  const currentYear = useMemo(() => {
    const date = new Date();
    return date.getFullYear();
  }, []);

  return (
    <StyledWrapper>
      <StyledHeader fixedsvg={fixedsvg} />
      <StyledMountPoint>
        {children}
        {!removeText && (
          <StyledText>
            Clicking Login indicates you agree with our <br />
            <StyledLink to='/general-terms'>General Terms</StyledLink> and{' '}
            <StyledExternalLink
              href='https://parklio.com/en/privacy-policy'
              target='_blank'
            >
              Privacy Policy
            </StyledExternalLink>
          </StyledText>
        )}
        {addNote && (
          <StyledText>
            If you need help, contact Parking administrator.
          </StyledText>
        )}
        <StyledCopyrigth>Parklio™ © {currentYear}</StyledCopyrigth>
      </StyledMountPoint>
    </StyledWrapper>
  );
}

const StyledCopyrigth = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: ${Color.TEXT_LIGHTER};
  margin-top: auto;
`;

const StyledMountPoint = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const StyledHeader = styled(BgTop)<{ fixedsvg?: string }>`
  bottom: 50vh;
  min-height: 50%;
  left: 50%;

  ${(props) => {
    const { fixedsvg } = props;

    if (fixedsvg === 'true') {
      return css`
        position: fixed;
        left: 0;
      `;
    }

    return css`
      position: absolute;
      margin-left: -50%;
    `;
  }}
`;

const StyledWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: ${Color.BACKGROUND_DARKER};
`;

const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 160%;
  color: ${Color.TEXT_LIGHTER};
`;

const StyledExternalLink = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 160%;
  color: ${Color.TEXT_LIGHTER};
`;

const StyledText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
  color: ${Color.TEXT_LIGHTER};
`;
