import React, { useMemo } from 'react';
import PermanentPositions from 'src/lots/models/PermanentPosition';
import BatteryIcon from 'src/products/constants/BatteryIcon';
import LockIcons from 'src/shared/constants/LockIcons';

import type Product from '../../../lots/models/Product';
import Button from '../../../shared/components/Button';
import CheckBox from '../../../shared/components/CheckBox';
import IconRow from '../../../shared/components/IconRow';
import Table from '../../../shared/components/Table';
import ButtonSize from '../../../shared/constants/ButtonSize';
import {
  getDeviceStatuses,
  getErrors,
  getOpenCloseOperationLabel,
} from '../../../shared/utils/getDeviceInfo';
import AllProductStatuses from '../../constants/AllProductStatuses';
import AllProductTypes from '../../constants/AllProductTypes';
import ProductIcon from '../../constants/ProductIcon';
import ProductOperations from '../../constants/ProductOperations';
import ProductStatus from '../../constants/ProductStatus';
import { StyledIcon } from '../BatteryPowerIcon';
import ProductStatuses from '../ProductStatuses';
import ProductTableIcon from '../ProductTableIcon';
import SignalStatus from '../SignalStatus';

interface ProductTableBrainRowProps {
  productData: Product;
  deviceInProgress: boolean;
  selectedForSharing: boolean;
  onClick: (
    productId: number,
    operation: ProductOperations | undefined
  ) => Promise<void>;
  updateSharableProducts: () => void;
  openProductDetailsModal: () => void;
  getDeviceOperation: (
    state: string
  ) => ProductOperations.OPEN | ProductOperations.CLOSE | undefined;
  isAnyGatewayOnline: boolean;
  lotHasGateway: boolean;
}

export default function ProductTableBrainRow(props: ProductTableBrainRowProps) {
  const {
    productData,
    deviceInProgress,
    selectedForSharing,
    onClick,
    updateSharableProducts,
    getDeviceOperation,
    openProductDetailsModal,
    isAnyGatewayOnline,
    lotHasGateway,
  } = props;
  const { id, name, brain, productError, status, rssi } = productData;
  const statuses = isAnyGatewayOnline
    ? getDeviceStatuses(productData)
    : AllProductStatuses.OFFLINE;
  const operation:
    | ProductOperations.OPEN
    | ProductOperations.CLOSE
    | undefined = getDeviceOperation(brain?.state || '');
  const label =
    status === ProductStatus.OFFLINE || !isAnyGatewayOnline
      ? AllProductStatuses.NO_OPERATION
      : brain?.permanentPosition === PermanentPositions.STAY_OPEN
      ? AllProductStatuses.PERMANENT_OPEN
      : getOpenCloseOperationLabel(brain?.state || '');
  const errors = getErrors(productError);
  const rtccError = useMemo(() => {
    if (errors.length === 0) return false;

    if (errors.length === 1 && productError?.rtccInvalid) return false;
    return true;
  }, [productError, errors]);
  const disabled = useMemo(
    () =>
      deviceInProgress ||
      statuses !== AllProductStatuses.ONLINE ||
      rtccError ||
      !isAnyGatewayOnline ||
      brain?.permanentPosition !== PermanentPositions.DISABLED,
    [
      deviceInProgress,
      statuses,
      rtccError,
      isAnyGatewayOnline,
      brain?.permanentPosition,
    ]
  );

  return (
    <Table.Row>
      <Table.Cell>
        <CheckBox
          checked={selectedForSharing}
          readonly
          onClick={updateSharableProducts}
          title={'Select'}
        />
      </Table.Cell>
      <Table.Cell primary>
        <div onClick={openProductDetailsModal}>
          <ProductTableIcon icon={ProductIcon.BRAIN} />
          {name}
        </div>
      </Table.Cell>
      <Table.Cell show={'smallDevices'}>
        {label !== AllProductStatuses.DISABLED ? (
          <Button
            primary
            size={ButtonSize.SMALL}
            type='button'
            onClick={() => onClick(id, operation)}
            isLoading={deviceInProgress}
            disabled={disabled}
          >
            {label === AllProductStatuses.OPEN && (
              <IconRow className={LockIcons.LOCK_OPEN} />
            )}
            {label === AllProductStatuses.CLOSE && (
              <IconRow className={LockIcons.LOCK_CLOSED} />
            )}
            {label}
          </Button>
        ) : (
          <>
            <div>
              <Button
                primary
                size={ButtonSize.SMALL}
                type='button'
                onClick={() => onClick(id, ProductOperations.OPEN)}
                isLoading={deviceInProgress}
                disabled={disabled}
              >
                <IconRow className={LockIcons.LOCK_OPEN} />
                {AllProductStatuses.OPEN}
              </Button>
            </div>
            <Button
              addLeftMargin={5}
              primary
              size={ButtonSize.SMALL}
              type='button'
              onClick={() => onClick(id, ProductOperations.CLOSE)}
              isLoading={deviceInProgress}
              disabled={disabled}
            >
              <IconRow className={LockIcons.LOCK_CLOSED} />
              {AllProductStatuses.CLOSE}
            </Button>
          </>
        )}
      </Table.Cell>
      {lotHasGateway && (
        <React.Fragment>
          <Table.Cell center>
            <StyledIcon className={BatteryIcon.POWER} />
          </Table.Cell>
          <Table.Cell
            error={errors.length > 1}
            invalid={
              statuses === AllProductStatuses.OFFLINE || errors.length === 1
            }
          >
            <ProductStatuses
              statuses={statuses}
              errors={errors}
              productType={AllProductTypes.BRAIN}
            />
          </Table.Cell>
          <Table.Cell center>
            <SignalStatus
              signalStatus={rssi}
              isAnyGatewayOnline={isAnyGatewayOnline}
            />
          </Table.Cell>
          <Table.Cell show={'largeDevices'}>
            {label !== 'Disabled' ? (
              <Button
                primary
                size={ButtonSize.SMALL}
                type='button'
                onClick={() => onClick(id, operation)}
                isLoading={deviceInProgress}
                disabled={disabled}
              >
                {label === AllProductStatuses.OPEN && (
                  <IconRow className={LockIcons.LOCK_OPEN} />
                )}
                {label === AllProductStatuses.CLOSE && (
                  <IconRow className={LockIcons.LOCK_CLOSED} />
                )}
                {label}
              </Button>
            ) : (
              <>
                <Button
                  addLeftMargin={5}
                  primary
                  size={ButtonSize.SMALL}
                  type='button'
                  onClick={() => onClick(id, ProductOperations.OPEN)}
                  isLoading={deviceInProgress}
                  disabled={disabled}
                >
                  <IconRow className={LockIcons.LOCK_OPEN} />
                  {AllProductStatuses.OPEN}
                </Button>
                <Button
                  addLeftMargin={5}
                  primary
                  size={ButtonSize.SMALL}
                  type='button'
                  onClick={() => onClick(id, ProductOperations.CLOSE)}
                  isLoading={deviceInProgress}
                  disabled={disabled}
                >
                  <IconRow className={LockIcons.LOCK_CLOSED} />
                  {AllProductStatuses.CLOSE}
                </Button>
              </>
            )}
          </Table.Cell>
        </React.Fragment>
      )}
    </Table.Row>
  );
}
