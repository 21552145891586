import React, { useCallback, useMemo } from 'react';
import ArrowIcons from 'src/shared/constants/ArrowIcons';
import styled, { css } from 'styled-components';
import ArrowDirection from '../../constants/ArrowDirection';
import Color from '../../constants/Color';

interface ArrowProps {
  arrowDirection: ArrowDirection;
  onClick?: () => void;
  disabled?: boolean;
}

export default function Arrow(props: ArrowProps) {
  const { arrowDirection, onClick, disabled } = props;

  const className = useMemo(() => {
    switch (arrowDirection) {
      case ArrowDirection.DOWN:
        return ArrowIcons.DOWN;
      case ArrowDirection.UP:
        return ArrowIcons.UP;
      case ArrowDirection.LEFT:
        return ArrowIcons.LEFT;
      case ArrowDirection.RIGTH:
        return ArrowIcons.RIGHT;
      default:
        return ArrowIcons.LEFT;
    }
  }, [arrowDirection]);

  const shouldClick = useCallback(() => {
    if (onClick) {
      if (disabled) {
        return false;
      }
      return onClick();
    }
    return undefined;
  }, [onClick, disabled]);

  return (
    <StyledWrapper onClick={shouldClick} disabled={disabled}>
      <StyledIcon className={className} disabled={disabled} />
    </StyledWrapper>
  );
}

const StyledIcon = styled.i<{ disabled?: boolean }>`
  font-size: 24px;
  color: ${Color.TEXT_DARKER};

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        color: ${Color.TEXT_LIGHT};
        cursor: default;
      `;
    }
    return css``;
  }}
`;

const StyledWrapper = styled.div<{ disabled?: boolean }>`
  width: calc(100% / 7);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        color: ${Color.TEXT_LIGHT};
        cursor: default;
      `;
    }
    return css``;
  }}
`;
