import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type { Socket } from 'socket.io-client';
import type Dropdown from 'src/keysharing/models/Dropdown';
import type LotChangeEventPayload from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventPayload';
import SocketTimer from 'src/shared/constants/SocketTimer';
import styled from 'styled-components';
import ShareDigitalkeyModal from '../../keysharing/components/ShareDigitalKeyModal';
import ShareQRCodesModal from '../../keysharing/components/ShareQRCodesModal';
import ShareWeblinkModal from '../../keysharing/components/ShareWeblinkModal';
import toLotChangeEvent from '../../lots/mappers/toLotChangeEvent';
import type Product from '../../lots/models/Product';
import Button from '../../shared/components/Button';
import Card from '../../shared/components/Card';
import CheckBox from '../../shared/components/CheckBox';
import Control from '../../shared/components/Control';
import DropdownFilter from '../../shared/components/DropdownFilter';
import { useGlobalFailureModal } from '../../shared/components/Modals/GlobalFailureModal';
import GlobalModal from '../../shared/components/Modals/GlobalModal';
import SelectFieldWithoutFormik from '../../shared/components/SelectField/SelectFieldWithoutFormik';
import Sort from '../../shared/components/Sort';
import StyledNoData from '../../shared/components/StyledNoData';
import Table from '../../shared/components/Table';
import Title from '../../shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';
import SelectAllFilterValue from '../../shared/constants/SelectAllFilterValue';
import SocketEvents from '../../shared/constants/SocketEvents';
import SocketPath from '../../shared/constants/SocketPath';
import { useGlobalModal } from '../../shared/hooks/useGlobalModal';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useSocket from '../../shared/hooks/useSocket';
import type Option from '../../shared/models/Option';
import ProductOperations from '../constants/ProductOperations';
import ProductState from '../constants/ProductState';
import ProductStatus from '../constants/ProductStatus';
import ProductTypes from '../constants/ProductTypes';
import useProductService from '../services/useProductService';
import BarrierDetailsModal from './BarrierDetailsModal';
import BollardDetailsModal from './BollardDetailsModal';
import BrainDetailsModal from './BrainDetailsModal';
import ChainDetailsModal from './ChainDetailsModal';
import GateDetailsModal from './GateDetailsModal';
import ProductTableBarrierRow from './ProductTableRow/ProductTableBarrierRow';
import ProductTableBollardRow from './ProductTableRow/ProductTableBollardRow';
import ProductTableBrainRow from './ProductTableRow/ProductTableBrainRow';
import ProductTableChainRow from './ProductTableRow/ProductTableChainRow';
import ProductTableGateRow from './ProductTableRow/ProductTableGateRow';

interface ProductTableProps {
  data: Product[];
  isLoading?: boolean;
  hideTitle?: boolean;
  onProductNameChange: (name: string, id: number) => void;
  isAnyGatewayOnline: boolean;
  currentPage: number;
  setActiveProductTypeId: (activeProductTypeId: Option | undefined) => void;
  onOrderDirection?: () => void;
  lotHasGateway: boolean;
}

const optionData: Option[] = [
  { key: SelectAllFilterValue, label: 'All' },
  { key: ProductTypes.BARRIER, label: 'Barrier' },
  { key: ProductTypes.GATE, label: 'Gate' },
  { key: ProductTypes.CHAIN, label: 'Chain' },
  { key: ProductTypes.BOLLARD, label: 'Bollard' },
  { key: ProductTypes.BRAIN, label: 'Brain' },
];

const initialDropdownValues: Dropdown = {
  filter: {
    key: SelectAllFilterValue,
    label: 'All',
  },
};

export default function ProductTable(props: ProductTableProps) {
  const {
    data,
    isLoading,
    hideTitle,
    setActiveProductTypeId,
    onProductNameChange,
    isAnyGatewayOnline,
    onOrderDirection,
    currentPage,
    lotHasGateway,
  } = props;

  const [activeId, setActiveId] = useState<number | undefined>();
  const [activeProductId, setActiveProductId] = useState<number | undefined>(
    undefined
  );
  const { changeStateOfProduct } = useProductService();
  const [devicesInProgress, setDevicesInProgress] = useState<{
    [key: number]: boolean;
  }>({});
  const [sharableProducts, setSharableProducts] = useState<number[]>([]);

  const isMounted = useIsMounted();
  const socket = useSocket(SocketPath.SOCKETIO);

  useEffect(() => {
    setSharableProducts([]);
  }, [currentPage]);

  const updateSharableProducts = useCallback((productId: number) => {
    setSharableProducts((sharableProducts) => {
      if (sharableProducts.includes(productId)) {
        return sharableProducts.filter(
          (sharableProduct) => sharableProduct !== productId
        );
      }
      return [...sharableProducts, productId];
    });
  }, []);

  const offlineDevices = useMemo(() => {
    const sharableDevices = data.filter((value) =>
      sharableProducts.includes(value.id)
    );
    if (!isAnyGatewayOnline) {
      return sharableDevices.map((value) => value.name);
    }
    const offlineProducts = sharableDevices.filter(
      (value) => value.status === ProductStatus.OFFLINE
    );
    return offlineProducts.map((value) => value.name);
  }, [data, sharableProducts, isAnyGatewayOnline]);

  const onSelectAllSharableProducts = useCallback(() => {
    if (sharableProducts.length === data.length) {
      setSharableProducts([]);
      return;
    }

    if (data.length === 0) {
      return;
    }

    const sharableIds = data.map((sharableId) => sharableId.id);

    setSharableProducts(sharableIds);
  }, [data, sharableProducts]);

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onClick = useCallback(
    async (productId: number, operation: ProductOperations | undefined) => {
      try {
        if (!operation) {
          return;
        }

        if (isMounted()) {
          setDevicesInProgress((devices) => ({
            ...devices,
            [productId]: true,
          }));
        }

        const response = await changeStateOfProduct(productId, { operation });

        let operationListener: Socket | undefined;

        const operationTimeout = setTimeout(() => {
          if (operationListener) {
            operationListener = socket?.off(
              SocketEvents.LOT_CHANGE,
              handleLotChange
            );
          }
          setDevicesInProgress((devices) => ({
            ...devices,
            [productId]: false,
          }));
          setMessage({ code: 'Unknown', message: 'Operation timeout' });
          openGlobalFailureModal();
        }, SocketTimer.PRODUCT_OPERATION_TIMEOUT);

        if (!socket) {
          throw Error();
        }

        const handleLotChange = (payload: string) => {
          try {
            const eventPayload: LotChangeEventPayload = JSON.parse(payload);
            const event = toLotChangeEvent(eventPayload);

            const { data, eventId } = event;
            const { id } = data;

            if (eventId !== response.eventId) return;

            setDevicesInProgress((devices) => ({ ...devices, [id]: false }));

            clearTimeout(operationTimeout);
            operationListener = socket?.off(
              SocketEvents.LOT_CHANGE,
              handleLotChange
            );
          } catch {
            setMessage({ code: 'Unknown', message: 'JSON parse error' });
            openGlobalFailureModal();
          }
        };
        operationListener = socket?.on(
          SocketEvents.LOT_CHANGE,
          handleLotChange
        );
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
          setDevicesInProgress((devices) => ({
            ...devices,
            [productId]: false,
          }));
        }
      }
    },
    [
      changeStateOfProduct,
      isMounted,
      openGlobalFailureModal,
      socket,
      setMessage,
    ]
  );

  const getDeviceOperation = useCallback((state: string) => {
    switch (state) {
      case ProductState.OPENED:
        return ProductOperations.CLOSE;
      case ProductState.CLOSED:
        return ProductOperations.OPEN;
      default:
        return undefined;
    }
  }, []);

  const [openShareDigitalKeyModal, closeShareDigitalKeyModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <ShareDigitalkeyModal
          productId={sharableProducts}
          closeParentModal={closeShareDigitalKeyModal}
          setSharableProducts={setSharableProducts}
        />
      </GlobalModal>
    )
  );

  const [openShareQRCode, closeShareQRCode] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <ShareQRCodesModal
        productId={sharableProducts[0]}
        closeParentModal={closeShareQRCode}
        setSharableProducts={setSharableProducts}
      />
    </GlobalModal>
  ));

  const [openShareWeblinkModal, closeShareWeblinkModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <ShareWeblinkModal
        productId={sharableProducts}
        closeParentModal={closeShareWeblinkModal}
        setSharableProducts={setSharableProducts}
        offlineDevices={offlineDevices}
      />
    </GlobalModal>
  ));

  const options: Option[] = optionData.map(({ key, label }) => ({
    key,
    label,
  }));

  const [openBarrierDetailsModal, closeBarrierDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <BarrierDetailsModal
          closeParentModal={closeBarrierDetailsModal}
          id={activeId}
          productId={activeProductId}
          onProductNameChange={onProductNameChange}
          isAnyGatewayOnline={isAnyGatewayOnline}
        />
      </GlobalModal>
    )
  );

  const [openGateDetailsModal, closeGateDetailsModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <GateDetailsModal
        closeParentModal={closeGateDetailsModal}
        id={activeId}
        productId={activeProductId}
        onProductNameChange={onProductNameChange}
        isAnyGatewayOnline={isAnyGatewayOnline}
      />
    </GlobalModal>
  ));

  const [openBrainDetailsModal, closeBrainDetailsModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <BrainDetailsModal
        closeParentModal={closeBrainDetailsModal}
        id={activeId}
        productId={activeProductId}
        onProductNameChange={onProductNameChange}
        isAnyGatewayOnline={isAnyGatewayOnline}
      />
    </GlobalModal>
  ));

  const [openChainDetailsModal, closeChainDetailsModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <ChainDetailsModal
        closeParentModal={closeChainDetailsModal}
        id={activeId}
        productId={activeProductId}
        onProductNameChange={onProductNameChange}
        isAnyGatewayOnline={isAnyGatewayOnline}
      />
    </GlobalModal>
  ));

  const [openBollardDetailsModal, closeBollardDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <BollardDetailsModal
          closeParentModal={closeBollardDetailsModal}
          id={activeId}
          productId={activeProductId}
          onProductNameChange={onProductNameChange}
          isAnyGatewayOnline={isAnyGatewayOnline}
        />
      </GlobalModal>
    )
  );

  return (
    <>
      {!hideTitle && <Title>Parklio Products</Title>}
      <Card>
        <Control left>
          <CheckBox
            readonly
            checked={data.length > 0 && data.length === sharableProducts.length}
            onClick={onSelectAllSharableProducts}
            disabled={data.length === 0}
            title='Select All'
          />
          <DropdownFilter>
            <SelectFieldWithoutFormik
              label=''
              placeholder='All'
              name='filter'
              initialValue={initialDropdownValues.filter}
              options={options}
              hideInput
              onChange={setActiveProductTypeId}
            />
          </DropdownFilter>
          <Button
            disabled={sharableProducts.length === 0}
            size={ButtonSize.MIDDLE}
            style={{ marginRight: '15px' }}
            onClick={openShareDigitalKeyModal}
          >
            Share Digital Key
          </Button>
          {lotHasGateway && (
            <Button
              disabled={sharableProducts.length === 0}
              size={ButtonSize.MIDDLE}
              style={{ marginRight: '15px' }}
              onClick={openShareWeblinkModal}
            >
              Share Weblink
            </Button>
          )}
          <Button
            disabled={sharableProducts.length !== 1}
            size={ButtonSize.MIDDLE}
            onClick={openShareQRCode}
          >
            Share QR Code
          </Button>
        </Control>
      </Card>
      <Card>
        <Table isLoading={isLoading}>
          <Table.Colgroup>
            <col span={1} style={{ width: '5%' }} />
            <col span={1} style={{ width: '24%' }} />
            <col span={1} style={{ width: '22%' }} />
            <col span={1} style={{ width: '10%' }} />
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '19%' }} />
          </Table.Colgroup>
          <Table.Head>
            <Table.Row>
              <Table.Header />
              <Table.Header>
                PRODUCT{' '}
                <StyledSpan onClick={onOrderDirection}>
                  <Sort className='pa-sort' />
                </StyledSpan>
              </Table.Header>
              {lotHasGateway && (
                <>
                  <Table.Header center show='smallDevices'>
                    REMOTE CONTROL
                  </Table.Header>
                  <Table.Header center>POWER</Table.Header>
                  <Table.Header>STATUS</Table.Header>
                  <Table.Header center>SIGNAL</Table.Header>
                  <Table.Header center show='largeDevices'>
                    REMOTE CONTROL
                  </Table.Header>
                </>
              )}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.length > 0 ? (
              data.map((productFindAllDetails) => {
                // TODO: TO BE REFACTORED INTO SEPARATE COMPONENT
                const { barrier, gate, chain, brain, bollard, id } =
                  productFindAllDetails;

                const selectedForSharing = sharableProducts.includes(id);

                const openBarrierModal = () => {
                  if (!barrier) return;

                  const { id: barrierId } = barrier;

                  setActiveId(barrierId);
                  setActiveProductId(id);
                  openBarrierDetailsModal();
                };

                const openGateModal = () => {
                  if (!gate) return;

                  const { id: gateId } = gate;

                  setActiveId(gateId);
                  setActiveProductId(id);
                  openGateDetailsModal();
                };

                const openBrainModal = () => {
                  if (!brain) return;

                  const { id: brainId } = brain;

                  setActiveId(brainId);
                  setActiveProductId(id);
                  openBrainDetailsModal();
                };

                const openChainModal = () => {
                  if (!chain) return;

                  const { id: chainId } = chain;

                  setActiveId(chainId);
                  setActiveProductId(id);
                  openChainDetailsModal();
                };

                const openBollardModal = () => {
                  if (!bollard) return;

                  const { id: bollardId } = bollard;

                  setActiveId(bollardId);
                  setActiveProductId(id);
                  openBollardDetailsModal();
                };

                if (barrier) {
                  return (
                    <ProductTableBarrierRow
                      key={id}
                      deviceInProgress={devicesInProgress[id]}
                      productData={productFindAllDetails}
                      onClick={onClick}
                      updateSharableProducts={() => updateSharableProducts(id)}
                      selectedForSharing={selectedForSharing}
                      openProductDetailsModal={openBarrierModal}
                      getDeviceOperation={getDeviceOperation}
                      isAnyGatewayOnline={isAnyGatewayOnline}
                      lotHasGateway={lotHasGateway}
                    />
                  );
                }

                if (gate) {
                  return (
                    <ProductTableGateRow
                      key={id}
                      deviceInProgress={devicesInProgress[id]}
                      productData={productFindAllDetails}
                      onClick={onClick}
                      updateSharableProducts={() => updateSharableProducts(id)}
                      selectedForSharing={selectedForSharing}
                      openProductDetailsModal={openGateModal}
                      getDeviceOperation={getDeviceOperation}
                      isAnyGatewayOnline={isAnyGatewayOnline}
                      lotHasGateway={lotHasGateway}
                    />
                  );
                }

                if (brain) {
                  return (
                    <ProductTableBrainRow
                      key={id}
                      deviceInProgress={devicesInProgress[id]}
                      productData={productFindAllDetails}
                      onClick={onClick}
                      updateSharableProducts={() => updateSharableProducts(id)}
                      selectedForSharing={selectedForSharing}
                      openProductDetailsModal={openBrainModal}
                      getDeviceOperation={getDeviceOperation}
                      isAnyGatewayOnline={isAnyGatewayOnline}
                      lotHasGateway={lotHasGateway}
                    />
                  );
                }

                if (chain) {
                  return (
                    <ProductTableChainRow
                      key={id}
                      deviceInProgress={devicesInProgress[id]}
                      productData={productFindAllDetails}
                      onClick={onClick}
                      updateSharableProducts={() => updateSharableProducts(id)}
                      selectedForSharing={selectedForSharing}
                      openProductDetailsModal={openChainModal}
                      getDeviceOperation={getDeviceOperation}
                      isAnyGatewayOnline={isAnyGatewayOnline}
                      lotHasGateway={lotHasGateway}
                    />
                  );
                }

                if (bollard) {
                  return (
                    <ProductTableBollardRow
                      key={id}
                      deviceInProgress={devicesInProgress[id]}
                      productData={productFindAllDetails}
                      onClick={onClick}
                      updateSharableProducts={() => updateSharableProducts(id)}
                      selectedForSharing={selectedForSharing}
                      openProductDetailsModal={openBollardModal}
                      getDeviceOperation={getDeviceOperation}
                      isAnyGatewayOnline={isAnyGatewayOnline}
                      lotHasGateway={lotHasGateway}
                    />
                  );
                }

                return null;
              })
            ) : (
              <Table.Row>
                <Table.Cell merged={7}>
                  <StyledNoData>No Parklio products.</StyledNoData>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
    </>
  );
}

const StyledSpan = styled.span`
  width: 25px;
  height: 25px;
`;
