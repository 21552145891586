import React, { useCallback, useMemo, useState } from 'react';
import formatDates from 'src/shared/utils/formatDates';
import styled, { css } from 'styled-components';
import Dropdown from '../../shared/components/Dropdown';
import Color from '../../shared/constants/Color';
import BatteryIcon from '../constants/BatteryIcon';

interface BatteryPowerIconProps {
  batteryStatus: number;
  updatedAt?: string;
}

export default function BatteryPowerIcon(props: BatteryPowerIconProps) {
  const { batteryStatus, updatedAt } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const icon = useMemo(() => {
    if (batteryStatus > 75) {
      return BatteryIcon.FULL;
    }

    if (batteryStatus > 50) {
      return BatteryIcon.HALF;
    }

    if (batteryStatus > 25) {
      return BatteryIcon.LOW;
    }

    return BatteryIcon.EMPTY;
  }, [batteryStatus]);

  const empty = useMemo(() => {
    if (!batteryStatus) {
      return true;
    }

    return batteryStatus <= 25;
  }, [batteryStatus]);

  return (
    <StyledWrapper onMouseEnter={open} onMouseLeave={close}>
      <StyledIcon className={icon} empty={empty} />
      {isDropdownOpen && updatedAt && (
        <Dropdown close={close} infoMessages>
          <StyledDescription>Last updated:</StyledDescription>
          <StyledDescription>{formatDates(updatedAt, true)}</StyledDescription>
        </Dropdown>
      )}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const StyledIcon = styled.i<{ empty?: boolean }>`
  font-family: ParklioIcons;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 27px;

  ${(props) => {
    const { empty } = props;
    if (empty) {
      return css`
        color: ${Color.ERROR};
      `;
    }

    return css``;
  }}
`;

const StyledDescription = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${Color.TEXT_DARKER};
  margin: 10px;
  justify-content: center;

  :first-child {
    margin-bottom: 0px;
  }

  :last-child {
    margin-top: 0px;
  }
`;
