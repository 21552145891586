import React, { useMemo } from 'react';
import AppRole from '../../shared/constants/AppRole';
import useUserHasRole from '../../shared/hooks/useUserHasRole';
import ParklioInternalDashboard from './ParklioInternalDashboard';
import PmsUserDashboard from './PmsUserDashboard';

export default function Dashboard() {
  const userHasRole = useUserHasRole();

  const userCanAddAccount = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PARKLIO_INTERNAL_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const pmsUser = useMemo(() => {
    const roles =
      AppRole.PMS_ADMIN | AppRole.PMS_OPERATOR | AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  return (
    <>
      {userCanAddAccount && <ParklioInternalDashboard />}
      {pmsUser && <PmsUserDashboard />}
    </>
  );
}
