import React from 'react';
import ButtonSize from '../../constants/ButtonSize';
import { useGlobalModal } from '../../hooks/useGlobalModal';
import Button from '../Button';
import Control from '../Control';
import Line from '../Line';
import Text from '../Text';
import GlobalModal from './GlobalModal';

interface GlobalConfirmationModalProps {
  closeParentModal?: () => void;
  action: (values?: any) => void;
  message: string;
  values?: any;
  messages?: { yes: string; no: string };
}

const closeAllModals = (
  action: (values?: any) => void,
  parentModal?: () => void,
  childModal?: () => void
) => {
  action();
  if (parentModal) parentModal();
  if (childModal) childModal();
};

export function useGlobalConfirmationModal(
  props: GlobalConfirmationModalProps
) {
  const { message, closeParentModal, action, values, messages } = props;

  const [openGlobalConfirmationModal, closeGlobalConfirmationModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen popup>
        <>
          <Text>{message}</Text>
          <Line />
          <Control center>
            <Button
              onClick={closeGlobalConfirmationModal}
              size={ButtonSize.LARGE}
              type='button'
            >
              {messages?.no || 'No'}
            </Button>
            <Button
              onClick={() =>
                closeAllModals(
                  () => action(values),
                  closeParentModal,
                  closeGlobalConfirmationModal
                )
              }
              size={ButtonSize.LARGE}
              type='button'
              primary
            >
              {messages?.yes || 'Yes'}
            </Button>
          </Control>
        </>
      </GlobalModal>
    ));

  return { openGlobalConfirmationModal, closeGlobalConfirmationModal };
}
