import React from 'react';
import ToggleSwitch from '../../shared/components/ToggleSwitch';
import DetailsColumn from './DetailsColumn';
import DetailsRow from './DetailsRow';

interface BarrierSystemControlFormProps {
  onChange: () => void;
  state: boolean | undefined;
}

export default function BarrierSystemControlForm(
  props: BarrierSystemControlFormProps
) {
  const { onChange, state } = props;

  return (
    <DetailsRow wider addMargin>
      <DetailsColumn>
        <ToggleSwitch
          state={state}
          condition
          onChange={onChange}
          name='Auto-close'
        />
      </DetailsColumn>
    </DetailsRow>
  );
}
