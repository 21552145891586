import type LoginFormValues from '../models/LoginFormValues';
import type LoginRequest from '../models/LoginRequest';

export default function toLoginRequest(data: LoginFormValues): LoginRequest {
  const { email, password } = data;

  return {
    email: email.replace(/\s/g, ''),
    password,
    type: 'PMS',
  };
}
