import { useCallback } from 'react';
import Method from 'src/shared/constants/Method';
import RequestMode from 'src/shared/constants/RequestMode';

interface IsGatewayAvailableService {
  get: (endpoint: string) => Promise<Response>;
}

export default function useIsGatewayAvailableService(): IsGatewayAvailableService {
  const generateHeaders = useCallback(async () => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return headers;
  }, []);

  const get = useCallback(
    async (endpoint: string) => {
      const fetchUrl = new URL(endpoint);
      const headers = await generateHeaders();
      const fetchOptions = {
        method: Method.GET,
        headers,
        mode: RequestMode.NO_CORS,
      };
      const result = await fetch(fetchUrl.toString(), fetchOptions);

      return result;
    },
    [generateHeaders]
  );

  return { get };
}
