import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Color from '../../shared/constants/Color';

export default function NewPasswordText() {
  return (
    <StyledWrapper>
      <StyledText>
        New password was successfully changed. Click to{' '}
        <StyledLink to='/login'>Login</StyledLink>.
      </StyledText>
    </StyledWrapper>
  );
}

const StyledText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: ${Color.TEXT_DARKER};
  margin: 36px 0px;
`;

const StyledWrapper = styled.div`
  width: 386px;
  padding: 35px 30px;
  background-color: ${Color.BACKGROUND_LIGHTER};
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 10px;
  margin: 35px 0px;
`;

const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 18px;
  line-height: 150%;
  color: ${Color.PRIMARY_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;
