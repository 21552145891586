import styled, { css } from 'styled-components';
import Color from '../../constants/Color';

interface RowProps {
  noBorderBottom?: boolean;
  disabled?: boolean;
}

const Row = styled.tr<RowProps>`
  width: 100%;
  border-bottom: 1px solid ${Color.BORDER_LIGHT};

  ${(props) => {
    const { noBorderBottom } = props;

    if (noBorderBottom) {
      return css`
        border-bottom: none;
        height: 52px;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        & > td {
          color: rgba(${Color.TEXT_LIGHT_RGBA}, 0.5);
        }
      `;
    }
    return css``;
  }}

    :last-child {
    border-bottom: none;
  }
`;

export default Row;
