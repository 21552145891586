import type SessionData from '../../shared/models/SessionData';
import generateAppRole from '../../shared/utils/generateAppRole';
import type LoginResponse from '../models/LoginResponse';

export default function toSessionData(data: LoginResponse): SessionData {
  const {
    token,
    name,
    email,
    account_role_id,
    account_user_role_id,
    account_id,
    account_user_id,
  } = data;

  return {
    token,
    email,
    name,
    role: generateAppRole(account_role_id, account_user_role_id),
    id: account_id,
    userId: account_user_id,
  };
}
