import { useField, useFormikContext } from 'formik';
import React from 'react';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import CounterField from 'src/shared/components/CounterField';
import Error from 'src/shared/components/Error';

import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import Section from 'src/shared/components/Section';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import ButtonSize from 'src/shared/constants/ButtonSize';

interface SendInquiryFormProps {
  closeParentModal: () => void;
}

export default function SendInquiryForm(props: SendInquiryFormProps) {
  const { closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });
  const [, meta] = useField({ name: 'at-least-one-count' });
  const { error } = meta;

  return (
    <>
      <Section>
        <Title bold>Licenses</Title>
        <Table margin customWidth>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <CounterField name='barriers' label='Barriers' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='bollards' label='Bollards' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='brains' label='Brains' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='chains' label='Chains ' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='gates' label='Gates' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='lprCameras' label='LPR cameras' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='parkingPlaces' label='Parking places' />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {error && <Error>*{error}</Error>}
      </Section>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Add
        </Button>
      </Control>
    </>
  );
}
