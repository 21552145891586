import { useCallback } from 'react';
import toManagerUpdate from 'src/managers/mappers/toManagerUpdate';
import toManagerUpdateParams from 'src/managers/mappers/toManagerUpdateParams';

import useApiService from '../../shared/services/useApiService';
import toManager from '../mappers/toManager';
import type Manager from '../models/Manager';
import type ManagerRemoveResponse from '../models/ManagerRemoveResponse';
import type ManagerResponse from '../models/ManagerResponse';
import type ManagerUpdate from '../models/ManagerUpdate';
import type ManagerUpdateParams from '../models/ManagerUpdateParams';
import type ManagerUpdateResponse from '../models/ManagerUpdateResponse';

interface ManagerService {
  findAllManagers: (lotId: number) => Promise<Manager[]>;
  addManagers: (lotId: number, managerIds: number) => Promise<ManagerUpdate[]>;
  removeManager: (
    lotId: number,
    managerId: number
  ) => Promise<ManagerRemoveResponse>;
}

export default function useManagerService(): ManagerService {
  const { patch, get, remove } = useApiService();

  const findAllManagers = useCallback(
    async (lotId: number) => {
      const response = await get<ManagerResponse[]>(`/lots/${lotId}/managers`);

      return response.data.map(toManager);
    },
    [get]
  );

  const addManagers = useCallback(
    async (lotId: number, managerIds: number) => {
      const params = toManagerUpdateParams(managerIds);

      const response = await patch<
        ManagerUpdateParams,
        ManagerUpdateResponse[]
      >(`/lots/${lotId}/managers`, params);

      return response.data.map(toManagerUpdate);
    },
    [patch]
  );

  const removeManager = useCallback(
    async (lotId: number, managerId: number) => {
      const response = await remove<ManagerRemoveResponse>(
        `/lots/${lotId}/managers/${managerId}`
      );

      return response;
    },
    [remove]
  );

  return { addManagers, findAllManagers, removeManager };
}
