import React from 'react';
import ButtonSize from '../../shared/constants/ButtonSize';
import InputIcon from '../constants/InputIcon';
import Button from './Button';
import Input from './Input';

export default function NewPasswordForm() {
  return (
    <>
      <Input
        name='password'
        type='password'
        icon={InputIcon.LOCK}
        placeholder='Enter Password'
        label='New Password'
        autoComplete='new-password'
      />
      <Input
        name='passwordConfirmation'
        type='password'
        icon={InputIcon.LOCK}
        placeholder='Repeat Password'
        label='Confirm New Password'
        autoComplete='new-password'
      />
      <Button size={ButtonSize.FULL} type='submit' primary>
        Change password
      </Button>
    </>
  );
}
