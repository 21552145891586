import { Formik, Form as UnstyledForm } from 'formik';
import type { FormikFormProps, FormikHelpers } from 'formik';
import React from 'react';
import styled from 'styled-components';
import type * as yup from 'yup';
import type { ObjectShape } from 'yup/lib/object';
import LoadingContext from '../contexts/LoadingContext';

interface FormProps<Values> {
  validationSchema: yup.ObjectSchema<ObjectShape>;
  initialValues: Values;
  onSubmit: (values: Values, helpers: FormikHelpers<Values>) => Promise<void>;
  name: string;
  children: React.ReactNode;
  isLoading?: boolean;
  autoComplete?: string;
}

export default function Form<Values extends object>(props: FormProps<Values>) {
  const {
    name,
    onSubmit,
    initialValues,
    validationSchema,
    children,
    isLoading,
    autoComplete,
  } = props;

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <LoadingContext.Provider value={{ isLoading }}>
        <StyledForm name={name} autoComplete={autoComplete}>
          {children}
        </StyledForm>
      </LoadingContext.Provider>
    </Formik>
  );
}

const StyledForm = styled(UnstyledForm)<FormikFormProps>`
  width: 100%;
`;
