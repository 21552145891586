import type DetectGatewayQuery from 'src/detection/models/DetectGatewayQuery';
import type DetectGatewayQueryParams from 'src/detection/models/DetectGatewayQueryParams';

export default function toDetectGatewayQuery(
  data: DetectGatewayQueryParams
): DetectGatewayQuery {
  const { lotId, status } = data;

  return {
    lot_id: lotId,
    status,
  };
}
