import React from 'react';
import styled from 'styled-components';
import Color from '../../constants/Color';

export default function Icon() {
  return (
    <StyledWrapper>
      <StyledIcon className='pa-calendar' />
    </StyledWrapper>
  );
}

const StyledIcon = styled.i`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${Color.TEXT_DARK};
`;

const StyledWrapper = styled.div`
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  background-color: ${Color.BACKGROUND_DARK};
  width: 43px;
  height: 36px;
  position: absolute;
  right: -1px;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
