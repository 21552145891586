import styled, { css } from 'styled-components';
import Color from '../constants/Color';

const Description = styled.div<{
  removeBottomMargin?: boolean;
  deleteView?: boolean;
  inquiryResponseView?: boolean;
  color?: string;
  errorMessage?: boolean;
}>`
  width: 100%;
  font-size: 13px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: ${Color.TEXT_DARKER};
  margin: 10px 0 20px 0;

  ${(props) => {
    const { removeBottomMargin } = props;

    if (removeBottomMargin) {
      return css`
        margin-bottom: 0;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { errorMessage } = props;

    if (errorMessage) {
      return css`
        margin: -10px 0 5px 0;
        font-size: 12px;
        font-weight: 600;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { color } = props;

    if (color) {
      return css`
        color: ${color};
      `;
    }
    return css``;
  }}

	${(props) => {
    const { deleteView } = props;

    if (deleteView) {
      return css`
        margin: 30px 0;
        font-size: 24px;
        text-align: center;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { inquiryResponseView } = props;

    if (inquiryResponseView) {
      return css`
        margin: 30px 0;
        font-size: 20px;
        text-align: center;
      `;
    }
    return css``;
  }}
`;

export default Description;
