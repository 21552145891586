import type AccountProductLicensesResponse from 'src/product-license/models/AccountProductLicensesResponse';
import type AccountProductLicenses from '../models/AccountProductLicenses';

export default function toAccountProductLicenses(
  data: AccountProductLicensesResponse
): AccountProductLicenses {
  const {
    parking_places,
    barriers,
    brains,
    bollards,
    chains,
    lpr_cameras,
    gates,
  } = data;

  return {
    parkingPlaces: parking_places,
    barriers,
    brains,
    bollards,
    chains,
    lprCameras: lpr_cameras,
    gates,
  };
}
