import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import type LotChangeEventPayload from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventPayload';
import GatewayDetailsModal from 'src/products/components/GatewayDetailsModal';
import OrderByDirections from 'src/products/constants/OrderByDirection';
import type Gateway from 'src/products/models/Gateway/Gateway';
import type GatewayStatusEvent from 'src/products/models/Gateway/GatewayStatusEvent';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import Toast from 'src/shared/components/Toast';
import PaginationSize from 'src/shared/constants/DataSize';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import toLotChangeEvent from '../../lots/mappers/toLotChangeEvent';
import type Product from '../../lots/models/Product';
import Content from '../../shared/components/Content';
import Main from '../../shared/components/Main';
import GlobalModal from '../../shared/components/Modals/GlobalModal';
import Pagination from '../../shared/components/Pagination';
import PaginationItemDisplay from '../../shared/constants/PaginationItemDisplay';
import SocketEvents from '../../shared/constants/SocketEvents';
import SocketPath from '../../shared/constants/SocketPath';
import { useGlobalModal } from '../../shared/hooks/useGlobalModal';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useSocket from '../../shared/hooks/useSocket';

import type Meta from '../../shared/models/Meta';
import type Option from '../../shared/models/Option';
import GatewayCards from '../components/GatewayCards';
import ProductTable from '../components/ProductTable';
import GatewayStatus from '../constants/GatewayStatus';
import ProductState from '../constants/ProductState';
import useGatewayService from '../services/useGatewayService';
import useProductService from '../services/useProductService';

interface Props {
  lotIdProps?: number;
  hideTitle?: boolean;
  dashboardView?: boolean;
}

const toastTimerMiliseconds = 10 * 1000;

export default function ProductList(props: Props) {
  const { hideTitle, lotIdProps, dashboardView } = props;
  const [gatewayData, setGatewayData] = useState<Gateway[]>([]);
  const [productData, setProductData] = useState<Product[]>([]);
  const [activeId, setActiveId] = useState<number | undefined>();
  const [activeProductTypeId, setActiveProductTypeId] = useState<
    Option | undefined
  >(undefined);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);
  const [gatewayStatuses, setGatewayStatuses] = useState<{
    [key: string]: GatewayStatus;
  }>({});
  const [isProductDataLoading, setIsProductDataLoading] = useState(false);
  const [isGatewayDataLoading, setIsGatewayDataLoading] = useState(false);
  const [isToastShowing, SetIsToastShowing] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [activeReboot, setActiveReboot] = useState<string | undefined>(
    undefined
  );
  const [orderDirection, setOrderDirection] = useState(OrderByDirections.ASC);

  const { findAllGateways } = useGatewayService();
  const { findAllProducts } = useProductService();
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const { params } = useRouteMatch<{ lotId?: string }>();
  const lotId = useMemo(() => {
    if (!params.lotId) {
      return lotIdProps;
    }
    return parseInt(params.lotId);
  }, [params, lotIdProps]);

  const isMounted = useIsMounted();
  const socket = useSocket(SocketPath.SOCKETIO);
  const toastTimeout = useRef<NodeJS.Timeout | undefined>();

  const onProductNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setProductData((oldProducts) => {
      const index = oldProducts.findIndex((product) => product.id === id);

      if (index === -1) return oldProducts;

      oldProducts[index].name = name;
      return [...oldProducts];
    });
  }, []);

  const onGatewayNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setGatewayData((oldGateway) => {
      const index = oldGateway.findIndex((gateway) => gateway.id === id);

      if (index === -1) return oldGateway;

      oldGateway[index].name = name;
      return [...oldGateway];
    });
  }, []);

  const showToast = useCallback((message: string, device?: string) => {
    SetIsToastShowing(true);
    setActiveReboot(device);
    setToastMessage(message);

    if (toastTimeout.current) {
      clearTimeout(toastTimeout.current);
    }
    toastTimeout.current = setTimeout(() => {
      SetIsToastShowing(false);
    }, toastTimerMiliseconds);
  }, []);

  const hideToast = useCallback(() => {
    SetIsToastShowing(false);
    if (!toastTimeout.current) {
      return;
    }
    clearTimeout(toastTimeout.current);
  }, []);

  const [openGatewayDetailsModal, closeGatewayDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <GatewayDetailsModal
          closeParentModal={closeModal}
          id={activeId}
          onGatewayNameChange={onGatewayNameChange}
          showToast={showToast}
        />
      </GlobalModal>
    )
  );

  const openModal = useCallback(
    (id: number) => {
      setActiveId(id);
      openGatewayDetailsModal();
    },
    [openGatewayDetailsModal]
  );

  const closeModal = useCallback(() => {
    setActiveId(undefined);
    closeGatewayDetailsModal();
  }, [closeGatewayDetailsModal]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!lotId) {
          return;
        }

        if (isMounted()) {
          setIsGatewayDataLoading(true);
        }

        const { data } = await findAllGateways({ lotId });

        const statuses = data.reduce((acc, event) => {
          const { uuid, status } = event;

          return {
            ...acc,
            [uuid]: status,
          };
        }, {});

        if (isMounted()) {
          setGatewayData(data);
          setGatewayStatuses(statuses);
          setIsGatewayDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsGatewayDataLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [findAllGateways, isMounted, lotId]);

  const onOrderDirection = useCallback(() => {
    if (orderDirection === OrderByDirections.DESC) {
      setOrderDirection(OrderByDirections.ASC);
    } else if (orderDirection === OrderByDirections.ASC) {
      setOrderDirection(OrderByDirections.DESC);
    }
  }, [orderDirection]);

  const getProductData = useCallback(
    async (page: number) => {
      try {
        if (!lotId) {
          return;
        }

        if (isMounted()) {
          setIsProductDataLoading(true);
        }

        const { data, meta } = await findAllProducts({
          lotId,
          productTypeId: activeProductTypeId?.key,
          orderByDirection: orderDirection,
          page,
          size: PaginationSize.STANDARD,
        });
        if (isMounted()) {
          setProductData(data);
          setIsProductDataLoading(false);
          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsProductDataLoading(false);
        }

        throw error;
      }
    },
    [findAllProducts, isMounted, lotId, activeProductTypeId, orderDirection]
  );

  useEffect(() => {
    getProductData(1);
  }, [getProductData]);

  const lotHasGateway = useMemo(() => gatewayData.length > 0, [gatewayData]);

  const isAnyGatewayOnline = useMemo(() => {
    const allOnlineGateways = gatewayData.filter(
      (gateway) => gateway.status === GatewayStatus.ONLINE
    );
    return allOnlineGateways.length > 0;
  }, [gatewayData]);

  useEffect(() => {
    const handleAllGatewayStatuses = (payload: string) => {
      try {
        const event: GatewayStatusEvent[] = JSON.parse(payload);

        if (!event) {
          return;
        }

        const statuses = event.reduce((acc, event) => {
          const { uuid, status } = event;

          return {
            ...acc,
            [uuid]: status,
          };
        }, {});

        setGatewayStatuses(statuses);
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    const handleOneGatewayStatus = (payload: string) => {
      try {
        const event: GatewayStatusEvent = JSON.parse(payload);
        const { uuid, status } = event;

        if (uuid === activeReboot) {
          const findGatewayName = gatewayData.find(
            (gateway) => gateway.uuid === uuid
          );
          showToast(`Gateway ${findGatewayName?.name} is Online`);
        }

        setGatewayStatuses((statuses) => ({
          ...statuses,
          [uuid]: status,
        }));
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    const handleLotChange = (payload: string) => {
      try {
        const eventPayload: LotChangeEventPayload = JSON.parse(payload);

        const eventData = toLotChangeEvent(eventPayload);

        const { data } = eventData;
        const {
          id,
          status,
          batteryStatus,
          productErrorId,
          rssi,
          barrier,
          gate,
          chain,
          bollard,
          productError,
          brain,
        } = data;

        setProductData((data) =>
          data.map((product) => {
            if (product.id !== id) {
              return product;
            }

            return {
              ...product,
              barrier: product.barrier
                ? {
                    ...product.barrier,
                    state: (barrier?.state === ProductState.OPENED
                      ? ProductState.OPENED
                      : barrier?.state === ProductState.CLOSED
                      ? ProductState.CLOSED
                      : undefined) as ProductState,
                    isCarOnTop: barrier?.isCarOnTop,
                  }
                : undefined,
              status,
              batteryStatus,
              rssi,
              productErrorId,
              productError: productError
                ? {
                    ...product.productError,
                    id: productError.errorId,
                    jammed: productError.jammed,
                    sensorDirty: productError.sensorDirty,
                    pinBroken: productError.pinBroken,
                    batteryEmpty: productError.batteryEmpty,
                    rtccInvalid: productError.rtccInvalid,
                    masterKeyNotSet: productError.masterKeyNotSet,
                  }
                : null,

              gate: product.gate
                ? {
                    ...product.gate,
                    state: (gate?.state === ProductState.OPENED
                      ? ProductState.OPENED
                      : gate?.state === ProductState.CLOSED
                      ? ProductState.CLOSED
                      : undefined) as ProductState,
                    permanentPosition: gate?.permanentPosition || '',
                  }
                : undefined,

              brain: product.brain
                ? {
                    ...product.brain,
                    state: (brain?.state === ProductState.OPENED
                      ? ProductState.OPENED
                      : brain?.state === ProductState.CLOSED
                      ? ProductState.CLOSED
                      : brain?.state === ProductState.DISABLED
                      ? ProductState.DISABLED
                      : undefined) as ProductState,
                    permanentPosition: brain?.permanentPosition || '',
                  }
                : undefined,

              chain: product.chain
                ? {
                    ...product.chain,
                    state: (chain?.state === ProductState.OPENED
                      ? ProductState.OPENED
                      : chain?.state === ProductState.CLOSED
                      ? ProductState.CLOSED
                      : undefined) as ProductState,
                    permanentPosition: chain?.permanentPosition || '',
                  }
                : undefined,

              bollard: product.bollard
                ? {
                    ...product.bollard,
                    state: (bollard?.state === ProductState.OPENED
                      ? ProductState.OPENED
                      : bollard?.state === ProductState.CLOSED
                      ? ProductState.CLOSED
                      : undefined) as ProductState,
                  }
                : undefined,
            };
          })
        );
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    socket?.on(SocketEvents.ALL_GATEWAYS_STATUS, handleAllGatewayStatuses);
    socket?.on(SocketEvents.GATEWAY_STATUS, handleOneGatewayStatus);
    socket?.on(SocketEvents.LOT_CHANGE, handleLotChange);

    return () => {
      socket?.off(SocketEvents.ALL_GATEWAYS_STATUS, handleAllGatewayStatuses);
      socket?.off(SocketEvents.GATEWAY_STATUS, handleOneGatewayStatus);
      socket?.off(SocketEvents.LOT_CHANGE, handleLotChange);
    };
  }, [
    socket,
    openGlobalFailureModal,
    setMessage,
    activeReboot,
    gatewayData,
    showToast,
  ]);

  const setActiveTypeId = useCallback(
    (activeProductTypeId: Option | undefined) => {
      setActiveProductTypeId(activeProductTypeId);
    },
    []
  );

  return (
    <Main dashboardView={dashboardView}>
      <Content widthSize='70%' dashboardView={dashboardView}>
        <ProductTable
          hideTitle={hideTitle}
          isLoading={isProductDataLoading}
          data={productData}
          setActiveProductTypeId={setActiveTypeId}
          onProductNameChange={onProductNameChange}
          isAnyGatewayOnline={isAnyGatewayOnline}
          onOrderDirection={onOrderDirection}
          currentPage={meta.currentPage}
          lotHasGateway={lotHasGateway}
        />
        {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
          <Pagination meta={meta} getData={getProductData} />
        )}
      </Content>
      <Content widthSize='27%' dashboardView={dashboardView}>
        <GatewayCards
          data={gatewayData}
          isLoading={isGatewayDataLoading}
          statuses={gatewayStatuses}
          openGatewayModal={openModal}
          dashboardView={dashboardView}
        />
      </Content>
      {isToastShowing && <Toast onClick={hideToast}>{toastMessage}</Toast>}
    </Main>
  );
}
