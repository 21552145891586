import React from 'react';
import styled from 'styled-components';
import useLoadingContext from '../hooks/useLoadingContext';
import Spinner from './Spinner';

interface SectionProps {
  children: React.ReactNode;
}

export default function Section(props: SectionProps) {
  const { children } = props;
  const { isLoading } = useLoadingContext();

  if (isLoading) {
    return (
      <StyledLoadingWrapper>
        <Spinner primary data-testid='sectionSpinner' />
      </StyledLoadingWrapper>
    );
  }

  return <StyledWrapper>{children}</StyledWrapper>;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px;

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const StyledLoadingWrapper = styled.div`
  margin: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
