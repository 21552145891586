import { useCallback } from 'react';
import toParkingPlace from 'src/detection/mappers/toParkingPlace';
import toParkingPlaceCreateRequest from 'src/detection/mappers/toParkingPlaceCreateRequest';
import toParkingPlaceQuery from 'src/detection/mappers/toParkingPlaceQuery';
import toParkingPlaceUpdateParams from 'src/detection/mappers/toParkingPlaceUpdateParams';
import type ParkingPlace from 'src/detection/models/ParkingPlace';
import type ParkingPlaceCreateRequest from 'src/detection/models/ParkingPlaceCreateRequest';
import type ParkingPlaceFormValues from 'src/detection/models/ParkingPlaceFormValues';
import type ParkingPlaceQuery from 'src/detection/models/ParkingPlaceQuery';
import type ParkingPlaceQueryParams from 'src/detection/models/ParkingPlaceQueryParams';
import type ParkingPlaceRemoveResponse from 'src/detection/models/ParkingPlaceRemoveResponse';
import type ParkingPlaceResponse from 'src/detection/models/ParkingPlaceResponse';
import type ParkingPlaceUpdateParams from 'src/detection/models/ParkingPlaceUpdateParams';
import type ParkingPlaceUpdateValues from 'src/detection/models/ParkingPlaceUpdateValues';
import ContentType from 'src/shared/constants/ContentType';
import toMeta from 'src/shared/mappers/toMeta';
import type GetAllDetails from 'src/shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import fetchAllData from '../utils/fetchAllData';

const parkingPlaceBaseURL = '/parking-places';

interface ParkingPlaceService {
  findOneParkingPlace: (id: number) => Promise<ParkingPlace>;
  findAllParkingPlaces: (
    queryParams: ParkingPlaceQueryParams
  ) => Promise<GetAllDetails<ParkingPlace>>;
  findLimitlessParkingPlaces: (
    queryParams: ParkingPlaceQueryParams
  ) => Promise<ParkingPlace[]>;
  findAllParkingPlacesParklioInternal: (
    queryParams: ParkingPlaceQueryParams
  ) => Promise<GetAllDetails<ParkingPlace>>;
  findLimitlessParkingPlacesParklioInternal: (
    queryParams: ParkingPlaceQueryParams
  ) => Promise<ParkingPlace[]>;
  createParkingPlace: (
    values: ParkingPlaceFormValues
  ) => Promise<ParkingPlace | ParkingPlace[]>;
  updateParkingPlace: (
    id: number,
    values: ParkingPlaceUpdateValues
  ) => Promise<ParkingPlace>;
  removeParkingPlace: (id: number) => Promise<ParkingPlaceRemoveResponse>;
}

export default function useParkingPlaceService(): ParkingPlaceService {
  const { get, post, patch, remove } = useApiService();

  const { get: getParklioInternal } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const findOneParkingPlace = useCallback(
    async (id: number) => {
      const response = await get<ParkingPlaceResponse>(
        `${parkingPlaceBaseURL}/${id}`
      );

      const data = toParkingPlace(response.data);

      return data;
    },
    [get]
  );

  const findAllParkingPlaces = useCallback(
    async (queryParams: ParkingPlaceQueryParams) => {
      const query = toParkingPlaceQuery(queryParams);

      const response = await get<ParkingPlaceResponse[], ParkingPlaceQuery>(
        `${parkingPlaceBaseURL}`,
        query
      );

      const data = response.data.map(toParkingPlace);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findLimitlessParkingPlaces = useCallback(
    async (queryParams: ParkingPlaceQueryParams) => {
      const response = await fetchAllData<
        ParkingPlace,
        ParkingPlaceQueryParams
      >(findAllParkingPlaces, queryParams);

      return response;
    },
    [findAllParkingPlaces]
  );

  const findAllParkingPlacesParklioInternal = useCallback(
    async (queryParams: ParkingPlaceQueryParams) => {
      const query = toParkingPlaceQuery(queryParams);

      const response = await getParklioInternal<
        ParkingPlaceResponse[],
        ParkingPlaceQuery
      >(`${parkingPlaceBaseURL}`, query);

      const data = response.data.map(toParkingPlace);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [getParklioInternal]
  );

  const findLimitlessParkingPlacesParklioInternal = useCallback(
    async (queryParams: ParkingPlaceQueryParams) => {
      const response = await fetchAllData<
        ParkingPlace,
        ParkingPlaceQueryParams
      >(findAllParkingPlacesParklioInternal, queryParams);

      return response;
    },
    [findAllParkingPlacesParklioInternal]
  );

  const createParkingPlace = useCallback(
    async (values: ParkingPlaceFormValues) => {
      const requestBody = toParkingPlaceCreateRequest(values);
      const response = await post<
        ParkingPlaceCreateRequest,
        ParkingPlaceResponse | ParkingPlaceResponse[]
      >(`${parkingPlaceBaseURL}`, requestBody);

      if (Array.isArray(response.data)) {
        return response.data.map(toParkingPlace);
      }

      return toParkingPlace(response.data);
    },
    [post]
  );

  const updateParkingPlace = useCallback(
    async (id: number, values: ParkingPlaceUpdateValues) => {
      const params = toParkingPlaceUpdateParams(values);
      const response = await patch<
        ParkingPlaceUpdateParams,
        ParkingPlaceResponse
      >(`${parkingPlaceBaseURL}/${id}`, params);

      return toParkingPlace(response.data);
    },
    [patch]
  );

  const removeParkingPlace = useCallback(
    async (id: number) => {
      const response = await remove<ParkingPlaceRemoveResponse>(
        `${parkingPlaceBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return {
    findAllParkingPlaces,
    findLimitlessParkingPlaces,
    findOneParkingPlace,
    createParkingPlace,
    updateParkingPlace,
    removeParkingPlace,
    findAllParkingPlacesParklioInternal,
    findLimitlessParkingPlacesParklioInternal,
  };
}
