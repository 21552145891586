import React, { useCallback, useRef, useState } from 'react';
import MultipleErrors from 'src/shared/constants/MultipleErrors';
import Dropdown from '../../shared/components/Dropdown';
import {
  InfoIcon,
  LabelWrapper,
  StyledDescription,
  StyledError,
  StyledErrorWrapper,
  StyledWrapper,
} from '../../shared/components/InfoMessage';
import AllProductStatuses from '../constants/AllProductStatuses';

interface ProductErrorStatusesProps {
  errors: string[];
  productType: string;
}

export default function ProductErrorStatuses(props: ProductErrorStatusesProps) {
  const { errors, productType } = props;
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  return (
    <StyledWrapper onMouseEnter={open} onMouseLeave={close} ref={wrapperRef}>
      <LabelWrapper>
        {errors.length < MultipleErrors.LENGTH && errors[0]}
        {errors.length >= MultipleErrors.LENGTH && MultipleErrors.TEXT}
        <InfoIcon className='pa-info' />
      </LabelWrapper>
      {isDropdownOpen && (
        <Dropdown close={close} infoMessages>
          {errors.map((error, key) => (
            <StyledErrorWrapper key={key}>
              <StyledError>{error}</StyledError>
              <StyledDescription>
                {error === AllProductStatuses.OFFLINE
                  ? `There is no connection between ${productType} and Gateway`
                  : error === AllProductStatuses.RTCC_INVALID
                  ? `Connect with the ${productType} on the spot and update time`
                  : `Check ${productType} on the spot`}
              </StyledDescription>
            </StyledErrorWrapper>
          ))}
        </Dropdown>
      )}
    </StyledWrapper>
  );
}
