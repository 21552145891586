import styled from 'styled-components';
import Color from '../../constants/Color';

const Navigation = styled.nav`
  padding: 30px 10px;
  margin: 30px 0px;
  border-width: 1px 0px;
  border-style: solid;
  border-color: ${Color.BORDER_LIGHT};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default Navigation;
