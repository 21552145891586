import type BarrierUpdateFormValues from 'src/products/models/Barrier/BarrierUpdateFormValues';
import type BarrierUpdateParams from 'src/products/models/Barrier/BarrierUpdateParams;';

export default function toBarrierUpdateParams(
  data: BarrierUpdateFormValues
): BarrierUpdateParams {
  const { name } = data;

  return {
    name,
  };
}
