import type WeblinkUpdateParams from '../models/Weblink/WeblinkUpdateParams';
import type WeblinkUpdateValues from '../models/Weblink/WeblinkUpdateValues';

export default function toWeblinkUpdateParams(
  data: WeblinkUpdateValues
): WeblinkUpdateParams {
  const { ids, status } = data;

  return { ids, status };
}
