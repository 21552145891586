import React from 'react';
import styled, { css } from 'styled-components';
import Color from '../../constants/Color';

interface GlobalModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  popup?: boolean;
}

export default function GlobalModal(props: GlobalModalProps) {
  const { isOpen, children, popup } = props;
  return (
    <>
      {isOpen && (
        <StyledWrapper popup={popup}>
          <StyledContainer popup={popup}>{children}</StyledContainer>
        </StyledWrapper>
      )}
    </>
  );
}

const StyledContainer = styled.div<{ popup?: boolean }>`
  position: relative;
  border: 1px solid ${Color.BORDER_LIGHT};
  background-color: ${Color.BACKGROUND_LIGHTER};
  border-radius: 10px;
  padding: 60px;

  ${(props) => {
    const { popup } = props;

    if (popup) {
      return css`
        margin: 120px 0px;
        width: 680px;
        @media (max-width: 999px) {
          width: 75%;
        }
      `;
    }

    return css`
      margin: 80px 0px;
      width: 780px;
      @media (max-width: 999px) {
        width: 85%;
      }
    `;
  }}
`;

const StyledWrapper = styled.div<{ popup?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${Color.BACKGROUND_DARKEST_TRANSPARENT};
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 300;
  width: 100vw;
  height: 100vh;
`;
