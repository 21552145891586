import type DetectGateway from 'src/detection/models/DetectGateway';
import type DetectGatewayFormValues from 'src/detection/models/DetectGatewayFormValues';

export default function toDetectGatewayFormValues(
  values: DetectGateway
): DetectGatewayFormValues {
  const { name, uuid, externalId } = values;

  return {
    name,
    uuid,
    externalId,
  };
}
