import { useMemo } from 'react';
import AllProductStatuses from '../constants/AllProductStatuses';
import GatewayStatus from '../constants/GatewayStatus';

export default function useGatewayStatusLabel(status?: GatewayStatus) {
  return useMemo(() => {
    if (status === GatewayStatus.OFFLINE) {
      return AllProductStatuses.OFFLINE;
    }

    if (status === GatewayStatus.ONLINE) {
      return AllProductStatuses.ONLINE;
    }

    return AllProductStatuses.UNKNOWN;
  }, [status]);
}
