import { useField, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import ProductIconByProductTypeId from 'src/products/constants/ProductIconByProductTypeId';
import type Barrier from 'src/products/models/Barrier/Barrier';
import useBarrierService from 'src/products/services/useBarrierService';
import Button from 'src/shared/components/Button';
import ExitButton from 'src/shared/components/Buttons/ExitButton';
import CheckBoxField from 'src/shared/components/CheckBoxField';
import Control from 'src/shared/components/Control';
import DateTimeDiv from 'src/shared/components/DateTimeDiv';
import DateTimeField from 'src/shared/components/DateTimeField';
import Description from 'src/shared/components/Description';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import Section from 'src/shared/components/Section';
import SelectField from 'src/shared/components/SelectField';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import ButtonSize from 'src/shared/constants/ButtonSize';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import type Option from '../../shared/models/Option';

interface AddLicensePlateFormProps {
  closeParentModal: () => void;
  lotId: number | undefined;
}

export default function AllowLicensePlateForm(props: AddLicensePlateFormProps) {
  const { closeParentModal, lotId } = props;
  const { dirty } = useFormikContext();
  const [isUnlimited] = useField({ name: 'isUnlimited' });
  const [startTime] = useField({ name: 'startTime' });
  const [barriers, setBarriers] = useState<Barrier[]>([]);
  const [search, setSearch] = useState('');
  const { findAllBarriers } = useBarrierService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const findBarriers = async () => {
      if (!lotId) {
        return;
      }

      const response = await findAllBarriers({ lotId });

      if (isMounted()) {
        setBarriers(response.data);
      }
    };
    findBarriers();
  }, [findAllBarriers, isMounted, lotId]);

  const options: Option[] = useMemo(
    () =>
      barriers
        .filter(({ product }) =>
          product.name.toLowerCase().includes(search.toLowerCase())
        )
        .map(({ product }) => ({
          key: product.id,
          label: product.name,
          icon: ProductIconByProductTypeId[product.productTypeId],
        })),
    [barriers, search]
  );

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Section>
        <Title bold>Add license plate to white list</Title>
        <Description removeBottomMargin>
          Only whitelisted license plates will be able to enter this Parking
          lot. You can optionally add the email and name of the parking lot
          guests for easier management in the Lot Access section.
        </Description>
        <TextField
          autofocus
          label='License plate*'
          placeholder='ST123LL, ST1234A, ... (max.20)'
          type='text'
          name='licensePlates'
          stacked
        />
        <TextField
          label='Guest Email (optional)'
          placeholder='Email'
          type='text'
          name='email'
          stacked
          customWidth='50%'
        />
        <TextField
          label='Guest Name (optional)'
          placeholder='Name'
          type='text'
          name='name'
          stacked
          customWidth='45%'
        />
        <SelectField
          label='Barrier'
          placeholder='Barrier'
          name='barriers'
          options={options}
          search={search}
          onSearch={setSearch}
          stacked
          short
          multiple
          customWidth='50%'
        />
        <CheckBoxField label='Unlimited Access' name='isUnlimited' />
        <DateTimeDiv>
          <DateTimeField
            name='startTime'
            label='Start Time'
            placeholderDate='Start Date'
            placeholderTime='Start Time'
            minBookingDate={new Date()}
            minutesOffset={30}
          />
          <DateTimeField
            name='endTime'
            label='End Time'
            placeholderDate='End Date'
            placeholderTime='End Time'
            minBookingDate={new Date(startTime.value)}
            endTimeOffset
            disabled={isUnlimited.value}
          />
        </DateTimeDiv>
      </Section>
      <Line />
      <Control>
        <Button
          size={ButtonSize.LARGE}
          type='button'
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
        >
          Cancel
        </Button>
        <Button size={ButtonSize.LARGE} type='submit' primary>
          Save
        </Button>
      </Control>
    </>
  );
}
