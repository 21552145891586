import type ProductChangeSettings from 'src/lots/models/ProductChangeSettings';
import type ProductChangeSettingsResponse from 'src/lots/models/ProductChangeSettingsResponse';

export default function toProductChangeSettings(
  data: ProductChangeSettingsResponse
): ProductChangeSettings {
  const { success, event_id } = data;

  return { success, eventId: event_id };
}
