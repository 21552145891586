import type AnprRemoteControlLogQuery from 'src/anpr/models/AnprRemoteControlLogQuery';
import type AnprRemoteControlLogQueryValues from 'src/anpr/models/AnprRemoteControlLogQueryValues';

export default function toAnprRemoteControlLogQuery(
  data: AnprRemoteControlLogQueryValues
): AnprRemoteControlLogQuery {
  const {
    page,
    size,
    lotId,
    dateTo,
    dateFrom,
    accountUserId,
    accountUserName,
    lprCameraDeviceId,
    lprCameraName,
  } = data;

  return {
    lot_id: lotId,
    date_to: dateTo?.toISOString(),
    date_from: dateFrom?.toISOString(),
    account_user_id: accountUserId,
    account_user_name: accountUserName,
    lpr_camera_device_id: lprCameraDeviceId,
    lpr_camera_name: lprCameraName,
    page,
    size,
  };
}
