import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import type AnprRemoteControlLog from 'src/anpr/models/AnprRemoteControlLog';
import type AnprRemoteControlLogFilterValues from 'src/anpr/models/AnprRemoteControlLogFilterValues';
import { StyledIcon } from 'src/shared/components/Breadcrumb';
import StyledLink from 'src/shared/components/BreadcrumbLink';
import Card from 'src/shared/components/Card';
import Content from 'src/shared/components/Content';
import Form from 'src/shared/components/Form';
import Main from 'src/shared/components/Main';
import Pagination from 'src/shared/components/Pagination';
import RemoteControlLogsFilter from 'src/shared/components/RemoteControlLogsFilter';
import StyledNoData from 'src/shared/components/StyledNoData';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import PaginationSize from 'src/shared/constants/DataSize';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import { formatDate, formatTime } from 'src/shared/utils/formatDateTime';
import styled from 'styled-components';
import * as yup from 'yup';
import PaginationItemDisplay from '../../shared/constants/PaginationItemDisplay';
import useIsMounted from '../../shared/hooks/useIsMounted';
import type Meta from '../../shared/models/Meta';
import useLogsSerivce from '../services/useLogsSerivce';

const initialValues: AnprRemoteControlLogFilterValues = {
  dateFrom: undefined,
  dateTo: undefined,
  accountUserId: '',
  accountUserName: '',
  lprCameraDeviceId: '',
  lprCameraName: '',
};

const validationSchema = yup.object().shape({
  dateFrom: yup.date().nullable().notRequired(),
  accountUserName: yup
    .string()
    .min(3, 'Operator name should be at least 3 characters long'),
  lprCameraName: yup
    .string()
    .min(3, 'Camera name should be at least 3 characters long'),
  dateTo: yup
    .date()
    .nullable()
    .notRequired()
    .when('dateFrom', {
      is: (dateFrom: Date | null) => dateFrom !== null,
      then: yup
        .date()
        .nullable()
        .notRequired()
        .min(yup.ref('dateFrom'), "End Date can't be before Start Date"),
    }),
});

export default function RemoteControlLogs() {
  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<AnprRemoteControlLog[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);
  const { getAllAnprRemoteControlLogs } = useLogsSerivce();

  const { params } = useRouteMatch<{ lotId: string }>();
  const { lotId } = params;

  const [filterValues, setFilterValues] =
    useState<AnprRemoteControlLogFilterValues>({
      dateFrom: undefined,
      dateTo: undefined,
      accountUserId: '',
      accountUserName: '',
      lprCameraDeviceId: '',
      lprCameraName: '',
    });

  const getRemoteControlLogs = useCallback(
    async (page: number) => {
      try {
        if (!lotId) {
          return;
        }

        if (isMounted()) {
          setIsDataLoading(true);
        }

        const {
          dateFrom,
          dateTo,
          accountUserId,
          accountUserName,
          lprCameraDeviceId,
          lprCameraName,
        } = filterValues;

        const { data, meta } = await getAllAnprRemoteControlLogs({
          page,
          size: PaginationSize.STANDARD,
          lotId: parseInt(lotId),
          dateFrom,
          dateTo,
          accountUserId: accountUserId ? parseInt(accountUserId) : undefined,
          accountUserName: accountUserName || undefined,
          lprCameraDeviceId: lprCameraDeviceId || undefined,
          lprCameraName: lprCameraName || undefined,
        });

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [getAllAnprRemoteControlLogs, isMounted, filterValues, lotId]
  );

  useEffect(() => {
    getRemoteControlLogs(1);
  }, [getRemoteControlLogs]);

  const onSubmit = useCallback(
    async (values: AnprRemoteControlLogFilterValues) => {
      const {
        dateFrom,
        dateTo,
        accountUserId,
        accountUserName,
        lprCameraDeviceId,
        lprCameraName,
      } = values;

      if (isMounted()) {
        setFilterValues({
          dateFrom,
          dateTo,
          accountUserId,
          accountUserName,
          lprCameraDeviceId,
          lprCameraName,
        });
      }
    },
    [isMounted]
  );

  const onResetAllFilters = useCallback(() => {
    if (isMounted()) {
      setFilterValues({
        dateFrom: undefined,
        dateTo: undefined,
        accountUserId: '',
        accountUserName: '',
        lprCameraDeviceId: '',
        lprCameraName: '',
      });
    }
  }, [isMounted]);

  return (
    <>
      <Main>
        <StyledLink to={`/parking/lots/${lotId}/anpr`}>
          <StyledIcon className='pa-chevron-left' small />
          <Title>ANPR Remote Control Logs</Title>
        </StyledLink>
      </Main>
      <Main>
        <Content widthSize='27%'>
          <Form
            name='Filter'
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <RemoteControlLogsFilter onResetAllFilters={onResetAllFilters} />
          </Form>
        </Content>
        <Content widthSize='70%'>
          <Card>
            <Table isLoading={isDataLoading}>
              <Table.Head>
                <Table.Row>
                  <Table.Header>DATE</Table.Header>
                  <Table.Header>TIME</Table.Header>
                  <Table.Header>OPERATOR</Table.Header>
                  <Table.Header>CAMERA ID</Table.Header>
                  <Table.Header>CAMERA</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {data.length > 0 ? (
                  data.map((logs) => {
                    const {
                      id,
                      createdAt,
                      accountUserId,
                      accountUser,
                      lprCamera,
                    } = logs;

                    const { name: userName } = accountUser;
                    const { name: cameraName, deviceId: cameraDeviceId } =
                      lprCamera;

                    return (
                      <Table.Row key={id}>
                        <Table.Cell>{formatDate(createdAt)}</Table.Cell>
                        <Table.Cell>{formatTime(createdAt)}</Table.Cell>
                        <Table.Cell>
                          <StyledSpan>{accountUserId}</StyledSpan>
                          <span>{userName}</span>
                        </Table.Cell>
                        <Table.Cell>{cameraDeviceId}</Table.Cell>
                        <Table.Cell>{cameraName}</Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell merged={5}>
                      <StyledNoData>No Anpr Remote Control Logs.</StyledNoData>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card>
          {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
            <Pagination meta={meta} getData={getRemoteControlLogs} />
          )}
        </Content>
      </Main>
    </>
  );
}

const StyledSpan = styled.span`
  margin-right: 10px;
`;
