import type LprImageStorageSubscriptionHistoryQuery from 'src/anpr/models/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistoryQuery';
import type LprImageStorageSubscriptionHistoryQueryParams from 'src/anpr/models/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistoryQueryParams';

export default function toLprImageStorageSubscriptionHistoryQuery(
  data: LprImageStorageSubscriptionHistoryQueryParams
): LprImageStorageSubscriptionHistoryQuery {
  const { lotId, lprCameraId, accountId, expiryDateFrom } = data;

  return {
    lot_id: lotId,
    lpr_camera_id: lprCameraId,
    account_id: accountId,
    expiry_date_from: expiryDateFrom,
  };
}
