import React, { useCallback } from 'react';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import Section from 'src/shared/components/Section';
import Title from 'src/shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';

interface FinishProps {
  closeParentModal: () => void;
  refreshPage?: boolean;
}

export default function Finish(props: FinishProps) {
  const { closeParentModal, refreshPage } = props;

  const closeModal = useCallback(() => {
    closeParentModal();
    if (refreshPage) window.location.reload();
  }, [closeParentModal, refreshPage]);

  return (
    <>
      <Line />
      <Section>
        <Title bold center>
          Your account was successfully created!
        </Title>
      </Section>
      <Control center>
        <Button onClick={closeModal} size={ButtonSize.LARGE} type='button'>
          Close
        </Button>
      </Control>
    </>
  );
}
