import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useLprCamerasService from 'src/anpr/services/useLprCamerasService';
import type LotDetectionChangeEvent from 'src/detection/socketEvents/LotDetectionChangeEvent';
import type LotDetectionChangeEventPayload from 'src/detection/socketEvents/LotDetectionChangeEventPayload';
import toLotDetectionChangeEvent from 'src/lots/mappers/toLotDetectionChangeEvent';
import type Lot from 'src/lots/models/Lot';
import type LotOccupancyFormValues from 'src/lots/models/LotOccupancyFormValues';
import DisplayCounter from 'src/products/components/DisplayCounter';
import ProductList from 'src/products/screens/ProductList';
import Card from 'src/shared/components/Card';
import Content from 'src/shared/components/Content';
import Form from 'src/shared/components/Form';
import LoadingWrapper from 'src/shared/components/LoadingWrapper';
import Main from 'src/shared/components/Main';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import SelectFieldWithoutFormik from 'src/shared/components/SelectField/SelectFieldWithoutFormik';
import Spinner from 'src/shared/components/Spinner';
import StyledNoData from 'src/shared/components/StyledNoData';
import Title from 'src/shared/components/Title';
import SocketEvents from 'src/shared/constants/SocketEvents';
import SocketPath from 'src/shared/constants/SocketPath';
import useSocket from 'src/shared/hooks/useSocket';
import { isNotString } from 'src/shared/utils/checks';
import lotOccupancyValidationScheme from 'src/shared/validation/LotOccupancyValidationScheme';
import styled from 'styled-components';
import type LotInfoDropdown from '../../lots/models/LotInfoDropdown';
import useLotService from '../../lots/services/useLotService';
import ProductErrorTable from '../../products/components/ProductErrorTable';
import AppRole from '../../shared/constants/AppRole';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useUserHasRole from '../../shared/hooks/useUserHasRole';
import type Option from '../../shared/models/Option';
import AccountInvitationList from './AccountInvitationList';

const defaultLotValues: LotInfoDropdown = {
  lotInfo: null,
};

const defaultLotOccupancyValues: LotOccupancyFormValues = {
  occupiedCount: 0,
  spotCount: 0,
};

export default function PmsUserDashboard() {
  const [lotData, setLotData] = useState<Lot[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();
  const [totalData, setTotalData] = useState(0);
  const { findAllLots, updateLot } = useLotService();
  const { listAllLprCameras } = useLprCamerasService();
  const [lotId, setLotId] = useState<Option | null | undefined>(null);
  const [search, setSearch] = useState('');
  const [initialLotValues, setInitialLotValues] =
    useState<LotInfoDropdown>(defaultLotValues);
  const [initialLotOccupancyValues, setInitialLotOccupancyValues] =
    useState<LotOccupancyFormValues>(defaultLotOccupancyValues);
  const socket = useSocket(SocketPath.SOCKETIO);
  const [disableInputField, setDisableInputField] = useState(true);
  const [hasCamera, setHasCamera] = useState(false);

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const handleLotDetectionChange = (payload: string) => {
      try {
        const eventPayload: LotDetectionChangeEventPayload =
          JSON.parse(payload);
        const eventData: LotDetectionChangeEvent =
          toLotDetectionChangeEvent(eventPayload);

        const { lots } = eventData;

        const event = lots.find((event) => event.id === lotId?.key);
        if (!event) return;

        const { occupiedCount } = event;

        setLotData((oldData) => {
          const index = oldData.findIndex((old) => old.id === lotId?.key);
          if (index === -1) {
            return oldData;
          }
          oldData[index].occupiedCount = occupiedCount;
          return [...oldData];
        });
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };
    socket?.on(SocketEvents.LOT_DETECTION_CHANGE, handleLotDetectionChange);

    return () => {
      socket?.off(SocketEvents.LOT_DETECTION_CHANGE, handleLotDetectionChange);
    };
  }, [lotId?.key, openGlobalFailureModal, setMessage, socket]);

  const userCanSeeInvites = useMemo(() => {
    const roles = AppRole.PMS_SUPER_ADMIN | AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data, meta } = await findAllLots();

        if (isMounted()) {
          setLotData(data);
          if (data.length > 0) {
            const lotDetails = {
              lotInfo: {
                key: data[0].id,
                label: data[0].name,
              },
            };
            setInitialLotValues(lotDetails);
            setLotId({ key: data[0].id, label: data[0].name });
          }
          if (meta !== undefined) {
            setTotalData(meta.total);
          }
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [findAllLots, isMounted]);

  useEffect(() => {
    if (isMounted()) {
      const lot = lotData.find((lot) => lot.id === lotId?.key);
      if (!lot) {
        setInitialLotOccupancyValues({
          occupiedCount: 0,
          spotCount: 0,
        });
        return;
      }
      setInitialLotOccupancyValues({
        occupiedCount: lot.occupiedCount,
        spotCount: lot.spotCount,
      });
    }
  }, [lotData, lotId?.key, isMounted]);

  const options = useMemo(
    () =>
      lotData
        .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
        .map(({ id, name }) => ({
          key: id,
          label: name,
        })),
    [lotData, search]
  );

  const onDropdownChange = useCallback((value: Option | undefined) => {
    setLotId(value);
  }, []);

  const onSubmitLotOccupancyValues = useCallback(
    async (
      values: LotOccupancyFormValues,
      { setErrors }: FormikHelpers<LotOccupancyFormValues>
    ) => {
      try {
        const { occupiedCount, spotCount } = values;
        if (!lotId) return;
        const response = await updateLot(lotId.key, {
          occupiedCount,
          spotCount,
        });
        if (isMounted()) {
          setDisableInputField(true);
          setLotData((oldData) => {
            const index = oldData.findIndex((old) => old.id === lotId?.key);
            if (index === -1) {
              return oldData;
            }
            oldData[index].occupiedCount = response.occupiedCount;
            oldData[index].spotCount = response.spotCount;
            return [...oldData];
          });
        }
      } catch (error: any) {
        if (isMounted()) {
          setDisableInputField(false);
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [isMounted, lotId, updateLot, openGlobalFailureModal, setMessage]
  );

  useEffect(() => {
    const getCameraData = async () => {
      if (!lotId) {
        return;
      }

      const { data } = await listAllLprCameras({ lotId: lotId.key });

      if (isMounted()) {
        if (data.length > 0) {
          setHasCamera(true);
        } else {
          setHasCamera(false);
        }
      }
    };

    getCameraData();
  }, [isMounted, lotId, listAllLprCameras]);

  return (
    <Main left>
      <Content widthSize='70%'>
        {isDataLoading ? (
          <LoadingWrapper>
            <Spinner primary data-testid='dashboardSpinner' />
          </LoadingWrapper>
        ) : (
          <>
            <Title>Parking lot - {lotId?.label}</Title>
            <Card>
              {totalData > 0 ? (
                <StyledCardData>
                  <SelectFieldWithoutFormik
                    label='Lot'
                    placeholder='Lot'
                    name='lotInfo'
                    initialValue={initialLotValues.lotInfo}
                    options={options}
                    onSearch={setSearch}
                    search={search}
                    hideLabel
                    short
                    linkToLot
                    onChange={onDropdownChange}
                  />
                  {hasCamera && (
                    <StyledVehicleCountFormDiv>
                      <Form
                        name='counter'
                        initialValues={initialLotOccupancyValues}
                        validationSchema={lotOccupancyValidationScheme}
                        onSubmit={onSubmitLotOccupancyValues}
                      >
                        <DisplayCounter
                          setDisableInputField={setDisableInputField}
                          disableInputField={disableInputField}
                        />
                      </Form>
                    </StyledVehicleCountFormDiv>
                  )}
                </StyledCardData>
              ) : (
                <StyledNoData>
                  Currently, you don&#39;t have access to a Parking Lot.
                  <br />
                  Please contact your Administrator.
                </StyledNoData>
              )}
            </Card>
            {totalData > 0 && (
              <ProductList dashboardView hideTitle lotIdProps={lotId?.key} />
            )}
          </>
        )}
      </Content>
      <Content widthSize={'25%'}>
        <Title>Products need attention</Title>
        <ProductErrorTable />
        {userCanSeeInvites && <AccountInvitationList />}
      </Content>
    </Main>
  );
}

const StyledCardData = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`;
const StyledVehicleCountFormDiv = styled.div`
  width: 50%;

  @media (max-width: 1199px) {
    margin-top: 25px;
    width: 100%;
  }
`;
