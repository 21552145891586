import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import LprCameraStatus from 'src/anpr/constants/LprCameraStatus';
import type CameraUpdateFormValues from 'src/anpr/models/LprCamera/CameraUpdateFormValues';
import type LprCamera from 'src/anpr/models/LprCamera/LprCamera';
import Button from 'src/shared/components/Button';
import DeleteButton from 'src/shared/components/Buttons/DeleteButton';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import Spinner from 'src/shared/components/Spinner';
import TextField from 'src/shared/components/TextField';
import ButtonSize from 'src/shared/constants/ButtonSize';
import DetailsColumn from '../../products/components/DetailsColumn';
import DetailsData from '../../products/components/DetailsData';
import DetailsRow from '../../products/components/DetailsRow';
import DetailsStatus from '../../products/components/DetailsStatus';
import useCameraStatusLabel from '../hooks/useCameraStatusLabel';

interface CameraUpdateFormProps {
  details?: LprCamera;
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
  closeParentModal: () => void;
}

export default function CameraUpdateForm(props: CameraUpdateFormProps) {
  const { details, isLoading, openRemoveModal, closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext<CameraUpdateFormValues>();

  const onRemove = useCallback(() => {
    if (!details?.id) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details]);

  const shouldRenderDetails = !isLoading && details;

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });
  const statusLabel = useCameraStatusLabel(details?.status);

  const offline = details?.status === LprCameraStatus.OFFLINE;

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='ANPR Name'
              placeholder='Name'
              type='text'
              name='name'
              stacked
              short
            />
            <DetailsStatus offline={offline} addVerticalMargin>
              {statusLabel}
            </DetailsStatus>
            <Line />
            <DetailsData label='DEVICE ID' value={details.deviceId} />
            <Line />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <DeleteButton onClick={onRemove} />
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
