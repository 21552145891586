import type LotChangeEventPayload from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventPayload';
import type LotChangeEvent from '../models/SocketEvents/LotChangeEvent/LotChangeEvent';
import toLotChangeEventData from './toLotChangeEventData';

export default function toLotChangeEvent(
  eventData: LotChangeEventPayload
): LotChangeEvent {
  const { event_id, data } = eventData;

  return {
    eventId: event_id,
    data: toLotChangeEventData(data),
  };
}
