import { useField } from 'formik';
import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import Color from '../constants/Color';
import Error from './Error';
import Label from './Label';

interface TextAreaProps {
  name: string;
  label: string;
  placeholder: string;
  descriptionView?: boolean;
  readonly?: boolean;
}

export default function TextArea(props: TextAreaProps) {
  const { label, name, placeholder, descriptionView, readonly } = props;
  const [field, meta] = useField({ name });
  const ref = useRef<React.ElementRef<'textarea'>>(null);
  const { onBlur, onChange, value } = field;
  const { error, touched } = meta;
  const shouldShowError = error && touched;

  const focusInput = useCallback(() => {
    ref?.current?.focus();
  }, []);
  return (
    <StyledWrapper descriptionView={descriptionView}>
      <Label onClick={focusInput}>{label}</Label>
      <StyledColumn short>
        <StyledTextarea
          disabled={readonly}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          ref={ref}
          name={name}
          placeholder={placeholder}
          descriptionView={descriptionView}
        />
      </StyledColumn>
      {shouldShowError && <Error>*{error}</Error>}
    </StyledWrapper>
  );
}

const StyledColumn = styled.div<{
  short?: boolean;
}>`
    display: flex;
    flex-direction: column;

    ${(props) => {
      const { short } = props;

      if (short) {
        return css`
          width: 300px;
          @media (max-width: 500px) {
            width: 250px;
          }
        `;
      }

      return css`
        width: 100%;
      `;
    }}
    }}
`;

const StyledWrapper = styled.div<{ descriptionView?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  ${(props) => {
    const { descriptionView } = props;

    if (descriptionView) {
      return css`
        margin-top: 30px;
      `;
    }
    return css``;
  }}

  @media (max-width: 999px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }

  :last-child {
    margin-bottom: 40px;
  }
`;

const StyledTextarea = styled.textarea<{ descriptionView?: boolean }>`
  height: 145px;
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${Color.TEXT_DARKER};
  background-color: ${Color.BACKGROUND_LIGHTEST};
  width: 70%;
  resize: none;
  padding: 10px;
  &:disabled {
    background: transparent;
    border: 0;
  }
  ${(props) => {
    const { descriptionView } = props;

    if (descriptionView) {
      return css`
        width: 100%;
      `;
    }
    return css``;
  }}

  @media (max-width: 500px) {
    width: 75%;
  }

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${Color.TEXT_LIGHT};
  }
`;
