import { useCallback } from 'react';
import toCameraCredentials from 'src/anpr/mappers/toCameraCredentials';
import toCameraUpdateParams from 'src/anpr/mappers/toCameraUpdateParams';
import toLprCamera from 'src/anpr/mappers/toLprCamera';
import toLprCameraQuery from 'src/anpr/mappers/toLprCameraQuery';
import type CameraCredentials from 'src/anpr/models/LprCamera/CameraCredentials';
import type CameraCredentialsResponse from 'src/anpr/models/LprCamera/CameraCredentialsResponse';
import type CameraRebootRequest from 'src/anpr/models/LprCamera/CameraRebootRequest';
import type CameraRebootResponse from 'src/anpr/models/LprCamera/CameraRebootResponse';
import type CameraRemoveResponse from 'src/anpr/models/LprCamera/CameraRemoveResponse';
import type CameraUpdateFormValues from 'src/anpr/models/LprCamera/CameraUpdateFormValues';
import type CameraUpdateParams from 'src/anpr/models/LprCamera/CameraUpdateParams';
import type CameraUpdateResponse from 'src/anpr/models/LprCamera/CameraUpdateResponse';
import type LprCamera from 'src/anpr/models/LprCamera/LprCamera';
import type LprCameraQuery from 'src/anpr/models/LprCamera/LprCameraQuery';
import type LprCameraQueryParams from 'src/anpr/models/LprCamera/LprCameraQueryParams';
import type LprCameraResponse from 'src/anpr/models/LprCamera/LprCameraResponse';
import type ProductChangeState from 'src/lots/models/ProductChangeState';
import type ProductChangeStateRequest from 'src/lots/models/ProductChangeStateRequest';
import type ProductChangeStateResponse from 'src/lots/models/ProductChangeStateResponse';
import type ProductChangeStateValues from 'src/lots/models/ProductChangeStateValues';

import toProductChangeState from 'src/products/mappers/Product/toProductChangeState';
import toProductChangeStateRequest from 'src/products/mappers/Product/toProductChangeStateRequest';
import toMeta from 'src/shared/mappers/toMeta';
import type GetAllDetails from 'src/shared/models/GetAllDetails';
import useApiService from 'src/shared/services/useApiService';

const lprCamerasURL = '/lpr-cameras';

interface LprCamerasService {
  listAllLprCameras: (
    values: LprCameraQueryParams
  ) => Promise<GetAllDetails<LprCamera>>;
  changeLprCameraState: (
    id: number,
    values: ProductChangeStateValues
  ) => Promise<ProductChangeState>;
  findOneCamera: (id: number) => Promise<LprCamera>;
  removeCamera: (id: number) => Promise<CameraRemoveResponse>;
  updateCamera: (
    id: number,
    values: CameraUpdateFormValues
  ) => Promise<CameraUpdateResponse>;
  rebootCamera: (id: number) => Promise<CameraRebootResponse>;
  getCameraCredentials: (id: number) => Promise<CameraCredentials>;
}

export default function useLprCamerasService(): LprCamerasService {
  const { post, get, patch, remove } = useApiService();

  const listAllLprCameras = useCallback(
    async (values: LprCameraQueryParams) => {
      const requestBody = toLprCameraQuery(values);

      const response = await get<LprCameraResponse[], LprCameraQuery>(
        `${lprCamerasURL}`,
        requestBody
      );

      const data = response.data.map(toLprCamera);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const changeLprCameraState = useCallback(
    async (id: number, values: ProductChangeStateValues) => {
      const requestBody = toProductChangeStateRequest(values);
      const response = await post<
        ProductChangeStateRequest,
        ProductChangeStateResponse
      >(`${lprCamerasURL}/${id}/state`, requestBody);

      const data = toProductChangeState(response.data);

      return data;
    },
    [post]
  );

  const findOneCamera = useCallback(
    async (id: number) => {
      const response = await get<LprCameraResponse>(`${lprCamerasURL}/${id}`);

      return toLprCamera(response.data);
    },
    [get]
  );

  const updateCamera = useCallback(
    async (id: number, values: CameraUpdateFormValues) => {
      const params = toCameraUpdateParams(values);
      const response = await patch<CameraUpdateParams, CameraUpdateResponse>(
        `${lprCamerasURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeCamera = useCallback(
    async (id: number) => {
      const response = await remove<CameraRemoveResponse>(
        `${lprCamerasURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  const rebootCamera = useCallback(
    async (id: number) => {
      const response = await post<CameraRebootRequest, CameraRebootResponse>(
        `${lprCamerasURL}/${id}/reboot`,
        {}
      );

      return response;
    },
    [post]
  );

  const getCameraCredentials = useCallback(
    async (id: number) => {
      const response = await get<CameraCredentialsResponse>(
        `${lprCamerasURL}/${id}/informations`
      );

      const data = toCameraCredentials(response.data);

      return data;
    },
    [get]
  );

  return {
    listAllLprCameras,
    changeLprCameraState,
    findOneCamera,
    updateCamera,
    removeCamera,
    rebootCamera,
    getCameraCredentials,
  };
}
