import type DetectGatewayUpdateParams from 'src/detection/models/DetectGatewayUpdateParams';
import type DetectGatewayUpdateValues from 'src/detection/models/DetectGatewayUpdateValues';

export default function toDetectGatewayUpdateParams(
  values: DetectGatewayUpdateValues
): DetectGatewayUpdateParams {
  const { name, externalId, uuid } = values;

  return {
    name,
    uuid,
    external_id: externalId,
  };
}
