import { useFormikContext } from 'formik';
import React from 'react';
import type DetectGatewayFormValues from 'src/detection/models/DetectGatewayFormValues';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import TextField from 'src/shared/components/TextField';
import ButtonSize from 'src/shared/constants/ButtonSize';
import DetailsColumn from '../../products/components/DetailsColumn';
import DetailsRow from '../../products/components/DetailsRow';

interface DetectGatewayAddFormProps {
  closeParentModal: () => void;
}
export default function DetectGatewayAddForm(props: DetectGatewayAddFormProps) {
  const { closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext<DetectGatewayFormValues>();

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      <DetailsRow>
        <DetailsColumn>
          <TextField
            label='NAME'
            placeholder='Name'
            type='text'
            name='name'
            short
            stacked
          />
        </DetailsColumn>
      </DetailsRow>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
