import styled, { css } from 'styled-components';
import Color from '../constants/Color';

const Line = styled.div<{ customWidth?: string; addMargin?: boolean }>`
  height: 1px;
  background-color: ${Color.BORDER_LIGHT};
  margin: 0px;

  ${(props) => {
    const { customWidth } = props;

    if (customWidth) {
      return css`
        width: ${customWidth};
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { addMargin } = props;

    if (addMargin) {
      return css`
        margin-top: 30px;
      `;
    }
    return css``;
  }}
`;

export default Line;
