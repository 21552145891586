import SelectAllFilterValue from 'src/shared/constants/SelectAllFilterValue';

enum SharedKeysStatusValues {
  ALL = SelectAllFilterValue,
  ACTIVE = 0,
  BLOCKED = 1,
  EXPIRED = 2,
  INVALID = 3,
  DELETED = 4,
}

export default SharedKeysStatusValues;
