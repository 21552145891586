import type QRCodeQuery from '../models/QRCodeQuery';
import type QRCodeQueryParams from '../models/QRCodeQueryParams';

export default function toQRCodeQuery(data: QRCodeQueryParams): QRCodeQuery {
  const { page, size, lotId, productId, startTime, endTime } = data;

  return {
    page,
    size,
    lot_id: lotId,
    product_id: productId,
    start_time: startTime,
    end_time: endTime,
  };
}
