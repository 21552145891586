import styled, { css } from 'styled-components';
import Color from '../constants/Color';

interface TitleProps {
  bold?: boolean;
  center?: boolean;
  short?: boolean;
  anpr?: boolean;
  tabModalView?: boolean;
  addTopMargin?: boolean;
  addBottomMargin?: boolean;
  endAlignment?: boolean;
  addCustomMargin?: string;
}

const Title = styled.h1<TitleProps>`
  font-family: Montserrat;
  font-style: normal;
  font-size: 26px;
  line-height: 32px;
  color: ${Color.TEXT_DARKER};
  margin: 30px 0px 0px 0px;

  ${(props) => {
    const { short } = props;

    if (short) {
      return css`
        margin: 0px 0px 0px 0px;
        font-size: 22px;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { anpr } = props;

    if (anpr) {
      return css`
        padding: 0 20px;
      `;
    }
    return css``;
  }}  
    
      ${(props) => {
    const { center } = props;

    if (center) {
      return css`
        text-align: center;
      `;
    }

    return css`
      text-align: left;
    `;
  }}

    ${(props) => {
    const { bold } = props;

    if (bold) {
      return css`
        font-weight: bold;
      `;
    }

    return css`
      font-weight: normal;
    `;
  }}
  
  ${(props) => {
    const { addTopMargin } = props;

    if (addTopMargin) {
      return css`
        margin: 76px 0 0 0;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { addBottomMargin } = props;

    if (addBottomMargin) {
      return css`
        margin: 0 0 30px 0;
        width: 100%;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { addCustomMargin } = props;

    if (addCustomMargin) {
      return css`
        margin: ${addCustomMargin};
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { endAlignment } = props;

    if (endAlignment) {
      return css`
        display: flex;
        align-items: end;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { tabModalView } = props;

    if (tabModalView) {
      return css`
        position: absolute;
        top: 75px;
        align-items: center;
        height: 40px
        display: flex;
        margin-top: 5px;

        @media (max-width: 700px) {
          position: initial;
        }
    `;
    }
    return css``;
  }}
`;

export default Title;
