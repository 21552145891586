import type BrainFormValues from 'src/products/models/Brain/BrainFormValues';
import type BrainUpdateParams from 'src/products/models/Brain/BrainUpdateParams';

export default function toBrainUpdateParams(
  data: BrainFormValues
): BrainUpdateParams {
  return {
    name: data.name,
  };
}
