import type LotEntryQuery from 'src/anpr/models/LotEntries/LotEntryQuery';
import type { LotEntryQueryValues } from 'src/anpr/models/LotEntries/LotEntryQueryValues';

export default function toLotEntryQuery(
  data: LotEntryQueryValues
): LotEntryQuery {
  const { page, size, lotId, licensePlate, startTime, endTime, camera } = data;

  return {
    lot_id: lotId,
    license_plate: licensePlate,
    start_time: startTime?.toISOString(),
    end_time: endTime?.toISOString(),
    page,
    size,
    direction: camera,
  };
}
