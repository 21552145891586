import styled, { css } from 'styled-components';
import Color from '../constants/Color';

const StyledInput = styled.input<{ removeMaring?: boolean }>`
  height: 36px;
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding: 0px 14px;
  color: ${Color.TEXT_DARKER};
  background-color: ${Color.BACKGROUND_LIGHTEST};
  width: 40%;
  margin: 30px 0;

  :first-child {
    margin-right: 5px;
  }

  :last-child {
    margin-top: 0px;
  }

  @media (max-width: 500px) {
    width: 75%;
  }

  :focus {
    outline: none;
  }

  ${(props) => {
    const { removeMaring } = props;
    if (removeMaring) {
      return css`
        margin: 0;
      `;
    }
    return css``;
  }};
  ::placeholder {
    color: ${Color.TEXT_LIGHT};
  }
`;

export default StyledInput;
