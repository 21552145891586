import type InvitationKey from '../models/InvitationKey/InvitationKey';
import type InvitationKeyResponse from '../models/InvitationKey/InvitationKeyResponse';
import toKeyProduct from './toKeyProduct';

export default function toInvitationKey(
  data: InvitationKeyResponse
): InvitationKey {
  const {
    id,
    sender_id,
    receiver_identifier,
    product_id,
    start_time,
    end_time,
    status,
    activated_at,
    created_at,
    updated_at,
    product,
  } = data;

  return {
    id,
    senderId: sender_id,
    receiverIdentifier: receiver_identifier,
    productId: product_id,
    startTime: start_time,
    endTime: end_time,
    status,
    activatedAt: activated_at,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    product: toKeyProduct(product),
  };
}
