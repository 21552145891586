import toTimeZone from '../../shared/mappers/toTimeZone';
import type QRCodeCreateRequest from '../models/QRCodeCreateRequest';
import type QRCodeSharingFormValues from '../models/QRCodes/QRCodeSharingFormValues';

export default function toQRCodeCreateRequest(
  productId: number,
  timezone: number,
  data: QRCodeSharingFormValues
): QRCodeCreateRequest {
  const { startTime, endTime, isUnlimited } = data;

  const responseBody: QRCodeCreateRequest = {
    product_id: productId,
    start_time: startTime ? startTime.toISOString() : '',
    end_time: isUnlimited ? null : endTime ? endTime.toISOString() : '',
    timezone: toTimeZone(timezone),
  };
  return responseBody;
}
