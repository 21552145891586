import { useCallback } from 'react';
import SessionStorageKey from '../constants/SessionStorageKey';
import type SessionData from '../models/SessionData';

interface TokenService {
  getValidToken: () => Promise<string | undefined>;
  getSessionData: () => SessionData | undefined;
  setSessionData: (sessionData: SessionData) => void;
  removeSessionData: () => void;
}

export default function useSessionService(): TokenService {
  const setSessionData = useCallback((sessionData: SessionData) => {
    const { token, role, name, email, id, userId } = sessionData;

    sessionStorage.setItem(SessionStorageKey.TOKEN, token);
    sessionStorage.setItem(SessionStorageKey.ROLE, role.toString());
    sessionStorage.setItem(SessionStorageKey.NAME, name);
    sessionStorage.setItem(SessionStorageKey.EMAIL, email);
    sessionStorage.setItem(SessionStorageKey.ID, id.toString());
    sessionStorage.setItem(SessionStorageKey.USER, userId.toString());
  }, []);

  const getSessionData = useCallback((): SessionData | undefined => {
    const token = sessionStorage.getItem(SessionStorageKey.TOKEN);
    const roleString = sessionStorage.getItem(SessionStorageKey.ROLE);
    const name = sessionStorage.getItem(SessionStorageKey.NAME);
    const email = sessionStorage.getItem(SessionStorageKey.EMAIL);
    const idString = sessionStorage.getItem(SessionStorageKey.ID);
    const userIdString = sessionStorage.getItem(SessionStorageKey.USER);

    if (
      !token ||
      !roleString ||
      !name ||
      !email ||
      !idString ||
      !userIdString
    ) {
      return undefined;
    }

    const role = parseInt(roleString);
    const id = parseInt(idString);
    const userId = parseInt(userIdString);

    return { token, role, name, email, id, userId };
  }, []);

  const removeSessionData = useCallback(() => {
    sessionStorage.removeItem(SessionStorageKey.TOKEN);
    sessionStorage.removeItem(SessionStorageKey.ROLE);
    sessionStorage.removeItem(SessionStorageKey.NAME);
    sessionStorage.removeItem(SessionStorageKey.EMAIL);
    sessionStorage.removeItem(SessionStorageKey.ID);
    sessionStorage.removeItem(SessionStorageKey.USER);
  }, []);

  const getValidToken = useCallback(async () => {
    const sessionData = getSessionData();

    return sessionData?.token;
  }, [getSessionData]);

  return { getSessionData, setSessionData, getValidToken, removeSessionData };
}
