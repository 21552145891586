import React, { useMemo } from 'react';
import DeviceTypes from 'src/products/constants/DeviceTypes';
import styled from 'styled-components';
import ProductIcon from '../constants/ProductIcon';

interface ProductTableIconProps {
  icon?: ProductIcon;
  iconType?: number;
  iconString?: DeviceTypes;
}

enum Icons {
  'pa-gateway' = 0,
  'pa-barrier' = 2,
  'pa-gate' = 3,
  'pa-brain' = 4,
  'pa-chain' = 5,
  'pa-bollard' = 6,
}

export default function ProductTableIcon(props: ProductTableIconProps) {
  const { icon, iconType, iconString } = props;

  const iconClass = useMemo(() => {
    if (icon) {
      return icon;
    }
    if (iconType) {
      return Icons[iconType];
    }
    if (iconString) {
      switch (iconString) {
        case DeviceTypes.GATE:
          return ProductIcon.GATE;
        case DeviceTypes.CHAIN:
          return ProductIcon.CHAIN;
        case DeviceTypes.BOLLARD:
          return ProductIcon.BOLLARD;
        default:
          return '';
      }
    }
    return '';
  }, [icon, iconType, iconString]);

  return <StyledIcon className={iconClass} />;
}

const StyledIcon = styled.i`
  font-family: ParklioIcons;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  display: inline;
`;
