import React, { useEffect, useState } from 'react';
import useAccountService from 'src/home/services/useAccountService';
import { ProductLicensesLabels } from 'src/license/constants/LicenseCountConstants';
import type AccountProductLicenses from 'src/product-license/models/AccountProductLicenses';
import type AccountProductLicensesCount from 'src/product-license/models/AccountProductLicensesCount';

import Card from 'src/shared/components/Card';
import StyledLink from 'src/shared/components/Link';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import Color from 'src/shared/constants/Color';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import styled from 'styled-components';

interface LicenseData {
  label: string;
  usedProducts: number;
  purchasedProducts: number;
}

export default function PmsLicenseTable() {
  const { getAccountProductLicensesCount, getAccountProductLicenses } =
    useAccountService();
  const [licenseData, setlicenseData] = useState<LicenseData[]>([]);
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const findLicenses = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }
        const countResponse = await getAccountProductLicensesCount();
        const licenseResponse = await getAccountProductLicenses();
        if (isMounted()) {
          setIsLoading(false);

          const combinedLicenses: LicenseData[] = [];
          if (!countResponse || !licenseResponse) return;

          for (const key in countResponse) {
            if (key in licenseResponse) {
              combinedLicenses.push({
                label: ProductLicensesLabels[key],
                usedProducts:
                  countResponse[key as keyof AccountProductLicensesCount],
                purchasedProducts:
                  licenseResponse[key as keyof AccountProductLicenses],
              });
            }
          }
          setlicenseData(
            combinedLicenses.sort(function (a, b) {
              return a.label.localeCompare(b.label);
            })
          );
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };
    findLicenses();
  }, [getAccountProductLicensesCount, getAccountProductLicenses, isMounted]);
  return (
    <>
      <Title>Licenses</Title>
      <Card>
        <Table isLoading={isLoading}>
          <Table.Body>
            {licenseData.length > 0 ? (
              licenseData.map((license) => {
                const { label, usedProducts, purchasedProducts } = license;
                return (
                  <Table.Row key={label}>
                    <Table.Cell>{label}</Table.Cell>
                    <Table.Cell invalid={usedProducts >= purchasedProducts}>
                      {usedProducts} / {purchasedProducts}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell>{'No licenses'}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
      <StyledDiv>
        <StyledText>
          For an upgrade of your license plan, contact our{' '}
          <StyledLink href='mailto:support@parklio.com'>
            customer support
          </StyledLink>
          .
        </StyledText>
      </StyledDiv>
    </>
  );
}

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledText = styled.p`
  color: ${Color.TEXT_DARKER};
  font-size: 14px;
`;
