import { useCallback } from 'react';
import toRotary from 'src/rotaries/mappers/toRotary';
import toRotaryCreateRequest from 'src/rotaries/mappers/toRotaryCreateRequest';
import toRotaryQuery from 'src/rotaries/mappers/toRotaryQuery';
import toRotaryUpdateParams from 'src/rotaries/mappers/toRotaryUpdateParams';
import type Rotary from 'src/rotaries/models/Rotary';
import type RotaryCreateRequest from 'src/rotaries/models/RotaryCreateRequest';
import type RotaryCreateResponse from 'src/rotaries/models/RotaryCreateResponse';
import type RotaryFormValues from 'src/rotaries/models/RotaryFormValues';
import type RotaryQuery from 'src/rotaries/models/RotaryQuery';
import type RotaryQueryParams from 'src/rotaries/models/RotaryQueryParams';
import type RotaryRemoveResponse from 'src/rotaries/models/RotaryRemoveResponse';
import type RotaryResponse from 'src/rotaries/models/RotaryResponse';
import type RotaryUpdateParams from 'src/rotaries/models/RotaryUpdateParams';
import type RotaryUpdateResponse from 'src/rotaries/models/RotaryUpdateResponse';
import type RotaryUpdateValues from 'src/rotaries/models/RotaryUpdateValues';
import toMeta from 'src/shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';

const rotaryBaseURL = '/rotaries';
interface RotaryService {
  findAllRotaries: (
    queryParams?: RotaryQueryParams
  ) => Promise<GetAllDetails<Rotary>>;
  findOneRotary: (id: number) => Promise<Rotary>;
  createRotary: (
    values: RotaryFormValues,
    lotId?: number
  ) => Promise<RotaryCreateResponse>;
  updateRotary: (
    id: number,
    values: RotaryUpdateValues,
    lotId?: number
  ) => Promise<RotaryUpdateResponse>;
  removeRotary: (id: number) => Promise<RotaryRemoveResponse>;
}

export default function useRotaryService(): RotaryService {
  const { post, get, remove, patch } = useApiService();

  const findAllRotaries = useCallback(
    async (queryParams?: RotaryQueryParams) => {
      const query = toRotaryQuery(queryParams);
      const response = await get<RotaryResponse[], RotaryQuery>(
        `${rotaryBaseURL}`,
        query
      );

      const data = response.data.map(toRotary);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findOneRotary = useCallback(
    async (id: number) => {
      const response = await get<RotaryResponse>(`${rotaryBaseURL}/${id}`);

      return toRotary(response.data);
    },
    [get]
  );

  const createRotary = useCallback(
    async (values: RotaryFormValues, lotId?: number) => {
      const requestBody = toRotaryCreateRequest(values, lotId);
      const response = await post<RotaryCreateRequest, RotaryCreateResponse>(
        `${rotaryBaseURL}`,
        requestBody
      );

      return response;
    },
    [post]
  );

  const updateRotary = useCallback(
    async (id: number, values: RotaryUpdateValues, lotId?: number) => {
      const params = toRotaryUpdateParams(values, lotId);
      const response = await patch<RotaryUpdateParams, RotaryUpdateResponse>(
        `${rotaryBaseURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeRotary = useCallback(
    async (id: number) => {
      const response = await remove<RotaryRemoveResponse>(
        `${rotaryBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return {
    findAllRotaries,
    findOneRotary,
    createRotary,
    updateRotary,
    removeRotary,
  };
}
