import type Lot from 'src/lots/models/Lot';
import type LotResponse from 'src/lots/models/LotResponse';

export default function toLot(data: LotResponse): Lot {
  const {
    id,
    name,
    address,
    description,
    time_zone,
    latitude,
    longitude,
    account_id,
    spot_count,
    occupied_count,
    detection_status,
    detection_implementation_status,
    lot_state_representation_vector,
    created_at,
    updated_at,
    deleted_at,
  } = data;

  return {
    id,
    name,
    address,
    description,
    timeZone: time_zone ? time_zone : new Date().getTimezoneOffset() / -60,
    latitude,
    longitude,
    accountId: account_id,
    spotCount: spot_count,
    occupiedCount: occupied_count,
    detectionStatus: detection_status,
    detectionImplementationStatus: detection_implementation_status,
    lotStateRepresentationVector: lot_state_representation_vector
      ? JSON.parse(lot_state_representation_vector)
      : undefined,
    createdAt: created_at,
    updatedAt: updated_at,
    deletedAt: deleted_at,
  };
}
