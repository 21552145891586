import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GatewayPort from 'src/products/constants/GatewayPort';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import useIsGatewayAvailableService from 'src/shared/services/useIsGatewayAvailableService';
import Button from '../../shared/components/Button';
import Control from '../../shared/components/Control';
import Line from '../../shared/components/Line';
import { useGlobalCancelModal } from '../../shared/components/Modals/GlobalCancelModal';
import Spinner from '../../shared/components/Spinner';
import TextField from '../../shared/components/TextField';
import ButtonSize from '../../shared/constants/ButtonSize';

import GatewayStatus from '../constants/GatewayStatus';
import useGatewayStatusLabel from '../hooks/useGatewayStatusLabel';
import type Gateway from '../models/Gateway/Gateway';
import type GatewayFormValues from '../models/Gateway/GatewayFormValues';
import DetailsColumn from './DetailsColumn';
import DetailsData from './DetailsData';
import DetailsRemove from './DetailsRemove';
import DetailsRow from './DetailsRow';
import DetailsStatus from './DetailsStatus';

interface GatewayDetailsPorps {
  details?: Gateway;
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
  closeParentModal: () => void;
}
export default function GatewayUpdateForm(props: GatewayDetailsPorps) {
  const { details, isLoading, openRemoveModal, closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext<GatewayFormValues>();
  const statusLabel = useGatewayStatusLabel(details?.status);
  const [url, setUrl] = useState('');
  const [isReachable, setIsReachable] = useState<boolean | undefined>(
    undefined
  );
  const { get } = useIsGatewayAvailableService();
  const isMounted = useIsMounted();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const onRemove = useCallback(() => {
    if (!details?.id) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details]);

  const shouldRenderDetails = !isLoading && details;
  const offline = details?.status === GatewayStatus.OFFLINE;

  useEffect(() => {
    const isIpReachable = async () => {
      try {
        if (!details) {
          return;
        }

        const { ipAddress } = details;

        if (ipAddress === '') {
          return;
        }

        const address = `http://${ipAddress}:${GatewayPort.GATEWAY_APP_PORT}`;

        await get(address);

        if (isMounted()) {
          setUrl(address);
          setIsReachable(true);
        }
      } catch (error) {
        if (isMounted()) {
          setIsReachable(false);
        }
        throw error;
      }
    };
    isIpReachable();
  }, [details, get, isMounted]);

  const ipAddressValue = useMemo(() => {
    if (!details || isReachable === undefined) {
      return 'Unknown';
    }

    if (isReachable === true) {
      return details.ipAddress;
    }

    return 'Unreachable via local network';
  }, [details, isReachable]);

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='Name'
              placeholder='Name'
              type='text'
              name='name'
              stacked
              short
            />
            <DetailsStatus offline={offline} addVerticalMargin>
              {statusLabel}
            </DetailsStatus>
            <Line />
            <DetailsData label='UUID' value={details.uuid} />
            {false && (
              <>
                <DetailsData
                  label='FIRMWARE'
                  value={details?.firmwareVersion || ''}
                />
                <DetailsData
                  label='IP ADDRESS'
                  value={ipAddressValue}
                  linkValue={isReachable ? url : false}
                />
              </>
            )}
            <Line />
            <DetailsRemove onRemove={onRemove} />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
