import type { FormikHelpers } from 'formik';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type AccountUserInvitationConfirmationFormValues from 'src/home/models/AccountUserInvitationConfirmationFormValues';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import PasswordCreationErrorMessages from 'src/shared/constants/PasswordCreationErrorMessages';
import { isNotString } from 'src/shared/utils/checks';
import * as yup from 'yup';
import useAccountUserInvitationService from '../../home/services/useAccountUserInvitationService';
import AccountUserInvitationConfirmationForm from '../components/AccountUserInvitationConfirmationForm';
import Container from '../components/Container';
import Form from '../components/Form';
import ParklioSign from '../components/ParklioSign';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';

const initialValues: AccountUserInvitationConfirmationFormValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(PasswordCreationErrorMessages.REQUIRED)
    .min(8, PasswordCreationErrorMessages.MIN_LEN)
    .matches(/^(?=.*[a-z])/, PasswordCreationErrorMessages.LOWERCASE)
    .matches(/^(?=.*[A-Z])/, PasswordCreationErrorMessages.UPPERCASE)
    .matches(/^(?=.*[0-9])/, PasswordCreationErrorMessages.NUMBER)
    .matches(
      /[!?@#$%^&*()\-/_"'=+{};:,<.>]/,
      PasswordCreationErrorMessages.SPECIAL_CHAR
    ),
  passwordConfirmation: yup
    .string()
    .required('Confirm New Password is a required field')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
});

export default function AccountUserInvitationConfirmation() {
  const { accountUserInvitationConfirmation } =
    useAccountUserInvitationService();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;

  const { email, token, signature } = qs.parse(search, {
    decoder: (decode) => decode,
    ignoreQueryPrefix: true,
  }) as { [key: string]: string };

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (
      values: AccountUserInvitationConfirmationFormValues,
      { setErrors }: FormikHelpers<AccountUserInvitationConfirmationFormValues>
    ) => {
      try {
        await accountUserInvitationConfirmation(
          email,
          token,
          signature,
          values
        );

        history.push('/');
      } catch (error: any) {
        if (isNotString(error) && error.code === undefined) {
          setErrors(error);
          return;
        }
        setMessage(error);
        openGlobalFailureModal();
      }
    },
    [
      accountUserInvitationConfirmation,
      email,
      token,
      signature,
      history,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  return (
    <Container>
      <ParklioSign />
      <Title>Create account</Title>
      <Subtitle>
        Your username is: <br /> {email}
      </Subtitle>
      <Form
        name='accountUserInvitationConfirmation'
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <AccountUserInvitationConfirmationForm />
      </Form>
    </Container>
  );
}
