import { useCallback } from 'react';

import toGate from 'src/products/mappers/Gate/toGate';
import toGateQuery from 'src/products/mappers/Gate/toGateQuery';
import toGateUpdateParams from 'src/products/mappers/Gate/toGateUpdateParams';
import type Gate from 'src/products/models/Gate/Gate';
import type GateFormValues from 'src/products/models/Gate/GateFormValues';
import type GateQuery from 'src/products/models/Gate/GateQuery';
import type GateQueryParams from 'src/products/models/Gate/GateQueryParams';
import type GateRemoveResponse from 'src/products/models/Gate/GateRemoveResponse';
import type GateResponse from 'src/products/models/Gate/GateResponse';
import type GateUpdateParams from 'src/products/models/Gate/GateUpdateParams';
import type GateUpdateResponse from 'src/products/models/Gate/GateUpdateResponse';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';

const gateBaseURL = '/gates';
interface GateService {
  findAllGates: (queryParams: GateQueryParams) => Promise<GetAllDetails<Gate>>;
  findOneGate: (id: number) => Promise<Gate>;
  updateGate: (
    id: number,
    values: GateFormValues
  ) => Promise<GateUpdateResponse>;
  removeGate: (id: number) => Promise<GateRemoveResponse>;
}

export default function useGateService(): GateService {
  const { get, remove, patch } = useApiService();

  const findAllGates = useCallback(
    async (queryParams: GateQueryParams) => {
      const query = toGateQuery(queryParams);
      const response = await get<GateResponse[], GateQuery>(
        `${gateBaseURL}`,
        query
      );

      const data = response.data.map(toGate);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findOneGate = useCallback(
    async (id: number) => {
      const response = await get<GateResponse>(`${gateBaseURL}/${id}`);

      const data = toGate(response.data);

      return data;
    },
    [get]
  );

  const updateGate = useCallback(
    async (id: number, values: GateFormValues) => {
      const params = toGateUpdateParams(values);
      const response = await patch<GateUpdateParams, GateUpdateResponse>(
        `${gateBaseURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeGate = useCallback(
    async (id: number) => {
      const response = await remove<GateRemoveResponse>(`${gateBaseURL}/${id}`);

      return response;
    },
    [remove]
  );

  return { findAllGates, findOneGate, updateGate, removeGate };
}
