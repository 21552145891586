import React from 'react';
import ButtonSize from '../../constants/ButtonSize';
import { useGlobalModal } from '../../hooks/useGlobalModal';
import Button from '../Button';
import Control from '../Control';
import Line from '../Line';
import Text from '../Text';
import GlobalModal from './GlobalModal';

interface GlobalCancelModalProps {
  closeParentModal: () => void;
}
export function useGlobalCancelModal(props: GlobalCancelModalProps) {
  const { closeParentModal } = props;
  const [openGlobalCancelModal, closeGlobalCancelModal] = useGlobalModal(() => (
    <GlobalModal isOpen popup>
      <>
        <Text>
          Are you sure you want to close modal? All changes will be discarded.
        </Text>
        <Line />
        <Control center>
          <Button
            onClick={closeGlobalCancelModal}
            size={ButtonSize.LARGE}
            type='button'
          >
            Cancel
          </Button>
          <Button
            autofocus
            onClick={closeParentModal}
            size={ButtonSize.LARGE}
            type='button'
            primary
          >
            Ok
          </Button>
        </Control>
      </>
    </GlobalModal>
  ));

  return { openGlobalCancelModal, closeGlobalCancelModal };
}
