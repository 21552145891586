import type InvitationKeyResendParams from '../models/InvitationKey/InvitationKeyResendParams';
import type InvitationKeyResendValues from '../models/InvitationKey/InvitationKeyResendValues';

export default function toInvitationKeyResendParams(
  data: InvitationKeyResendValues
): InvitationKeyResendParams {
  const { ids } = data;

  return { ids };
}
