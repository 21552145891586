import { useCallback } from 'react';
import toChain from 'src/products/mappers/Chain/toChain';
import toChainQuery from 'src/products/mappers/Chain/toChainQuery';
import toChainUpdateParams from 'src/products/mappers/Chain/toChainUpdateParams';
import type Chain from 'src/products/models/Chain/Chain';
import type ChainFormValues from 'src/products/models/Chain/ChainFormValues';
import type ChainQuery from 'src/products/models/Chain/ChainQuery';
import type ChainQueryParams from 'src/products/models/Chain/ChainQueryParams';
import type ChainRemoveResponse from 'src/products/models/Chain/ChainRemoveResponse';
import type ChainResponse from 'src/products/models/Chain/ChainResponse';
import type ChainUpdateParams from 'src/products/models/Chain/ChainUpdateParams';
import type ChainUpdateResponse from 'src/products/models/Chain/ChainUpdateResponse';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';

const chainBaseURL = '/chains';
interface ChainService {
  findAllChains: (
    queryParams: ChainQueryParams
  ) => Promise<GetAllDetails<Chain>>;
  findOneChain: (id: number) => Promise<Chain>;
  updateChain: (
    id: number,
    values: ChainFormValues
  ) => Promise<ChainUpdateResponse>;
  removeChain: (id: number) => Promise<ChainRemoveResponse>;
}

export default function useChainService(): ChainService {
  const { get, remove, patch } = useApiService();

  const findAllChains = useCallback(
    async (queryParams: ChainQueryParams) => {
      const query = toChainQuery(queryParams);
      const response = await get<ChainResponse[], ChainQuery>(
        `${chainBaseURL}`,
        query
      );

      const data = response.data.map(toChain);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findOneChain = useCallback(
    async (id: number) => {
      const response = await get<ChainResponse>(`${chainBaseURL}/${id}`);

      const data = toChain(response.data);

      return data;
    },
    [get]
  );

  const updateChain = useCallback(
    async (id: number, values: ChainFormValues) => {
      const params = toChainUpdateParams(values);
      const response = await patch<ChainUpdateParams, ChainUpdateResponse>(
        `${chainBaseURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeChain = useCallback(
    async (id: number) => {
      const response = await remove<ChainRemoveResponse>(
        `${chainBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return { findAllChains, findOneChain, updateChain, removeChain };
}
