import React from 'react';
import Button from '../../shared/components/Button';
import Card from '../../shared/components/Card';
import Control from '../../shared/components/Control';
import StyledNoData from '../../shared/components/StyledNoData';
import Table from '../../shared/components/Table';
import Title from '../../shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';
import type Manager from '../models/Manager';

interface ManagerTableProps {
  manageUsers: () => void;
  data: Manager[];
  isLoading?: boolean;
}

export default function ManagerTable(props: ManagerTableProps) {
  const { data, manageUsers, isLoading } = props;

  return (
    <>
      <Title>Parking Managers</Title>
      <Card>
        <Control>
          <Button size={ButtonSize.MIDDLE} onClick={manageUsers}>
            Parking Managers
          </Button>
        </Control>
      </Card>
      <Card>
        <Table isLoading={isLoading}>
          <Table.Head>
            <Table.Row>
              <Table.Header>USER</Table.Header>
              <Table.Header>EMAIL</Table.Header>
              <Table.Header>PHONE</Table.Header>
              <Table.Header>ROLE</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.length > 0 ? (
              data.map((managerFindAllDetails) => {
                const { accountUser } = managerFindAllDetails;

                const {
                  id,
                  name,
                  email,
                  phone,
                  role: { label: roleLabel },
                } = accountUser;

                return (
                  <Table.Row key={id}>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{email}</Table.Cell>
                    <Table.Cell>{phone}</Table.Cell>
                    <Table.Cell>{roleLabel}</Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell merged={4}>
                  <StyledNoData>No operators added.</StyledNoData>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
    </>
  );
}
