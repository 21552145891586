import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import useBollardService from 'src/products/services/useBollardService';
import * as yup from 'yup';
import ExitButton from '../../shared/components/Buttons/ExitButton';
import Form from '../../shared/components/Form';
import { useGlobalConfirmationModal } from '../../shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../shared/components/Modals/GlobalSuccessModal';
import Tabs from '../../shared/components/Tabs';
import useIsMounted from '../../shared/hooks/useIsMounted';

import { isNotString } from '../../shared/utils/checks';
import type Bollard from '../models/Bollard/Bollard';
import type BollardFormValues from '../models/Bollard/BollardFormValues';
import SharedProductList from '../screens/SharedProductList';
import BollardUpdateForm from './BollardUpdateForm';

interface BollardDetailsModalProps {
  id: number | undefined;
  productId: number | undefined;
  closeParentModal: () => void;
  onProductNameChange: (name: string, id: number, zoneId?: number) => void;
  isAnyGatewayOnline: boolean;
}

export default function BollardDetailsModal(props: BollardDetailsModalProps) {
  const {
    id,
    productId,
    closeParentModal,
    onProductNameChange,
    isAnyGatewayOnline,
  } = props;
  const isMounted = useIsMounted();
  const { findOneBollard, removeBollard, updateBollard } = useBollardService();
  const defaultFormValues: BollardFormValues = {
    name: '',
  };
  const [details, setDetails] = useState<Bollard | undefined>();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const loadBollardDetails = async () => {
      if (!id) {
        return;
      }
      try {
        if (isMounted()) {
          setAreDetailsLoading(true);
        }

        const data = await findOneBollard(id);
        if (isMounted()) {
          setDetails(data);
          setInitialValues({
            name: data.product.name,
          });
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    loadBollardDetails();
  }, [id, isMounted, findOneBollard]);

  const onBollardRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await removeBollard(id);

      if (isMounted()) {
        setMessageSuccess('Bollard deleted successfully!');
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeBollard,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onBollardRemove,
    message: 'Are you sure you want to remove this bollard?',
  });

  const onBollardFormSubmit = useCallback(
    async (
      values: BollardFormValues,
      { setErrors, resetForm }: FormikHelpers<BollardFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }
        await updateBollard(id, values);
        if (isMounted()) {
          resetForm({});
          setMessageSuccess('Bollard updated successfully!');
          openGlobalSuccessModal();
          onProductNameChange(
            values.name,
            productId || 0,
            details?.product.zoneId
          );
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      id,
      updateBollard,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      onProductNameChange,
      productId,
      setMessage,
      details,
    ]
  );

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is a required field'),
  });

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='bollard'
            onSubmit={onBollardFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
          >
            <BollardUpdateForm
              details={details}
              openRemoveModal={openGlobalConfirmationModal}
              closeParentModal={closeParentModal}
              isAnyGatewayOnline={isAnyGatewayOnline}
              isLoading={areDetailsLoading}
            />
          </Form>
        </Tabs.Panel>
        <Tabs.Panel name='sharedAccess' label='Shared Access'>
          <SharedProductList modalView productId={productId || 0} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
