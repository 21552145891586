import type ProductChangeSettingsRequest from 'src/lots/models/ProductChangeSettingsRequest';
import type ProductChangeSettingsValues from 'src/lots/models/ProductChangeSettingsValues';

export default function toProductChangeSettingsRequest(
  data: ProductChangeSettingsValues
): ProductChangeSettingsRequest {
  const { isAutoclose } = data;

  return { is_autoclose: isAutoclose };
}
