import { PlanLabels } from 'src/anpr/constants/LprImageStorageSubscriptionPlans';
import type LprImageStorageSubscriptionStatuses from 'src/anpr/constants/LprImageStorageSubscriptionStatuses';
import type LprImageStorageSubscriptionCreateFormValues from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionCreateFormValues';
import type LprImageStorageSubscriptionCreateRequest from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscriptionCreateRequest';

export default function toLprImageStorageSubscriptionCreateRequest(
  data: LprImageStorageSubscriptionCreateFormValues,
  lprCameraId: number,
  status: LprImageStorageSubscriptionStatuses
): LprImageStorageSubscriptionCreateRequest {
  const { subscriptionPlan } = data;

  return {
    subscription_plan: PlanLabels[subscriptionPlan?.key || 0],
    lpr_camera_id: lprCameraId,
    status,
  };
}
