import type Bollard from 'src/products/models/Bollard/Bollard';
import type BollardResponse from 'src/products/models/Bollard/BollardResponse';
import toConcreteProduct from '../Product/toConcreteProduct';

export default function toBollard(data: BollardResponse): Bollard {
  const { id, product, state } = data;

  return {
    id,
    product: toConcreteProduct(product),
    state,
  };
}
