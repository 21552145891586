import React, { useMemo } from 'react';
import useAuthContext from 'src/auth/hooks/useAuthContext';
import Color from 'src/shared/constants/Color';
import styled from 'styled-components';
import SideMenu from '../../shared/components/SideMenu';
import AppRole from '../../shared/constants/AppRole';
import useUserHasRole from '../../shared/hooks/useUserHasRole';

interface AccountMenuPorps {
  close: () => void;
}

export default function AccountMenu(props: AccountMenuPorps) {
  const { close } = props;
  const userHasRole = useUserHasRole();
  const { sessionData } = useAuthContext();

  const userCanReadAccountInfo = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userCanReadAccountLicenses = useMemo(() => {
    const roles =
      AppRole.PMS_SUPER_ADMIN | AppRole.PMS_ADMIN | AppRole.PMS_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const userCanReadAccountUsers = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN |
      AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userIsSuperAdmin = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PARKLIO_INTERNAL_OPERATOR;
    return userHasRole(roles);
  }, [userHasRole]);

  return (
    <SideMenu
      close={close}
      title='Account Settings'
      subtitle={sessionData?.email}
    >
      <SideMenu.Navigation>
        {userCanReadAccountInfo && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/info`}>
            Info
          </SideMenu.Link>
        )}
        {userCanReadAccountLicenses && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/licenses`}>
            Licenses
          </SideMenu.Link>
        )}
        {userIsSuperAdmin && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/invoice`}>
            Invoice Setup
          </SideMenu.Link>
        )}
        {userCanReadAccountUsers && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/users`}>
            Parking Managers
          </SideMenu.Link>
        )}
        {userIsParklioInternal && (
          <SideMenu.Link
            to={`/settings/account/${sessionData?.id}/anpr-subscription`}
          >
            ANPR Image Storage
          </SideMenu.Link>
        )}
        {userIsParklioInternal && (
          <SideMenu.Link to={`/settings/account/${sessionData?.id}/detect`}>
            Detection
          </SideMenu.Link>
        )}
        <Link
          href='https://billing.stripe.com/p/login/4gw7uq66S4r577icMM'
          target='_blank'
          rel='noopener noreferrer'
        >
          Subscriptions
        </Link>
      </SideMenu.Navigation>
    </SideMenu>
  );
}

const Link = styled.a`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.TEXT_DARKER};
  text-decoration: none;
  margin-bottom: 30px;

  :last-of-type {
    margin-bottom: 0px;
  }
`;
