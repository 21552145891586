import type RotaryParkingPlaceQuery from 'src/rotaries/models/RotaryParkingPlace/RotaryParkingPlaceQuery';
import type RotaryParkingPlaceQueryParams from 'src/rotaries/models/RotaryParkingPlace/RotaryParkingPlaceQueryParams';

export default function toRotaryParkingPlaceQuery(
  data: RotaryParkingPlaceQueryParams
): RotaryParkingPlaceQuery {
  const { rotaryId } = data;

  return {
    rotary_id: rotaryId,
  };
}
