import type Lot from 'src/lots/models/Lot';
import type LotFormValues from 'src/lots/models/LotFormValues';
import {
  ParkingDetectionImplementationStatus,
  StatusValues,
} from 'src/products/constants/ParkingDetectionImplementationStatus';
import TimeZone from 'src/shared/constants/TimeZone';
import capitalizeFirstLetter from 'src/shared/utils/capitalizeFirstLetter';

export default function toLotFormValues(data: Lot): LotFormValues {
  const {
    name,
    address,
    description,
    timeZone,
    latitude,
    longitude,
    detectionImplementationStatus,
  } = data;

  return {
    name,
    address,
    description: description || '',
    timeZone:
      timeZone !== null
        ? {
            key: timeZone,
            label: TimeZone.find((x) => x.key === timeZone)?.label || '',
          }
        : null,
    latitude: latitude ? latitude.toString() : '',
    longitude: longitude ? longitude.toString() : '',
    isDetect:
      detectionImplementationStatus !==
      ParkingDetectionImplementationStatus.NOT_IMPLEMENTED,
    detectionImplementationStatus: {
      key: StatusValues[detectionImplementationStatus],
      label: capitalizeFirstLetter(detectionImplementationStatus),
    },
  };
}
