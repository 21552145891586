import type InvitationKeyUpdateByIdParams from '../models/InvitationKey/InvitationKeyUpdateByIdParams';
import type InvitationKeyUpdateByIdValues from '../models/InvitationKey/InvitationKeyUpdateByIdValues';

export default function toInvitationKeyUpdateByIdParams(
  data: InvitationKeyUpdateByIdValues
): InvitationKeyUpdateByIdParams {
  const { status } = data;

  return { status };
}
