import type DetectCamera from 'src/detection/models/DetectCamera';
import type DetectCameraResponse from 'src/detection/models/DetectCameraResponse';
import toDetectCameraViewSchema from './toDetectCameraViewSchema';

export default function toDetectCamera(
  data: DetectCameraResponse
): DetectCamera {
  const {
    id,
    lot_id,
    uuid,
    name,
    parking_detection_gateway_id,
    created_at,
    updated_at,
    deleted_at,
    view_schema,
  } = data;

  return {
    id,
    lotId: lot_id,
    uuid,
    name,
    gatewayId: parking_detection_gateway_id,
    createdAt: created_at,
    updatedAt: updated_at,
    deletedAt: deleted_at,
    viewSchema: view_schema ? toDetectCameraViewSchema(view_schema) : undefined,
  };
}
