import React, { useCallback, useState } from 'react';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import * as yup from 'yup';
import type ResetPasswordFormValues from '../../settings/models/ResetPasswordFormValues';
import useAccountUserService from '../../settings/services/useAccountUserService';
import useIsMounted from '../../shared/hooks/useIsMounted';
import Container from '../components/Container';
import Form from '../components/Form';
import ParklioSign from '../components/ParklioSign';
import ResetPasswordForm from '../components/ResetPasswordForm';
import ResetPasswordText from '../components/ResetPasswordText';
import Title from '../components/Title';

const initialValues: ResetPasswordFormValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is a required field')
    .email('Enter a valid email address'),
});

export default function ResetPassword() {
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState('');
  const { requestResetPassword } = useAccountUserService();
  const isMounted = useIsMounted();

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (values: ResetPasswordFormValues) => {
      try {
        await requestResetPassword(values);

        if (isMounted()) {
          setIsSent(true);
          setEmail(values.email);
        }
      } catch (error: any) {
        if (isMounted()) {
          openGlobalFailureModal();
          setMessage(error);
          setIsSent(false);
        }
      }
    },
    [isMounted, openGlobalFailureModal, requestResetPassword, setMessage]
  );

  return (
    <Container>
      <ParklioSign
        onClick={() => window.location.assign('/login')}
        cursorpointer='true'
      />
      <Title>Reset password</Title>
      {!isSent && (
        <Form
          name='resetPassword'
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <ResetPasswordForm />
        </Form>
      )}
      {isSent && <ResetPasswordText email={email} />}
    </Container>
  );
}
