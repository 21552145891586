import type LprImageStorageSubscriptionHistory from 'src/anpr/models/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistory';
import type LprImageStorageSubscriptionHistoryResponse from 'src/anpr/models/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistoryResponse';
import toLprCamera from './toLprCamera';

export default function toLprImageStorageSubscriptionHistory(
  data: LprImageStorageSubscriptionHistoryResponse
): LprImageStorageSubscriptionHistory {
  const {
    id,
    lpr_camera_id,
    lpr_storage_subscription_id,
    parklio_internal_id,
    previous_subscription_plan,
    target_subscription_plan,
    activation_date,
    expiry_date,
    termination_date,
    deleted_at,
    created_at,
    updated_at,
    lpr_camera,
  } = data;

  return {
    id,
    lprCameraId: lpr_camera_id,
    lprStorageSubscriptionId: lpr_storage_subscription_id,
    parklioInternalId: parklio_internal_id,
    activationDate: activation_date,
    expiryDate: expiry_date,
    terminationDate: termination_date,
    previousSubscriptionPlan: previous_subscription_plan,
    targetSubscriptionPlan: target_subscription_plan,
    deletedAt: deleted_at ? new Date(deleted_at) : null,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    lprCamera: toLprCamera(lpr_camera),
  };
}
