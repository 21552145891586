import type AccountProductLicensesCount from 'src/product-license/models/AccountProductLicensesCount';
import type AccountProductLicensesCountResponse from 'src/product-license/models/AccountProductLicensesCountResponse';

export default function toAccountProductLicensesCount(
  data: AccountProductLicensesCountResponse
): AccountProductLicensesCount {
  const {
    products,
    parking_places,
    barriers,
    brains,
    bollards,
    chains,
    lprs,
    gates,
  } = data;

  return {
    products,
    parkingPlaces: parking_places,
    barriers,
    brains,
    bollards,
    chains,
    lprCameras: lprs,
    gates,
  };
}
