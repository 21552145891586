import type WeblinkResendParams from '../models/Weblink/WeblinkResendParams';
import type WeblinkResendValues from '../models/Weblink/WeblinkResendValues';

export default function toWeblinkResendParams(
  data: WeblinkResendValues
): WeblinkResendParams {
  const { ids } = data;

  return { ids };
}
