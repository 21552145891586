import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'src/shared/components/Button';
import Card from 'src/shared/components/Card';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import Section from 'src/shared/components/Section';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import ToggleField from 'src/shared/components/ToggleField';
import ButtonSize from '../../shared/constants/ButtonSize';
import useIsMounted from '../../shared/hooks/useIsMounted';
import type Option from '../../shared/models/Option';
import useAccountUserRoleService from '../../shared/services/useAccountUserRoleService';
import type UserProfileFormValues from '../models/UserProfileFormValues';

export default function UserProfileForm() {
  const { isSubmitting } = useFormikContext<UserProfileFormValues>();
  const [options, setOptions] = useState<Option[]>([]);
  const { findAllAccountUserRoles } = useAccountUserRoleService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      const data = await findAllAccountUserRoles();

      if (isMounted()) {
        const options = data.map(({ id, name }) => ({
          key: id,
          label: name,
        }));

        setOptions(options);
      }
    };

    getData();
  }, [findAllAccountUserRoles, isMounted]);

  return (
    <>
      <Title>User Profile</Title>
      <Card>
        <Section>
          <ToggleField label='Role' name='role' options={options} readonly />
          <TextField
            label='Email'
            placeholder='Email'
            name='email'
            type='email'
            short
          />
          <TextField
            label='Name'
            placeholder='Name'
            name='name'
            type='text'
            short
          />
          <TextField
            label='Phone'
            placeholder='Phone'
            name='phone'
            type='text'
            short
          />
        </Section>
        <Line />
        <Control>
          <Button
            size={ButtonSize.LARGE}
            type='submit'
            primary
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </Control>
      </Card>
    </>
  );
}
