import React from 'react';
import styled, { css } from 'styled-components';
import Color from '../constants/Color';

interface CardProps {
  children: React.ReactNode;
  addHorizontalPadding?: boolean;
  addVerticalPadding?: boolean;
  paddingForSmallScreens?: boolean;
  sticky?: boolean;
  modalView?: boolean;
  removeAllStyles?: boolean;
  removeMargins?: boolean;
  anprView?: boolean;
  cameraModalView?: boolean;
  reportsView?: boolean;
  removeTopMargin?: boolean;
  addLastChildMargin?: boolean;
  zoneView?: boolean;
  removeLastChildMargin?: boolean;
  vehicleCount?: boolean;
  lastRef?: (node: HTMLDivElement | null) => void;
}

export default function Card(props: CardProps) {
  const {
    children,
    addHorizontalPadding,
    addVerticalPadding,
    paddingForSmallScreens,
    sticky,
    modalView,
    removeAllStyles,
    removeMargins,
    anprView,
    cameraModalView,
    reportsView,
    removeTopMargin,
    addLastChildMargin,
    zoneView,
    removeLastChildMargin,
    vehicleCount,
    lastRef,
  } = props;

  return (
    <StyledWrapper
      addHorizontalPadding={addHorizontalPadding}
      addVerticalPadding={addVerticalPadding}
      paddingForSmallScreens={paddingForSmallScreens}
      sticky={sticky}
      modalView={modalView}
      removeAllStyles={removeAllStyles}
      removeMargins={removeMargins}
      anprView={anprView}
      cameraModalView={cameraModalView}
      reportsView={reportsView}
      removeTopMargin={removeTopMargin}
      addLastChildMargin={addLastChildMargin}
      zoneView={zoneView}
      removeLastChildMargin={removeLastChildMargin}
      vehicleCount={vehicleCount}
      ref={lastRef}
    >
      {children}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{
  addHorizontalPadding?: boolean;
  addVerticalPadding?: boolean;
  paddingForSmallScreens?: boolean;
  sticky?: boolean;
  modalView?: boolean;
  removeAllStyles?: boolean;
  removeMargins?: boolean;
  anprView?: boolean;
  cameraModalView?: boolean;
  reportsView?: boolean;
  addLastChildMargin?: boolean;
  zoneView?: boolean;
  removeLastChildMargin?: boolean;
  removeTopMargin?: boolean;
  vehicleCount?: boolean;
}>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  background-color: ${Color.BACKGROUND_LIGHTER};

  :last-child {
    margin-bottom: 40px;
  }

  ${(props) => {
    const { sticky } = props;

    if (sticky) {
      return css`
        margin-top: 0;
        position: sticky;
        top: 0;
        z-index: 100;
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { removeLastChildMargin } = props;

    if (removeLastChildMargin) {
      return css`
        :last-child {
          margin-bottom: 0px;
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { addLastChildMargin } = props;

    if (addLastChildMargin) {
      return css`
        @media (max-width: 768px) {
          :last-child {
            margin-bottom: 40px;
          }
        }
      `;
    }

    return css`
      @media (max-width: 768px) {
        :last-child {
          margin-bottom: 0px;
        }
      }
    `;
  }};

  ${(props) => {
    const { modalView, anprView, reportsView, cameraModalView } = props;

    if (modalView) {
      return css`
        padding-bottom: 20px;
      `;
    }

    if (anprView) {
      return css`
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 3px 0;
        overflow: auto;
      `;
    }

    if (cameraModalView) {
      return css`
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 30px 0;
        overflow: auto;
        border-radius: 5px 0 0 5px;
        border-top: 1px solid ${Color.BORDER_LIGHT};
        border-bottom: 1px solid ${Color.BORDER_LIGHT};
      `;
    }

    if (reportsView) {
      return css`
        display: flex;
        margin-top: 30px;
        background-color: ${Color.BACKGROUND};
        justify-content: center;

        :last-child {
          margin-bottom: 0px;
        }
      `;
    }

    return css`
        box-shadow: 0px 0px 30px ${Color.SHADOW_DARK_TRANSPARENT};
        margin-top: 30px;
        padding: 30px;
      }
    `;
  }};

  ${(props) => {
    const { addHorizontalPadding } = props;

    if (addHorizontalPadding) {
      return css`
        padding: 0 30px;
        :last-child {
          margin-bottom: 0px;
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { addVerticalPadding } = props;

    if (addVerticalPadding) {
      return css`
        padding: 10px 0;
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { paddingForSmallScreens } = props;

    if (paddingForSmallScreens) {
      return css`
        @media (max-width: 500px) {
          padding: 10px;
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { removeMargins } = props;

    if (removeMargins) {
      return css`
        margin-top: 0;
        padding: 20px 0;
        display: flex;
        :last-child {
          margin-bottom: 0;
        }

        @media (max-width: 1400px) {
          margin-top: 30px;
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { removeAllStyles } = props;

    if (removeAllStyles) {
      return css`
        all: unset;
        width: 100%;
        :last-child {
          margin-bottom: 0px;
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { removeTopMargin } = props;

    if (removeTopMargin) {
      return css`
        margin-top: 0;
        padding: 15px;
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { zoneView } = props;

    if (zoneView) {
      return css`
        margin-top: 0;
        padding: 15px;
        :last-child {
          margin-bottom: 15px;
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { vehicleCount } = props;

    if (vehicleCount) {
      return css`
        display: flex;
        flex-direction: row;
        margin: auto;
        :last-child {
          margin-bottom: 15px;
        }
      `;
    }
    return css``;
  }};
`;
