import Color from 'src/shared/constants/Color';

import styled, { css } from 'styled-components';

export const List = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  :last-child {
    margin-bottom: 40px;
  }
`;

export const CardWrapper = styled.div`
  height: 150px;
  box-shadow: 0px 0px 30px ${Color.SHADOW_DARK_TRANSPARENT};
  border-radius: 5px;
  background-color: ${Color.BACKGROUND_LIGHTER};
  justify-items: center;
  padding: 20px;
  margin: 0px 20px 20px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  min-width: 150px;
  position: relative;
`;

export const ParkingPlaceCardWrapper = styled.div<{
  numberOfChildren?: number;
  empty?: boolean;
}>`
    box-shadow: 0px 0px 30px ${Color.SHADOW_DARK_TRANSPARENT};
    border-radius: 5px;
    background-color: ${Color.BACKGROUND_LIGHTER};
    justify-items: center;
    display: grid;
    position: relative;
    justify-content: center;
    margin: 0px 5px 5px 0px;

    ${(props) => {
      const { numberOfChildren, empty } = props;

      if (numberOfChildren && !empty) {
        return css`
          grid-template-columns: repeat(
            auto-fit,
            calc(100% / ${numberOfChildren} - 5px)
          );
          width: calc(100% / ${numberOfChildren} - 5px);
          height: auto;
          max-width: 100px;
          max-height: 100px;
        `;
      }

      if (numberOfChildren && empty) {
        return css`
          box-shadow: none;
          background-color: inherit;
          grid-template-columns: repeat(
            auto-fit,
            calc(100% / ${numberOfChildren} - 5px)
          );
          min-width: calc(100% / ${numberOfChildren} - 5px);
          height: auto;
          max-width: 100px;
          max-height: 100px;
          padding-bottom: min(calc(100% / ${numberOfChildren} - 5px), 100px);
        `;
      }

      return css`
        grid-template-columns: repeat(auto-fit, 100px);
        width: 100px;
        height: 100px;
      `;
    }}}
`;

export const UpdateInfo = styled.p<{ parkingPlace?: boolean }>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  flex: 2;
  align-items: center;
  display: flex;
  margin: 10px;
  justify-content: center;
  padding: 10px 5px;
  text-align: center;
  cursor: pointer;

  ${(props) => {
    const { parkingPlace } = props;

    if (parkingPlace) {
      return css`
        line-height: 12px;
        margin: 5px;
      `;
    }

    return css`
      line-height: 19px;
      margin: 10px;
    `;
  }}
`;

export const Name = styled.p<{ parkingPlace?: boolean }>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: ${Color.TEXT_BRAND};
  width: 100%;
  min-width: 65px;
  word-break: break-all;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }

  ${(props) => {
    const { parkingPlace } = props;

    if (parkingPlace) {
      return css`
        font-size: 12px;
        line-height: 12px;
        width: max-content;
        margin: 0;
        margin-bottom: 5px;
        padding: 10px 5px 5px 5px;
      `;
    }

    return css`
      font-size: 14px;
      line-height: 19px;
      margin: 5px 5px 10px 5px;
    `;
  }}
`;

export const Status = styled.p<{ offline?: boolean }>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 5px;
  flex: 2;

  ${(props) => {
    const { offline } = props;

    if (offline) {
      return css`
        color: ${Color.ERROR};
      `;
    }

    return css`
      color: ${Color.TEXT_DARKER};
    `;
  }}
`;

export const OccupancyCircle = styled.div<{ color: Color }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  margin: 0 auto;
  margin-bottom: 10px;

  ${(props) => {
    const { color } = props;

    if (color) {
      return css`
        background-color: ${color};
      `;
    }
    return css``;
  }}
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
`;
