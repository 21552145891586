import type LotWhitelistQuery from 'src/anpr/models/LotWhitelist/LotWhitelistQuery';
import type LotWhitelistQueryParams from 'src/anpr/models/LotWhitelist/LotWhitelistQueryParams';

export default function toLotWhitelistQuery(
  data: LotWhitelistQueryParams
): LotWhitelistQuery {
  const { page, size, lotId, licensePlate, startTime, endTime, status } = data;

  return {
    lot_id: lotId,
    license_plate: licensePlate,
    start_time: startTime,
    end_time: endTime,
    page,
    size,
    status,
  };
}
