import type ProductError from 'src/lots/models/ProductError';
import type ProductErrorResponse from 'src/lots/models/ProductErrorResponse';

export default function toProductError(
  data: ProductErrorResponse
): ProductError {
  const { id, device_id, name, lot_id, status, product_type_id } = data;
  return {
    id,
    deviceId: device_id,
    name,
    lotId: lot_id,
    status,
    productTypeId: product_type_id,
  };
}
