import type KeyLogUpdateByIdParams from '../models/KeyLog/KeyLogUpdateByIdParams';
import type KeyLogUpdateByIdValues from '../models/KeyLog/KeyLogUpdateByIdValues';

export default function toKeyLogUpdateByIdParams(
  data: KeyLogUpdateByIdValues
): KeyLogUpdateByIdParams {
  const { status } = data;

  return { status };
}
