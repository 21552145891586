import React from 'react';
import CheckedIcons from 'src/shared/constants/CheckedIcons';
import styled, { css } from 'styled-components';
import Color from '../../constants/Color';

interface ItemProps {
  onSelect: () => void;
  isSelected: boolean;
  label: string;
  tariffView?: boolean;
}

export default function Item(props: ItemProps) {
  const { onSelect, label, isSelected, tariffView } = props;

  const icon = isSelected ? CheckedIcons.YES : CheckedIcons.NO;

  return (
    <StyledWrapper
      isSelected={isSelected}
      onClick={onSelect}
      tariffView={tariffView}
    >
      {tariffView && <i className={icon} />}
      <StyledText isSelected={isSelected}>{label}</StyledText>
    </StyledWrapper>
  );
}

const StyledText = styled.p<{ isSelected: boolean }>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  user-select: none;

  ${(props) => {
    const { isSelected } = props;

    if (!isSelected) {
      return css`
        color: ${Color.TEXT_BRAND};
        :hover {
          color: ${Color.PRIMARY_HOVER};
        }
      `;
    }

    return css`
      color: ${Color.TEXT_LIGHTEST};
    `;
  }}
`;

const StyledWrapper = styled.div<{ isSelected: boolean; tariffView?: boolean }>`
  height: 32px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin: 5px;
  cursor: pointer;

  & > i {
    font-size: 12px;
    margin-right: 5px;
  }

  ${(props) => {
    const { isSelected } = props;

    if (!isSelected) {
      return css`
        border: 1px solid ${Color.BORDER_BRAND};

        & > i {
          color: ${Color.PRIMARY_BRAND};
        }

        :hover {
          background: ${Color.BACKGROUND_HOVER};
          text-decoration: none;
          transition: all 0.4s ease 0s;
          box-shadow: 0 10px 10px -10px ${Color.SHADOW_DARKEST_TRANSPARENT};
        }
      `;
    }

    return css`
      background-color: ${Color.PRIMARY_BRAND};
      padding: 0px 21px;
      border: none;

      & > i {
        color: ${Color.BACKGROUND_LIGHTEST};
      }

      :hover {
        color: ${Color.BACKGROUND_LIGHTEST};
        background: ${Color.PRIMARY_HOVER};
        text-decoration: none;
        transition: all 0.4s ease 0s;
        box-shadow: 0 10px 10px -10px ${Color.SHADOW_DARKEST_TRANSPARENT};
      }
    `;
  }}

  ${(props) => {
    const { isSelected, tariffView } = props;

    if (tariffView) {
      if (isSelected) {
        return css`
          padding: 0 14px;
        `;
      }

      return css`
        padding: 0 13px;
      `;
    }
    return css``;
  }}
`;
