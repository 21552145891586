import type DetectCamera from 'src/detection/models/DetectCamera';
import type DetectCameraFormValues from 'src/detection/models/DetectCameraFormValues';

export default function toDetectCameraFormValues(
  data: DetectCamera,
  gatewayName: string
): DetectCameraFormValues {
  const { uuid, name, gatewayId } = data;

  return {
    uuid,
    name,
    gateway: { key: gatewayId, label: gatewayName },
  };
}
