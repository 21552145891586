import type AccountUserInvitationUpdateParams from 'src/home/models/AccountUserInvitationUpdateParams';
import type AccountUserInvitationUpdateValues from 'src/home/models/AccountUserInvitationUpdateValues';

export default function toAccountUserInvitationUpdateParams(
  data: AccountUserInvitationUpdateValues
): AccountUserInvitationUpdateParams {
  const { status } = data;
  return {
    status,
  };
}
