import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalConfirmationModal } from 'src/shared/components/Modals/GlobalConfirmationModal';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import AccountUserInvitationTable from '../../settings/components/AccountUserInvitationTable';
import type AccountUserInvitation from '../models/AccountUserInvitation';
import useAccountUserInvitationService from '../services/useAccountUserInvitationService';

export default function AccountInvitationList() {
  const [data, setData] = useState<AccountUserInvitation[]>([]);
  const [activeId, setActiveId] = useState<number | undefined>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [resendingInvitation, setResendingInvitation] = useState<{
    [key: number]: boolean;
  }>({});
  const {
    findAllAccountUserInvitations,
    removeAccountUserInvitation,
    resendAccountUserInvitation,
    blockAccountUserInvitation,
  } = useAccountUserInvitationService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await findAllAccountUserInvitations();

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [findAllAccountUserInvitations, isMounted]);

  const onRemove = useCallback(async () => {
    if (activeId) {
      await removeAccountUserInvitation(activeId);

      const { data } = await findAllAccountUserInvitations();

      if (isMounted()) {
        setData(data);
      }

      setActiveId(undefined);
    }
  }, [
    setData,
    findAllAccountUserInvitations,
    removeAccountUserInvitation,
    isMounted,
    activeId,
  ]);

  const onResendInvitation = useCallback(
    async (id: number, email: string) => {
      try {
        if (isMounted()) {
          setResendingInvitation((invitation) => ({
            ...invitation,
            [id]: true,
          }));
        }

        await blockAccountUserInvitation(id);
        await resendAccountUserInvitation(email);

        if (isMounted()) {
          setData((oldInvitations) => {
            const index = oldInvitations.findIndex(
              (invitation) => invitation.id === id
            );

            if (index === -1) return oldInvitations;

            oldInvitations[index].updatedAt = new Date();
            return [...oldInvitations];
          });
          setResendingInvitation((invitation) => ({
            ...invitation,
            [id]: false,
          }));
        }
      } catch (error) {
        if (isMounted()) {
          setResendingInvitation((invitation) => ({
            ...invitation,
            [id]: false,
          }));
        }
        throw error;
      }
    },
    [
      setData,
      blockAccountUserInvitation,
      resendAccountUserInvitation,
      isMounted,
    ]
  );

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onRemove,
    message:
      'Are you sure you want to withdraw invitation for this parking manager?',
  });

  const openRemoveModal = useCallback(
    (id: number) => {
      openGlobalConfirmationModal();
      setActiveId(id);
    },
    [openGlobalConfirmationModal]
  );

  return (
    <AccountUserInvitationTable
      data={data}
      isLoading={isDataLoading}
      openRemoveModal={openRemoveModal}
      onResendInvitation={onResendInvitation}
      resendingInvitation={resendingInvitation}
    />
  );
}
