import { useCallback } from 'react';
import toCurrency from 'src/shared/mappers/toCurrency';
import type Currency from 'src/shared/models/Currency';
import type CurrencyResponse from 'src/shared/models/CurrencyResponse';
import useApiService from './useApiService';

interface CurrencyService {
  findAllCurrencies: () => Promise<Currency[]>;
}

const currencyBaseURL = '/currencies';

export default function useCurrencyService(): CurrencyService {
  const { get } = useApiService();

  const findAllCurrencies = useCallback(async () => {
    const response = await get<CurrencyResponse[]>(`${currencyBaseURL}`);
    const data = response.data.map(toCurrency);

    return data;
  }, [get]);

  return { findAllCurrencies };
}
