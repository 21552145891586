import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import Button from '../../shared/components/Button';
import DeleteButton from '../../shared/components/Buttons/DeleteButton';
import Control from '../../shared/components/Control';
import Line from '../../shared/components/Line';
import { useGlobalCancelModal } from '../../shared/components/Modals/GlobalCancelModal';
import Spinner from '../../shared/components/Spinner';
import TextField from '../../shared/components/TextField';
import ButtonSize from '../../shared/constants/ButtonSize';

import AllProductStatuses from '../constants/AllProductStatuses';
import useChainStatusLabel from '../hooks/useChainStatusLabel';
import type Chain from '../models/Chain/Chain';
import type ChainFormValues from '../models/Chain/ChainFormValues';
import DetailsColumn from './DetailsColumn';
import DetailsData from './DetailsData';
import DetailsLabel from './DetailsLabel';
import DetailsRow from './DetailsRow';
import DetailsStatus from './DetailsStatus';

interface ChainUpdateFormProps {
  details?: Chain;
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
  closeParentModal: () => void;
  isAnyGatewayOnline: boolean;
}
export default function ChainUpdateForm(props: ChainUpdateFormProps) {
  const {
    details,
    isLoading,
    openRemoveModal,
    closeParentModal,
    isAnyGatewayOnline,
  } = props;
  const { isSubmitting, dirty } = useFormikContext<ChainFormValues>();
  const statusLabel = useChainStatusLabel(details?.product, isAnyGatewayOnline);

  const onRemove = useCallback(() => {
    if (!details?.id) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details]);

  const shouldRenderDetails = !isLoading && details;

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && details && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='Name'
              placeholder='Name'
              type='text'
              name='name'
              short
              stacked
            />
            <DetailsLabel>STATUS</DetailsLabel>
            <DetailsStatus
              offline={
                details.product.productError !== null ||
                (statusLabel && statusLabel[0] === AllProductStatuses.OFFLINE)
              }
            >
              {statusLabel?.map((status) => (
                <p key={status}>{status}</p>
              ))}
            </DetailsStatus>
            <Line />

            <DetailsData
              label='Product ID'
              value={details.product.id || 'N/A'}
            />

            <DetailsData
              label='Unique identifier'
              value={details.product.deviceId || 'N/A'}
            />

            <DetailsData
              label='Firmware version'
              value={details.product.firmwareVersion || 'N/A'}
            />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <DeleteButton onClick={onRemove} />
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
