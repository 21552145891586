import React, { useCallback, useMemo } from 'react';
import BatteryIcon from 'src/products/constants/BatteryIcon';
import PowerTypes from 'src/products/constants/PowerTypes';
import styled from 'styled-components';

import type Product from '../../../lots/models/Product';
import Button from '../../../shared/components/Button';
import CheckBox from '../../../shared/components/CheckBox';
import IconRow from '../../../shared/components/IconRow';
import Table from '../../../shared/components/Table';
import ButtonSize from '../../../shared/constants/ButtonSize';
import {
  getDeviceStatuses,
  getErrors,
} from '../../../shared/utils/getDeviceInfo';
import AllProductStatuses from '../../constants/AllProductStatuses';
import AllProductTypes from '../../constants/AllProductTypes';
import OperationIcon from '../../constants/OperationIcon';
import ProductIcon from '../../constants/ProductIcon';
import type ProductOperations from '../../constants/ProductOperations';
import ProductState from '../../constants/ProductState';
import ProductStatus from '../../constants/ProductStatus';
import BatteryPowerIcon, { StyledIcon } from '../BatteryPowerIcon';
import ProductStatuses from '../ProductStatuses';
import ProductTableIcon from '../ProductTableIcon';
import SignalStatus from '../SignalStatus';

interface ProductTableBarrierRowProps {
  productData: Product;
  deviceInProgress: boolean;
  selectedForSharing: boolean;
  onClick: (
    productId: number,
    operation: ProductOperations | undefined
  ) => Promise<void>;
  updateSharableProducts: () => void;
  openProductDetailsModal: () => void;
  getDeviceOperation: (
    state: string
  ) => ProductOperations.OPEN | ProductOperations.CLOSE | undefined;
  isAnyGatewayOnline: boolean;
  lotHasGateway: boolean;
}

export default function ProductTableBarrierRow(
  props: ProductTableBarrierRowProps
) {
  const getBarrierOperationLabel = useCallback((state: string) => {
    switch (state) {
      case ProductState.OPENED:
        return AllProductStatuses.EXTEND;
      case ProductState.CLOSED:
        return AllProductStatuses.LOWER;
      default:
        return AllProductStatuses.NO_OPERATION;
    }
  }, []);

  const {
    productData,
    deviceInProgress,
    selectedForSharing,
    onClick,
    updateSharableProducts,
    getDeviceOperation,
    openProductDetailsModal,
    isAnyGatewayOnline,
    lotHasGateway,
  } = props;

  const {
    name,
    batteryStatus,
    barrier,
    id,
    productError,
    status,
    rssi,
    powerType,
    updatedAt,
  } = productData;

  const buttonLabel =
    status === ProductStatus.OFFLINE || !isAnyGatewayOnline
      ? AllProductStatuses.NO_OPERATION
      : barrier?.isCarOnTop
      ? AllProductStatuses.CAR_ON_TOP
      : getBarrierOperationLabel(barrier?.state || '');
  const statuses = isAnyGatewayOnline
    ? getDeviceStatuses(productData)
    : AllProductStatuses.OFFLINE;
  const operation:
    | ProductOperations.OPEN
    | ProductOperations.CLOSE
    | undefined = getDeviceOperation(barrier?.state || '');
  const errors = getErrors(productError);
  const rtccError = useMemo(() => {
    if (errors.length === 0) return false;

    if (errors.length === 1 && productError?.rtccInvalid) return false;
    return true;
  }, [productError, errors]);

  const disabled = useMemo(
    () =>
      deviceInProgress ||
      statuses !== AllProductStatuses.ONLINE ||
      barrier?.isCarOnTop ||
      rtccError ||
      !isAnyGatewayOnline,
    [
      deviceInProgress,
      statuses,
      barrier?.isCarOnTop,
      rtccError,
      isAnyGatewayOnline,
    ]
  );

  return (
    <Table.Row>
      <Table.Cell>
        <CheckBox
          checked={selectedForSharing}
          readonly
          onClick={updateSharableProducts}
          title='Select'
        />
      </Table.Cell>
      <Table.Cell primary>
        <StyledProductDiv onClick={openProductDetailsModal}>
          <ProductTableIcon icon={ProductIcon.BARRIER} />
          {name}
        </StyledProductDiv>
      </Table.Cell>
      <Table.Cell show='smallDevices'>
        <Button
          primary
          size={ButtonSize.SMALL}
          type='button'
          onClick={() => onClick(id, operation)}
          isLoading={deviceInProgress}
          disabled={disabled}
        >
          {buttonLabel === AllProductStatuses.EXTEND && (
            <IconRow className={OperationIcon.UP} />
          )}
          {buttonLabel === AllProductStatuses.LOWER && (
            <IconRow className={OperationIcon.DOWN} />
          )}
          {buttonLabel}
        </Button>
      </Table.Cell>
      {lotHasGateway && (
        <>
          <Table.Cell center>
            {powerType === PowerTypes.BATTERY ? (
              <BatteryPowerIcon
                batteryStatus={batteryStatus}
                updatedAt={updatedAt}
              />
            ) : (
              <StyledIcon className={BatteryIcon.POWER} />
            )}
          </Table.Cell>
          <Table.Cell
            error={errors.length > 0 || statuses === AllProductStatuses.OFFLINE}
          >
            <ProductStatuses
              statuses={statuses}
              errors={errors}
              productType={AllProductTypes.BARRIER}
            />
          </Table.Cell>
          <Table.Cell center>
            <SignalStatus
              signalStatus={rssi}
              isAnyGatewayOnline={isAnyGatewayOnline}
            />
          </Table.Cell>
          <Table.Cell show='largeDevices'>
            <Button
              primary
              size={ButtonSize.SMALL}
              type='button'
              onClick={() => onClick(id, operation)}
              isLoading={deviceInProgress}
              disabled={disabled}
            >
              {buttonLabel === AllProductStatuses.EXTEND && (
                <IconRow className={OperationIcon.UP} />
              )}
              {buttonLabel === AllProductStatuses.LOWER && (
                <IconRow className={OperationIcon.DOWN} />
              )}
              {buttonLabel}
            </Button>
          </Table.Cell>
        </>
      )}
    </Table.Row>
  );
}

const StyledProductDiv = styled.div`
  width: 100%;
`;
