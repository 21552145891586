import React from 'react';
import Button from 'src/shared/components/Button';
import Card from 'src/shared/components/Card';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import Section from 'src/shared/components/Section';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';

export default function UserSecurityForm() {
  return (
    <>
      <Title>User Security</Title>
      <Card>
        <Section>
          <TextField
            label='Old Password'
            placeholder='Old Password'
            name='oldPassword'
            type='password'
            short
            autoComplete='new-password'
          />
          <TextField
            label='New Password'
            placeholder='New Password'
            name='newPassword'
            type='password'
            short
            autoComplete='new-password'
          />
          <TextField
            label='Confirm New Password'
            placeholder='Confirm New Password'
            name='newPasswordConfirmation'
            type='password'
            short
            autoComplete='new-password'
          />
        </Section>
        <Line />
        <Control>
          <Button size={ButtonSize.LARGE} type='submit' primary>
            Save
          </Button>
        </Control>
      </Card>
    </>
  );
}
