import { useCallback, useMemo } from 'react';
import type AcceptAutoExtendResponse from 'src/home/models/AcceptAutoExtendResponse';
import type AccountRemoveResponse from 'src/home/models/AccountRemoveResponse';
import toAccountProductLicenses from 'src/product-license/mappers/toAccountProductLicenses';

import toAccountProductLicensesCount from 'src/product-license/mappers/toAccountProductLicensesCount';
import type AccountProductLicenses from 'src/product-license/models/AccountProductLicenses';
import type AccountProductLicensesCount from 'src/product-license/models/AccountProductLicensesCount';
import type AccountProductLicensesCountResponse from 'src/product-license/models/AccountProductLicensesCountResponse';
import type AccountProductLicensesResponse from 'src/product-license/models/AccountProductLicensesResponse';
import toAccountInfo from 'src/settings/mappers/toAccountInfo';
import SessionStorageKey from 'src/shared/constants/SessionStorageKey';

import type AccountInfo from '../../settings/models/AccountInfo';
import type AccountInfoResponse from '../../settings/models/AccountInfoResponse';
import AppRole from '../../shared/constants/AppRole';
import useUserHasRole from '../../shared/hooks/useUserHasRole';
import toMeta from '../../shared/mappers/toMeta';
import type ApiResponse from '../../shared/models/ApiResponse';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toAccount from '../mappers/toAccount';
import toAccountCreateRequest from '../mappers/toAccountCreateRequest';
import toAccountQuery from '../mappers/toAccountQuery';
import toAccountUpdateRequest from '../mappers/toAccountUpdateRequest';
import type AcceptAutoExtendRequest from '../models/AcceptAutoExtendRequest';
import type Account from '../models/Account';
import type AccountCreateRequest from '../models/AccountCreateRequest';
import type AccountCreateResponse from '../models/AccountCreateResponse';
import type AccountQuery from '../models/AccountQuery';
import type AccountQueryParams from '../models/AccountQueryParams';
import type AccountResponse from '../models/AccountResponse';
import type AccountUpdateRequest from '../models/AccountUpdateRequest';
import type AccountUpdateResponse from '../models/AccountUpdateResponse';
import type AccountUpdateValues from '../models/AccountUpdateValues';
import type AccountWizardValues from '../models/AccountWizardValues';

const accountBaseURL = '/accounts';

export interface AccountService {
  createAccount: (
    values: AccountWizardValues
  ) => Promise<AccountCreateResponse>;
  updateAccount: (
    values: AccountUpdateValues,
    accountId: number
  ) => Promise<AccountUpdateResponse>;
  findAllAccounts: (
    params: AccountQueryParams
  ) => Promise<GetAllDetails<Account>>;
  findAccountById: (id: number) => Promise<AccountInfo>;
  removeAccount: (id: number) => Promise<AccountRemoveResponse>;
  acceptAutoExtend: () => Promise<AcceptAutoExtendResponse>;
  getAccountProductLicenses: () => Promise<AccountProductLicenses | undefined>;
  getAccountProductLicensesCount: () => Promise<
    AccountProductLicensesCount | undefined
  >;
  getAccountProductLicensesParklioInternal: (
    id: number
  ) => Promise<AccountProductLicenses | undefined>;
}

export default function useAccountService(): AccountService {
  const { get, post, patch, remove } = useApiService();
  const userHasRole = useUserHasRole();

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const findAllAccounts = useCallback(
    async (queryParams: AccountQueryParams) => {
      const query = toAccountQuery(queryParams);
      const response = await get<AccountResponse[], AccountQuery>(
        `${accountBaseURL}`,
        query
      );
      const data = response.data.map(toAccount);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const createAccount = useCallback(
    async (values: AccountWizardValues) => {
      const requestBody = toAccountCreateRequest(values);

      const response = await post<AccountCreateRequest, AccountCreateResponse>(
        `${accountBaseURL}`,
        requestBody
      );

      return response.data;
    },
    [post]
  );

  const updateAccount = useCallback(
    async (values: AccountUpdateValues, id: number) => {
      const requestBody = toAccountUpdateRequest(values);
      let response: ApiResponse<AccountUpdateResponse>;

      if (
        userIsParklioInternal &&
        id !== parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '')
      ) {
        response = await patch<AccountUpdateRequest, AccountUpdateResponse>(
          `${accountBaseURL}/${id}`,
          requestBody
        );
      } else {
        response = await patch<AccountUpdateRequest, AccountUpdateResponse>(
          `${accountBaseURL}`,
          requestBody
        );
      }

      return response.data;
    },
    [patch, userIsParklioInternal]
  );

  const findAccountById = useCallback(
    async (id: number) => {
      const response = await get<AccountInfoResponse>(
        `${accountBaseURL}/${id}`
      );

      const data = toAccountInfo(response.data);

      return data;
    },
    [get]
  );

  const removeAccount = useCallback(
    async (id: number) => {
      const response = await remove<AccountRemoveResponse>(
        `${accountBaseURL}/${id}`
      );

      return response.data;
    },
    [remove]
  );

  const acceptAutoExtend = useCallback(async () => {
    const response = await post<
      AcceptAutoExtendRequest,
      AcceptAutoExtendResponse
    >(`${accountBaseURL}/auto-extend`, {});

    return response.data;
  }, [post]);

  const getAccountProductLicenses = useCallback(async () => {
    const response = await get<AccountProductLicensesResponse | undefined>(
      `${accountBaseURL}/product-licenses`
    );
    if (response.data) {
      const data = toAccountProductLicenses(response.data);
      return data;
    }
    return undefined;
  }, [get]);

  const getAccountProductLicensesCount = useCallback(async () => {
    const response = await get<AccountProductLicensesCountResponse | undefined>(
      `${accountBaseURL}/product-counts`
    );
    if (response.data) {
      const data = toAccountProductLicensesCount(response.data);
      return data;
    }
    return undefined;
  }, [get]);

  const getAccountProductLicensesParklioInternal = useCallback(
    async (id: number) => {
      const response = await get<AccountProductLicensesResponse | undefined>(
        `${accountBaseURL}/${id}/product-licenses`
      );
      if (response.data) {
        const data = toAccountProductLicenses(response.data);
        return data;
      }
      return undefined;
    },
    [get]
  );

  return {
    getAccountProductLicenses,
    getAccountProductLicensesCount,
    getAccountProductLicensesParklioInternal,
    findAllAccounts,
    createAccount,
    updateAccount,
    findAccountById,
    removeAccount,
    acceptAutoExtend,
  };
}
