import { useMemo } from 'react';
import Status from 'src/products/constants/Status';
import AllProductStatuses from '../constants/AllProductStatuses';

export default function useStatusLabel(status: string | undefined) {
  return useMemo(() => {
    if (status === Status.OFFLINE) {
      return AllProductStatuses.OFFLINE;
    }

    if (status === Status.ONLINE) {
      return AllProductStatuses.ONLINE;
    }

    return AllProductStatuses.UNKNOWN;
  }, [status]);
}
