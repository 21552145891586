import { useFormikContext } from 'formik';
import React from 'react';
import capitalizeFirstLetter from 'src/shared/utils/capitalizeFirstLetter';
import Button from '../../shared/components/Button';
import ExitButton from '../../shared/components/Buttons/ExitButton';
import Control from '../../shared/components/Control';
import Description from '../../shared/components/Description';
import Line from '../../shared/components/Line';
import { useGlobalCancelModal } from '../../shared/components/Modals/GlobalCancelModal';
import Section from '../../shared/components/Section';
import StyledModalIcon from '../../shared/components/StyledModalIcon';
import TextField from '../../shared/components/TextField';
import ButtonSize from '../../shared/constants/ButtonSize';

interface DeleteModalProps {
  closeParentModal: () => void;
  type: string;
  name: string | undefined;
}

export default function DeleteModal(props: DeleteModalProps) {
  const { closeParentModal, type, name } = props;
  const { dirty } = useFormikContext();

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });
  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Section>
        <StyledModalIcon className='pa-delete' size='80px' />
        <Description deleteView>
          If you are sure you want to delete the <b>{name}</b>, enter {type}{' '}
          name
        </Description>
        <TextField
          label=''
          placeholder={`Enter ${capitalizeFirstLetter(type)} name`}
          type='text'
          name='name'
          short
          deleteView
        />
        <Description deleteView>and proceed...</Description>
      </Section>
      <Line addMargin />
      <Control>
        <Button size={ButtonSize.LARGE} type='submit' primary>
          Yes, Delete {capitalizeFirstLetter(type)}
        </Button>
        <Button
          size={ButtonSize.LARGE}
          type='button'
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
        >
          No, Cancel
        </Button>
      </Control>
    </>
  );
}
