import type LotChangeEventBrain from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventBrain';
import type LotChangeEventBrainPayload from 'src/lots/models/SocketEvents/LotChangeEvent/LotChangeEventBrainPayload';

export default function toLotChangeEventBrain(
  data: LotChangeEventBrainPayload
): LotChangeEventBrain {
  const { state, permanent_position } = data;

  return {
    state,
    permanentPosition: permanent_position,
  };
}
