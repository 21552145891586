import InvitationKeysStatuses from 'src/keysharing/constants/InvitationKeysStatuses';
import InvitationKeysStatusValues from 'src/keysharing/constants/InvitationKeysStatusValues';
import KeyLogsStatuses from 'src/keysharing/constants/KeyLogsStatuses';
import SharedKeysStatusValues from 'src/keysharing/constants/SharedKeysStatusValues';
import WeblinksStatuses from 'src/keysharing/constants/WeblinksStatuses';
import WeblinksStatusValues from 'src/keysharing/constants/WeblinksStatusValues';

export function getInvitationKeyStatus(status?: InvitationKeysStatusValues) {
  switch (status) {
    case InvitationKeysStatusValues.ACTIVATED:
      return InvitationKeysStatuses.ACTIVATED;
    case InvitationKeysStatusValues.BLOCKED:
      return InvitationKeysStatuses.BLOCKED;
    case InvitationKeysStatusValues.EXPIRED:
      return InvitationKeysStatuses.EXPIRED;
    case InvitationKeysStatusValues.INVALID:
      return InvitationKeysStatuses.INVALID;
    case InvitationKeysStatusValues.DELETED:
      return InvitationKeysStatuses.DELETED;
    case InvitationKeysStatusValues.PENDING:
      return InvitationKeysStatuses.PENDING;
    default:
      return undefined;
  }
}

export function getSharedKeyStatus(status?: SharedKeysStatusValues): any {
  switch (status) {
    case SharedKeysStatusValues.ACTIVE:
      return KeyLogsStatuses.ACTIVE;
    case SharedKeysStatusValues.BLOCKED:
      return KeyLogsStatuses.BLOCKED;
    case SharedKeysStatusValues.EXPIRED:
      return KeyLogsStatuses.EXPIRED;
    case SharedKeysStatusValues.INVALID:
      return KeyLogsStatuses.INVALID;
    case SharedKeysStatusValues.DELETED:
      return KeyLogsStatuses.DELETED;
    default:
      return undefined;
  }
}

export function getWeblinkStatus(status?: WeblinksStatusValues) {
  switch (status) {
    case WeblinksStatusValues.ACTIVE:
      return WeblinksStatuses.ACTIVE;
    case WeblinksStatusValues.BLOCKED:
      return WeblinksStatuses.BLOCKED;
    case WeblinksStatusValues.EXPIRED:
      return WeblinksStatuses.EXPIRED;
    case WeblinksStatusValues.INVALID:
      return WeblinksStatuses.INVALID;
    case WeblinksStatusValues.DEPLETED:
      return WeblinksStatuses.DEPLETED;
    default:
      return undefined;
  }
}
