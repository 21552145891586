import type AccountUserInvitationResponse from 'src/home/models/AccountUserInvitationResponse';
import type AccountUserInvitation from '../models/AccountUserInvitation';

export default function toAccountUserInvitation(
  data: AccountUserInvitationResponse
): AccountUserInvitation {
  const { id, name, receiver_identifier, status, updated_at } = data;

  return {
    id,
    name,
    email: receiver_identifier,
    status,
    updatedAt: new Date(updated_at),
  };
}
