import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import Section from 'src/shared/components/Section';
import SelectField from 'src/shared/components/SelectField';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import AccountRole from 'src/shared/constants/AccountRole';
import ButtonSize from '../../shared/constants/ButtonSize';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useWizardContext from '../../shared/hooks/useWizardContext';
import type Option from '../../shared/models/Option';
import useAccountRoleService from '../../shared/services/useAccountRoleService';

interface InfoStepProps {
  closeParentModal: () => void;
}

export default function InfoStep(props: InfoStepProps) {
  const { closeParentModal } = props;
  const { dirty } = useFormikContext();
  const { next } = useWizardContext();
  const [options, setOptions] = useState<Option[]>([]);
  const { filterAccountRoles } = useAccountRoleService();
  const isMounted = useIsMounted();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  useEffect(() => {
    const getData = async () => {
      const data = await filterAccountRoles(AccountRole.PARKLIO_INTERNAL);
      const options = data.map(({ id, name }) => ({
        key: id,
        label: name,
      }));
      if (isMounted()) {
        setOptions(options);
      }
    };

    getData();
  }, [filterAccountRoles, isMounted]);

  return (
    <>
      <Section>
        <Title bold>Create Account</Title>
        <TextField
          label='Account'
          placeholder='Account'
          type='text'
          stacked
          name='name'
          autofocus
        />
        <SelectField
          label='Account Type'
          placeholder='Account Type'
          name='accountRole'
          options={options}
          stacked
        />
        <Title bold>Contact Person</Title>
        <TextField
          label='Contact Name'
          placeholder='Contact Name'
          type='text'
          stacked
          name='contactName'
        />
        <TextField
          label='Contact Email'
          placeholder='Contact Email'
          type='text'
          stacked
          name='contactEmail'
        />
        <TextField
          label='Contact Phone'
          placeholder='Contact Phone'
          type='text'
          stacked
          name='contactPhone'
        />
      </Section>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
        >
          Cancel
        </Button>
        <Button size={ButtonSize.LARGE} type='button' primary onClick={next}>
          Next step
        </Button>
      </Control>
    </>
  );
}
