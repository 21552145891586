import type NewPasswordQuery from '../models/NewPasswordQuery';

export default function toNewPasswordQuery(
  token: string,
  signature: string
): NewPasswordQuery {
  return {
    token,
    signature,
  };
}
