import LprImageStorageSubscriptionStatuses from 'src/anpr/constants/LprImageStorageSubscriptionStatuses';
import type LprImageStorageSubscription from 'src/anpr/models/ImageStorageSubscription/LprImageStorageSubscription';

const hasExpiryDateChanged = (
  storageSubscriptionPlan: LprImageStorageSubscription | null
) => {
  if (!storageSubscriptionPlan) {
    return undefined;
  }

  const { expiryDate, initialExpiryDate, status } = storageSubscriptionPlan;
  return (
    status === LprImageStorageSubscriptionStatuses.ON_HOLD &&
    expiryDate !== initialExpiryDate
  );
};

const remainingTime = (
  storageSubscriptionPlan: LprImageStorageSubscription | null
) => {
  if (!storageSubscriptionPlan) {
    return undefined;
  }

  const { terminationDate } = storageSubscriptionPlan;
  const currentDate = new Date(new Date().setHours(0, 0, 0));

  const time = new Date(terminationDate).getTime() - currentDate.getTime();
  const remaining = Math.floor(time / 1000 / 60 / 60 / 24);

  return remaining;
};

const isStatusInRegularGracePeriod = (
  storageSubscriptionPlan: LprImageStorageSubscription | null
) => {
  if (!storageSubscriptionPlan) {
    return undefined;
  }

  const { expiryDate, initialExpiryDate, status } = storageSubscriptionPlan;

  return (
    status === LprImageStorageSubscriptionStatuses.ON_HOLD &&
    expiryDate === initialExpiryDate
  );
};

export { hasExpiryDateChanged, remainingTime, isStatusInRegularGracePeriod };
