import type ProductLicenses from 'src/product-license/models/ProductLicenses';
import type ProductLicensesResponse from 'src/product-license/models/ProductLicensesResponse';

export default function toProductLicenses(
  data: ProductLicensesResponse
): ProductLicenses {
  const {
    id,
    active,
    parking_places,
    barriers,
    brains,
    bollards,
    chains,
    lpr_cameras,
    gates,
    account_id,
    created_by,
    created_at,
    updated_at,
  } = data;

  return {
    id,
    active,
    parkingPlaces: parking_places,
    barriers: barriers,
    brains: brains,
    bollards: bollards,
    chains: chains,
    lprCameras: lpr_cameras,
    gates: gates,
    accountId: account_id,
    createdBy: created_by,
    createdAt: new Date(created_at),
    updatedAt: updated_at ? new Date(updated_at) : null,
  };
}
