import type WeblinkMetaDataQuery from '../models/Weblink/WeblinkMetaDataQuery';
import type WeblinkMetaDataValues from '../models/Weblink/WeblinkMetaDataValues';

export default function toWeblinkMetaDataQuery(
  data: WeblinkMetaDataValues
): WeblinkMetaDataQuery {
  const { token } = data;

  return {
    token,
  };
}
