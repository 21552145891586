import type DetectCameraUpdateParams from 'src/detection/models/DetectCameraUpdateParams';
import type DetectCameraUpdateValues from 'src/detection/models/DetectCameraUpdateValues';

export default function toDetectCameraUpdateParams(
  data: DetectCameraUpdateValues
): DetectCameraUpdateParams {
  const { name, externalId, uuid, gatewayId } = data;

  return {
    name,
    external_id: externalId,
    uuid,
    parking_detection_gateway_id: gatewayId,
  };
}
