import { useField, useFormikContext } from 'formik';
import React from 'react';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import DateTimeDiv from 'src/shared/components/DateTimeDiv';
import DateTimeField from 'src/shared/components/DateTimeField';
import Error from 'src/shared/components/Error';
import ErrorDiv from 'src/shared/components/ErrorDiv';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import Section from 'src/shared/components/Section';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import ButtonSize from '../../shared/constants/ButtonSize';
import type WeblinkFormValues from '../models/Weblink/WeblinkFormValues';

interface WeblinkFormProps {
  closeParentModal: () => void;
  offlineDevices: string[];
}

export default function WeblinkForm(props: WeblinkFormProps) {
  const { closeParentModal, offlineDevices } = props;
  const { isSubmitting, dirty } = useFormikContext<WeblinkFormValues>();
  const [startTime] = useField({ name: 'startTime' });

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      <Section>
        <Title bold>Share Weblink</Title>
        {offlineDevices.length > 0 ? (
          <ErrorDiv>
            <Error>There is no connection with Gateway and products.</Error>
            <Error>Weblink won&#39;t be created for:</Error>
            <Error bold>{offlineDevices.map((id) => ` ${id}`)}</Error>
          </ErrorDiv>
        ) : null}
        <TextField
          label='Email'
          placeholder='Email'
          type='text'
          name='receiverEmail'
          stacked
        />
        <DateTimeDiv>
          <DateTimeField
            name='startTime'
            label='Start Time'
            placeholderDate='Start Date'
            placeholderTime='Start Time'
            minBookingDate={new Date()}
          />
          <DateTimeField
            name='endTime'
            label='End Time'
            placeholderDate='End Date'
            placeholderTime='End Time'
            minBookingDate={new Date(startTime.value)}
            endTimeOffset
          />
        </DateTimeDiv>
      </Section>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Share Weblink
        </Button>
      </Control>
    </>
  );
}
