import React, { useEffect, useState } from 'react';
import RotaryTable from 'src/rotaries/components/RotaryTable';
import type Rotary from 'src/rotaries/models/Rotary';
import useRotaryService from 'src/rotaries/services/useRotaryService';
import Content from 'src/shared/components/Content';
import Main from 'src/shared/components/Main';
import StyledHeader from 'src/shared/components/StyledHeader';
import Title from 'src/shared/components/Title';
import useIsMounted from '../../shared/hooks/useIsMounted';

export default function RotaryList() {
  const [data, setData] = useState<Rotary[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { findAllRotaries } = useRotaryService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await findAllRotaries();

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }

        throw error;
      }
    };

    getData();
  }, [findAllRotaries, isMounted]);

  return (
    <Main left>
      <Content>
        <StyledHeader>
          <Title addTopMargin>Rotaries</Title>
        </StyledHeader>
        <RotaryTable isLoading={isDataLoading} data={data} />
      </Content>
    </Main>
  );
}
