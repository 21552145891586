import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import formatDates from 'src/shared/utils/formatDates';
import Button from '../../shared/components/Button';
import DeleteButton from '../../shared/components/Buttons/DeleteButton';
import Control from '../../shared/components/Control';
import Line from '../../shared/components/Line';
import { useGlobalCancelModal } from '../../shared/components/Modals/GlobalCancelModal';
import Spinner from '../../shared/components/Spinner';
import TextField from '../../shared/components/TextField';
import ButtonSize from '../../shared/constants/ButtonSize';
import AllProductStatuses from '../constants/AllProductStatuses';
import useBarrierStatusLabel from '../hooks/useBarrierStatusLabel';
import type Barrier from '../models/Barrier/Barrier';
import type BarrierUpdateFormValues from '../models/Barrier/BarrierUpdateFormValues';
import BatteryDetailsData from './BatteryDetailsData';
import DetailsColumn from './DetailsColumn';
import DetailsData from './DetailsData';
import DetailsRow from './DetailsRow';
import DetailsStatus from './DetailsStatus';

interface BarrierUpdateFormProps {
  details?: Barrier;
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
  closeParentModal: () => void;
  isAnyGatewayOnline: boolean;
}

export default function BarrierUpdateForm(props: BarrierUpdateFormProps) {
  const {
    details,
    isLoading,
    openRemoveModal,
    closeParentModal,
    isAnyGatewayOnline,
  } = props;
  const { isSubmitting, dirty } = useFormikContext<BarrierUpdateFormValues>();

  const onRemove = useCallback(() => {
    if (!details?.id) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details]);

  const shouldRenderDetails = !isLoading && details;

  const statusLabel = useBarrierStatusLabel(
    details?.product,
    isAnyGatewayOnline,
    details?.isCarOnTop
  );

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && details && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='Name'
              placeholder='Name'
              type='text'
              name='name'
              short
              stacked
            />
            <BatteryDetailsData
              label='Status'
              batteryStatus={details.product.batteryStatus}
              powerType={details.product.powerType}
            />
            <DetailsStatus
              offline={
                details.product.productError !== null ||
                (statusLabel &&
                  statusLabel[0] === AllProductStatuses.OFFLINE) ||
                details.isCarOnTop
              }
            >
              {statusLabel?.map((status) => (
                <p key={status}>{status}</p>
              ))}
            </DetailsStatus>
            <Line />

            <DetailsData
              label='Product ID'
              value={details.product.id || 'N/A'}
            />

            <DetailsData
              label='Unique identifier'
              value={details.product.deviceId || 'N/A'}
            />

            <DetailsData
              label='Firmware version'
              value={details.product.firmwareVersion || 'N/A'}
            />
            <DetailsData
              label='Last updated'
              value={formatDates(details.product.updatedAt, true) || 'N/A'}
            />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <DeleteButton onClick={onRemove} />
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
