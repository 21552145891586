import toAccountUserRole from 'src/home/mappers/toAccountUserRole';
import type AccountUser from '../models/AccountUser';
import type AccountUserResponse from '../models/AccountUserResponse';

export default function toAccountUser(data: AccountUserResponse): AccountUser {
  const { id, name, email, phone, account_user_role_id } = data;

  return {
    id,
    name,
    email,
    phone,
    role: {
      key: account_user_role_id,
      label: toAccountUserRole(account_user_role_id),
    },
  };
}
