import React from 'react';
import PermanentPositions from 'src/lots/models/PermanentPosition';
import ToggleSwitch from '../../shared/components/ToggleSwitch';
import DetailsColumn from './DetailsColumn';
import DetailsRow from './DetailsRow';
interface GateSystemControlFormProps {
  onChange: () => void;
  state: string | undefined;
}

export default function GateSystemControlForm(
  props: GateSystemControlFormProps
) {
  const { state, onChange } = props;

  return (
    <React.Fragment>
      <DetailsRow wider addMargin>
        <DetailsColumn>
          <ToggleSwitch
            state={state}
            onChange={onChange}
            name='Permanent Open'
            condition={PermanentPositions.STAY_OPEN}
            info={
              "While active, the product goes into a permanently open state and can be deactivated here or by manual override on the product itself. Connect app, Weblinks or Shared digital keys can't control the product while in the permanently open state."
            }
          />
        </DetailsColumn>
      </DetailsRow>
    </React.Fragment>
  );
}
