import { useCallback } from 'react';
import toProductLicenseAddRequest from 'src/product-license/mappers/toProductLicenseAddRequest';
import toProductLicenses from 'src/product-license/mappers/toProductLicenses';
import toProductLicensesQuery from 'src/product-license/mappers/toProductLicensesQuery';
import toProductLicenseUpdateParams from 'src/product-license/mappers/toProductLicenseUpdateParams';
import type ProductLicensesFormValues from 'src/product-license/models/ProductLicenseFormValues';
import type ProductLicensesQuery from 'src/product-license/models/ProductLicenseQuery';
import type ProductLicenseRemoveResponse from 'src/product-license/models/ProductLicenseRemoveResponse';
import type ProductLicenses from 'src/product-license/models/ProductLicenses';
import type ProductLicensesQueryParams from 'src/product-license/models/ProductLicensesQueryParams';
import type ProductLicensesRequestValues from 'src/product-license/models/ProductLicensesRequestValues';
import type ProductLicensesResponse from 'src/product-license/models/ProductLicensesResponse';
import type ProductLicenseUpdateParams from 'src/product-license/models/ProductLicenseUpdateParams';
import type ProductLicenseUpdateResponse from 'src/product-license/models/ProductLicenseUpdateResponse';
import type ProductLicenseUpdateValues from 'src/product-license/models/ProductLicenseUpdateValues';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';

const licenseProductBaseURL = '/product-licenses';

interface ProductLicensesService {
  getProductLicenses: (
    productLicensesParams: ProductLicensesQueryParams
  ) => Promise<GetAllDetails<ProductLicenses>>;
  addNewProductLicense: (
    values: ProductLicensesFormValues
  ) => Promise<ProductLicensesResponse>;
  updateProductLicense: (
    id: number,
    values: ProductLicenseUpdateValues
  ) => Promise<ProductLicenseUpdateResponse>;
  removeProductLicense: (id: number) => Promise<ProductLicenseRemoveResponse>;
}

export default function useProductLicenseService(): ProductLicensesService {
  const { post, get, patch, remove } = useApiService();

  const getProductLicenses = useCallback(
    async (productLicensesParams: ProductLicensesQueryParams) => {
      const params = toProductLicensesQuery(productLicensesParams);

      const response = await get<
        ProductLicensesResponse[],
        ProductLicensesQuery
      >(`${licenseProductBaseURL}`, params);
      const data = response.data.map(toProductLicenses);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const addNewProductLicense = useCallback(
    async (values: ProductLicensesFormValues) => {
      const requestBody = toProductLicenseAddRequest(values);
      const response = await post<
        ProductLicensesRequestValues,
        ProductLicensesResponse
      >(`${licenseProductBaseURL}`, requestBody);
      return response.data;
    },
    [post]
  );

  const updateProductLicense = useCallback(
    async (id: number, values: ProductLicenseUpdateValues) => {
      const params = toProductLicenseUpdateParams(values);
      const response = await patch<
        ProductLicenseUpdateParams,
        ProductLicenseUpdateResponse
      >(`${licenseProductBaseURL}/${id}`, params);

      return response.data;
    },
    [patch]
  );

  const removeProductLicense = useCallback(
    async (id: number) => {
      const response = await remove<ProductLicenseRemoveResponse>(
        `${licenseProductBaseURL}/${id}`
      );
      return response.data;
    },
    [remove]
  );

  return {
    getProductLicenses,
    addNewProductLicense,
    updateProductLicense,
    removeProductLicense,
  };
}
