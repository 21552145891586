import type ProductChain from 'src/lots/models/ProductChain';
import type ProductChainResponse from 'src/lots/models/ProductChainResponse';

export default function toProductChain(
  data: ProductChainResponse
): ProductChain {
  const { id, state, permanent_position } = data;

  return {
    id,
    state,
    permanentPosition: permanent_position,
  };
}
