import type Product from 'src/lots/models/Product';
import type ProductResponse from 'src/lots/models/ProductResponse';
import toMasterKey from 'src/managers/mappers/toMasterKey';
import toConcreteProductError from './toConcreteProductError';
import toProductBarrier from './toProductBarrier';
import toProductBollard from './toProductBollard';
import toProductBrain from './toProductBrain';
import toProductChain from './toProductChain';
import toProductsGate from './toProductGate';

export default function toProduct(data: ProductResponse): Product {
  const {
    id,
    device_id,
    product_type_id,
    zone_id,
    name,
    status,
    firmware_version,
    battery_status,
    power_type,
    barrier,
    master_key,
    gate,
    chain,
    bollard,
    product_error,
    product_error_id,
    rssi,
    updated_at,
    brain,
  } = data;

  return {
    id,
    deviceId: device_id,
    productTypeId: product_type_id,
    zoneId: zone_id,
    name,
    status,
    firmwareVersion: firmware_version,
    batteryStatus: battery_status,
    productErrorId: product_error_id,
    rssi,
    powerType: power_type,
    updatedAt: updated_at,
    productError: product_error ? toConcreteProductError(product_error) : null,
    barrier: barrier ? toProductBarrier(barrier) : undefined,
    gate: gate ? toProductsGate(gate) : undefined,
    chain: chain ? toProductChain(chain) : undefined,
    bollard: bollard ? toProductBollard(bollard) : undefined,
    masterKey: master_key ? toMasterKey(master_key) : undefined,
    brain: brain ? toProductBrain(brain) : undefined,
  };
}
