import React from 'react';
import type LprCameraStatus from 'src/anpr/constants/LprCameraStatus';
import SystemControlButton from 'src/shared/components/SystemControlButton';
import DetailsColumn from '../../products/components/DetailsColumn';
import DetailsRow from '../../products/components/DetailsRow';

interface CameraSystemControlFormProps {
  onClick: () => void;
  status?: LprCameraStatus;
  deviceInProgress: boolean;
}

export default function CameraSystemControlForm(
  props: CameraSystemControlFormProps
) {
  const { onClick, status, deviceInProgress } = props;

  return (
    <DetailsRow wider addMargin>
      <DetailsColumn>
        <SystemControlButton
          onClick={onClick}
          name='Reboot camera'
          info='The camera will turn off and then turn on again. It might take a few minutes for the camera to connect to the network.'
          status={status}
          deviceInProgress={deviceInProgress}
        />
      </DetailsColumn>
    </DetailsRow>
  );
}
