import React from 'react';
import Color from 'src/shared/constants/Color';
import styled from 'styled-components';

function VerticalLine() {
  return <OuterLine />;
}
export default VerticalLine;

const OuterLine = styled.div`
  width: 5px;
  height: 30px;
  margin: auto;
  position: relative;
  overflow: hidden;
  background: ${Color.PRIMARY_BRAND};
`;
