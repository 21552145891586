import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Color from '../constants/Color';
import PaginationIcon from '../constants/PaginationIcon';
import type Meta from '../models/Meta';

interface Props {
  meta: Meta;
  getData: (page: number) => void;
  pageRangeDisplay?: number;
}

const generatePagesArray = (from: number, to: number) => {
  let i = from;
  const range = [];

  for (i = from; i <= to; i++) {
    range.push(i);
  }

  return range;
};

const DEFAULT_PAGE_RANGE_DISPLAY = 10;

export default function Pagination(props: Props) {
  const { meta, getData, pageRangeDisplay } = props;
  const { currentPage, lastPage, perPage, total } = meta;
  const [displayPages, setDisplayPages] = useState<number[]>([]);

  const displayPageRange = pageRangeDisplay || DEFAULT_PAGE_RANGE_DISPLAY;
  const leftOffset = Math.floor(displayPageRange / 2);
  const rightOffset = Math.round(displayPageRange / 2 - 1);
  const centralPageInFirstPageRange = Math.floor(displayPageRange / 2 + 1);

  const generatePagesDisplay = useCallback(
    (currentPage: number) => {
      if (lastPage <= displayPageRange) {
        setDisplayPages(generatePagesArray(1, lastPage));
        return;
      }

      if (currentPage <= centralPageInFirstPageRange) {
        setDisplayPages(generatePagesArray(1, displayPageRange));
        return;
      }

      if (currentPage + rightOffset >= lastPage) {
        setDisplayPages(
          generatePagesArray(lastPage - (displayPageRange - 1), lastPage)
        );
        return;
      }
      setDisplayPages(
        generatePagesArray(currentPage - leftOffset, currentPage + rightOffset)
      );
    },
    [
      lastPage,
      centralPageInFirstPageRange,
      leftOffset,
      rightOffset,
      displayPageRange,
    ]
  );

  useEffect(() => {
    generatePagesDisplay(currentPage);
  }, [generatePagesDisplay, currentPage]);

  const onPreviousPageHandler = useCallback(() => {
    if (currentPage === 1) return;
    getData(currentPage - 1);
  }, [currentPage, getData]);

  const onNextPageHandler = useCallback(() => {
    if (currentPage === lastPage) return;
    getData(currentPage + 1);
  }, [currentPage, lastPage, getData]);

  const onFirstPageHandler = useCallback(() => {
    if (currentPage === 1) return;
    getData(1);
  }, [currentPage, getData]);

  const onLastPageHandler = useCallback(() => {
    if (currentPage === lastPage) return;
    getData(lastPage);
  }, [currentPage, lastPage, getData]);

  return (
    <StyledDiv>
      <StyledUl>
        <StyledLi>
          <StyledButton
            disable={currentPage === 1}
            onClick={onFirstPageHandler}
          >
            <i className={PaginationIcon.FIRST} />
          </StyledButton>
        </StyledLi>
        <StyledLi>
          <StyledButton
            disable={currentPage === 1}
            onClick={onPreviousPageHandler}
          >
            <i className={PaginationIcon.PREV} />
          </StyledButton>
        </StyledLi>
        {displayPages.map((displayPage) => {
          const onJumpPage = () => {
            getData(displayPage);
          };

          return (
            <StyledLi key={displayPage}>
              <StyledPage
                active={currentPage === displayPage}
                onClick={onJumpPage}
              >
                {displayPage}
              </StyledPage>
            </StyledLi>
          );
        })}
        <StyledLi>
          <StyledButton
            disable={currentPage === lastPage}
            onClick={onNextPageHandler}
          >
            <i className={PaginationIcon.NEXT} />
          </StyledButton>
        </StyledLi>
        <StyledLi>
          <StyledButton
            disable={currentPage === lastPage}
            onClick={onLastPageHandler}
          >
            <i className={PaginationIcon.LAST} />
          </StyledButton>
        </StyledLi>
      </StyledUl>
      <StyledP>
        {currentPage * perPage < total
          ? `${currentPage * perPage}/${total}`
          : `${total}/${total}`}
      </StyledP>
    </StyledDiv>
  );
}

const StyledPage = styled.button<{ active?: boolean }>`
  border: none;
  background: none;
  font-size: 18px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  padding: 5px 10px;
  margin: 2px;
  cursor: pointer;
  :focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 3px 6px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 2px 4px;
  }

  ${(props) => {
    const { active } = props;
    if (active) {
      return css`
        color: white;
        background: ${Color.PRIMARY_BRAND};
        outline: none;
        border-radius: 5px;

        :hover {
          background: ${Color.PRIMARY_HOVER};
        }
      `;
    }
    return css``;
  }}
`;

const StyledButton = styled.button<{ disable?: boolean }>`
  display: flex;
  align-items: center;
  background: none;
  font-size: 18px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  border: none;
  background: ${Color.PRIMARY_BRAND};
  color: white;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 5px;
  cursor: pointer;
  :focus {
    outline: none;
  }

  :hover {
    background: ${Color.PRIMARY_HOVER};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 2px 4px;
  }

  ${(props) => {
    const { disable } = props;
    if (disable) {
      return css`
        background: ${Color.SHADOW_DARK_TRANSPARENT};
        cursor: default;

        :hover {
          background: ${Color.SHADOW_DARK_TRANSPARENT};
        }
      `;
    }
    return css``;
  }}
`;

const StyledUl = styled.ul`
  display: flex;
  padding: 0;
  margin-left: 1%;
`;

const StyledLi = styled.li`
  display: flex;
`;

const StyledDiv = styled.div`
  margin: 1% auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 480px) {
    display: block;
  }
`;

const StyledP = styled.p`
  margin: auto 1%;
  font-size: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;

  @media (max-width: 480px) {
    font-size: 14px;
    margin-top: -3%;
  }
`;
