import React from 'react';
import type CheckedIcons from 'src/shared/constants/CheckedIcons';
import styled, { css } from 'styled-components';
import type ActionIcon from '../../constants/ActionIcon';
import type PaginationIcon from '../../constants/PaginationIcon';

interface IconProps {
  icon: ActionIcon | PaginationIcon | CheckedIcons;
  disabled?: boolean;
  alignRight?: boolean;
}

export default function Icon(props: IconProps) {
  const { icon, disabled, alignRight } = props;

  return (
    <StyledWrapper disabled={disabled} alignRight={alignRight}>
      <StyledIcon className={icon} />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ disabled?: boolean; alignRight?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        cursor: not-allowed;
      `;
    }

    return css`
      cursor: pointer;
    `;
  }}

  ${(props) => {
    const { alignRight } = props;

    if (alignRight) {
      return css`
        justify-content: flex-end;
      `;
    }

    return css`
      justify-content: center;
    `;
  }}
`;

const StyledIcon = styled.i`
  font-family: ParklioIcons;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
`;
