import SharedButton from 'src/shared/components/Button';
import styled, { css } from 'styled-components';
import Color from '../../shared/constants/Color';

const Button = styled(SharedButton)`
  ${(props) => {
    const { primary } = props;

    if (primary) {
      return css`
        color: ${Color.TEXT_LIGHTEST};
      `;
    }

    return '';
  }}
`;

export default Button;
