import { useCallback } from 'react';
import toQRCode from 'src/keysharing/mappers/toQRCode';
import toQRCodeCreateRequest from 'src/keysharing/mappers/toQRCodeCreateRequest';
import toQRCodeImgQuery from 'src/keysharing/mappers/toQRCodeImgQuery';
import toQRCodeQuery from 'src/keysharing/mappers/toQRCodeQuery';

import type QRCode from 'src/keysharing/models/QRCode';
import type QRCodeCreateRequest from 'src/keysharing/models/QRCodeCreateRequest';
import type QRCodeQuery from 'src/keysharing/models/QRCodeQuery';
import type QRCodeQueryParams from 'src/keysharing/models/QRCodeQueryParams';
import type QRCodeRemoveReponse from 'src/keysharing/models/QRCodeRemoveReponse';
import type QRCodeResponse from 'src/keysharing/models/QRCodeResponse';
import type QRCodeSharingFormValues from 'src/keysharing/models/QRCodes/QRCodeSharingFormValues';
import ContentType from 'src/shared/constants/ContentType';
import toMeta from 'src/shared/mappers/toMeta';
import type GetAllDetails from 'src/shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';

const qrCodeBaseURL = '/qr-codes';
interface QRCodesService {
  createQRCode: (
    productId: number,
    timezone: number,
    values: QRCodeSharingFormValues
  ) => Promise<QRCode>;

  createQRCodeImg: (
    productId: number,
    timezone: number,
    values: QRCodeSharingFormValues
  ) => Promise<string>;
  getQRCodeImg: (id: number, timezone: number) => Promise<string>;

  getAllQRCodes: (query: QRCodeQueryParams) => Promise<GetAllDetails<QRCode>>;

  removeQRCode: (id: number) => Promise<QRCodeRemoveReponse>;
}

export default function useQRCodeService(): QRCodesService {
  const { post, get, remove } = useApiService();
  const { postResponseImg } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: false,
    acceptImg: true,
  });
  const { getImg } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: false,
    acceptImg: true,
  });
  const createQRCode = useCallback(
    async (
      productId: number,
      timezone: number,
      values: QRCodeSharingFormValues
    ) => {
      const requestBody = toQRCodeCreateRequest(productId, timezone, values);

      const response = await post<QRCodeCreateRequest, QRCode>(
        `${qrCodeBaseURL}`,
        requestBody
      );
      return response.data;
    },
    [post]
  );

  const createQRCodeImg = useCallback(
    async (
      productId: number,
      timezone: number,
      values: QRCodeSharingFormValues
    ) => {
      const requestBody = toQRCodeCreateRequest(productId, timezone, values);

      const response = await postResponseImg<QRCodeCreateRequest>(
        `${qrCodeBaseURL}`,
        requestBody
      );
      return URL.createObjectURL(response.img);
    },
    [postResponseImg]
  );

  const getQRCodeImg = useCallback(
    async (qrId: number, timezone: number) => {
      const requestBody = toQRCodeImgQuery(timezone);
      const response = await getImg(`${qrCodeBaseURL}/${qrId}`, requestBody);

      return URL.createObjectURL(response.img);
    },
    [getImg]
  );

  const getAllQRCodes = useCallback(
    async (query: QRCodeQueryParams) => {
      const request = toQRCodeQuery(query);
      const response = await get<QRCodeResponse[], QRCodeQuery>(
        `${qrCodeBaseURL}`,
        request
      );
      const data = response.data.map(toQRCode);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const removeQRCode = useCallback(
    async (id: number) => {
      const response = await remove<QRCodeRemoveReponse>(
        `${qrCodeBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return {
    createQRCode,
    createQRCodeImg,
    getAllQRCodes,
    getQRCodeImg,
    removeQRCode,
  };
}
