import Color from 'src/shared/constants/Color';
import LotEntryDirections from 'src/shared/constants/LotEntryDirections';

export default function getLotEntryColor(
  relatedLotEntryId: number | null,
  direction: LotEntryDirections
) {
  if (!relatedLotEntryId && direction === LotEntryDirections.OUT)
    return Color.ERROR;
  if (direction === LotEntryDirections.OUT) return Color.TEXT_DARKER;
  if (direction === LotEntryDirections.IN) return Color.PRIMARY_BRAND;

  return Color.ERROR;
}
