import React from 'react';
import type ParkingPlace from 'src/detection/models/ParkingPlace';
import ParkingDetectionStatus from 'src/products/constants/ParkingDetectionStatus';
import Card from 'src/shared/components/Card';
import Table from 'src/shared/components/Table';
import { getDetectionStatusColor } from 'src/shared/utils/getDetectionStatusColor';

interface ParkingPlaceTableProps {
  parkingPlaces: ParkingPlace[];
  detectionStatus: ParkingDetectionStatus | undefined;
  isLoading: boolean;
  openEditModal: (data?: ParkingPlace) => void;
  userIsParklioInternal?: boolean;
}

const ParkingPlacesTable: React.FC<ParkingPlaceTableProps> = (props) => {
  const {
    parkingPlaces,
    detectionStatus,
    isLoading,
    openEditModal,
    userIsParklioInternal,
  } = props;
  return (
    <Card>
      <Table isLoading={isLoading}>
        <Table.Head>
          <Table.Row>
            {userIsParklioInternal && <Table.Header>ID</Table.Header>}
            <Table.Header>Name</Table.Header>
            {userIsParklioInternal && <Table.Header>UUID</Table.Header>}
            <Table.Header>Status</Table.Header>
            <Table.Header />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {parkingPlaces.map((item) => {
            const { id, name, uuid, occupancyStatus } = item;
            return (
              <Table.Row key={id}>
                {userIsParklioInternal && <Table.Cell>{id}</Table.Cell>}
                <Table.Cell primary action={openEditModal} data={item}>
                  {name}
                </Table.Cell>
                {userIsParklioInternal && <Table.Cell>{uuid}</Table.Cell>}
                <Table.Cell
                  occupancyColor={getDetectionStatusColor(
                    occupancyStatus,
                    detectionStatus === ParkingDetectionStatus.ONLINE
                  )}
                >
                  {occupancyStatus.toUpperCase()}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Card>
  );
};
export default ParkingPlacesTable;
