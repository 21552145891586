import { useCallback } from 'react';
import useAuthContext from 'src/auth/hooks/useAuthContext';
import AccountRole from '../../shared/constants/AccountRole';
import type SessionData from '../../shared/models/SessionData';
import useApiService from '../../shared/services/useApiService';
import useSessionService from '../../shared/services/useSessionService';
import toLoginRequest from '../mappers/toLoginRequest';
import toSessionData from '../mappers/toSessionData';
import type LoginFormValues from '../models/LoginFormValues';
import type LoginRequest from '../models/LoginRequest';
import type LoginResponse from '../models/LoginResponse';

interface AuthService {
  logIn: (values: LoginFormValues) => Promise<SessionData>;
  logOut: () => void;
}

export default function useAuthService(): AuthService {
  const { setIsLoggedIn, setSessionData: setSessionDataState } =
    useAuthContext();
  const { setSessionData, removeSessionData } = useSessionService();
  const { post } = useApiService({
    withAuth: false,
  });

  const logIn = useCallback(
    async (values: LoginFormValues): Promise<SessionData> => {
      const requestBody = toLoginRequest(values);

      const response = await post<LoginRequest, LoginResponse>(
        '/auth/account/login',
        requestBody
      );
      if (response.data.account_role_id === AccountRole.CONNECT) {
        response.data.token = '';
      }

      const sessionData = toSessionData(response.data);

      setIsLoggedIn(true);
      setSessionData(sessionData);
      setSessionDataState(sessionData);

      return sessionData;
    },
    [setIsLoggedIn, post, setSessionData, setSessionDataState]
  );

  const logOut = useCallback(() => {
    setIsLoggedIn(false);
    setSessionDataState(undefined);
    removeSessionData();
  }, [removeSessionData, setIsLoggedIn, setSessionDataState]);

  return { logIn, logOut };
}
