import { useCallback } from 'react';
import toDetectGatewayCreateRequest from 'src/detection/mappers/toDetectGatewayCreateRequest';
import toDetectGatewayQuery from 'src/detection/mappers/toDetectGatewayQuery';
import toDetectGatewayUpdateParams from 'src/detection/mappers/toDetectGatewayUpdateParams';
import type DetectGateway from 'src/detection/models/DetectGateway';
import type DetectGatewayFormValues from 'src/detection/models/DetectGatewayFormValues';
import type DetectGatewayQuery from 'src/detection/models/DetectGatewayQuery';
import type DetectGatewayQueryParams from 'src/detection/models/DetectGatewayQueryParams';
import type DetectGatewayRemoveResponse from 'src/detection/models/DetectGatewayRemoveResponse';
import type DetectGatewayRequest from 'src/detection/models/DetectGatewayRequest';
import type DetectGatewayResponse from 'src/detection/models/DetectGatewayResponse';
import type DetectGatewayUpdateParams from 'src/detection/models/DetectGatewayUpdateParams';
import type DetectGatewayUpdateValues from 'src/detection/models/DetectGatewayUpdateValues';
import ContentType from 'src/shared/constants/ContentType';
import toMeta from 'src/shared/mappers/toMeta';
import type GetAllDetails from 'src/shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toDetectGateway from '../mappers/toDetectGateway';

const detectionGatewayBaseURL = '/parking-detection-gateways';

interface DetectGatewayService {
  findAllDetectGateways: (
    queryParams: DetectGatewayQueryParams
  ) => Promise<GetAllDetails<DetectGateway>>;
  findAllDetectGatewaysParklioInternal: (
    queryParams: DetectGatewayQueryParams
  ) => Promise<GetAllDetails<DetectGateway>>;
  findOneDetectGateway: (id: number) => Promise<DetectGateway>;
  updateDetectGateway: (
    id: number,
    values: DetectGatewayUpdateValues
  ) => Promise<DetectGateway>;
  createDetectGateway: (
    values: DetectGatewayFormValues,
    lotId: number
  ) => Promise<DetectGateway>;
  removeDetectGateway: (id: number) => Promise<DetectGatewayRemoveResponse>;
}

export default function useDetectGatewayService(): DetectGatewayService {
  const { get, post, patch, remove } = useApiService();

  const { get: getParklioInternal } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const findAllDetectGateways = useCallback(
    async (queryParams: DetectGatewayQueryParams) => {
      const query = toDetectGatewayQuery(queryParams);
      const response = await get<DetectGatewayResponse[], DetectGatewayQuery>(
        `${detectionGatewayBaseURL}`,
        query
      );

      const data = response.data.map(toDetectGateway);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findAllDetectGatewaysParklioInternal = useCallback(
    async (queryParams: DetectGatewayQueryParams) => {
      const query = toDetectGatewayQuery(queryParams);
      const response = await getParklioInternal<
        DetectGatewayResponse[],
        DetectGatewayQuery
      >(`${detectionGatewayBaseURL}`, query);

      const data = response.data.map(toDetectGateway);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [getParklioInternal]
  );

  const findOneDetectGateway = useCallback(
    async (id: number) => {
      const response = await get<DetectGatewayResponse>(
        `${detectionGatewayBaseURL}/${id}`
      );

      const data = toDetectGateway(response.data);

      return data;
    },
    [get]
  );

  const createDetectGateway = useCallback(
    async (values: DetectGatewayFormValues, lotId: number) => {
      const requestBody = toDetectGatewayCreateRequest(values, lotId);
      const response = await post<DetectGatewayRequest, DetectGatewayResponse>(
        `${detectionGatewayBaseURL}`,
        requestBody
      );

      return toDetectGateway(response.data);
    },
    [post]
  );

  const updateDetectGateway = useCallback(
    async (id: number, values: DetectGatewayUpdateValues) => {
      const params = toDetectGatewayUpdateParams(values);
      const response = await patch<
        DetectGatewayUpdateParams,
        DetectGatewayResponse
      >(`${detectionGatewayBaseURL}/${id}`, params);

      return toDetectGateway(response.data);
    },
    [patch]
  );

  const removeDetectGateway = useCallback(
    async (id: number) => {
      const response = await remove<DetectGatewayRemoveResponse>(
        `${detectionGatewayBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return {
    findAllDetectGateways,
    findAllDetectGatewaysParklioInternal,
    findOneDetectGateway,
    updateDetectGateway,
    createDetectGateway,
    removeDetectGateway,
  };
}
